#underlying .uppertbl{
    width:100%;
    height:100%;
    table-layout: fixed;
    margin-bottom: 20px;
    margin-top: 10px;
}
#underlying .uppertbl-col-bid{
    border-right:10px solid white;
    vertical-align: middle;
    width:50%;    
}
#underlying .uppertbl-col-chglbl{
    border-bottom:10px solid white;
    width:25%;
}
#underlying .uppertbl-col-chgval{
    border-bottom:10px solid white;
    text-align:right;
    width:25%;    
}
#underlying .uppertbl-col-pchgval{
    text-align:right
}
#underlying .uppertbl td{
    padding:15px;

}
#underlying .loadingindicator {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("../../../assets/images/bullet/loading.gif") center center no-repeat;
    background-color: white;
    z-index: 990;
    opacity: 0.6;
}
#underlying .bidprice.val{
    font-size:60px;
    line-height:40px;
}
body.eng #underlying .bidprice.val{
    font-size: 40px;
}
#underlying #srtable{
    border-bottom:1px #CCCCCC dotted;
}
#underlying #srtable>table{
    table-layout: fixed;
    margin-bottom: 0;
}
#underlying #srtable .srtable-leftlbl{
    width:30%;
}
#underlying #srtable .srtable-leftval{
    width:20%;
    border-right:20px solid #fff;
}
#underlying #srtable .srtable-rightlbl{
    width:30%;
    border-left:20px solid #fff;
}
#underlying #srtable .srtable-rightval{
    width:20%;
}
#underlying #pstable,
#underlying #indtable{
    display:table;width:100%;margin-bottom:20px;
}
#underlying #pstable{
    margin-bottom:40px;
}
#underlying #indtable table{
    table-layout: fixed;
}
#underlying #indtable table th{
    font-weight: normal;
    color: #0675C2;
}
#underlying #indtable table.visible-md th{
    width:25%
}
#underlying #chartbox{
    margin-bottom:35px;
}
#underlying .ctab{
    width:100%;
    /*margin-bottom: 20px;*/
}
#underlying .ctab-item{
    text-align: center;
    width:16.5%;
    border: 2px solid #FECC0A !important;
    height: 33px;
    cursor: pointer;
}
#underlying .ctab-item.active{
    background-color: #FECC0A; 
}
#underlying .ctab-item-space{
    width:12px;
}
#underlying .chart-legend{
    margin-bottom: 20px;
}
#underlying .chart-legend-left,
#underlying .chart-legend-right{
    text-align: center;
}
#underlying .chart-legend-bar{
    display: inline-block;
    position: relative;
    width: 20px;
    height: 3px;
    bottom: 4px;
    margin-left: 5px;
}
#underlying .chart-legend-bar.warrant{
    background-color: #F7941E;
}
#underlying .chart-legend-bar.underlying{
    background-color: #097CCF;
}
#underlying .chart-legend-tbl{
    width: 200px;
    margin: 0 auto;
}
#underlying .nochart{
    width:100%;
    height:100%;
    border: 1px solid #CCC;
    position:relative;
}
#underlying .nochart-txt{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
#underlying #underlying-disclaimer table{
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 15px;
}
#underlying #underlying-disclaimer table tr>td:first-child{
    white-space: nowrap;
}
#underlying #defination{
    margin-top:10px;  
}
#underlying #defination .panel-heading{
    background-color: #666666;
    padding: 4px 10px;
}
#underlying #defination .panel-title{
    font-size: 21px;
}
#underlying #defination .glyphicon{
    color: white;
}
#underlying #defination #collapse_dwcompare3{
    font-size: 18px;
    padding: 10px;
}
#underlying #defination .table{
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 15px;
}
#underlying #defination .table td{
    padding-left: 0;
}
#underlying #defination .table tr td:first-child{
    white-space: nowrap;
}
#dropdown-dwterms{
    margin-top: 10px;
    margin-bottom: 30px;
}
#underlying .ta_panel{
    margin-top: 20px;
}
#underlying .ta_panel .row{
    margin-bottom: 10px;
    margin-left: -10px;
    margin-right: -10px;
}
#underlying .MAtab .ctab-item{
    width: 20%;
}
#underlying .typetab .ctab-item{
    width: 34%;
}
#underlying .txtbox{
    width:100%;
    text-align: center;
}
#underlying .txtcheckbox{
    width:100%;
}
#underlying .txtcheckbox .checkbox{
    position: absolute;
    right: 12px;
    top: 16px;
    margin-top: -8px;
}
#underlying .txttbl_lbl{
    white-space: nowrap;
}
#underlying .txttbl_input{
    padding-left: 10px;
    padding-right: 10px;
}
#underlying .txttbl_input.last{
    padding-right: 0px
}
#underlying #dwtable .table th,
#underlying #dwtable .table td{
    font-size: 15px;
}
#underlying .highcharts-bindings-wrapper * {
    box-sizing: content-box;
}
#underlying .highcharts-menu-item{
    font-size: 13px !important;
    font-family: 'MCQGlobal_A_Lt';
}
/*******************************************************************************
 underlying section combobox
*******************************************************************************/
#underlying .combobox {
    position: relative;
}
#underlying .combobox-toggle {
    position: absolute;
    display:block;
    top: 0;
    bottom: 0;
    right:0;
    width: 36px;
    padding: 0;
    cursor: pointer;
}
#underlying .combobox-toggle, 
#underlying .combobox-toggle:focus, 
#underlying .combobox-toggle:hover, 
#underlying .combobox-toggle:visited{
    color: #000;
}
#underlying .combobox-input {
    margin: 0;
    padding: 5px 10px;
    width:100%;
    text-align: center;
}
#underlying .combobox-toggle-icon{
    margin-top: 10px;
    margin-right: 12px;
}
#underlying .ui-widget{
    font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
    font-size: 14px;
}
#underlying .ui-menu .ui-menu-item{
    text-align: center;
}
#underlying .ui-menu .ui-menu-item a.ui-state-hover{
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5; 
    border-color:#f5f5f5;
}
#underlying .ui-menu .ui-menu-item a:visited,
#underlying .ui-menu .ui-menu-item a:focus,
#underlying .ui-menu .ui-menu-item a:hover{
    color: #262626
}
#underlying .ui-autocomplete.ui-menu{
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
}
#underlying #dropdown-underlying{
    margin-top: 10px;
    margin-bottom: 30px;
}
#underlying #chartbox .highcharts-axis-resizer {
    stroke: #eee;
}
#underlying #chartbox .highcharts-axis-resizer:hover {
    stroke: #ccc;
}
#underlying #chartbox .highcharts-point-up {
    fill: green;
    stroke: green;
}
#underlying #chartbox .highcharts-point-down {
    fill: red;
    stroke: red;
}
#underlying #chartbox .highcharts-macd-series .highcharts-point{
    fill: red;
    stroke: red;
}
#underlying #chartbox .highcharts-macd-series .highcharts-negative{
    fill: blue;
    stroke: blue;
}
#underlying #dwtblgroup #calltable, 
#underlying #dwtblgroup #puttable{
    table-layout: fixed;
}
#underlying #dwtblgroup .top-table{
    margin-bottom: 40px;
}
#underlying #dwtblgroup table tbody tr td img{
    width: 18px;
    margin-left: 5px;
}
#underlyingg #dwtblgroup table{
    text-align: center !important;
}
#underlying #dwtblgroup table tr td{
    text-align: center !important;
}
#underlying #dwtblgroup table tr th{
    text-align: center !important;
}
body.eng #underlying #dwtblgroup table tr td{
    line-height: 1.42857143;
}
body.eng #underlying #dwtblgroup table tr th{
    font-family: "MCQGlobal_A_Md";
    line-height: normal;
}
/*******************************************************************************
 dwterms section combobox
*******************************************************************************/
#underlying .combobox {
    position: relative;
}
#underlying .combobox-toggle {
    position: absolute;
    display:block;
    top: 0;
    bottom: 0;
    right:0;
    width: 36px;
    padding: 0;
    cursor: pointer;
}
#underlying .combobox-toggle, 
#underlying .combobox-toggle:focus, 
#underlying .combobox-toggle:hover, 
#underlying .combobox-toggle:visited{
    color: #000;
}
#underlying .combobox-input {
    margin: 0;
    padding: 2.6px 10px;
    width:100%;
    text-align: center;
}
#underlying .combobox-toggle-icon{
    margin-top: 10px;
    margin-right: 12px;
}
#underlying .ui-widget{
    font-family: 'DBHeaventLight','DBHeavent';
    font-size: 21px;
}
#underlying .ui-menu .ui-menu-item{
    text-align: center;
}
#underlying .ui-menu .ui-menu-item a.ui-state-hover{
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5; 
    border-color:#f5f5f5;
}
#underlying .ui-menu .ui-menu-item a:visited,
#underlying .ui-menu .ui-menu-item a:focus,
#underlying .ui-menu .ui-menu-item a:hover{
    color: #262626
}
#underlying .ui-autocomplete.ui-menu{
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
}
#underlying #chartbox #rsiValue,
#underlying #chartbox #bollingerValue{
    width: 100%;
    text-align: center;
}
#underlying #chartbox .txttbl_input input {
    width: 100%;
    text-align: center;
}
#underlying #defination .panel-heading{
    background-color: #666666;
    padding: 2px 10px;
}
#underlying #defination .panel-title{
    font-size: 14px;
}
#underlying #defination .glyphicon{
    color: white;
}
#underlying #dwtblgroup .__react_component_tooltip{
    padding: 5px 9px;
    opacity: 1;
}
#underlying #dwtblgroup p.tooltip-content{
    margin: 0px;
    padding: 0px;
    font-size: 21px;
    line-height: 1;
    font-family: 'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif;
}
body.eng #underlying #dwtblgroup p.tooltip-content{
    font-size: 14px;
    line-height: normal;
    font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
}


#underlying .select_align_center {
    text-align: center;
}
#underlying .select_align_center input {
    text-align: center;
    display: flex;
    min-width: 100% !important;
    width: 100% !important;
}
/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (min-width: 992px) and (max-width: 1199px) {
    #underlying .container-fluid > .row > .col-md-8 > .section{
        padding: 40px;
    }
    #underlying .container-fluid > .row > .col-md-4 > .section{
        padding: 20px;
    }
}

@media only screen and (max-width: 991px){
    #underlying #defination .panel-heading button{
        border: none;
        background-color: transparent;
        width: 100%;
        text-align: center;
        padding: 0px;
        position: relative;
    }
    #underlying #defination .panel-heading button > span::before{
        display: none;
    }
    #underlying #defination .panel-title {
        font-size: 21px;
        position: relative;
    }
    body.eng #underlying #defination .panel-title{
        font-size: 14px;
    }
    #underlying #defination .panel-heading button img {
        width: 20px;
        position: absolute;
        top: 3px;
        right: 0px;
    }
    body.eng #underlying #defination .table td{
        font-size: 12px;
        line-height: 1.42857143;
    }
}

@media screen and (max-width: 767px){
    #underlying h3{
        font-size: 24px;
        margin-bottom: 0px;
        margin-top: 10px;
    }
    #underlying .uppertbl{
        margin-top:0;
    }
    #underlying .uppertbl td{
        padding:4px 10px;   
    }
    #underlying .uppertbl-col-bid{
        border-right:0;
        width:40%;
    }
    #underlying .uppertbl-col-bid .lbl, 
    #underlying .uppertbl-col-chglbl, 
    #underlying .uppertbl-col-pchglbl{
        font-size: 18px;
    }
    #underlying .uppertbl-col-bid .val{
        font-size: 21px;
        line-height: normal;
    }
    #underlying .uppertbl-col-chgval, 
    #underlying .uppertbl-col-pchgval{
        font-size: 24px;
    }
    #underlying .uppertbl-col-chglbl{
        border-bottom:0;
        width:35%;    
    }
    #underlying .uppertbl-col-chgval{
        border-bottom:0;
        width:25%;
    }
    #underlying #srtable>table{
        font-size: 18px;
    }
    #underlying #srtable .srtable-leftlbl{
        width:15%;
    }
    #underlying #srtable .srtable-leftval{
        border-left:0;
    }
    #underlying #srtable .srtable-rightlbl{
        border-right:0;
    }
    #underlying  #indtable table{
        font-size: 17px;
    }
    #underlying #indtable{
        margin-bottom:0;
    }
    #underlying #indtable table{
        margin-bottom:0;
    }
    #underlying #underlying-disclaimer table{
        font-size: 17px;
    }
    #underlying p.small{
        font-size: 17px;
    }
    body.eng #underlying p.small{
        font-size: 11px;
    }
    #underlying #defination #collapse_dwcompare3{
        font-size: 17px;
    }
    #underlying #ticker{
        margin-bottom: 0px;
    }
    #underlying #defination .table td{
        font-size: 17px;
    }
    #underlying #defination .panel-heading{
        padding: 4px 10px;
    }
    #underlying #dwtblgroup table tr td{
        font-size: 21px;
    }
    #underlying #dwtblgroup table tr th{
        font-size: 21px;
    }
    body.eng #underlying .uppertbl-col-bid .lbl, 
    body.eng #underlying .uppertbl-col-chglbl, 
    body.eng #underlying .uppertbl-col-pchglbl {
        font-size: 12px;
    }
    body.eng #underlying .bidprice.val {
        font-size: 14px;
    }
    body.eng #underlying .uppertbl-col-chgval, 
    body.eng #underlying .uppertbl-col-pchgval{
        font-size: 16px;
    }
    body.eng #underlying #defination .table td {
        font-size: 11px;
        line-height: 1.42857143;
    }
    body.eng #underlying #defination .panel-heading button img{
        width: 14px;
    }
    body.eng #underlying #dwtblgroup table tr td,
    body.eng #underlying #dwtblgroup table tr th {
        font-size: 14px;
    }
    #underlying #dwtblgroup p.tooltip-content{
        font-size: 17px;
    }
    body.eng #underlying #dwtblgroup p.tooltip-content{
        font-size: 11px;
    }
}
