
#indexdw .tablearea{
    background-color: white;
    margin-left: -40px;
    margin-right: -40px;
}
#indexdw .tablearea .td-text{
    padding: 50px;
    width: 45.13%;
}
#indexdw .tablearea .setdw-table th, 
#indexdw .tablearea .setdw-table td{
    text-align: center;
}
body.eng #indexdw .tablearea .setdw-table th,
body.eng #indexdw .tablearea .setdw-table td.text-bold{
    font-family: "MCQGlobal_A_Md";
    line-height: normal;
}
body.eng #indexdw .tablearea .setdw-table td,
body.eng #indexdw .tablearea .setdw-table td.text-bold{
    line-height: 1.42857143;
}
#indexdw .tablearea img{
    width:100%;
}
#indexdw .inner_title{
    font-size: 66px;
    line-height: 1;
    padding-bottom: 15px;
}
body.eng #indexdw .inner_title{
    font-size: 44px;
    line-height: normal;
}

#indexdw .learnmore{
    position: relative;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 20px;
}
#indexdw .learnmore .learnmore-img{
    width: 100%;
}
#indexdw .learnmore .learmore-title{
    font-size: 66px;
    color: #FFFFFF;
    margin-bottom: 10px;
    text-transform: uppercase;
}
body.eng #indexdw .learnmore .learmore-title{
    font-size: 44px;
}
#indexdw .learnmore .learnmore-wrapper{
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
#indexdw .tablearea a{
    display: inline-block;
    height: 35px;
    width:100px;
    line-height: 35px;
    background-color: #666;
    border: none;
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #FECC0A; 
    text-align: center;
}
#indexdw .learnmore a{
    display: block;
    height: 33px;
    line-height: 33px;
    width:100px;
    background: inherit;
    background-color: rgba(254, 204, 10, 1);
    border: none;
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 24px;
    color: rgb(102, 102, 102);
    margin-left: auto;
    margin-right: auto;
}
body.eng #indexdw .learnmore a{
    font-size: 16px;
}
/* ============== */
@media screen and (min-width: 992px) and (max-width: 1199px) {
    #indexdw .container-fluid > .section{
        padding: 40px;
    }
}

@media screen and (max-width: 992px){
    #indexdw .tablearea{
        margin-left: -10px;
        margin-right: -10px;
    }
    #indexdw .tablearea .inner_title{
        font-size: 42px;
    }
    body.eng #indexdw .inner_title{
        font-size: 28px;
    }
    #indexdw .tablearea p, 
    #indexdw .tablearea ul{
        font-size: 21px;
    }
    body.eng #indexdw .tablearea p, 
    body.eng #indexdw .tablearea ul{
        font-size: 14px;
    }
    #indexdw .learnmore .learmore-title{
        font-size: 42px;
    }
    body.eng #indexdw .learnmore .learmore-title{
        font-size: 28px;
    }
}
@media screen and (max-width: 767px){
    #indexdw .tablearea .td-text{
        width:100%;
        padding: 20px 10px;
    }
    #indexdw .tablearea .altarea{
        background-color:#333;
    }
    #indexdw .tablearea .inner_title .lead{
        font-size: 63px;
    }
    #indexdw .tablearea .altarea p, #indexdw .tablearea .altarea ul, #indexdw .tablearea .altarea th, #indexdw .tablearea .altarea td{
        color: #fff;
    }
    #indexdw .tablearea .altarea th, #indexdw .tablearea .altarea td{
        font-size: 17px;
    }
    #indexdw .tablearea .altarea .table-striped-inverse>tbody>tr:nth-of-type(even) {
        background-color: #666;
    }
    #indexdw .learnmore .learmore-title{
        font-size: 36px;
    }
    #indexdw .tablearea a{
        display: block;
        width:auto;
        height: 33px;
        background-color: #666;
        border: none;
        line-height: 33px;
        margin-top: 20px;
    }
    #indexdw .learnmore a{
        margin-left: 10px;
        margin-right: 10px;
        width: auto;
    }
    #indexdw .tablearea.first tr,
    #indexdw .tablearea.third tr{
        display: flex;
        flex-direction: column-reverse;
    }
    #indexdw .tablearea.second tr,
    #indexdw .tablearea.fourth tr,
    #indexdw .tablearea.fifth tr{
        display: flex;
        flex-direction: column;
    }
    #indexdw .tablearea.second tr td.td-text p{
        margin-bottom: 0px;
    }
    #indexdw .tablearea.altarea.fifth{
        background-color: #333;
    }
    #indexdw .tablearea.altarea.fifth p{
        color: #fff;
    }
    #indexdw .tablearea.altarea.fifth .setdw-table tr{
        display: table-row;
    }
    #indexdw .tablearea.altarea.fifth .setdw-table tr th,
    #indexdw .tablearea.altarea.fifth .setdw-table tr td{
        color: #fff;
    }
    #indexdw .tablearea.fifth tr > td.td-text:last-child{
        padding-top: 0px;
    }
    #indexdw .tablearea.fifth tr > td.td-text:first-child{
        padding-bottom: 0px;
    }
    #indexdw .tablearea.altarea.fifth .setdw-table tbody tr:nth-child(2) {
        background-color: #666;
    }
    #indexdw .tablearea.altarea.fifth .setdw-table tr td,
    #indexdw .tablearea.altarea.fifth .setdw-table tr th{
        font-size: 17px;
    }
    body.eng #indexdw .tablearea.altarea.fifth .setdw-table tr td,
    body.eng #indexdw .tablearea.altarea.fifth .setdw-table tr th{
        font-size: 11px;
    }
    body.eng #indexdw .learnmore .learmore-title{
        font-size: 24px;
    }
}