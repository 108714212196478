#dwtips .section-p{
    margin-bottom: 30px;
    min-height: inherit;
}
#dwtips .newstbl-htxt{
    font-family: "DBHeaventLight";
    height: 30px;
    line-height: 30px;
    background-color: #CCC;
    padding-left: 10px;
}
#dwtips .newstbl-row{
    position:relative;
    margin-top: 10px;
    background-color: rgb(245, 246, 247);
}
#dwtips .newstbl-content{
    margin-bottom:40px;
}
#dwtips .newstbl-content .newstbl-row:last-child{
    border-bottom:1px solid #FFF;
}
#dwtips .newstbl-col-right{
    margin-left:20px;
    margin-right: 20px;
    display: flex;
}
#dwtips .newstbl-col-left{
    position:absolute;
    top:26px;
    left:0;
    text-align: left;
}
#dwtips .newstbl-col-left span{
    font-family: "DBHeaventMedium";
    color: #0675C2;
}
#dwtips .newstbl-col-dt{
    margin:0;
    font-size: 24px;
    padding-top:8px;
    padding-bottom:8px;
    display: inline-block;
}
body.eng #dwtips .newstbl-col-dt{
    font-size: 16px;
    margin-bottom: 6px;
}
#dwtips .newstbl-col-dd{
    margin-bottom: 30px;
    max-height: 76px;
    overflow: hidden;
}
#dwtips .newstbl-col-rm{
    text-align: right;
    margin-left: auto;
    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
}
#dwtips .newstbl-col-rm img{
    width: 50%;
}
#dwtips .newstbl-col-rm .btn{
    width: 150px;
}
#dwtips .newstbl-bttm{
    position:relative;
    padding-left:40px;
    padding-right:40px;
    padding-top:8px;
    padding-bottom:8px;
    height: 32px;
    margin-bottom: 20px;
}
#dwtips .newstbl-cal-icon.input-group{
    position: absolute;
    top:0px;
    right: 0px;
    display: inline-block;
    border-collapse: inherit;
}
#dwtips .carousel-root.ad-section{
    margin-top: 20px;
    width: 100%;
    height: auto;
    padding: 0px;
}
#dwtips .ad-section .slider-wrapper {
    background: #000000;
}
#dwtips .ad-section .slider-wrapper li.slide iframe {
    width: 100%;
    margin: 0px;
    min-height: 190px;
    position: relative;
}
#dwtips .ad-section .slider-wrapper li.slide a{
    height: 100%;
}
#dwtips .ad-section .carousel .carousel-status{
    display: none;
}
#dwtips .ad-section .carousel .control-dots .dot{
    width: 15px;
    height: 15px;
    box-shadow: none;
    background-color: #000;
    border: solid 1px #fff;
    opacity: 1;
    margin: 0px 5px;
}
#dwtips .ad-section .carousel .control-dots .dot.selected{
    background-color: #FECC0A;
    border-color: #FECC0A;
}
#dwtips .input-group-addon{
    text-align:right;
    padding:0;
    color: inherit;
    background-color: inherit;
    border: 0;
    border-radius: inherit;
    display:inline-block;
}
#dwtips .newstbl .swiper-container {
    width: 100%;
    min-height: 300px;
} 
#dwtips .newstbl .swiper-pagination{
    position:inherit;
}
#dwtips .tips-table{
    display: flex;
    margin-bottom: 20px;
    background-color: #000000;
}
#dwtips .tips-img{
    width: 60%;
}
#dwtips .tips-img img{
    width: 100%;
}
#dwtips .tips-text{
    width: 40%;
    padding: 20px;
    margin: auto;
}
#dwtips .tips-text p{
    color: #FFFFFF;
    font-size: 34px;
    font-weight: bold;
}
body.eng #dwtips .tips-text p{
    font-size: 28px;
}
#dwtips .tips-text a {
    min-width: auto;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    #dwtips .container-fluid > .row > .col-md-8 > .section{
        padding: 40px;
    }
    #dwtips .container-fluid > .row > .col-md-4 > .section{
        padding: 20px;
    }
}
@media screen and (max-width:767px){
    #dwtips .newstbl-col-dd{
        max-height: 68px;
    }
    #dwtips .tips-text p{
        font-size: 23px;
    }
    #dwtips .tips-text{
        padding: 10px;
    }
    body.eng #dwtips .page-header h1{
        font-size: 18px;
    }
    body.eng #dwtips .tips-text p{
        font-size: 15px;
    }
}