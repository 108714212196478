.adverTiseMent.custom{
    padding: 0;
    background-color: #000;
    margin-top: 20px;
    width: 100%;
    position: relative;
}
.adverTiseMent.custom button.slick-arrow{
    display: none !important;
}
.adverTiseMent.custom ul.slick-dots{
    bottom: 15px;
}
.adverTiseMent.custom .slick-slide a:focus{
    outline: none;
}
.adverTiseMent.custom .slick-slide img{
    max-width: 100%;
    max-height: 280px;
    margin: 0px auto;
}
.adverTiseMent.custom ul.slick-dots li{
    width: 16px;
    height: 16px;
    overflow: hidden;
}
.adverTiseMent.custom ul.slick-dots li button{
    padding: 0px;
    width: 100%;
    height: 100%;
    border: solid 1px #ddd;
    border-radius: 50%;
    background-color: transparent;
}
.adverTiseMent.custom ul.slick-dots li.slick-active button{
    border-color: #FECC0A;
    background-color: #FECC0A;
}
.adverTiseMent.custom ul.slick-dots li button::before{
    display: none;
}

.adverTiseMent.custom .videoplayer {
    margin-top: 0%;
    margin-bottom: 2%;
}
.adverTiseMent.custom .videoplayer .videoplayer-container{
    height: 0;
    width: 100%;
    padding-bottom: 50%;
    overflow: hidden;
    position: relative;
}
.adverTiseMent.custom .videoplayer iframe{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}