#dwturnover .tblh{
    position:relative;
    margin-top:20px;
    height:40px;
    margin-bottom:10px;
}
#dwturnover .tblh-left{
    position: absolute;
    left:0;
    bottom:0;
}
#dwturnover .tblh-right{
    position:absolute;
    right:0;
    bottom:0;
}
#dwturnover .carousel-root.ad-section{
    margin-top: 20px;
    width: 100%;
    overflow: hidden;
    padding: 0px;
}
#dwturnover .table-striped > tbody > tr:nth-of-type(odd){
    --bs-table-striped-bg: transparent;
}
#dwturnover table{
    border-collapse: collapse;
}
#dwturnover #totalTbl table {
    table-layout: fixed;
}
#dwturnover table tbody{
    border-top: none;
}
#dwturnover table td,#dwturnover table th{
    padding: 8px;
    border: none;
    font-size: 16px;
    line-height: 1;
}
#dwturnover table th{
    text-align: center;
    font-family: "DBHeaventMedium" !important;
    line-height: 1;
}
#dwturnover table td{
    line-height: 23px;
}
#dwturnover  .chartbox-market-turn-over .tblh{
    margin-bottom: 20px;
}
#dwturnover .tblh h2.small{
    font-family: 'DBHeaventMedium';
    font-size: 27px;
    font-weight: normal;
    margin: 0;
}
body.eng #dwturnover .tblh h2.small{
    font-size: 18px;
    font-family: 'MCQGlobal_A_Md';
}
#dwturnover .lastupdatebox{
    font-size: 12px;
    float:right;
}
body.eng #dwturnover .lastupdatebox{
    font-size: 12px;
}
#dwturnover .tbl th, 
#dwturnover .tbl td{
    text-align: center;
    vertical-align: middle;
    padding: 8px;
    font-size: 24px;
    font-family: 'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif;
    line-height: 1;
}
body.eng #dwturnover .tbl th, 
body.eng #dwturnover .tbl td{
    font-size: 16px;
}
body.eng #dwturnover .tbl th{
    font-family: "MCQGlobal_A_Md" !important;
    line-height: normal;
}
body.eng #dwturnover .tbl td{
    font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
    line-height: 1.42857143;
}
#dwturnover .tbl tr td{
    white-space: nowrap;
}
#dwturnover .chartbox-market-turn-over h2.small{
    font-family: 'DBHeaventMedium';
    font-size: 27px;
    font-weight: normal;
    margin:0;
    text-align: center;
    margin-bottom: 10px;
}
body.eng #dwturnover .chartbox-market-turn-over h2.small{
    font-size: 18px;
    font-family: 'MCQGlobal_A_Md';
}
#dwturnover .chartbox-bars-market-turn-over{
    height:340px;
    width:100%;
}
#dwturnover .chartbox-market-turn-over .legend{
    text-align: center;
    padding: 30px
}
#dwturnover .chartbox-market-turn-over .legend-item{
    display: inline-block;
    position: relative;
}
#dwturnover .chartbox-market-turn-over .legend-item-box{
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0;
}
#dwturnover .chartbox-market-turn-over .legend-item-txt{
    white-space: nowrap;
    line-height: 18px;
    margin-left: 28px;
    margin-right: 20px;
    margin-bottom: 5px;
}
#dwturnover .chartbox-market-turn-over .legend-item-box.index{
    background-color: #DD5C10;
}
#dwturnover .chartbox-market-turn-over .legend-item-box.stock{
    background-color: #F7941E;
}






#dwturnover .carousel-root.ad-section{
    margin-top: 20px;
    width: 100%;
    padding: 0px;
    height: auto;
}
#dwturnover .ad-section .slider-wrapper {
    background: #000000;
}
#dwturnover .ad-section .slider-wrapper li.slide iframe {
    width: 100%;
    margin: 0px;
    min-height: 190px;
}
#dwturnover .ad-section .slider-wrapper li.slide a{
    height: 100%;
}
#dwturnover .ad-section .carousel .carousel-status{
    display: none;
}
#dwturnover .ad-section .carousel .control-dots .dot{
    width: 15px;
    height: 15px;
    box-shadow: none;
    background-color: #000;
    border: solid 1px #fff;
    opacity: 1;
    margin: 0px 5px;
}
#dwturnover .ad-section .carousel .control-dots .dot.selected{
    background-color: #FECC0A;
    border-color: #FECC0A;
}

#dwturnover .lastupdatebox {
    font-size: 18px;
    float: right;
}

/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (min-width: 992px) and (max-width: 1199px){
    #dwturnover .row > .col-md-8 > .section {
        padding: 40px;
    }
    #dwturnover .row > .col-md-4 > .section {
        padding: 20px;
    }
}

@media screen and (max-width: 991px){
    #dwturnover .tbl th, 
    #dwturnover .tbl td{
        padding: 8px 4px;
    }
    #dwturnover .tbl th{
        font-size: 20px;
    }
    #dwturnover .tbl td{
        font-size: 18px;
    }
    body.eng #dwturnover .tbl th{
        font-size: 13px;
    }
    body.eng #dwturnover .tbl td{
        font-size: 12px;
    }
}

@media screen and (max-width: 767px){    
    body.eng #dwturnover .page-header h1{
        font-size: 18px;
    }
}
