/*******************************************************************************
 home page
*******************************************************************************/

em {
  font-style: italic;
  font-size: 14px;
}

/* header .navbar-header {
    margin-left: 0 !important;
} */


/*******************************************************************************
 jumbotron and hometop
*******************************************************************************/

.jumbotron {
  position: absolute;
  width: 100%;
  background: transparent;
  top: 20px;
  left: 0;
}

#home .slick-slider.show-desk {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: -60px;
}

.hometop .homeimg img {
  width: 100%;
  margin-top: 25px;
}

.hometop .homeimg .homeimg-achor {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.tab-container {
  margin-bottom: 20px;
}
#home .hometop .carousel .control-dots {
  position: absolute;
  bottom: 15%;
  margin: 0px !important;
  padding: 0;
  width: auto;
  z-index: 1;
  right: 5%;
}
#home .hometop .carousel .control-dots li.dot{
  width: 16px !important;
  height: 16px !important;
  border: solid 1.5px #fff;
  margin: 0px 2px;
  border-radius: 50%;
  background-color: transparent !important;
  opacity: 1;
}
#home .hometop .carousel .control-dots li.dot.selected{
  border-color: #FECC0A;
  background-color: #FECC0A !important;
}

.video-wrapper {
  display: block;
  width: 100%;
  height: auto;
}

.video-wrapper video {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.jumbotron p {
  margin-bottom: 5px;
}

.jumbotron .buttonSmallText {
  font-size: 14px;
  color: #ffffff;
  padding-left: 10px;
}
#announcement table td {
  border: none;
}
#announcement .announcement-col .media {
  width: 100%;
  display: flex;
}
.jumbotron .visible-xs .buttonSmallText {
  padding-left: 0px;
}

.jumbotron .btn.btn-primary {
  font-family: "MCQGlobal_W_Md";
  /*    min-width: inherit;
    line-height: 1;*/
}
#home .carousel-slider button.control-arrow{
  padding: 0px;
  width: 65px;
  left: 0;
  z-index: 100000;
  background-color: transparent;
  color: #ddd;
}
#home .carousel-slider button.control-arrow:hover{
  background-color: rgba(146, 146, 146, 0.5);
}
#home .carousel-slider button.control-arrow.control-next{
  left: inherit;
  right: 0;
}
#home .carousel-slider button.control-arrow::before{
    border-color: #ddd;
    border-width: 0 5px 5px 0;
    top: 52%;
    right: 15px !important;
    position: absolute;
    padding: 6px;
}
.hometop .gallery-nav.backward {
  left: 0;
}

.hometop .gallery-nav.forward {
  right: 0;
}
#home #dwtool #lmtablecontainer{
  min-height: auto;
}
#home #dwtool .__react_component_tooltip{
  opacity: 1;
  padding: 0;
  border: none;
}
#home #dwtool .hotlist-left .hotlist-col,
#home #dwtool .hotlist-right .hotlist-col{
  padding: 0px 5px;
}
#home #dwtool .hotlist-left .hotlist-col a,
#home #dwtool .hotlist-right .hotlist-col a{
  color: #333;
}
#home #dwtool .hotlist-left .hotlist-col a .hotlist-item-nm,
#home #dwtool .hotlist-right .hotlist-col a .hotlist-item-nm{
  line-height: 1.42857143;
}
#home #dwtool .bg-tooltip{
  max-width: 280px;
  padding: 0;
}
#home #dwtool .bg-tooltip .tooltip-content{
  font-style: normal;
}
#home #dwtool #indexfuture .priceUpdateInfo{
  margin-bottom: 10px;
}
#home #dwtool .priceUpdateInfo .bg-tooltip .tooltip-content{
  font-size: 21px;
  line-height: 1;
}
body.eng #home #dwtool .priceUpdateInfo .bg-tooltip .tooltip-content{
  font-size: 14px;
  line-height: normal;
}



.hometop .gallery-nav-icon {
  display: block;
  position: absolute;
  width: 16px;
  height: 26px;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.hometop .gallery-nav {
  position: absolute;
  width: 64px;
  top: 0;
  bottom: 0;
  height: 100%;
}

.hometop .gallery-nav:hover {
  background-color: rgba(146, 146, 146, 0.5);
}

.hometop .gallery-nav.swiper-button-disabled {
  visibility: hidden;
}

.hometop .swiper-button-next,
.hometop .swiper-container-rtl .swiper-button-prev,
.hometop .swiper-button-prev,
.hometop .swiper-container-rtl .swiper-button-next {
  background-image: none;
}

.hometop .swiper-button-next,
.hometop .swiper-button-prev {
  margin-top: 0;
}

#home .slick-slider.show-desk {
  z-index: 0;
}

.hometop .gallery-item {
  padding-bottom: 0;
}

.hometop .swiper-container-horizontal > .swiper-pagination-bullets,
.hometop .swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10%;
  text-align: right;
  right: 5%;
  left: auto;
}

.hometop
  .swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
}

.swiper-slide .jumbotron {
  background: none;
}

.swiper-slide .container-fluid {
  text-align: left;
}

/*******************************************************************************
 tabnav
*******************************************************************************/

.tabnav {
  margin-bottom: 30px;
}

.tabnav-item {
  padding: 15px 5px 20px 5px;
  text-align: center;
  background-color: #fff;
  border-bottom: 5px solid #eee;
  cursor: pointer;
  position: relative;
}

.tabnav-img {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.tabnav .active:after {
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -18px;
  vertical-align: middle;
  content: " ";
  border-right: 18px solid transparent;
  border-bottom: 18px solid #fecc0a;
  border-left: 18px solid transparent;
}

.tabnav-item.active,
.tabnav-item:focus,
.tabnav-item:hover {
  background-color: #000;
  border-bottom: 5px solid #fecc0a;
}

.tabnav-item.active .tabnav-htxt,
.tabnav-item:hover .tabnav-htxt,
.tabnav-item.focus .tabnav-htxt {
  color: #fecc0a;
}

.tabnav-item.active .tabnav-txt,
.tabnav-item:hover .tabnav-txt,
.tabnav-item:focus .tabnav-txt {
  color: #fff;
}

.tabnav-htxt {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 24px;
}
body.eng .tabnav-htxt {
  font-size: 16px;
}

.tabnav-txt {
  font-size: 20px;
  height: 36px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  line-height: 1;
}
body.eng .tabnav-txt{
  font-size: 13px;
  line-height: 1.42857143;
}
#dwnews #qlink-subscribe p,
#dwedu #qlink-investoracademy p,
#dwedu #qlink-dwindex p{
  margin-bottom: 10px;
}
#dwnews #qlink-subscribe a.btn-primary,
#dwedu #qlink-investoracademy a.btn-primary{
  line-height: 1.42857143;
}
#dwedu #edu-dwtips .newstbl-row{
  position: relative;
  background-color: rgb(245, 246, 247);
  margin-bottom: 10px;
}
#dwedu #edu-dwtips .newstbl-col-right {
  margin-left: 20px;
  margin-right: 10px;
  display: flex;
}
#dwedu #edu-dwtips .newstbl-col-right a.newstbl-col-rm{
  display: inline-block;
  text-align: right;
  min-width: 35px;
  padding-top: 8px;
}
body.eng #dwedu #edu-dwtips .newstbl-col-right a.newstbl-col-rm{
  padding-top: 11px;
}
#dwedu #edu-dwtips .newstbl-col-dt {
  font-family: "DBHeaventMedium";
  margin: 0;
  font-size: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 6px;
  display: inline-block;
  color: #333;
}
body.eng #dwedu #edu-dwtips .newstbl-col-dt{
  font-family: "MCQGlobal_A_Md";
  line-height: 1.42857143;
  font-size: 16px;
}
#dwedu #edu-dwtips .newstbl-col-rm img{
  width: 50%;
}
#home #dwedu #qlink-investoracademy a.btn-primary{
  line-height: 1;
}
body.eng #home #dwedu #qlink-investoracademy a.btn-primary {
  line-height: 1.42857143;
}
#home #dwedu #qlink-investoracademy .section-qlink-investoracademy{
  min-height: 180px;
}
#dwedu #qlink-investoracademy .section-qlink-investoracademy{
  min-height: 175px;
}
#dwedu #qlink-dwindex .section-qlink-indexdw{
  background: url(../../assets/images/bg/qlink-bg-indexdw-home.png) left bottom no-repeat;
  min-height: 180px;
  margin-top: 10px;
}
#home #dwedu #qlink-dwindex .section-qlink-indexdw{
  background: url(../../assets/images/bg/qlink-bg-indexdw-home.jpg) left bottom no-repeat;
  min-height: 180px !important;
}
#home #qlink-faq p{
  height: auto !important;
}
#home #qlink-faq .section-btn-wapper a{
  line-height: 1 !important;
}
body.eng #home #qlink-faq .section-btn-wapper a{
  line-height: 1.42857143 !important;
}
#home #qlink-faq .question-list {
  border: 1px solid #EAEAEA;
}
#home #qlink-faq .question-list>div {
  background-color: #F5F6F7;
  margin: 10px;
  padding: 7px 10px;
  border-radius: 5px;
}
#home #qlink-faq .question-list a {
  color: #333333;
}
body.eng #home #qlink-faq .question-list a {
  font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.42857143;
}
#home #qlink-faq .question-list img {
  margin-top: -3px;
  cursor: pointer;
  width: 22px;
  margin-left: 4px;
}
#home #qlink-faq .question-header {
  text-align: center;
  height: 35px;
  line-height: 35px;
  background-color: #666;
}
#home #dwedu #qlink-dwindex p{ 
  height: auto;
}
#home #dwedu #qlink-dwindex .section-qlink-indexdw a{
 line-height: 1;
}
body.eng #home #dwedu #qlink-dwindex .section-qlink-indexdw a{
  line-height: 1.42857143;
 }
#dwnews #todayhighlight img{
  width: 100%;
  max-width: 690px;
  margin-bottom: 10px;
}
#dwnews #video-dwstat-lgmd{
  width: 100%;
  padding: 0;
  margin-top: 20px;
}
#dwnews #video-dwstat-lgmd li.slide,
#dwedu #video-dwstat-lgmd li.slide,
#dwstat #video-dwstat-lgmd li.slide{
  background-color: #fff;
}
#dwnews #marketcommentary p.news-summary,
#dwnews #todayhighlight p.news-summary,
#dwnews #marketwrap p.news-summary{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 10px; 
  min-height: auto;
  height: auto;
}
body.eng #dwnews #marketcommentary p.news-summary,
body.eng #dwnews #todayhighlight p.news-summary,
body.eng #dwnews #marketwrap p.news-summary{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 10px; 
  min-height: auto;
  height: auto;
  font-size: 14px;
}
#dwnews #marketcommentary .section-btn-wapper,
#dwnews #todayhighlight .section-btn-wapper,
#dwnews #marketwrap .section-btn-wapper{
  margin-top: 20px;
}
#dwnews #marketcommentary .section-btn-wapper a,
#dwnews #todayhighlight .section-btn-wapper a,
#dwnews #marketwrap .section-btn-wapper a,
#dwedu #qlink-dwindex a.btn.btn-primary,
#dwedu #seminar .section-btn-wapper a,
#dwedu #qlink-faq a.btn.btn-primary{
  line-height: 1; 
}
body.eng #dwnews #marketcommentary .section-btn-wapper a,
body.eng #dwnews #todayhighlight .section-btn-wapper a,
body.eng #dwnews #marketwrap .section-btn-wapper a,
body.eng #dwedu #qlink-dwindex a.btn.btn-primary,
body.eng #dwedu #seminar .section-btn-wapper a,
body.eng #dwedu #qlink-faq a.btn.btn-primary{
  line-height: 1.42857143; 
}
#dwnews #marketwrap .news-title,
#dwnews #todayhighlight .news-title,
#dwnews #marketcommentary .news-title{
  line-height: 1.42857143;
}
#home #dwedu #seminar .section-btn-wapper a{
  line-height: 1;
}
body.eng #home #dwedu #seminar .section-btn-wapper a {
  line-height: 1.42857143;
}
#dwnews #photogallery .carousel-root > .carousel:last-child{
  display: none;
}
#dwnews #photogallery .carousel-root p.carousel-status{
  display: none;
}
#dwnews #photogallery .gallery-item-wapper .gallery-item-txt{
  line-height: 1.42857143;
}
#dwnews #photogallery .gallery-item-wapper .btn.btn-primary{
  line-height: 1.42857143;
  border: none;
}
#dwnews #photogallery .gallery-item-wapper img{
  width: auto;
  margin: 0px auto;
}
#dwedu #video-dwstat-lgmd{
  width: 100%;
  padding: 0px;
}
#dwedu #seminar table tr th{
  padding: 8px;
  font-size: 16px;
}
#home #dwedu #seminar table tr th{
  font-size: 24px;
}
body.eng #home #dwedu #seminar table tr th{
  font-family: "MCQGlobal_A_Md";
  line-height: normal;
  font-size: 16px;
}
#dwedu #seminar table tr td{
  padding: 8px;
  font-size: 14px;
  line-height: 1.42857143;
  vertical-align: top;
  border: none;
  font-family: 'MCQGlobal_W_Lt','MCQGlobal_W_Bd','Roboto', Arial, sans-serif;
}
#home #dwedu #seminar table tr td,
#home #dwedu #seminar table tr td a{
  font-size: 21px;
  font-family: 'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif;
  line-height: 1;
  font-weight: 500;
}
body.eng #home #dwedu #seminar table tr td,
body.eng #home #dwedu #seminar table tr td a{
  font-size: 14px;
  font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
  line-height: 1.42857143;
}
#dwedu #seminar p{
  margin-bottom: 10px;
}

#dwedu #seminar table.table-striped tbody tr{
  --bs-table-striped-bg: transparent;
}

#dwstat #moneyflow p{
  margin-bottom: 10px;
}
#dwstat #moneyflow .chartbox-legend{
  position: relative;
  margin: 0;
}
#home #dwstat #moneyflow #topInPieChart-lgmd p{
  margin: 0px;
  font-size: 13px;
}
#dwstat #moneyflow a.btn-primary.btn{
  line-height: 1.42857143;
}
#dwstat #toptradeddw > p,
#dwstat #turnover > p{
  margin-bottom: 10px;
}
#dwstat #toptradeddw .col-sm-6 > h2.small{
  margin: 20px 0px 10px;
  font-size: 24px;
  font-family: "DBHeaventMedium";
}
body.eng #dwstat #toptradeddw .col-sm-6 > h2.small{
  font-size: 16px;
  font-family: "MCQGlobal_A_Md";
}
#dwstat #toptradeddw table tbody tr{
  --bs-table-striped-bg:transparent;
}
#dwstat #toptradeddw table tbody tr td{
  border: none;
  line-height: 1.42857143;
}
#dwstat #toptradeddw .section-btn-wapper a.btn-primary,
#dwstat #turnover .section-btn-wapper a.btn-primary{
  line-height: 1.42857143;
}
#dwstat #toptradeddw .section-btn-wapper a.btn-primary{
  line-height: 1;
}
body.eng #dwstat #toptradeddw .section-btn-wapper a.btn-primary{
  line-height: 1.42857143;
}
#home #dwtool #toptradedstock #topTbl table th{
  vertical-align: top !important;
}
#home #dwtool #toptradedstock #topTbl table th.top-underlying,
#home #dwtool #toptradedstock #topTbl table th.top-expiry{
  vertical-align: middle !important;
}
#home #toptradedstock #topTbl.calldw .hide-calldw {
  display: table-row !important;
}
#home #toptradedstock #topTbl.calldw .hide-putdw {
  display: none !important;
}
#home #dwstat #toptradeddw .checkbox-mqonly label{
  font-weight: normal;
  position: relative;
  top: -2px;
  margin-left: 8px;
  font-size: 18px;
}
body.eng #home #dwstat #toptradeddw .checkbox-mqonly label{
  font-size: 12px;
}

#home #dwedu #qlink-dwvideo P{
  margin-bottom: 50px;
}
#home #dwedu #qlink-dwvideo .section-btn-wapper, 
#home #dwedu #seminar .section-btn-wapper {
  margin-top: 60px;
}

#home #toptradedstock .tablist-item-txt{
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
  line-height: normal;
  padding: 0 5px;
}
body.eng #home #toptradedstock .tablist-item-txt{
  font-size: 13px;
}

#home #dwnews .news-title {
  font-family: "DBHeaventMedium";
  margin-bottom: 20px;
  line-height: 1;
}
body.eng #home #dwnews .news-title {
  font-family: "MCQGlobal_A_Md";
  margin-bottom: 20px;
  line-height: 1.42857143;
}
#home #dwnews .news-summary {
  height: 34px;
  font-size: 21px;
  overflow: hidden;
}
#home #dwnews .news-summary.newsImage,
body.eng #home #dwnews .news-summary.newsImage{
  height: 100%;
}
#home #dwnews #todayhighlight .news-summary.newsImage img{
  margin-bottom: 0px;
}

#home #dwnews .holder-subscribe {
  background: url("../../assets/images/home/Subscribe.jpg") 0 0 no-repeat;
  color: white;
  background-size: cover;
}
#home #dwnews #subscribe{
  background-color: unset;
}
#home #dwnews #subscribe h1{
  font-size: 33px !important;
  font-family: 'DBHeaventLight';
}
body.eng #home #dwnews #subscribe h1{
  font-size: 22px !important;
  font-family: 'MCQGlobal_A_Lt';
}
body.eng #home #dwnews #subscribe .text_subscribe_registration_success,
body.eng #home #dwnews #subscribe #text-content ~ .small{
  line-height: 1.42857143;
}
#home #dwnews #subscribe .section-p {
  min-height: unset;
  color: #fff;
}
#home #dwnews #subscribe #email{
  width: 100%;
  border: solid 1px;
  padding: 0 8px;
  height: 34px;
  line-height: 34px;
  color: white;
  background-color: unset;
}
#home #subscribe .warning {
  height: 20px;
  color: red;
  margin-bottom: 5px;
  padding-left: 8px;
}
#home #subscribe .ppbtn {
  color: #0675C2;
  cursor: pointer;
}
#home #subscribe #submitBtn {
  width: auto;
  height: auto;
  padding: 6px 12px;
  line-height: 26px;
}
body.eng #home #subscribe #submitBtn{
  line-height: 1.42857143;
  padding: 7px 12px;
}


.modal-dialog.home-subscribe{
  margin: 25vh auto !important;
  width: 100%;
  max-width: 50% !important;
  height: auto;
  min-height: auto;
  background: #000000;
  color: #ffffff;
}
.modal-dialog.home-subscribe .modal-content{
  border: none;
}
.modal-dialog.home-subscribe .popupbox-content{
  padding: 60px;
}
.modal-dialog.home-subscribe .popupbox-content img.btn-close{
  top: 11px;
  right: 11px;
  opacity: 1;
}
.modal-dialog.home-subscribe .popupbox-content input{
  margin-bottom: 10px !important;
  border: solid 1px rgb(118, 118, 118);
  border-radius: 2px !important;
}
.modal-dialog.home-subscribe .popupbox-content p.small{
  margin-bottom: 10px;
  font-size: 12px;
}
.modal-dialog.home-subscribe .popupbox-content #submitBtn{
  margin-top: 0px;
  border-radius: 0px !important;
  height: auto;
  line-height: 1.42857143;
  padding: 6px 12px;
  border: solid 1px #e4b709 !important;
}
.modal-dialog.home-subscribe #popup-customized{
  position: fixed !important;
    width: 100vw !important;
    top: 0px !important;
    left: 0 !important;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-dialog.home-subscribe #popup-customized div{
  width: auto;
  margin: 0px;
  display: inline-block;
}
.homeLive-modal.modal-dialog{
  margin: 15vh auto !important;
  width: 100%;
  height: auto;
  background: #FFFFFF;
  max-width: 80% !important;
  min-height: auto;
}
.homeLive-modal.modal-dialog img.btn-close{
  z-index: 1;
  opacity: 1;
  right: 11px;
  top: 11px;
}
.homeLive-modal.modal-dialog .modal-content{
  border: none;
}
.homeLive-modal.modal-dialog .popupbox-content{
  padding: 60px;
  background-color: #fff;
}
.homeLive-modal.modal-dialog .popupbox-content h2.ppc-h{
  margin: 20px 0px 10px;
  font-size: 30px;
}
.homeLive-modal.modal-dialog .popupbox-content p{
  color: #333;
  margin-bottom: 10px;
}
.homeLive-modal.modal-dialog .popupbox-content ul{
  padding-left: 40px;
  margin-bottom: 10px;
}
.homeLive-modal.modal-dialog .popupbox-content ul li{
  line-height: 1.42857143;
}

.nav-dtxt {
  text-align: center;
  margin-top: 10px;
  margin-bottom: -30px;
  display: none;
}

.nav-dtxt.show {
  display: block;
}

/* #home ~ #lightbox a.btn-close::before,
#home ~ #lightbox a.btn-close::after{
  display: none;
} */
/* #home ~ #lightbox a.noshow{
  color: #fff !important;
} */
 

.tabnav-item-dwtool .tabnav-img {
  background: url("../../assets/images/home/dwtool-lg.png") 0 0 no-repeat;
}
.tabnav-item-dwstat .tabnav-img {
  background: url("../../assets/images/home/dwstat-lg.png") 0 0 no-repeat;
}

.tabnav-item-dwedu .tabnav-img {
  background: url("../../assets/images/home/dwedu-lg.png") 0 0 no-repeat;
}

.tabnav-item-dwnews .tabnav-img {
  background: url("../../assets/images/home/dwnews-lg.png") 0 0 no-repeat;
}

.tabnav-item-dwtool.active .tabnav-img,
.tabnav-item-dwtool:hover .tabnav-img,
.tabnav-item-dwtool:focus .tabnav-img {
  background: url("../../assets/images/home/dwtool-active-lg.png") 0 0 no-repeat;
}
.tabnav-item-dwstat.active .tabnav-img,
.tabnav-item-dwstat:hover .tabnav-img,
.tabnav-item-dwstat:focus .tabnav-img {
  background: url("../../assets/images/home/dwstat-active-lg.png") 0 0 no-repeat;
}

.tabnav-item-dwedu.active .tabnav-img,
.tabnav-item-dwedu:hover .tabnav-img,
.tabnav-item-dwedu:focus .tabnav-img {
  background: url("../../assets/images/home/dwedu-active-lg.png") 0 0 no-repeat;
}

.tabnav-item-dwnews.active .tabnav-img,
.tabnav-item-dwnews:hover .tabnav-img,
.tabnav-item-dwnews:focus .tabnav-img {
  background: url("../../assets/images/home/dwnews-active-lg.png") 0 0 no-repeat;
}

    
.tab-container {
  overflow: hidden;
}

/*******************************************************************************
 section
*******************************************************************************/

.section-chartbox {
  margin-top: 72px;
  margin-bottom: 80px;
}

.section-btn-wapper {
  width: 100%;
  text-align: right;
  margin-top: 20px;
}

.section-btn-wapper button {
  min-width: 190px;
}

.section-left {
  width: 50%;
  padding-right: 40px;
  display: inline-block;
}

.section-right {
  display: inline-block;
  width: 50%;
  padding-right: 0;
}

.section-right::after {
  clear: both;
}

/*.section.advertising-banner{
    padding:0;
}*/

/*******************************************************************************
 live index future (global market)
*******************************************************************************/

/*#indexfuture p span{
     font-family: "MCQGlobal_W_Md"; 
}*/

#indexfuture table {
  margin-bottom: 0px;
}
#home #lmatrix #dropdown-livematrix .css-1s2u09g-control,
#home #lmatrix #dropdown-livematrix .css-1pahdxg-control{
  border-color: rgb(118, 118, 118);
  border-radius: 2px;
  max-height: 40px;
  min-height: auto;
  box-shadow: none;
  font-size: 24px;
}
body.eng #home #lmatrix #dropdown-livematrix .css-1s2u09g-control,
body.eng #home #lmatrix #dropdown-livematrix .css-1pahdxg-control{
  font-size: 16px;
}
body.eng #home #lmatrix #dropdown-livematrix .css-1pahdxg-control ~ div, 
body.eng #home #lmatrix #dropdown-livematrix .css-1s2u09g-control ~ div{
  margin-top: 0px;
}
#home #lmatrix #dropdown-livematrix .css-1s2u09g-control input,
#home #lmatrix #dropdown-livematrix .css-1pahdxg-control input{
  text-align: center;
}
#home #lmatrix #dropdown-livematrix .css-1s2u09g-control .css-6j8wv5-Input,
#home #lmatrix #dropdown-livematrix .css-1pahdxg-control .css-6j8wv5-Input{
  display: flex;
}
#home #lmatrix #dropdown-livematrix .css-1s2u09g-control span,
#home #lmatrix #dropdown-livematrix .css-1pahdxg-control span{
  display: none;
}
#home #lmatrix #dropdown-livematrix .css-1s2u09g-control svg,
#home #lmatrix #dropdown-livematrix .css-1pahdxg-control svg{
  color: #000;
}
#home #dwtool .holder-qlink-dwchart{
  display: none; 
}
#home #dwtool #qlink-dwchart p{
  margin-bottom: 10px;
}
#home #dwtool #qlink-dwchart .section-btn-wapper .btn.btn-primary{
  line-height: 1.42857143;
}

#indexfuture table th,
#indexfuture table td {
  text-align: center;
  border-bottom: 1px solid #333333;
  padding-left: 2px;
  padding-right: 2px;
  --bs-table-accent-bg: transparent;
  font-size: 18px;
  line-height: 1.42857143;
  vertical-align: top;
}

#home #indexfuture table th{
  font-family: "DBHeaventMedium";
  font-weight: normal;
  line-height: 1;
  font-size: 24px;
  vertical-align: bottom;
}
body.eng #home #indexfuture table th{
  line-height: normal;
  font-family: "MCQGlobal_A_Md";
  font-size: 16px;
}
#home #indexfuture table td{
  font-family: 'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif;
  font-size: 24px;
  line-height: 1;
}
body.eng #home #indexfuture table td{
  font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.42857143;
}
#home #indexfuture table td a{
  font-family: "DBHeaventMedium";
  text-decoration: none;
  color: #0675C2;
}
body.eng #home #indexfuture table td a{
  font-family: "MCQGlobal_A_Md";
}
table#today_table tbody {
  border-top: none;
}
#indexfuture table tbody{
  border:none;
}
.media .media-left {
  padding-right: 10px;
}
#quickHeadlines #topTbl table {
  white-space: inherit !important;
  font-size: 16px !important;
  text-align: left;
}
.media .media-body {
  width: 100%;
}
.announcement-col .media {
  display: table;
}


#indexfuture .uptick {
  background-color: #6cc263 !important;
  color: #000 !important;
}

#indexfuture .uptick .val.up {
  color: #000 !important;
}

#indexfuture .downtick {
  background-color: #c61c2d !important;
  color: #fff !important;
}

#indexfuture .downtick .val.up {
  color: #fff !important;
}

/*******************************************************************************
 hotlist (global market)
*******************************************************************************/

/*#hotlist {
    min-height:610px;
}*/

.hotlist-col {
  padding-left: 5px;
  padding-right: 5px;
}

.hotlist-left,
.hotlist-right {
  padding: 0;
}

#dwtool #hotlist .hotlist-col-h {
  font-family: "MCQGlobal_W_Md";
  width: 100%;
  text-align: center;
  background-color: #dfdbcf;
  padding: 7px 0;
  line-height: 1.42857143;
}

.hotlist-item-nm,
.hotlist-item-pc {
  font-family: "MCQGlobal_W_Md";
  font-size: 15px;
}

.hotlist-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

/* .hotlist-row.first {
  padding-top: 10px;
} */

.hotlist-item {
  width: 100%;
  padding: 10px;
  height: 100px;
  position: relative;
  cursor: pointer;
}

.right-border {
  border-right: 1px solid #d2d2d2;
}

  .hotlist-item-trend {
  width: 26px;
  height: 16px;
  background-position-x: 0;
  background-position-y: 0;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/bullet/hotlist-up.png");
  margin-top: 7px;
}

.hotlist-item-trend.up {
  background-image: url("../../assets/images/bullet/hotlist-up.png");
}

.hotlist-item-trend.down {
  background-image: url("../../assets/images/bullet/hotlist-down.png");
}  

/* .hotlist-item-nm {
  margin-bottom: 6px;
} */

.hotlist-item-params {
  position: absolute;
  top: 27px;
  left: 42px;
}

.hotlist-item-eg {
  font-size: 14px;
  margin-top: 5px;
  line-height: 1.42857143;
}

.hotlist-item-pc {
  font-size: 26px;
}

.hotlist-more {
  margin-top: 60px;
}

/*******************************************************************************
 gmkt (global market)
*******************************************************************************/

.gmkt {
  display: block;
  border-bottom: 1px dotted #ccc;
  padding-bottom: 20px;
}

.gmkt > h3 {
  margin-bottom: 0;
}

.gmkt-list .gmkt:last-child {
  border-bottom-style: hidden;
}

.gmkt-item {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  width: 100%;
}

.gmkt-ps {
  font-size: 22px;
}

.gmkt-ps.up span {
  color: greenyellow;
}

.gmkt-ps.down span {
  color: red;
}


#home #facebook-iframe iframe{
  width: auto;
}
/*.gmkt-ps.up:before{
    content:"\25b2";
    content:"\e252";
    color:red;
}
.gmkt-ps.down:before{
    content:"\25bc";
    content:"\e253";
    color:greenyellow;
}*/

/*******************************************************************************
 wcalbox (warrant calculator)
*******************************************************************************/

.wcalbox-tbl {
  margin-bottom: 30px;
}

.wcalbox-tbl tr > td {
  padding: 5px 20px 5px 0px;
  vertical-align: middle;
}

.wcalbox-tbl tr > td:last-child {
  padding: 0;
}

.wcalbox-tbl tr {
  height: 60px;
}

.wcalbox-input {
  height: 100%;
  width: 130px;
}

.slider.slider-horizontal {
  width: 256px;
}

.searchctrl {
  table-layout: fixed;
  margin-top: 40px;
  margin-bottom: 20px;
  vertical-align: middle;
}

.searchctrl-col {
  width: 25%;
}

.searchctrl .searchctrl-col:first-child {
  width: 50%;
}

.searchctrl .searchctrl-col:last-child {
  border-left: 10px solid #fff;
}

.searchctrl-small,
.searchctrl-xsmall {
  margin-top: 15px;
}

.searchctrl-small-toolbar {
  margin-top: 5px;
  margin-bottom: 15px;
  border-bottom: 1px dotted #ccc;
}

.searchctrl-small-toolbar .dropdown {
  display: inline-block;
}

.banner {
  /*    background-color:#fff;*/
  padding-top: 30px;
  padding-bottom: 30px;
}

.banner .banner-img {
  width: 100%;
  height: auto;
}

.astrerisk-msg {
  position: relative;
  top: -15px;
  left: 0;
}

/*******************************************************************************
 live matrix input box lmbox 
*******************************************************************************/

.btn.btn-primary {
  min-width: 190px;
}

/*.section-qlink-achor .btn.btn-primary{
    min-width: 190px;
}
.section-btn-wapper .btn.btn-primary{
    min-width: 190px;
}*/

.section-qlink-dwsearch {
  height: 126px;
  min-height: 126px;
}

.section-qlink-dwcalculator {
  /* background: url("../../assets/images/bg/qlink-lg-bg-calculator.png") left bottom no-repeat; */
  min-height: 219px;
}

/*******************************************************************************
 section min-height
*******************************************************************************/

#home #qlink-dwsearch .section-p {
  min-height: 30px;
  margin-bottom: 10px;
}
#home #qlink-dwsearch .filter-item .Warrent_dropdown > div{
  border-radius: 0px;
  border-color: #AEAEAE;
  min-height: auto;
  cursor: pointer;
}
#home #qlink-dwsearch .filter-item.first .css-1s2u09g-control > div > div,
#home #qlink-dwsearch .filter-item.first .css-1pahdxg-control > div > div{
  text-align: center;
  display: flex;
  justify-content: center;
}
#home #qlink-dwsearch .filter-item.first .css-1s2u09g-control input,
#home #qlink-dwsearch .filter-item.first .css-1pahdxg-control  input{
  text-align: center;
  min-width:100% !important;
  width: 100% !important;
}
#home #qlink-dwsearch .filter-item.first .Warrent_dropdown > div{
  border-radius: 2px;
  border-color: rgb(118, 118, 118);
}
#home #qlink-dwsearch .filter-item .Warrent_dropdown > div.css-1pahdxg-control{
  box-shadow: 0 0 0 1px #000;
  border-color: #000;
}
#home #qlink-dwsearch .filter-item .Warrent_dropdown > div span{
  display: none;
}
#home #qlink-dwsearch .filter-item .Warrent_dropdown > div svg{
  color: #000;
}
body.eng .btn {
  font-size: 16px;
  line-height: 1.42857143;
}
#home table.table.table-striped{
  border-collapse: collapse;
}
#home table.table.table-striped tbody {
    border-top: none;
}
#home #dwtool #toptradedstock p.section-txt{
  margin-bottom: 10px;
}
#home #dwtool #toptradedstock .btn.btn-primary{
  line-height: 1.42857143;
}
#home #dwtool #quickHeadlines .section-btn-wapper .ppbtn{
  line-height: 1.42857143;
}
/*#dwchart.section{
    min-height: 390px;
}*/

/*#lmatrix.section .section-p{
    min-height: 106px;
}
#dwcalculator.section .section-p{
    min-height: 90px;
}*/

/*******************************************************************************
 section min-height
*******************************************************************************/

.dropmenu-inverse .dropmenu-row {
  color: #fecc0a;
  border-bottom: 1px solid #fecc0a;
}

.dropmenu-inverse .dropmenu-row a,
.dropmenu-solid dropmenu-row a:hover,
.dropmenu-solid .dropmenu-row a:visited {
  color: #fecc0a;
}

.dropmenu-inverse .icon-tooltip-bg {
  background: #000;
  opacity: inherit;
}

.dropmenu-inverse.nav-icon-tooltip:before {
  border-bottom-color: #000;
  opacity: inherit;
}

/*******************************************************************************
 chartbox
*******************************************************************************/

#dwstat #moneyflow .chartbox {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.chartbox-legend {
  position: absolute;
  right: 0;
}

.chartbox-legend-item {
  font-size: 12px;
  position: relative;
  height: 18px;
  margin-bottom: 10px;
}

.chartbox-legend-square {
  display: inline-block;
  width: 18px;
  height: 18px;
  position: absolute;
  top: -1px;
}

.chartbox-legend-txt {
  margin-left: 23px;
}

.chartbox-legend table {
  table-layout: fixed;
  width: 100%;
}

.chartbox-legend.visible-sm,
.chartbox-legend.visible-xs {
  position: inherit;
  margin-top: 10px;
  min-width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.chartbox-legend table td {
  padding-bottom: 10px;
}

.chartbox-legend.visible-sm .chartbox-legend-item,
.chartbox-legend.visible-xs .chartbox-legend-item {
  margin-bottom: 0;
}

.chartbox-legend-square.cbg00 {
  background-color: #0675c2;
}

.chartbox-legend-square.cbg01 {
  background-color: #00a1e0;
}

.chartbox-legend-square.cbg02 {
  background-color: #6cc263;
}

.chartbox-legend-square.cbg03 {
  background-color: #f7941e;
}

.chartbox-legend-square.cbg04 {
  background-color: #fecc0a;
}

.chartbox-legend-square.cbg05 {
  background-color: #c61c2d;
}

/*******************************************************************************
 #toptradeddw
*******************************************************************************/

#toptradeddw h2.small {
  font-size: 16px;
  font-family: MCQGlobal_W_Md;
}
#toptradeddw table.table tr th {
  vertical-align: bottom;
}
#toptradeddw .row {
  margin-right: -10px;
  margin-left: -10px;
}

#toptradeddw table,
#toptradeddw table a {
  font-size: 14px;
}

#toptradeddw #topindices td,
#toptradeddw #topdw td {
  white-space: nowrap;
}

#toptradeddw #topindices th,
#toptradeddw #topindices td,
#toptradeddw #topdw th,
#toptradeddw #topdw td {
  text-align: center;
}

/*******************************************************************************
 #todayhighlight, #marketcommentary, #marketwrap
*******************************************************************************/

.news-title {
  font-family: MCQGlobal_W_Md;
  margin-bottom: 20px;
}

.news-summary {
  min-height: 80px;
  /*    font-size: 14px;*/
  overflow: hidden;
}

.news-chart img {
  width: 100%;
  max-width: 690px;
  margin-bottom: 10px;
}

.news-table table {
  width: 100%;
  max-width: 690px;
  margin-bottom: 10px;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}

.news-table table th {
  background-color: #e5f6fe;
  border-bottom: 1px solid #e5f6fe !important;
}

.news-table table td {
  background-color: #f7f7f7;
}

.news-table table th,
.news-table table td {
  font-size: 14px;
  border-right: 1px solid #ffffff;
  border-top: 1px solid #ffffff;
  padding: 4px 2px !important;
  text-align: center;
}

#home #toptradeddw #topindices th, 
#home #toptradeddw #topdw th{
  font-family: "DBHeaventMedium";
  font-weight: normal;
  line-height: 1;
  border-bottom: 1px solid #FECC0A;
  vertical-align: bottom;
  font-size: 21px;
  text-align: left;
  padding: 8px;
}
body.eng #home #toptradeddw #topindices th, 
body.eng #home #toptradeddw #topdw th{
  font-family: "MCQGlobal_A_Md";
  line-height: normal;
  font-size: 14px;
}
#home #toptradeddw #topindices td, 
#home #toptradeddw #topindices td a,
#home #toptradeddw #topdw td,
#home #toptradeddw #topdw td a{
  border-top: 0;
  line-height: 1;
  font-size: 21px !important;
  font-family: 'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif;
  text-align: left;
}
#home #toptradeddw #topindices td,
#home #toptradeddw #topdw td{
  padding: 8px;
}
#home #toptradeddw #topindices tr.emptymsg td,
#home #toptradeddw #topdw tr.emptymsg td{
  text-align: center;
}

body.eng #home #toptradeddw #topindices td, 
body.eng #home #toptradeddw #topindices td a, 
body.eng #home #toptradeddw #topdw td, 
body.eng #home #toptradeddw #topdw td a{
  line-height: 1.42857143;
  font-size: 14px !important;
  font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
}

/*******************************************************************************
 #activity
*******************************************************************************/

#activity.section p {
  margin-bottom: 20px;
}

/*******************************************************************************
 #soldout, #annoucement tables
*******************************************************************************/

#soldout table,
#announcement table {
  margin-top: 2px;
  margin-bottom: 10px;
}

.holiday-readmore,
.general-readmore,
.soldout-readmore,
.staticbrand-readmore,
.expiringwarrants-readmore {
  cursor: pointer;
}

#announcement .media-heading {
  font-family: "Roboto";
  font-weight: 500;
  font-style: Medium;
  font-size: 16px;
  margin-bottom: 0;
}

#announcement .date {
  font-size: 12px;
}
#announcement h4 .date{
  margin-bottom: 11px;
  display: block;
  margin-top: 4px;
}

#announcement .media {
  margin-bottom: 30px;
}

#announcement .media:last-child {
  margin-bottom: 0px;
}

#announcement .media-body p {
  font-size: 14px;
}
#home #dwtool #announcement .media-body p{
  margin-bottom: 10px;
}
#home #dwtool #announcement .announcement-nolink-table .sold-out p{
  margin: 0px;
  text-align: center;
  padding: 7.5px 8px;
  font-family: "MCQGlobal_W_Md";
}
#home #dwtool #announcement .staticband table tr td,
#home #dwtool #announcement .expiring table tr td{
  padding: 6px 8px;
  line-height: 1.42857143;
  vertical-align: top;
}
#home #dwtool #announcement .announcement-list > .row{
  margin-left: -10px;
  margin-right: -10px;
}
#home #dwtool #announcement .announcement-list > .row:first-child > .announcement-col:first-child > .media{
  margin-top: 0px;
}
#home #dwtool #announcement .announcement-list .announcement-col > .media{
  margin-top: 15px;
}

#announcement p {
  font-family: "Roboto";
  font-weight: 300;
  font-style: Light;
  font-size: 14px;
}

#announcement .media-readmore {
  font-family: 'MCQGlobal_W_Bd', "Helvetica Neue";
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #0675c2;
  text-align: right;
}

#announcement .announcement-table {
  color: #0675c2;
  font-size: 14px;
  font-family: "MCQGlobal_W_Md";
  width: 100%;
  max-width: 100%;
  margin-left: 0;
}

#announcement .announcement-nolink-table {
  font-size: 14px;
  font-family: "MCQGlobal_W_Md";
  width: 100%;
  max-width: 100%;
  margin-left: 0;
}

#announcement .announcement-table td,
#announcement .announcement-nolink-table td {
  width: 50%;
}

#announcement .soldoutmoreccontent,
#announcement .staticbrandmoreccontent,
#announcement .expiringwarrantsmoreccontent {
  display: none;
}

#soldout table th,
#soldout table td,
#announcement .table th,
#announcement .table td {
  text-align: center;
}

#soldout table td,
#announcement table td {
  padding-top: 6px;
  padding-bottom: 6px;
}

#soldout table th,
#announcement table th {
  border-bottom: 0px;
}

/*#soldout table tbody,
#announcement table tbody{*/

#soldout table tbody {
  height: 105px;
}

#soldout table tbody td,
#soldout table thead th,
#announcement table tbody td,
#announcement table thead th {
  width: 48%;
}

/*******************************************************************************
 swiper
*******************************************************************************/
#newsbanner .swiper-pagination .swiper-pagination-bullet{
  cursor: pointer;
  width: 8px;
  height: 8px;
  opacity: inherit;
  margin-left: 2px;
  margin-right: 2px;
  background: url("../../assets/images/bullet/page-marker.png") 0 0 no-repeat;
}

#newsbanner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: url("../../assets/images/bullet/page-marker-active.png") 0 0
    no-repeat;
}

.swiper-slide a,
.swiper-slide img {
  border: none;
}

/*******************************************************************************
 gallery
*******************************************************************************/

.gallery.section {
  padding: 0;
}

.gallery-item {
  position: relative;
  text-align: center;
  background-color: #000;
  /* padding-bottom: 46%; */
}

#home #dwnews #photogallery .gallery-item{
  padding-bottom: 46%;
}
#home #dwnews #photogallery .gallery-item-txt{
  font-family: 'DBHeaventLight';
  font-size: 90px;
  line-height: 1;
}
body.eng #home #dwnews #photogallery .gallery-item-txt{
  font-family: 'MCQGlobal_A_Lt';
  font-size: 60px;
  line-height: 1.42857143;
}
#home #dwnews #photogallery .gallery-item-btn{
  line-height: 1;
}
body.eng #home #dwnews #photogallery .gallery-item-btn{
  line-height: 1.42857143;
}
#home #dwnews #photogallery ul.slick-dots{
  bottom: 25px;
}
#home #dwnews #photogallery ul.slick-dots li{
  margin: 0px 5px;
  width: 16px;
  height: 16px;
}
#home #dwnews #photogallery ul.slick-dots li button{
  background-color: transparent;
  border: solid 1px #ddd;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  margin: 0px !important;
  padding: 0px;
}
#home #dwnews #photogallery ul.slick-dots li.slick-active button{
  background-color: #FECC0A;
  border-color: #FECC0A;
}
#home #dwnews #photogallery ul.slick-dots li button::before{
  display: none;
}
#home #subscribeModal .overlay-inner{
  margin: 25vh auto;
  width: 50%;
  height: auto;
  background: #000000;
  color: #ffffff;
}
#home #subscribeModal .overlay-inner .btn-close {
  top: 15px;
  right: 15px;
  width: 16px;
  height: 16px;
}
#home #popup-customized > div{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}


.gallery-item-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.gallery-item-achor {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
}

.gallery-item-body {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.gallery-item-txt {
  font-family: "MCQGlobal_W_Lt";
  font-weight: 200;
  font-style: normal;
  font-size: 60px;
  color: #ffffff;
  margin-bottom: 10px;
}

.gallery-item-btn {
  min-width: 150px;
}

.gallery-item-achor {
  border: none;
}

.gallery-item-img {
  height: 100%;
}

.gallery-item-wapper {
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.gallery-nav.backward {
  left: 0;
}

.gallery-nav.forward {
  right: 0;
}

.gallery-nav-icon {
  display: block;
  position: absolute;
  width: 16px;
  height: 26px;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.gallery-nav {
  position: absolute;
  width: 64px;
  top: 0;
  bottom: 0;
  height: 100%;
}

.gallery-nav:hover {
  background-color: rgba(146, 146, 146, 0.5);
}

.gallery-nav.swiper-button-disabled {
  visibility: hidden;
}

.gallery-nav.backward .gallery-nav-icon {
  background: url("../../assets/images/bullet/nav-backward.png") center center
    no-repeat;
}

.gallery-nav.forward .gallery-nav-icon {
  background: url("../../assets/images/bullet/nav-forward.png") center center
    no-repeat;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: none;
}

.swiper-button-next,
.swiper-button-prev {
  margin-top: 0;
}

/*******************************************************************************
 advertisment-bottom: special handling for advertisment banner
*******************************************************************************/

.advertisment-bottom .section.advertising-banner {
  margin-top: 0px;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f3f3f4;
}

.advertisment-bottom .swiper-pagination-bullet-active {
  background-color: inherit !important;
}

/*******************************************************************************
 videoplayer: wrapper for displaying youtube player
*******************************************************************************/

#qlink-dwvideo .videoplayer {
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

#qlink-dwvideo .videoplayer-container {
  height: 0;
  width: 100%;
  padding-bottom: 57%;
  overflow: hidden;
  position: relative;
}

#qlink-dwvideo iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

/*******************************************************************************
 overide section qlinks
*******************************************************************************/

.section-qlink-indexdw {
  background: url("../../assets/images/bg/qlink-bg-indexdw-home.png") left
    bottom no-repeat;
  min-height: 180px;
  margin-top: 10px;
}

.section-qlink-faq {
  background: url("../../assets/images/bg/qlink-bg-faq-home.jpg") 100px bottom
    no-repeat;
  min-height: 201px !important;
  margin-top: 40px;
}

.section-qlink-investoracademy {
  background: url("../../assets/images/bg/qlink-bg-investoracademy-home.jpg")
    left bottom no-repeat;
  min-height: 175px;
  margin-top: 24px;
}

/*******************************************************************************
 livematrix section
*******************************************************************************/

#lmtablecontainer tr.midpoint.bgcolor-08 {
  background-color: rgba(9, 124, 207, 0.2);
}

#lmtablecontainer tr.midpoint {
  background-color: rgba(9, 124, 207, 0.2);
}

#home #lmatrix .section-p {
  min-height: 30px;
}
#home #dwtool #lmatrix p.section-p{
  margin-bottom: 10px;
  min-height: auto;
}

#lmatrix .ticker {
  padding-top: 2px;
  line-height: 1.42857143;
}

#lmatrix .ricbar img {
  width: 30px;
  margin-top: 2px;
  height: 30px;
}

#lmatrix #lmtablecontainer table tr th,
#lmatrix #lmtablecontainer table tr td {
  text-align: center;
  padding: 8px;
}
#lmatrix #lmtablecontainer table#today_table tr th{
  vertical-align: bottom;
}
body.eng #lmatrix #lmtablecontainer table#today_table tr th{
  font-family: "MCQGlobal_A_Md";
  line-height: normal;
}
#lmatrix #lmtablecontainer table tr td{
  line-height: 1;
}
body.eng #lmatrix #lmtablecontainer table tr td{
  line-height: 1.42857143;
}
#lmatrix #lmtablecontainer table tr td.text-content{
  background-color: transparent !important;
}

#lmatrix .lbtable {
  border-bottom: 1px solid #fecc0a;
  margin-bottom: 20px;
}

/*******************************************************************************
 livematrix section combobox
*******************************************************************************/

.combobox {
  position: relative;
}

.combobox-toggle {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  width: 36px;
  padding: 0;
  cursor: pointer;
}

.combobox-toggle,
.combobox-toggle:focus,
.combobox-toggle:hover,
.combobox-toggle:visited {
  color: #000;
}

.combobox-input {
  margin: 0;
  padding: 5px 10px;
  width: 100%;
  text-align: center;
}

.combobox-toggle-icon {
  margin-top: 10px;
  margin-right: 12px;
}

.ui-widget {
  font-family: "MCQGlobal_W_Lt", 'MCQGlobal_W_Bd', "Roboto", Arial,
    sans-serif;
  font-size: 14px;
}

.ui-menu .ui-menu-item {
  text-align: center;
}

.ui-menu .ui-menu-item a.ui-state-hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.ui-menu .ui-menu-item a:visited,
.ui-menu .ui-menu-item a:focus,
.ui-menu .ui-menu-item a:hover {
  color: #262626;
}

.ui-autocomplete.ui-menu {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}

/*******************************************************************************
 #toptradedstock
*******************************************************************************/

#home #toptradedstock #topTbl table {
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #fecc0a;
  margin-bottom: 10px;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 14px;
  border-collapse: collapse;
}

#home #toptradedstock #topTbl table th {
  font-family: "MCQGlobal_W_Md";
  font-weight: normal;
  text-align: center;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

#home #toptradedstock #topTbl table th,
#home #toptradedstock #topTbl table td {
  padding: 3px;
  /*padding: 8px;*/
  line-height: 1.42857143;
  vertical-align: top;
}

#home #toptradedstock #topTbl table td.right-b01 {
  border-right: 1px solid #ddd;
}

#home #toptradedstock #topTbl.calldw .col_put {
  display: none;
}

#home #toptradedstock #topTbl.putdw .col_call {
  display: none;
}

#home #toptradedstock #topTbl.putdw .hide-putdw {
  display: none !important;
}

#home #toptradedstock #topTbl.calldw .hide-calldw {
  display: none !important;
}

.css-14el2xx-placeholder {
  color: hsl(0, 0%, 50%);
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  text-align: center;
}

#home #toptradedstock .lastupdatebox {
  text-align: right;
  width: 100%;
  font-size: 16px;
  display: block;
  overflow: hidden;
  margin-bottom: 20px;
}

#home #toptradedstock #topTbl table th.top-underlying {
  vertical-align: middle;
}

#home #toptradedstock #topTbl table th.top-expiry {
  vertical-align: middle;
}

/*******************************************************************************
 Technical Analysis section
*******************************************************************************/

#technicalAnalysis .recogniaUnderlying span {
  margin-right: 10px;
}

#technicalAnalysis #topSection {
  display: flex;
  justify-content: space-between;
}
#topSection h3#defultUnderlying {
  margin: 20px 0px 10px;
}
#technicalAnalysis #topSection .section-btn-wapper .btn.btn-primary{
  line-height: 1.42857143;
}
#technicalAnalysis .recogniaUnderlying {
  line-height: 1.42857143;
}
#technicalAnalysis > .section-btn-wapper .btn.btn-primary{
  line-height: 1.42857143;
}
#home #lmatrix .section-btn-wapper .btn.btn-primary{
  line-height: 1;
}
body.eng #home #lmatrix .section-btn-wapper .btn.btn-primary{
  line-height: 1.42857143;
}

#home #topmover .hotlist-row > .row{
  margin-left: -10px;
  margin-right: -10px;
}
/*******************************************************************************
 dwsearch section
*******************************************************************************/

#qlink-dwsearch .section-btn-wapper.section-center {
  display: flex;
  justify-content: space-between;
}

#qlink-dwsearch .filter-item {
  margin-bottom: 10px;
}

#qlink-dwsearch .filter-item .dropdown > button.btn {
  width: 100%;
}
#qlink-dwsearch .filter-item ul.dropdown-menu li a {
  width: 100%;
  display: block;
  text-align: left;
  color: #000;
  font-size: 15px;
}
#qlink-dwsearch .filter-item ul.dropdown-menu li a:hover {
  background-color: #f5f5f5;
}
#qlink-dwsearch .filter-item .dropdown > button.btn i {
  float: right;
}
#home #toptradedstock #topTbl table th,
#home #toptradedstock #topTbl table td {
  padding: 3px !important;
  line-height: 1.42857143 !important;
  vertical-align: top !important;
}
/*******************************************************************************
 Home container
*******************************************************************************/

#home .container {
  padding-left: 10px;
  padding-right: 10px;
}

/*******************************************************************************
 Home scrolable table
*******************************************************************************/

#home table.table-scroll {
  width: 100%;
  /*    border-spacing: 0;
    border: 2px solid black;*/
}

#home table.table-scroll th,
#home table.table-scroll td,
#home table.table-scroll tr,
#home table.table-scroll thead,
#home table.table-scroll tbody {
  display: block;
}

#home table.table-scroll thead tr {
  /* fallback */
  width: 96%;
  /* minus scroll bar width */
  width: -webkit-calc(100% - 16px);
  width: -moz-calc(100% - 16px);
  width: calc(100% - 16px);
}

#home table.table-scroll tr:after {
  content: " ";
  display: block;
  visibility: hidden;
  clear: both;
}

#home table.table-scroll tbody {
  overflow-y: auto;
  overflow-x: hidden;
}

#home table.table-scroll tbody td,
#home table.table-scroll thead th {
  float: left;
}

/*******************************************************************************
 Home seminar table
*******************************************************************************/

#home #seminar tbody td {
  font-size: 14px;
}

#home #seminar tbody td.date,
#home #seminar tbody td.time {
  white-space: nowrap;
}

/*******************************************************************************
 loading image
*******************************************************************************/

#home .chartbox .loadingindicator {
  z-index: 30;
}

.overlay {
  position: fixed;
  overflow-y: scroll;
  display: table;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.6);
}

.overlay-inner {
  margin: 15vh auto;
  width: 80%;
  height: auto;
  background: #ffffff;
}

#ppboxHTML2 .overlay-inner {
  margin: 25vh auto;
  width: 50%;
  height: auto;
  background: #000000;
  color: #ffffff;
}

.popupbox-content {
  padding: 60px;
}

.btn-close {
  top: 15px;
  right: 15px;
  width: 16px;
  height: 16px;
}

 

#ppboxHTML2 .page-header {
  margin-top: 0px;
}

#ppboxHTML2 p,
#ppboxHTML2 h3 {
  color: #ffffff;
}

#ppboxHTML2 input,
.popupbox-content input {
  width: 100% !important;
  margin-bottom: 10px !important;
  color: #333333 !important;
  border-radius: 0px !important;
}

#popup-customized h5 {
  max-width: 440px;
  padding: 20px;
  margin: auto;
  text-align: center;
  background-color: #fff;
  color: #000;
  width: 100%;
  min-width: 440px;
}

/*******************************************************************************
Home page lightbox
********************************************************************************/

#lightbox {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  text-align: center;
}

#lightbox > div {
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.6);
}

#lightbox .inner-area {
  display: inline-block;
  position: relative;
  z-index: 3000;
}

#lightbox .inner-area img {
  width: 100%;
}

#lightbox .btn-close {
  position: absolute;
  left: 2px;
  top: 8px;
  width: 32px;
  height: 32px;
}

#lightbox .btn-close:before,
#lightbox .btn-close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 17px;
  width: 2px;
  background-color: #ffffff;
}

#lightbox .btn-close:before {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Safari */
  transform: rotate(45deg);
}

#lightbox .btn-close:after {
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  /* IE 9 */
  -webkit-transform: rotate(-45deg);
  /* Safari */
  transform: rotate(-45deg);
}

#lightbox .noshow {
  font-size: 12px;
  text-decoration: underline;
  /*color:#333333;*/
  color: #333333;
  position: absolute;
  bottom: 6px;
  left: 8px;
}

#lightbox .countdown-txt {
  position: absolute;
  top: 5px;
  right: 8px;
  font-size: 12px;
  color: #ffffff;
  font-weight: bold;
}

#lightbox .animatedVideo {
  position: absolute;
  top: 20%;
  bottom: 20%;
  left: 5%;
  right: 5%;
}

#lightbox .animatedVideo iframe {
  width: 100%;
  height: 100%;
}

/*******************************************************************************
Home page Quick Headlines
********************************************************************************/

#quickHeadlines #topTbl table th,
#quickHeadlines #topTbl table td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
}

#quickHeadlines #topTbl table {
  width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
}

#quickHeadlines #topTbl table .detail {
  text-align: right;
}

#quickHeadlines #topTbl table .news {
  width: 22%;
  font-family: "MCQGlobal_W_Md";
  text-align: left;
}

#quickHeadlines #topTbl table .newsTitle {
  text-align: left;
}

#quickHeadlines .section-btn-wapper.section-center {
  display: flex;
  justify-content: space-between;
}

#quickHeadlines .section-btn-wapper.section-center .section-txt {
  text-align: left;
  margin-bottom: 10px;
  font-weight: normal;
}

#ppboxHTML2 .warning {
  /*height: 20px;*/
  color: red;
  margin-bottom: 5px;
  padding-left: 8px;
}


/* ============== */

.search_btn {
  width: 100%;
  margin-top: 5px;
}

#qlink-dwsearch .row {
  margin-left: -10px;
  margin-right: -10px;
}

.swiper-button-next:after{
  display: none;
}

.swiper-button-prev:after{
  display: none;
}
 
#newsbanner .gallery-nav {
  position: absolute;
  width: 64px;
  top: 0;
  bottom: 0;
  height: 100%;
}

#newsbanner .gallery-nav:hover {
  background-color: rgba(146, 146, 146, 0.5);
}

#newsbanner .gallery-nav.backward {
  left: 0;
} 

#newsbanner .swiper-pagination .swiper-pagination-bullet {
  width: 16px;
  height: 16px;
}
 
#home #lmatrix .ricbar {
  font-family: "MCQGlobal_A_Md";
  font-size: 24px;
  height: 36px;
  border: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 1px 10px;
  line-height: 1.42857143;
  margin: 0px;
}
 
#dw28 .tipscontent {
  vertical-align: top;
  width: 100%;
}

#dw28 .newstbl-row {
  position: relative;
  background-color: rgb(245, 246, 247);
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: -10px;
}

#dw28 .newstbl-col-left {
  text-align: center;
}

#dw28 .newstbl-col-left, #dw28 .newstbl-col-right {
  font-family: "DBHeaventMedium";
  padding-top: 10px;
  padding-bottom: 10px;
}

#dw28 .newstbl-col-right {
  color: #0675C2;
  cursor: pointer;
  padding-left: 0px;
}
 
#home #dwtool #indexfuture .priceUpdateInfo {
  padding: 0px;
  margin: 5px 0px 10px;
  font-size: 22px;
  line-height: 1;
}
body.eng #home #dwtool #indexfuture .priceUpdateInfo{
  font-size: 15px;
}
#home #dwtool #indexfuture .priceUpdateInfo em {
  font-size: 22px;
}
body.eng #home #dwtool #indexfuture .priceUpdateInfo em{
  font-size: 15px;
  line-height: normal;
}












#topmover .page-header{
  display: flex;
  justify-content: space-between;
}
#topmover .tw_table > .col-xs-6{
  padding-left: 10px;
  padding-right: 10px;
}
#topmover .vmbtn,#topmover .vabtn,#topmover .lmbtn,#topmover .wtbtn{
  cursor: pointer;
  border: 2px solid #FECC0A !important;
  font-size: 18px;
  margin-top: 5px;
  padding: 2px;
  text-align: center;
  width: 45%;
  color: #333;
  line-height: 1;
}
body.eng #topmover .vmbtn,body.eng #topmover .vabtn,body.eng #topmover .lmbtn,body.eng #topmover .wtbtn{
  font-size: 12px;
}
#topmover .vabtn{
  margin-left: 5px;
  width: 80%;
}
#topmover .vmbtn-link{
  color: #333333;
  display: flex;
 
}

#home #toptradedstock .tablist{
  position: relative;
  height: 60px;
  margin-top: 15px;
  margin-bottom: 5px;
}
#home #toptradedstock #topTbl table{
  font-size: 21px;
}
#home #toptradedstock #topTbl table th {
  font-family: "DBHeaventMedium";
  font-weight: normal;
  text-align: center;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
body.eng #home #toptradedstock #topTbl table th{
  font-family: "MCQGlobal_A_Md";
  line-height: 1.42857143 !important;
  font-size: 14px;
}
#home #toptradedstock #topTbl table th, 
#home #toptradedstock #topTbl table td {
  padding: 8px !important;
  line-height: 1 !important;
  vertical-align: top !important;
}
#home #toptradedstock #topTbl table td{
  font-family: 'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif;
}
#home #toptradedstock #topTbl table tr.emptymsg td{
  vertical-align: middle !important;
}

body.eng #home #toptradedstock #topTbl table td{
  line-height: 1.42857143 !important;
  font-size: 14px;
  font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
}


.hotlist-item-change{
  font-size: 21px;
}
body.eng .hotlist-item-change{
  font-size: 14px;
  font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
  line-height: 1.42857143;
}
.hotlist-item-dataarea{
  cursor: pointer;
}
.hotlist-item-dataarea .hotlist-item-trend,
.hotlist-item-dataarea .hotlist-item-pc,
.hotlist-item-dataarea .hotlist-item-TRDPRC_1,
.hotlist-item-dataarea .hotlist-item-nc{
  pointer-events: none;
}
.hotlist-col-h{
  font-family:"DBHeaventMedium";
  width:100%;
  text-align: center;
  background-color:#DFDBCF;
  padding:7px 0;
}
body.eng .hotlist-col-h{
  font-family: "MCQGlobal_A_Md";
  line-height: 1.42857143;
}
 
.hotlist-row{
  margin-right: 0;
  margin-left: 0;
}
 
.hotlist-item{
  width:100%;
  padding:10px;
  position:relative;
}
.hotlist-item.active{
  background-color: #CEE5F5;        
}
.right-border{
  border-right:1px solid #d2d2d2;
}  
 


.hotlist-item-nm{
  /*margin-bottom:6px;*/
  font-size: 23px;
  font-weight: bold;
  cursor: pointer;
  min-height: 42px;
  color: #0675C2;
  font-family: 'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif;
}
body.eng .hotlist-item-nm{
  font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
  font-size: 15px;
}
.hotlist-item-nm:hover{
  text-decoration: underline;
}
.hotlist-item-unm{
 font-size: 23px;
 white-space: nowrap; 
}
 
.hotlist-item-eg{
  font-size:21px;
  margin-top: 5px;
}
.hotlist-item-pc{
  padding-left: 10px;
  font-size: 29px;
}
body.eng .hotlist-item-pc{
  font-size: 19px;
  font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
}
.hotlist-more{
  margin-top:60px;
}
.hotlist-item-data{
  display: flex;
}
.hotlist-table{
  border-bottom:1px solid #FECC0A;
  display: block;
  max-height: 476px;
  overflow-y: scroll;  
}
.hotlist-table table thead th{
  width:10%;
  border-right:1px solid #fff;
  position: sticky;
  top: 0;
}
.hotlist-table table tbody td{
  padding: 15px 0 ;
}
#home #dwtips .newstbl-col-dt {
  color: #0675C2 !important;
  margin: 0;
  font-size: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 6px;
  display: inline-block;
}
body.eng #home #dwtips .newstbl-col-dt{
  font-size: 16px;
  font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
  line-height: 1.42857143;
}
#home #advertisment .videoplayer {
  margin-top: unset;
}
#home #dwtips .newstbl-col-rm {
  text-align: right;
  display: inline-block;
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 30px;
  max-width: 30px;
}
#home #dwtips .newstbl-col-rm img{
  width: 17.5px;
  height: 17.5px;
}
#home #dwtips .newstbl-row{
  margin-top: 0px;
  margin-bottom: 10px;
}
#home #dwtips .newstbl-content {
  margin-bottom: 0px;
}
#dw28 .newstbl-col-left, #dw28 .newstbl-col-right{
  font-family: "DBHeaventMedium";
  padding-top:10px;
  padding-bottom:10px;
}
body.eng #dw28 .newstbl-col-left, body.eng #dw28 .newstbl-col-right{
  font-family: "MCQGlobal_A_Md";
  line-height: 1.42857143;
}
#dw28 .newstbl-col-left{
  text-align: center;
}
#dw28 .newstbl-col-right{
  color: #0675C2;
  cursor: pointer;
  padding-left: 0px;
}
#dw28 .newstbl-row{
  position:relative;
  background-color: rgb(245, 246, 247);
  margin-bottom: 10px;
  margin-left: 0px;
}
#dw28 .newstbl-col-dt{
  font-family: "DBHeaventMedium";
  margin:0;
  font-size: 24px;
  padding-top:8px;
  padding-bottom:8px;
  margin-bottom:6px;
  display: inline-block;
}
#dw28 .newstbl-col-rm{
  text-align:right;
  display: inline-block;
}
#dw28 .tipscontent{
  vertical-align: top;
  width: 100%;
}
#dw28 .newstbl-content{
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
#ppboxHTML3 .overlay-inner {
  margin: 25vh auto;
  width: 50%;
  height: auto;
  background: #000000;
  color: #ffffff;
}
#ppboxHTML3 #ppboxHTML3 {
  padding: 0;
}
#ppboxHTML3 .ppimg{
  max-width: 100%;
}

#home #lastView .page-header{
  border-bottom: none;
}
body.eng #home #lastView .table>thead>tr>th{
  border-bottom: none;
}
body.eng #home #lastView .table-closed>tbody tr:first-child{
  border-top: none;
}
body.eng #home #lastView .table-closed>tbody tr:last-child{
  border-bottom: none;
}
body.eng #home #lastView .table-closed tr th{
  font-family: "MCQGlobal_A_Md";
  line-height: normal; 
}
body.eng #home #lastView .table-closed tr td,
body.eng #home #lastView .table-closed tr td a{
  line-height: 1.42857143;
}
#home #advertisment {
  background-color: #000;
}

#home #line{
  text-align: center;
  font-family: "DBHeaventMedium";
  font-size: 36px;
}
body.eng #home #line{
  font-family: "MCQGlobal_A_Md";
  font-size: 24px;
}
body.eng #home #line .thai_text{
  font-family: "DBHeaventMedium";
  font-size: 24px;
  line-height: 1.42857143;
}
#home #line div img {
  width: auto;
} 

 

.tw_area{
  background-color: #CEE5F5;
  margin: 0 -10px;
  padding: 0 10px;
}
.tw-name{
  font-weight: bold;
  font-size: 27px;
  margin-right: 19%;
  margin-left: 10px;
}
body.eng .tw-name{
  font-size: 18px;
}
.tw_table{
  background-color: #CEE5F5;
  /* max-height: 220px; */
}
.tw_warrant table{
  margin-bottom: 10px;
}
.tw_dsply_name{
  font-weight: bold;
}
.call_table .tw_type{
  padding: 3px 12px;
  background-color: #70C05F;
  border-radius: 5px;
  font-size: 27px;
  color:#FFFFFF;
  font-weight: 500;
}
body.eng .call_table .tw_type{
  font-size: 14px;
}
.tw_area .pdl_10 {
  padding-left: 20px;
}
.put_table .tw_type{
  padding: 3px 12px;
  background-color: #A91F2E;
  color:#FFFFFF;
  border-radius: 5px;
  font-size: 27px;
  font-weight: 500;
}
body.eng .put_table .tw_type{
  font-size: 14px;
}
.tw_ticker{
  font-size: 27px;
  font-weight: bold;
  color: #DD9529;
  padding-left: 10px;
}
.ulist{
  margin-bottom:20px;
  max-height: 300px;
  overflow-y: scroll;
  display: none;
}
.ulist.active{
  display:block;
}
.hottab{
  color:#7f7f7f;
  cursor: pointer;
}
.hottab.active{
  color:#333333;
      font-weight: bold;
}


.hotlist-item-pc {
  padding-left: 10px;
  font-size: 29px;
  font-family: 'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif; 
}

#topmover .hotlist-right .hotlist-item {
  height: auto;
}

#topmover .hotlist-right {
  padding: 0;
}
#topmover .hotlist-row .tw_area {
  margin: 0;
}
#topmover .hotlist-row .tw_area .tw_warrant .tw_title,
#topmover .tw_area .tw_warrant .tw_title {
  display: flex;
  /* align-items: center; */
  margin-top: 10px;
}

#lastView thead {
    background-color: #DFDBCF;
}



#home .slick-slider.show-desk{
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  margin-bottom: -40px;
}
#home .slick-slider.show-desk > .slick-list{
  height: 100%;
}
#home .slick-slider.show-desk > .slick-list > .slick-track{
  height: 100% !important;
}
#home .slick-slider.show-desk > .slick-list > .slick-track > .slick-slide{
  height: 100%;
}
#home .slick-slider.show-desk button.slick-arrow {
  background-color: initial;
  color: #ddd;
  height: 100%;
  left: 0;
  padding: 0;
  width: 65px;
  z-index: 10;
  top: 0;
  transform: translateY(0);
}
#home .slick-slider.show-desk button.slick-arrow:before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAaCAYAAAC+aNwHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHUSURBVDhPlZQ/S8NAGMbzb7DQbg4d/AAiWQotjaBDhoIdBAcXRzcHF7+Lu4KDg90cOjhEoUtJwaVDP4BIB8FCA83QPz7P5RITL23jD65379t73rt7771o/2EwGJyhvff7/RPp0nTZb8X3/XNd1x8xtFarVYBxu16v9woFwKpH6Dw0SzhAHGRrAIoxuYvJZelKMzbkIBeInQ1i8rp2BxDvo+O2q8LxBwTulUql/CMgy1XTNH0M9yJPllhs23agHIFiwzC48jrxyLKsU4ppZwLEYpyZ21egeLlcurVabSJdv3VQVNxsNsfSJTD5MxwOy/jzDeID4VUZLxaLY8dxPqWdYFA8m826GNuRS4EruhB/RGYWIwzDO6zMSstDiFGyo8hUYRJ3o6EKzj3B1pOE5WFgwgUTJO0MTCgTywRLl4K4hQKFw7s/TF9fjKgDeTUttMwVxXAn8/n8mQmXroRMKRet/7gKifIWGAQTfaya+wIZJAiCluu6IW3lLfDKIG5jYrJKGl55pVJ58DxPfFxyXyPhhwTdC9qOcKh0ptPpxdoAJP0djDwKtxsDkE1BcMyvrQGIDPIkzTSXShLzaDQaHbzWG2kKaCPh99IsBnZyjfaNdhV5NO0HR+Tivbr5dpUAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  border-color: #ddd;
  border-width: 0 5px 5px 0;
  content: "";
  height: 30px;
  left: 50%;
  padding: 0;
  position: absolute;
  right: 15px!important;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 20px;
  opacity: 1;
}
#home .slick-slider.show-desk button.slick-arrow.slick-next {
  left: inherit;
  right: 0;
}
#home .slick-slider.show-desk button.slick-arrow.slick-next:before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAaCAYAAAC+aNwHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAG+SURBVDhPlZStTsNQFMfb2wmSdQtyggdAINcWEhCVhOBA7A2Qm0DgcAhI2BsgEEPgRwJJDWZZ5yr2AIglmCVbMrMP/qc9LWz33rX8ktt7zuk9//t9jZQwDA9R+uCKQ4Uw6YPEfVQBSo385XLZcl23TXYe5mZyCkQaEHlhV4tYrVZPqNeSCSHEM6Zzwa4WYZpmyPYmJfzr5ImIyWTSQv2auBKxCKZ5zL6E8H1/DpEGbK0IptnVicS7QARBsGPb9jt6VDaEyBTlCAsbcSgmEyCiKLJns1lXJwJGKH69Xh8m7oYA8V8RSYBgkT5E6Iyo+FosFo7neSOlANHr9Wo4C4FOBOsxxGHztQIEiViW1Ye5l0QkQsGGknK5PEU1TjwZjGJXK5AuJsyDJCIxwvTOLXbWwJkoYf7bzsQY/05oJ6QRUHKlUulsSZ5Sz8ptTJNhKi8QJ58i+ZNDRjaCvGQwx7Zd/k0mMoFqtXqPSnt10XsDB+eN3YxYYDAYPKJBM44ooCfOcRzlbRV4MG7zkre9j/QinbEtkZdMCDS6QT1P3F8Qvy7yMgs0+qAFgp2JYFRtxB/YLQY9nijfKHccKoBh/AAoXNp+5gvhJgAAAABJRU5ErkJggg==);
}
#home .slick-slider button.slick-arrow:hover {
  background-color: hsla(0,0%,57%,.5);
}
#home .slick-slider.show-mob{
  display: none;
}
#home .slick-slider.show-mob ul.slick-dots{
  display: none !important;
}
#home .slick-slider.show-desk ul.slick-dots{
  width: 100%;
  right: 5%;
  bottom: 20%;
  display: flex !important;
  justify-content: flex-end;
}
#home .slick-slider.show-desk ul.slick-dots li{
  width: 14px;
  height: 14px;
  margin: 0px 5px;
}
#home .slick-slider.show-desk ul.slick-dots li button{
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: solid 1px #ddd;
  border-radius: 50%;
}
#home .slick-slider.show-desk ul.slick-dots li.slick-active button{
  background-color: #FECC0A;
  border-color: #FECC0A;
}
#home .slick-slider.show-desk ul.slick-dots li button::before{
  display: none;
}


#home #newsbanner .slick-slider.show-desk{
  margin-bottom: 0px;
}
#home #newsbanner .slick-slider.show-desk .gallery-item{
  background-color: transparent;
  text-align: center;
}
#home #newsbanner .slick-slider.show-desk .gallery-item a{
  display: inline-block;
}
#home #newsbanner .slick-slider.show-desk .gallery-item img{
  width: auto;
  max-width: 100%;
}
#home #newsbanner .slick-slider.show-desk button.slick-arrow{
  height: calc(100% - 7px);
}
#home #newsbanner .slick-slider.show-desk button.slick-arrow:before{
  top: 50%;
  transform: translate(-50%,0%);
}
#home #newsbanner .slick-slider.show-desk ul.slick-dots{
  display: block !important;
  position: absolute;
  bottom: 25px;
  right: inherit;
}
#home #newsbanner .slick-slider.show-desk ul.slick-dots li{
  width: auto;
  height: auto;
}
#home #newsbanner .slick-slider.show-desk ul.slick-dots li button{
  width: 16px;
  height: 16px;
  opacity: 1;
  border-radius: 50%;
  border: solid 1px #c5c5c5;
  background-color: transparent;
}
#home #newsbanner .slick-slider.show-desk ul.slick-dots li.slick-active button{
  background-color: #FECC0A;
  border-color: #FECC0A;
}
#home #newsbanner .slick-slider.show-desk ul.slick-dots li button::before{
  display: none;
}

#home ~ #lightbox .countdown-txt{
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}
body.eng #home ~ #lightbox .countdown-txt,
body.eng #home~#lightbox a.noshow{
  font-size: 12px;
}
#home ~ #lightbox a.noshow{
  color: #333;
  font-size: 18px;
}
#home ~ #lightbox a.btn-close{
  padding: 0px;
  color: #000;
  opacity: 1;
  width: 32px;
  height: 32px;
  left: 2px;
}
#home ~ #lightbox .btn-close::before, 
#home ~ #lightbox .btn-close::after {
  position: absolute;
  left: 15px;
  content: '';
  height: 17px;
  width: 2px;
  background-color: #ffffff;
}

#home #popup-customized h5{
  font-size: 24px;
}
body.eng #home #popup-customized h5 {
  font-size: 16px; 
}

#home #dwtool ~ #ppboxHTML3 img.btn-close{
  top: 15px;
  right: 15px;
}

#home #dwtool .select_align_center {
  text-align: center;
}
#home #dwtool .select_align_center input {
  text-align: center;
  display: flex;
  min-width: 100% !important;
  width: 100% !important;
}

/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (min-width: 992px) and (max-width: 1199px){
  #home .slick-slider.show-desk button.slick-arrow:before{
    top: 50%;
  }
  #home .section-qlink {
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: -40px;
  }
}
@media screen and (max-width: 992px) {
  .overlay-inner {
    width: 90%;
  }
  .overlay-inner .popupbox-content {
    padding-left: 30px;
    padding-right: 30px;
  }
  .news-table table th,
  .news-table table td {
    font-size: 12px;
  }
  
}

@media screen and (max-width: 991px) {
  /* .hotlist-item-pc {
    font-size: 26px;
  } */
  body.eng #home #dwtool .hotlist-right .hotlist-col a {
    max-width: 95px;
  }
  body.eng #home #dwtool .hotlist-right .hotlist-col .hotlist-item.active{
    flex-direction: column;
  }
  body.eng #home #dwtool .hotlist-right .hotlist-col .hotlist-item.active a {
    max-width: 100%;
    line-height: 24px;
  }
  #topindices .table > tbody > tr > td,
  #topindices .table > tbody > tr > th,
  #topindices .table > tfoot > tr > td,
  #topindices .table > tfoot > tr > th,
  #topindices .table > thead > tr > td,
  #topindices .table > thead > tr > th,
  #topdw .table > tbody > tr > td,
  #topdw .table > tbody > tr > th,
  #topdw .table > tfoot > tr > td,
  #topdw .table > tfoot > tr > th,
  #topdw .table > thead > tr > td,
  #topdw .table > thead > tr > th {
    padding: 8px;
  }
  .right-border {
    border-right: 0;
  }
  .section {
    padding: 20px 10px;
    margin-top: 10px;
  }
  .col-left {
    padding-left: 10px;
    padding-right: 5px;
  }
  .col-right {
    padding-left: 5px;
    padding-right: 10px;
  }
  .section-qlink {
    position: relative;
    background-color: #fff;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -20px;
  }
  .section-qlink-achor {
    position: absolute;
    bottom: 20px;
    right: 10px;
  }
  .section-qlink-dwsearch {
    /* background: url("../../assets/images/bg/qlink-bg-dwsearch.png") left bottom no-repeat; */
    min-height: 160px;
  }
  .section-qlink-dwcalculator {
    /* background: url("../../assets/images/bg/qlink-bg-calculator.png") left bottom no-repeat; */
    min-height: 136px;
  }
  .section-qlink-indexdw {
    /* background: url("../../assets/images/bg/qlink-bg-indexdw.png") left bottom no-repeat; */
    min-height: 170px;
    margin-top: 10px;
  }
  .section-qlink-faq {
    /* background: url("../../assets/images/bg/qlink-bg-faq.png") 50px bottom no-repeat; */
    min-height: 144px;
    margin-top: 10px;
  }
  .section-qlink-investoracademy {
    /* background: url("../../assets/images/bg/qlink-bg-investoracademy.png") left bottom no-repeat; */
    min-height: 163px;
    margin-top: 10px;
  }
  #dwcalculator.section .section-p {
    min-height: inherit;
  }
  #lmatrix.section .section-p {
    min-height: 60px;
  }
  .hotlist-row {
    margin-bottom: 0;
  }
  .tabnav .col-xs-3 {
    padding-left: 5px;
    padding-right: 5px;
  }
  /*.video-wrapper video {
        margin-top: inherit;
    }*/
  .gallery-item-txt {
    font-size: 40px;
  }
  #indexfuture table th,
  #indexfuture table td,
  #announcement table th,
  #announcement table td,
  #soldout table th,
  #soldout table td {
    font-size: 14px;
  }
  #home #dwtool #lmatrix p.section-p{
    min-height: 60px;
  }
  #home #lmatrix .ticker {
    padding-top: 4px;
  }
  #ppboxHTML2 .overlay-inner {
    margin: 15vh auto;
    width: 90%;
  }
  .popupbox-content {
    padding: 20px;
  }
  #home #toptradedstock #topTbl table td,
  #home #toptradedstock #topTbl table th {
    font-size: 12px;
  }
  .hometop .swiper-container-horizontal > .swiper-pagination-bullets,
  .hometop .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 17%;
  }
  .hometop
    .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }
  #home .hometop .carousel .control-dots{
    bottom: 5%;
  }
  #home .hometop .carousel .control-dots li.dot{
    width: 12px !important;
    height: 12px !important;
  }
  #home nav.tabnav > .row > .col-md-3{
    padding-left: 5px;
    padding-right: 5px;
  }
  /* #home .nav-dtxt.show{
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -30px);
    width: calc(100% - 20px);
  } */
  #home .section {
    padding: 20px 10px;
    margin-top: 10px;
  }
  #home  #video-dwtool-sm .section.advertising-banner{
    padding: 0px;
  }
  #home #hotlist .hotlist-left,
  #home #hotlist .hotlist-right{
    width: 100%;
  }
  #home #dwtool .hotlist-right .hotlist-row{
    display: flex !important;
    flex-wrap: wrap;
  }
  #home #dwtool .hotlist-right .hotlist-col{
    width: 100%;
    max-width: 100%;
    padding: 0px;
  }
  #home #advertisment .swiper-slide{
    background-color: #000;
  }
  
  #home #qlink-dwsearch .section-btn-wapper .btn.btn-primary{
    min-width: 120px;
    width: auto;
  }
  #home #qlink-dwsearch .section-btn-wapper a > .btn.btn-primary{
    width: 100%;
  }
  #home #qlink-dwsearch .section-btn-wapper a {
    width: 100%;
    max-width: 48%;
  }
  #home #dwtool .holder-qlink-dwchart{
    display: block;
  }
  #home #dwstat > .frame-lgmd > .row > .holder-moneyflow{
    padding-left: 10px;
    padding-right: 5px;
  }
  #home #dwstat > .frame-lgmd > .row > .holder-advertisment{
    padding-right: 10px;
    padding-left: 5px; 
    margin-top: 10px !important;
  }
  #dwstat #video-dwstat-lgmd li.slide .gallery-item{
    padding-bottom: 0px; 
  }
  #dwstat #moneyflow .chartbox{
    margin-bottom: 5px;
    margin-top: 25px;
  }
  #dwstat #moneyflow .chartbox #topInPieChart-lgmd{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #home #dwstat > .frame-lgmd{
    display: flex;
    flex-direction: column;
  }
  
  #dwedu > .frame-lgmd > .row > .col-md-6:first-child,
  #dwnews > .frame-lgmd > .row > .col-md-6:first-child{
    padding-left: 10px;
    padding-right: 5px;
  }
  #dwedu > .frame-lgmd > .row > .col-md-6:last-child,
  #dwnews > .frame-lgmd > .row > .col-md-6:last-child{
    padding-right: 10px;
    padding-left: 5px;
  }
  #home #dwedu #qlink-investoracademy .section-qlink-investoracademy{
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
    min-height: 140px;
    background: url("../../assets/images/bg/qlink-bg-investoracademy.png")
    left bottom no-repeat;
  }
  #dwedu #qlink-investoracademy .section-qlink-investoracademy .section-qlink-achor,
  #dwedu #qlink-dwindex .section-qlink-indexdw .section-qlink-achor,
  #dwedu #qlink-faq .section-qlink-faq .section-qlink-achor,
  #dwnews #qlink-subscribe .section-qlink-subscribe .section-qlink-achor{
    bottom: 20px;
    right: 10px;
  }
  #dwedu #video-dwedu-lgmd{
    display: none;
  }
  #dwedu #video-dwstat-lgmd{
    margin-top: 10px;
  }
  #dwedu #video-dwstat-lgmd ul li .gallery-item,
  #dwnews #video-dwstat-lgmd ul li .gallery-item{
    padding-bottom: 0px;
  }
  #dwedu #video-dwstat-lgmd .carousel-root .carousel:last-child,
  #dwnews #video-dwstat-lgmd .carousel-root .carousel:last-child{
    display: none;
  }
  #home #dwedu #qlink-dwindex .section-qlink-indexdw{
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
    min-height: 147px !important;
    background: url("../../assets/images/bg/qlink-bg-indexdw.png")
    left bottom no-repeat;
  }
  #dwedu #qlink-faq .section-qlink-faq{
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
    min-height: 144px !important;
    background: url("../../assets/images/bg/qlink-bg-faq.png")
    50px bottom no-repeat;
  }
  #dwnews #qlink-subscribe .section-qlink-subscribe{
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
    min-height: 155px;
  }
  #dwnews #video-dwstat-lgmd{
    margin-top: 10px;
  }
  #home .section#photogallery{
    padding: 0px;
  }
  #dwnews #photogallery .gallery-item-wapper .btn.btn-primary {
    display: inline-block !important;
  }
  #dwnews #photogallery .gallery-item-wapper .btn.btn-primary.xs-view{
    display: none !important;
  }
  #home .carousel-slider button.control-arrow{
    display: none;
  }
  #home .slick-slider.show-desk button.slick-arrow:before{
    top: 55%;
  }
  #home .slick-slider.show-desk{
    margin-bottom: -47px;
  }

  #home #subscribeModal .overlay-inner{
    margin: 15vh auto;
    width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .hometop .swiper-container-horizontal > .swiper-pagination-bullets,
  .hometop .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 5%;
  }
}



@media screen and (min-width: 768px) {
  #home .container {
    width: 768px;
  }
  #home .section-qlink-dwsearch {
    min-height: 115px;
  }
  #home #qlink-dwchart p {
    height: 45px;
  }
  #home #moneyflow .section-btn-wapper {
    margin-top: 0px;
  }
  #home .section-qlink-dwmarketturnover {
    min-height: 107px;
  }
  #home #seminar .section-btn-wapper {
    margin-top: 60px !important;
  }
  #home #qlink-dwindex p,
  #home #qlink-faq p {
    height: 43px;
    margin-bottom: 10px;
  }
  .hometop {
    height: 350px;
    margin-bottom: -60px;
  }
  #home #dwedu #qlink-dwindex p,
  #home #dwedu #qlink-faq p{
    height: 43px !important;
  }
}

@media screen and (min-width: 992px) {
  
  #topindices .table > tbody > tr > td,
  #topindices .table > tbody > tr > th,
  #topindices .table > tfoot > tr > td,
  #topindices .table > tfoot > tr > th,
  #topindices .table > thead > tr > td,
  #topindices .table > thead > tr > th,
  #topdw .table > tbody > tr > td,
  #topdw .table > tbody > tr > th,
  #topdw .table > tfoot > tr > td,
  #topdw .table > tfoot > tr > th,
  #topdw .table > thead > tr > td,
  #topdw .table > thead > tr > th {
    padding: 8px;
    padding-left: 4px;
    padding-right: 4px;
  }
  #home .slick-slider.show-desk {
    height: 425px;
  }
  #home #newsbanner .slick-slider.show-desk{
    height: auto;
  }
  
  .tabnav-htxt {
    font-size: 33px;
  }
  body.eng .tabnav-htxt {
    font-size: 22px;
  }
  /*.video-wrapper video {
        margin-top: -20px;
    }*/
  #home .container {
    width: 992px;
  }
  #home .section-qlink-dwsearch {
    min-height: 126px;
  }
  /*#home #lmatrix .section-p {
        height: 130px;
    }*/
  #home #qlink-dwindex p,
  #home #qlink-faq p,
  #home #qlink-dwchart p {
    height: 32px;
  }
  body.eng #home #dwedu #qlink-dwindex p, 
  body.eng #home #dwedu #qlink-faq p, 
  body.eng #home #dwedu #qlink-dwchart p{
    height: auto !important;
  }
  #home #qlink-dwcalculator p {
    height: 148px;
  }
  #home #moneyflow p {
    min-height: auto;
    height: auto;
  }
  #home #moneyflow #topInPieChart-lgmd {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #video-dwstat-lgmd .gallery-item{
    padding-bottom: 0px;
    background-color: #fff;
  }

  #video-dwstat-lgmd .carousel-root > .carousel:last-child{
  display: none;
}
  #home #moneyflow .section-btn-wapper {
    margin-top: 20px;
  }
  #home .section-qlink-dwmarketturnover {
    min-height: 145px;
  }
  #home .section-qlink-indexdw {
    min-height: 170px;
  }
  #home .hometop .carousel .control-dots{
    bottom: 10%;
  }
  #home .hometop .carousel .control-dots li.dot{
    width: 14px !important;
    height: 14px !important;
  }
  #home .section-qlink-indexdw {
    min-height: 170px !important;
  }
  #home #dwedu #qlink-dwindex .section-qlink-indexdw{
    min-height: 154px !important;
  }
}

@media screen and (min-width: 1100px) {
  #home .slick-slider.show-desk ul.slick-dots{
    bottom: 20%;
  }
  #home .slick-slider.show-desk ul.slick-dots li {
    width: 16px;
    height: 16px;
  }
}

@media screen and (min-width: 1200px) {
 
  #topindices .table > tbody > tr > td,
  #topindices .table > tbody > tr > th,
  #topindices .table > tfoot > tr > td,
  #topindices .table > tfoot > tr > th,
  #topindices .table > thead > tr > td,
  #topindices .table > thead > tr > th,
  #topdw .table > tbody > tr > td,
  #topdw .table > tbody > tr > th,
  #topdw .table > tfoot > tr > td,
  #topdw .table > tfoot > tr > th,
  #topdw .table > thead > tr > td,
  #topdw .table > thead > tr > th {
    padding: 8px;
  }
  #home .slick-slider.show-desk{
    height: 528px;
  }
  #home #newsbanner .slick-slider.show-desk{
    height: auto;
  }
  .tabnav-htxt {
    font-size: 33px;
  }
  body.eng .tabnav-htxt {
    font-size: 22px;
    line-height: normal;
  }
  /*.video-wrapper video {
        margin-top: -80px;
    }*/
  #home .container {
    width: 1200px;
  }
  /*#home #lmatrix .section-p {
        height: 103px;
    }*/
  #home .section-qlink-dwmarketturnover {
    min-height: 129px;
  }
  #home #qlink-dwcalculator p {
    height: auto;
  }
  #home #seminar .section-btn-wapper {
    margin-top: 80px !important;
  }
  body.eng #home #dwedu #qlink-dwindex .section-qlink-indexdw {
    min-height: 180px !important;
  }
  #dwedu #seminar table tr td:nth-child(2){
    min-width: 175px;
  }
}

@media screen and (min-width: 1400px) {
  .hometop .swiper-container-horizontal > .swiper-pagination-bullets,
  .hometop .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 22%;
  }
}

@media screen and (min-width: 1500px) {
  .hometop {
    height: 560px;
  }
  /*.video-wrapper video {
        margin-top: -160px;
    }*/
  .hometop .swiper-container-horizontal > .swiper-pagination-bullets,
  .hometop .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 10%;
  }
}

@media screen and (min-width: 1600px) {
  /*.video-wrapper video {
        margin-top: -160px;
    }*/
  .hometop .swiper-container-horizontal > .swiper-pagination-bullets,
  .hometop .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 10%;
  }
}
 
@media screen and (max-width: 360px) {
  .tabnav-htxt {
    font-size: 12px;
  }
}

/*******************************************************************************
 responsive : jumbotron and hometop
*******************************************************************************/

@media screen and (max-width: 680px) {
  .jumbotron h1 {
    font-size: 32px;
  }
  .jumbotron p.lead {
    font-size: 16px;
  }
}

@media screen and (max-width: 450px) {
  .jumbotron h1 {
    font-size: 20px;
  }
  .jumbotron p.lead {
    font-size: 14px;
  }
  .jumbotron .buttonSmallText {
    font-size: 10px;
  }
  .jumbotron .btn {
    min-width: 120px;
    font-size: 12px;
    padding: 4px;
  }
}

@media screen and (max-width: 340px) {
  #home #toptradedstock #topTbl table td,
  #home #toptradedstock #topTbl table th {
    font-size: 8px;
  }
}

.btn-close {
  top: 15px;
  right: 15px;
  width: 16px;
  height: 16px;
}

.carousel .control-dots {
  margin: 14px 0px 43px -78px !important;
  text-align: end !important;
}
.carousel .control-dots .dot {
  background: #fecc0a !important;
  height: 11px !important;
  width: 11px !important;
  box-shadow: 1px 1px 2px white !important;
}

.up {
  color: #6cc263 !important;
}

.down {
  color: #c61c2d !important;
}

/* extra homepage css */
.Warrent_dropdown {
  text-align: center !important;
  /* font-size: 14px; */
  font-weight: 400;
  line-height: 20px;
}
.warrant-search_dropdown {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
#indexfuture .downtick {
  background-color: #C61C2D !important;
}
#indexfuture .uptick {
  background-color: #6CC263 !important;
}
/* =========================================================================================================================================================================== */
 

@media screen and (max-width: 991px){
  .right-border{
      border-right:0;
  }
   .section{
      padding:20px 10px;
      margin-top:10px;
  }
  .col-left{
      padding-left: 10px;
      padding-right: 5px;
  }
  .col-right{
      padding-left:5px;
      padding-right:10px;
  }
  .section-qlink{
      position: relative;
      background-color: #fff;
      margin-left: -10px;
      margin-right:-10px;
      margin-bottom: -20px;
  }

  .section-qlink-achor{
      position:absolute;
      bottom:20px;
      right:10px;
  }

/*   
  .section-qlink-dwsearch{
      background: url("../../img/bg/qlink-bg-dwsearch.png") left bottom no-repeat;
      min-height: 160px;
  }
  .section-qlink-dwcalculator{
      background: url("../../img/bg/qlink-bg-calculator.png") left bottom no-repeat;
      background:unset;
      min-height: 136px;
  }
  .section-qlink-indexdw{
      background: url("../../img/bg/qlink-bg-indexdw.png") left bottom no-repeat;
      min-height: 147px;
      margin-top: 10px;
  }
  .section-qlink-faq{
      background: url("../../img/bg/qlink-bg-faq.png") 50px bottom no-repeat;
      min-height: 144px;
      margin-top: 10px;
  }
  .section-qlink-investoracademy{
      background: url("../../img/bg/qlink-bg-investoracademy.png") left bottom no-repeat;
      min-height: 140px;
      margin-top: 10px;
  }  */

  #dwcalculator.section .section-p {
      min-height: inherit;
  }
  #lmatrix.section .section-p{
      min-height: 60px;
  }
  .hotlist-row{
      margin-bottom:0;
  }
  .tabnav .col-xs-3 {
      padding-left: 5px;
      padding-right: 5px;
  }
  .video-wrapper video{
      margin-top: inherit;
  }
  #home #dwnews #photogallery .gallery-item-txt{
      font-size: 60px;
  }
  body.eng #home #dwnews #photogallery .gallery-item-txt{
    font-size: 40px;
  }
  #qlink-dwsearch .btn.btn-primary{
      min-width:120px;
  }
  #qlink-dwcalculator.section{
      min-height: auto;
  }
  #ppboxHTML2 .overlay-inner {
      margin: 15vh auto;
      width: 90%;
  }
  #ppboxHTML3 .overlay-inner {
      margin: 15vh auto;
      width: 90%;
  }
  .popupbox-content {
      padding: 20px;
  }
  .overlay-inner .popupbox-content {
      padding-left: 30px;
      padding-right: 30px;
  }
  /* #dw28 .newstbl-col-left, #dw28 .newstbl-col-right {
     width: 120px;
  } */


  /* ========== */

  
  #home #toptradedstock #topTbl table td,
  #home #toptradedstock #topTbl table th{
      font-size: 18px;
      padding-left: 2px;
      padding-right: 2px;
  }
  body.eng #home #toptradedstock #topTbl table th,
  body.eng #home #toptradedstock #topTbl table td{
    font-size: 12px;
    padding-left: 2px;
    padding-right: 2px;
  }
  #home #toolstoptradedstock #topTbl table td,
  #home #toolstoptradedstock #topTbl table th{
      font-size: 27px;
      padding-left: 2px;
      padding-right: 2px;
  }
  .tw_table{
      font-size: 19px;
  }
  body.eng .tw_table {
    font-size: 13px;
  }
  .hotlist-col .hotlist-item.index-item{
      display: flex;
      justify-content: space-between;
  }
  #topmover .hotlist-col .hotlist-item.index-item .vmbtn-link{
      flex-direction: column;
  }
  #topmover .hotlist-col .hotlist-item.index-item .vmbtn-link .vmbtn,#topmover .hotlist-col .hotlist-item.index-item .vmbtn-link .vabtn{
      width: 100%;
      margin: 0;
      margin-top: 10px;
      padding: 2px 20px;
  }
  .ulist {
      max-height: 220px;
  }
  .broker-table-holder {
      overflow: scroll;
      max-height: 60vh;
  }
  /* ============== */

  div#home nav.tabnav.container-fluid > .row {
      display: flex;
      flex-wrap: wrap;
    }

  #topmover .hotlist-row .tw_area {
    width: 100%;
  }
  #topmover .visible-sm .hkslist .put_table {
    width: 100%;
  }

  #home nav.tabnav > .row > .col-md-3 .tabnav-item {
    height: auto;
  }
  #home #topmover .visible-xs > .tw_area{
    margin: 0px;
  }


}

@media screen and (max-width: 767px) {
  
  #home .slick-slider.show-desk{
    display: none;
  }
  #home #newsbanner .slick-slider.show-desk{
    display: block;
  }
  #home .slick-slider.show-mob{
    display: block;
  }
  #home .slick-slider.show-mob .gallery-item img{
    margin-top: 45px !important;
  }
  #home #dwnews #photogallery .gallery-item-txt{
    font-size: 24px;
  }
  .tabnav {
    margin-top: -9px;
  }
  #home nav.tabnav > .row > .col-md-3 .tabnav-item{
    height: 78px;
  }
  /*    .tabnav .row{
        margin-left:-16px;
        margin-right:-16px;
    }*/
  .tabnav .col-xs-3 {
    padding-left: 1.5px;
    padding-right: 1.5px;
  }
  .tabnav-htxt {
    font-size: 21px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 0.8;
  }
  body.eng .tabnav-htxt{
    font-size: 14px;
  }
  body.eng .nav-dtxt{
    font-size: 14px;
  }
  .tabnav-img {
    width: 25px;
    height: 25px;
    margin-bottom: 5px;
    background-size: cover !important;
  }
  .tabnav-item {
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
    height: 78px;
  }
  .tabnav .active:after {
    margin-left: -12px;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fecc0a;
    border-left: 12px solid transparent;
  }
  .page-header h1.small {
    font-size: 18px;
  }
  #hotlist {
    min-height: auto;
  }
  .hotlist-item-nm {
    font-size: 17px;
  }
  .hotlist-item-pc {
    font-size: 27px;
  }
  .hotlist-item-eg {
    font-size: 11px;
  }
  #home #newsbanner .slick-slider.show-desk button.slick-arrow{
    display: none !important;
  }
  .section {
    padding: 20px 10px;
    margin-top: 20px;
  }
  .section-qlink {
    margin-left: 0;
    margin-bottom: 0;
  }
  body.eng #home .section-qlink{
    margin-left: 0;
    margin-bottom: 0;
  }
  .section-qlink-achor {
    position: inherit;
    bottom: inherit;
    right: inherit;
    width: 100%;
    text-align: center;
  }
  .section-btn-wapper {
    text-align: center;
  }
  #topSection .section-btn-wapper {
    text-align: right;
  }
  .section-qlink-dwsearch {
    /*        background: inherit;*/
    min-height: inherit;
    margin-top: 30px;
  }
  .banner {
    background-color: #f3f3f4;
    padding-top: 0;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .banner .banner-img {
    width: 100%;
    height: auto;
  }
  .banner .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .section-qlink-dwcalculator {
    min-height: 150px;
    background-size: 80%;
  }
  .section-qlink-dwcalculator .btn {
    margin-top: 90px;
  }
  .right-border {
    border-right: 0;
  }
  
  /*.tabnav-item-dwtool .tabnav-img {
         background: url("../../assets/images/home/dwtool-xs.png") 0 0 no-repeat; 
    }
    .tabnav-item-dwstat .tabnav-img {
         background: url("../../assets/images/home/dwstat-xs.png") 0 0 no-repeat; 
    }
    .tabnav-item-dwedu .tabnav-img {
         background: url("../../assets/images/home/dwedu-xs.png") 0 0 no-repeat; 
    }
    .tabnav-item-dwnews .tabnav-img {
         background: url("../../assets/images/home/dwnews-xs.png") 0 0 no-repeat; 
    }
    .tabnav-item-dwtool.active .tabnav-img,
    .tabnav-item-dwtool:hover .tabnav-img,
    .tabnav-item-dwtool:focus .tabnav-img {
         background: url("../../assets/images/home/dwtool-active-xs.png") 0 0 no-repeat; 
    }
    .tabnav-item-dwstat.active .tabnav-img,
    .tabnav-item-dwstat:hover .tabnav-img,
    .tabnav-item-dwstat:focus .tabnav-img {
         background: url("../../assets/images/home/dwstat-active-xs.png") 0 0 no-repeat; 
    }
    .tabnav-item-dwedu.active .tabnav-img,
    .tabnav-item-dwedu:hover .tabnav-img,
    .tabnav-item-dwedu:focus .tabnav-img {
         background: url("../../assets/images/home/dwedu-active-xs.png") 0 0 no-repeat; 
    }
    .tabnav-item-dwnews.active .tabnav-img,
    .tabnav-item-dwnews:hover .tabnav-img,
    .tabnav-item-dwnews:focus .tabnav-img {
         background: url("../../assets/images/home/dwnews-active-xs.png") 0 0 no-repeat; 
    }*/
 

  

  #toptradeddw h2.small {
    font-size: 14px;
    display: block;
    text-align: center;
  }
  #toptradeddw table,
  #toptradeddw table a {
    font-size: 11px;
  }
  .section-qlink-indexdw {
    /* background: url("../../assets/images/bg/qlink-bg-indexdw.png") center bottom no-repeat; */
    margin-left: -50px;
  }
  .section-qlink-faq {
    /* background: url("../../assets/images/bg/qlink-bg-faq.png") center bottom no-repeat; */
    margin-left: -45px;
  }
  /*.section-qlink-investoracademy {
         background: url("../../assets/images/bg/qlink-bg-investoracademy.png") center bottom no-repeat; 
    }*/
  #indexfuture table th,
  #indexfuture table td,
  #announcement table th,
  #announcement table td,
  #soldout table th,
  #soldout table td {
    font-size: 11px;
  }
  .table-responsive {
    border: 0;
  }
  #lightbox .countdown-txt {
    font-size: 9px;
    top: 4px;
  }
  #home #toptradedstock #topTbl table td,
  #home #toptradedstock #topTbl table th {
    font-size: 11px;
  }
  .hometop .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 40px;
    /*right: 15%;*/
  }
  .hometop
    .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    width: 9px;
    height: 9px;
  }
  /*#home #technicalAnalysis #topSection,*/
  #home #quickHeadlines .section-btn-wapper.section-center/*, #home #qlink-dwsearch .section-btn-wapper.section-center*/ {
    display: block;
  }
  /*#home #qlink-dwsearch #searchSubmitBtn {
        margin-bottom: 5px;
    }*/
  #home #qlink-dwsearch .btn.btn-primary {
    min-width: 150px;
  }
  #home #lmatrix .ricbar {
    height: 24px;
    font-size: 16px;
    justify-content: center;
  }
  #lmatrix #lmtablecontainer table#today_table tr th,
  #lmatrix #lmtablecontainer table#today_table tr th span{
    font-size: 21px;
  }
  body.eng #lmatrix #lmtablecontainer table#today_table tr th,
  body.eng #lmatrix #lmtablecontainer table#today_table tr th span,
  body.eng #lmatrix #lmtablecontainer table tr td.text-content{
    font-size: 14px;
  }
  #lmatrix #lmtablecontainer table tr td.text-content{
    font-size: 21px;
  }
  #home #lmatrix .ticker {
    padding-top: 4px;
  }
  #home #lmatrix .ricbar img {
    margin-top: 4px;
    margin-bottom: 4px;
    width: 25px;
    height: 25px;
  }
  #home #lastView table tr th{
    font-size: 21px;
  }
  body.eng #home #lastView table tr th{
    font-size: 14px;
  }
  #home #lastView table tr td{
    font-size: 21px;
  }
  body.eng #home #lastView table tr td{
    font-size: 14px;
  }
  #home .hometop .carousel .control-dots{
    bottom: 40px;
  }
  #home .hometop .carousel .control-dots li.dot{
    width: 9px !important;
    height: 9px !important;
  }
  #home .carousel-slider button.control-arrow{
    display: none;
  }
  
  #home nav.tabnav > .row > .col-md-3{
    padding-left: 1.5px;
    padding-right: 1.5px;
    width: calc(25% - 0px);
    margin: 0px;
  }

  #home .section#hotlist{
    margin-top: 20px;
  }
  #home .page-header h1.small {
    font-size: 27px !important;
  }
  body.eng #home .page-header h1.small{
    font-size: 18px !important;
  }
  body.eng #home #dwnews #subscribe h1{
    font-size: 18px !important;
  }
  body.eng #home #dwnews #subscribe .section-p,
  body.eng #home #dwnews #subscribe{
    font-size: 14px;
  }
  body.eng .btn{
    font-size: 14px;
  }
  body.eng #home #dwnews .news-title{
    font-size: 14px;
  }
  #home #indexfuture table th,
  #home #indexfuture table td{
    font-size: 21px;
  }
  #home #dwtool .frame-lgmd > .row > .col-left{
    padding-right: 10px;
    display: flex;
    flex-direction: column;
  }
  #home #dwtool .frame-lgmd > .row > .col-left .holder-indexfuture{
    order: 1;
  }
  #home #dwtool .frame-lgmd > .row > .col-left .holder-topmover{
    order: 2;
  }
  #home #dwtool .frame-lgmd > .row > .col-left .holder-newsbanner{
    order: 3;
  }
  #home #dwtool .frame-lgmd > .row > .col-left .holder-qlink-dwsearch{
    order: 2;
  }
  #home .section {
    padding: 20px 10px;
    margin-top: 20px;
  }
  #lmatrix #lmtablecontainer table tr th,
  #lmatrix #lmtablecontainer table tr th span,
  #lmatrix #lmtablecontainer table tr td,
  #lmatrix #lmtablecontainer table tr td span,
  #quickHeadlines #topTbl table th, 
  #quickHeadlines #topTbl table td,
  #quickHeadlines #topTbl table td span{
    font-size: 14px;
  }
  #home #lmatrix #dropdown-livematrix .css-1s2u09g-control, 
  #home #lmatrix #dropdown-livematrix .css-1pahdxg-control{
    max-height: 35px;
    line-height: 18px;
    font-size: 21px; 
  }
  body.eng #home #lmatrix #dropdown-livematrix .css-1pahdxg-control, 
  body.eng #home #lmatrix #dropdown-livematrix .css-1s2u09g-control {
    font-size: 14px;
  }
  #home #qlink-dwsearch .filter-item .Warrent_dropdown > div{
    min-height: 34px;
  }
  #home #qlink-dwsearch .filter-item .Warrent_dropdown > div .css-tlfecz-indicatorContainer,
  #home #qlink-dwsearch .filter-item .Warrent_dropdown > div .css-1gtu0rj-indicatorContainer{
    padding: 6px 8px;
  }
  #home #qlink-dwsearch .section-btn-wapper .btn.btn-primary{
    min-width: auto;
  }
  #home #qlink-dwsearch .section-btn-wapper a{
    width: auto;
    max-width: initial;
  }
  #home #toptradedstock .lastupdatebox{
    font-size: 14px;
  }
  #announcement .soldout .announcement-nolink-table .row .col-md-6{
    width: 50%;
    padding-left: 0;
    padding-right: 0;
  }
  #announcement .soldout .announcement-nolink-table .row .col-md-6 p{
    font-size: 11px;
  }
  #home .section#turnover{
    margin-top: 35px; 
  }
  #home #dwedu .row > .col-md-6:first-child,
  #home #dwedu .row > .col-md-6:last-child{
    display: flex;
    flex-direction: column;
  }
  #home #dwedu .row > .col-md-6:first-child .holder-qlink-dwvideo{
    order: 1;
  }
  #home #dwedu .row > .col-md-6:first-child .holder-qlink-investoracademy{
    order: 2;
  }
  #home #dwedu .row > .col-md-6:first-child .holder-seminar{
    order: 3;
  }
  #home #dwedu .row > .col-md-6:last-child .holder-edu-dwtips{
    order: 1;
  }
  #home #dwedu .row > .col-md-6:last-child .holder-qlink-faq{
    order: 2;
  }
  #home #dwedu .row > .col-md-6:last-child .holder-qlink-dwindex{
    order: 3;
  }
  #home #toptradeddw table.table {
    margin-bottom: 20px;
  }
  #dwstat #toptradeddw #topdw h2.small{
    margin-top: 0px;
  }
  #dwstat #moneyflow .chartbox {
    margin-bottom: 25px;
  }
  #home #dwstat > .frame-lgmd > .row > .holder-advertisment {
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 20px !important;
  }
  #home #toptradeddw #topindices th, 
  #home #toptradeddw #topdw th{
    padding: 8px;
    font-size: 17px;
  }
  body.eng #home #toptradeddw #topindices th, 
  body.eng #home #toptradeddw #topdw th,
  body.eng #home #toptradeddw #topindices td, 
  body.eng #home #toptradeddw #topindices td a, 
  body.eng #home #toptradeddw #topdw td, 
  body.eng #home #toptradeddw #topdw td a{
    font-size: 11px !important;
  }
  #home #toptradeddw #topindices td, 
  #home #toptradeddw #topindices td a, 
  #home #toptradeddw #topdw td, 
  #home #toptradeddw #topdw td a{
    font-size: 17px !important;
  }
  #home #dwstat .row .col-right,
  #home #dwstat .row .col-left{
    padding-left: 10px;
    padding-right: 10px;
  }
  #home #dwstat > .frame-lgmd > .row > .holder-moneyflow ,
  #dwedu > .frame-lgmd > .row > .col-md-6:last-child, 
  #dwnews > .frame-lgmd > .row > .col-md-6:last-child{
    padding-left: 10px;
    padding-right: 10px;
  }
  #home #dwstat > .frame-lgmd > .row > .holder-advertisment .carousel:last-child{
    display: none; 
  }
  #dwedu > .frame-lgmd > .row > .col-md-6:first-child, 
  #dwnews > .frame-lgmd > .row > .col-md-6:first-child{
    padding-left: 10px;
    padding-right: 10px;
  }
  #home #dwedu #qlink-investoracademy .section-qlink-investoracademy{
    margin-left: 0;
    margin-bottom: 0;
    background-position: center bottom;
  }
  #home #seminar tbody td a{
    font-size: 14px;
  }
  #home #dwedu #seminar table tr th{
    font-size: 21px;
  }
  body.eng #home #dwedu #seminar table tr th{
    font-size: 14px;
  }
  body.eng #home #qlink-faq .question-list a{
    font-size: 14px;
  }
  #dwedu #qlink-faq .section-qlink-faq {
    margin-left: -45px;
    margin-bottom: 0px;
    background-position: center bottom;
  }
  #home .section#qlink-faq > p{
    margin-bottom: 10px;
  }
  #home #dwedu #qlink-dwindex .section-qlink-indexdw {
    margin-left: -50px;
    background-position: center bottom;
  }
  #home .section#qlink-dwindex > .section-btn-wapper{
    margin-top: 40px;
  }
  #dwedu #video-dwstat-lgmd {
    margin-top: 20px;
  }
  #dwnews #qlink-subscribe .section-qlink-subscribe {
    margin-left: 0;
    margin-bottom: 0;
  }
  #dwnews #photogallery .gallery-item-wapper .btn.btn-primary {
    display: none !important;
  }
  #dwnews #photogallery .gallery-item-wapper .btn.btn-primary.xs-view {
    display: block !important;
    position: absolute;
    background-color: transparent !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  
  /* #home #toptradedstock .tablist{
    margin-bottom: 0px;
  } */
  #home #toptradedstock #topTbl table td,
  #home #toptradedstock #topTbl table th{
      font-size: 17px;
      padding-left: 2px !important;
      padding-right: 2px !important;
  }
  body.eng #home #toptradedstock #topTbl table th, 
  body.eng #home #toptradedstock #topTbl table td{
    font-size: 11px;
  }
  body.eng #dwstat #toptradeddw .col-sm-6 > h2.small{
    font-size: 14px;
  }
  #dwstat #toptradeddw .col-sm-6 > h2.small{
    font-size: 21px;
  }
  #home #toptradedstock .lastupdatebox {
      font-size: 17px;
  }
  #home #toptradedstock .tablist .tablist-item{
      width: 65px;
  }
  #toolstoptradedstock .tablist{
      margin-bottom: 0px;
  }
  #home #toolstoptradedstock #topTbl table td,
  #home #toolstoptradedstock #topTbl table th{
      font-size: 17px;
  }
  #home #toolstoptradedstock .lastupdatebox {
      font-size: 17px;
  }
  #home #toolstoptradedstock .tablist .tablist-item{
      width: 65px;
  }
  #qlink-dwsearch .searchSubmitBtn{
      height: 43px;
  }

  #home #dwnews .row > .col-md-6{
    display: flex;
    flex-direction: column;
  }
  #home #dwnews .holder-todayhighlight{
    order: 1;
  }
  #home #dwnews .holder-marketcommentary{
    order: 2;
  }
  #home #dwnews .holder-marketwrap{
    order: 3;
  }
  body.eng #home #dwnews #photogallery .gallery-item-txt{
    font-size: 16px;
  }

  #home #dwtool .priceUpdateInfo .bg-tooltip .tooltip-content{
    font-size: 17px;
  }
  body.eng #home #dwtool .priceUpdateInfo .bg-tooltip .tooltip-content{
    font-size: 11px;
  }
  body.eng #home #indexfuture table th,
  body.eng #home #indexfuture table td{
    font-size: 14px;
  }
  body.eng .hotlist-item-nm{
    font-size: 11px;
  }
  body.eng .hotlist-item-pc {
    font-size: 18px;
  }
  body.eng #topmover .hotlist-right .hotlist-item.active{
    display: block;
  }
  body.eng .call_table .tw_type{
    font-size: 14px;
    font-weight: bold;
    color: #DD9529;
  }
  body.eng .put_table .tw_type{
    font-size: 14px;
    font-weight: bold;
    color: #fff;
  }
  body.eng #topmover .tw_area .tw_warrant table tr td{
    font-size: 13px;
  }


}

 
 
 
@media screen and (max-width: 375px) {
  #home #toptradedstock #topTbl table td,
  #home #toptradedstock #topTbl table th{
      font-size: 15px;
  }
  #home #toolstoptradedstock #topTbl table td,
  #home #toolstoptradedstock #topTbl table th{
      font-size: 15px;
  }
}
@media screen and (max-width: 340px) {
  #home #toptradedstock #topTbl table td,
  #home #toptradedstock #topTbl table th{
      font-size: 12px;
  }
  #home #toolstoptradedstock #topTbl table td,
  #home #toolstoptradedstock #topTbl table th{
      font-size: 12px;
  }
}


 