#activities .section img{
    width: 100%;
}
#activities .photo-main {
    border-bottom: 4px solid #FECC0A;
}
#activities .photo-tip{
    text-align: center;
}
#activities .photo-tip h2,
#activities .photo-tip ~ h3{
    margin: 20px 0px 10px;
}
/* #activities .photo-tip h2{
    font-size: 30px;
} */
#activities .section .row{
    margin-left: -10px;
    margin-right: -10px;
}
#activities p{
    margin-bottom: 10px;
}
#activities .a_list .col-sm-4 {
    min-height: 300px;
}
#activities .view_btn{
    display: inline-block;
    background-color: #FECC0A;
    width: 150px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin: auto;
    color: #666666;
}
#activities .photo-tip .tip-text {
    font-size: 36px;
    margin-top: 10px;
    margin-bottom: 5px;
    line-height: 1;
}
body.eng #activities .photo-tip .tip-text{
    font-size: 24px;
    line-height: 1.42857143;
}
body.eng #activities .photo-tip h2{
    font-size: 30px;
}
body.eng #activities .page-header h3{
    font-size: 26px;
}
#activities .a_list .col-sm-4 .photo-sub img.mobile{
    display: none;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    #activities .container-fluid > .row > .col-md-12 > .section{
        padding: 40px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    #activities .section{
        padding: 10px;
    }
    #activities .photo-tip .tip-text{
        font-size: 27px;
        margin-top: 5px;
        margin-bottom: 3px;
    }
    body.eng #activities .photo-tip .tip-text{
        font-size: 18px;
    }
}

@media screen and (max-width: 767px){
    #activities .a_list .col-sm-4 .photo-sub,
    #activities .a_list .col-sm-4 .photo-tip{
        width: 50%;
        border-bottom: 1px solid #cccccc;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    #activities .photo-tip .tip-text{
        padding-left: 10px;
        padding-right: 10px;
        font-size: 24px;
        margin-top: 5px;
        margin-bottom: 3px;
        text-align: left;
    }
    #activities .a_list .col-sm-4 {
        display: flex;
        min-height: auto;
    }
    #activities .a_list .col-sm-4 .photo-tip{
        display: flex;
        align-items: center;
    }
    #activities .a_list .col-sm-4 .photo-tip > a{
        display: none;
    }
    #activities .a_list .col-sm-4 .photo-sub img.desktop{
        display: none;
    }
    #activities .a_list .col-sm-4 .photo-sub img.mobile{
        display: block;
        cursor: pointer;
    }
}