#livematrix .table > tbody > tr > td,
#livematrix .table > tbody > tr > th,
#livematrix .table > tfoot > tr > td,
#livematrix .table > tfoot > tr > th,
#livematrix .table > thead > tr > td,
#livematrix .table > thead > tr > th {
  vertical-align: middle;
}
#livematrix a {
  cursor: pointer;
}
#livematrix .ta_panel{
  display: none;
}
#livematrix #underlying #chartbox {
  margin-bottom: 0px;
}
#livematrix #underlying .chartFrame {
  height: auto!important;
}
#livematrix #chartID {
  height: 500px!important;
}
#livematrix #_result {
  padding-left:5px;
}
#livematrix .time-number2 {
  display: none;  
}
#livematrix .dropdown ul a {
  text-align: center;
}
#livematrix .ricbar {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
}
#livematrix .lmtabcontainer {
  padding: 0;
  margin-top: 18px;
}
#livematrix .lmtab {
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #fecc0a !important;
  line-height: 25px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
#livematrix .lmtab.pressed {
  background-color: #fecc0a;
}
#livematrix #lmtablecontainer {
  min-height: 150px;
}
#livematrix .lbtable td.right-b01 {
  border-right: 1px solid #ddd;
}
#livematrix .lbtable,
#livematrix .lbtable thead th {
  border-bottom: 1px solid #fecc0a;
  text-align: center;
  margin-bottom: 0px;
  user-select: none;
}
#livematrix #five_day_table > tbody > tr > td.subtable {
  width: 20%;
  font-size: 17px;
  padding: 0;
  vertical-align: top;
  border-left: 1px solid #ddd;
}
#livematrix #five_day_table > tbody > tr > td.subtable td {
  white-space: nowrap;
}
#livematrix #five_day_table > tbody > tr > td:first-child {
  border-left: 0;
}
#livematrix #five_day_table tbody th {
  font-family: "DBHeaventMedium";
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  /* padding: 8px 0; */
}
#livematrix #five_day_table table.innertable td {
  text-align: center;
  padding: 2px 0;
}
#livematrix #five_day_table td.bgcolor-02 {
  background-color: rgba(248, 217, 138, 0.6) !important;
}
#livematrix .icon-refresh:after {
  font-family: "FontAwesome";
  content: "\f0e2";
}
#livematrix .color-tip {
  width: 170px;
}
#livematrix .color-tip > div {
  display: inline-block;
  height: 18px;
  line-height: 18px;
}
#livematrix .color-tip .color-icon {
  width: 18px;
}
#livematrix .color-tip .tip-text {
  margin-left: 20px;
}
#livematrix #last_update {
  float: right;
  font-size: 18px;
  margin: auto 0;
  font-family: "DBHeaventMedium";
}
#livematrix
  .container-fluid
  .hidden-xs.hidden-sm.col-md-4
  #dailyhsi
  div:last-child
  img {
  width: auto;
}
#livematrix #srtable {
  margin-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid #ddd;
}
#livematrix #srtable > table {
  margin-bottom: 0;
}
#livematrix #srtable .srtable-leftlbl {
  width: 30%;
}
#livematrix #srtable .srtable-leftval {
  width: 20%;
}
#livematrix #srtable .srtable-rightlbl {
  width: 30%;
}
#livematrix #srtable .srtable-rightval {
  width: 20%;
}
#livematrix #srtable table tbody tr > td {
  white-space: nowrap;
}
#livematrix #srtable table tbody tr > td:nth-child(2) {
  border-right: 20px solid #fff;
}
#livematrix .skipholiday tr {
  background-color: #ff0;
}
#livematrix .skipholiday td {
  background-color: #e4ac76 !important;
  /*background-color: rgba(250,250,0,0.6);*/
}
#livematrix .skipholiday td:nth-child(2) {
  /*background-color: #E4AC76 !important;*/
  background-color: rgba(250, 250, 0, 0.4);
}
#livematrix .hidematrix {
  background-color: #f8d98a !important;
}
#livematrix #lmatrix_tbl_f,
#livematrix .lmatrix_tbl_f {
  font-size: 17px;
}
#livematrix #lmatrix_tbl_f hr {
  margin-bottom: 10px;
  border-top: 1px solid #ddd;
}
#livematrix #lmatrix_tbl_f ol {
  margin-left: -20px;
}
#stickerheader tbody tr+tr{
  display: none!important;
}
#livematrix #lmatrix_tbl_f li {
  margin-bottom: 10px;
}
#livematrix #lmatrix_tbl_f2 {
  font-size: 12px;
}
#livematrix #lmatrix_tbl_f2 hr {
  margin-bottom: 10px;
  border-top: 1px solid #ddd;
}
#livematrix #lmatrix_tbl_f2 ol {
  margin-left: -20px;
}
#livematrix #lmatrix_tbl_f2 li {
  margin-bottom: 10px;
}
#livematrix #orientationcontainer {
  display: none;
  font-size: 18px;
  padding: 8px 0;
  text-align: center;
  background-color: #f3f3f4;
}
/* #livematrix #defination .panel-heading {
  background-color: #666666;
  padding: 4px 10px;
} */

#livematrix #defination .glyphicon {
  color: white;
}
#livematrix #defination #collapse_dwcompare3 {
  font-size: 18px;
  padding: 10px;
}
#livematrix td.text-content {
  background-color: #f3f3f4;
}
#livematrix #p-contract {
  margin-bottom: 20px;
}
#livematrix #p-contract .text-bold {
  font-family: "DBHeaventMedium";
}
#livematrix .indicator {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 18px;
  margin: 5px 0px 25px 0px;
}
body.eng #livematrix .indicator{
  font-size: 12px;
}
#livematrix #lmtablecontainer thead th {
  position: relative;
}
#livematrix #stickerheader {
  position: fixed;
  left: 0;
  right: 0;
  /*    top:52px;*/
  display: none;
  overflow: hidden;
}
#livematrix #stickerheader .container-fluid {
  padding-left: 15px;
  padding-right: 15px;
}
#livematrix #stickerheader .section {
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
#livematrix #stickerheader #lmtablecontainer {
  min-height: inherit;
}
#livematrix #stickerheader #lmtablecontainer table {
  border-bottom: 0;
}
#livematrix .printimg {
  position: absolute;
  width: 20px !important;
  top: 50%;
  left: 0;
  margin-top: -8px;
  cursor: pointer;
}
#livematrix .lm_message_head,
#livematrix ~ #stickerheader .lm_message_head {
  color: #333333;
  font-weight: 700;
  text-align: center;
  padding: 8px 0;
  font-size: 11px;
  font-family: "MCQGlobal_A_Md";
  font-weight: normal;
  line-height: normal;
  position: relative;
}

#livematrix .lm_message,
#livematrix ~ #stickerheader .lm_message {
  padding: 4px 6px;
  text-align: center;
  word-break: break-word;
  background: #f3f3f4;
  border-top: 1px solid #fecc0a;
  border-bottom: 1px solid #fecc0a;
}

#print_content {
  display: none;
}
#livematrix #lmtablecontainer tr.midpoint,
#livematrix #lmtablecontainer tr.midpoint.bgcolor-08 {
  background-color: rgba(9, 124, 207, 0.2);
}
#livematrix #lastview table tr.striped {
  background-color: #f5f6f7;
}
#livematrix .hsi_item.table {
  max-width: 300px;
  text-align: center;
  margin: auto;
  margin-bottom: 10px;
}
#livematrix .hsi_item th {
  text-align: center;
}
#livematrix .sp500_item.table {
  max-width: 300px;
  text-align: center;
  margin: auto;
  margin-bottom: 10px;
}
#livematrix .sp500_item th {
  text-align: center;
}
#livematrix #indexfuture table {
  font-size: 17px;
}
#livematrix #indexfuture table tbody {
  font-size: 21px;
}
/*******************************************************************************
livematrix section combobox
*******************************************************************************/
#livematrix .combobox {
  position: relative;
}
#livematrix .combobox-toggle {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  width: 36px;
  padding: 0;
  cursor: pointer;
}
#livematrix .combobox-toggle,
#livematrix .combobox-toggle:focus,
#livematrix .combobox-toggle:hover,
#livematrix .combobox-toggle:visited {
  color: #000;
}
#livematrix .combobox-input {
  margin: 0;
  padding: 2.6px 10px;
  width: 100%;
  text-align: center;
}
#livematrix .combobox-toggle-icon {
  margin-top: 10px;
  margin-right: 12px;
}
#livematrix .ui-widget {
  font-family: "DBHeaventLight", "DBHeavent";
  font-size: 21px;
}
#livematrix .ui-menu .ui-menu-item {
  text-align: center;
}
#livematrix .ui-menu .ui-menu-item a.ui-state-hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
#livematrix .ui-menu .ui-menu-item a:visited,
#livematrix .ui-menu .ui-menu-item a:focus,
#livematrix .ui-menu .ui-menu-item a:hover {
  color: #262626;
}
#livematrix .ui-autocomplete.ui-menu {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}
#livematrix .highlightbid {
  background-color: #fecc0a;
}
#livematrix #today_table tbody tr .text-content td {
  display: block;
  width: 100%;
}
/* .up{
    color: #6CC263;
} */
#livematrix table .row_HSI td {
  padding-top: 0px !important;
}
#livematrix .bg-tooltip {
  width: 400px;
}
#livematrix .tooltip-content {
  /* padding: 5px; */
  text-align: left;
  word-wrap: break-word;
  font-size: 18px;
  line-height: normal;
  font-style: normal;
  font-family: "DBHeaventLight", "DBHeavent", "Roboto", Arial, sans-serif;
}
/* #livematrix .table-noHeaderborder {
    table-layout: fixed;
} */
#livematrix .table-noHeaderborder tr th {
  padding: 0px;
  font-size: 20px;
  width: 20%;
}
#livematrix .show-index-sm {
  display: none;
}

#livematrix #defination {
  margin-top: 20px;
  margin-bottom: 20px;
}
#livematrix #indexfuture table{
  margin-bottom: 20px;
}
#livematrix #indexfuture table tr th{
  font-size: 17px;
  text-align: left;
  border-bottom-color: #FECC0A;
  width: auto;
  line-height: 1;
  padding: 2px;
}
#livematrix #indexfuture table tr td,
#livematrix #indexfuture table tr td a{
  font-size: 21px;
  text-align: left;
  line-height: 1;
  border: none;
  padding-top: 2px !important;
}
#livematrix #indexfuture table tr td img{
  width: 22px !important;
  margin: 0px !important;
}
#livematrix #indexfuture .priceUpdateInfo em{
  font-size: 24px;
}
#livematrix #dailyhsi .news-title{
  margin-bottom: 0px;
  font-family: 'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif;
  font-size: 24px;
  line-height: 1;
}
#livematrix #dailyhsi .news-summary{
  min-height: auto;
  font-size: 24px;
  line-height: 1;
  overflow: inherit;
}
#livematrix #top_area > .lmtabcontainer > table tr td{
  padding: 8px;
}
#livematrix #lastview table tr:nth-child(even) {
  background-color: #fff;
}
#livematrix ~ #stickerheader{
  position: fixed;
  left: 0;
  right: 0;
  top: 52px;
  display: none;
  overflow: hidden;
}
#livematrix ~ #stickerheader.active{
  display: block;
}
#livematrix ~ #stickerheader .container-fluid{
  padding: 0px 15px;
}
#livematrix ~ #stickerheader .section{
  padding: 0px 40px;
  margin-top: 0px;
}
#livematrix ~ #stickerheader .ricbar{
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 10px;
}
#livematrix ~ #stickerheader #last_update {
  float: right;
  font-size: 18px;
  margin: auto 0;
  font-family: "DBHeaventMedium";
}
body.eng #livematrix ~ #stickerheader #last_update{
  font-size: 12px;
  font-family: "MCQGlobal_A_Md";
}
#livematrix ~ #stickerheader .printimg {
  position: absolute;
  width: 20px;
  top: 50%;
  left: 0;
  margin-top: -8px;
  cursor: pointer;
}
#livematrix ~ #stickerheader #lmtablecontainer th {
  position: relative;
  text-align: center;
}
body.eng #livematrix ~ #stickerheader #lmtablecontainer th {
  font-family: "MCQGlobal_A_Md";
  font-weight: normal;
  line-height: normal;
}
body.eng #livematrix .table>thead>tr>th{
  font-family: "MCQGlobal_A_Md";
  font-weight: normal;
  line-height: normal;
}
body.eng #livematrix .table>tbody>tr>td{
  line-height: 1.42857143;
}
body.eng #livematrix #srtable table tr th{
  line-height: 1.42857143;
}
body.eng #livematrix #lmatrix_tbl_f, 
body.eng #livematrix .lmatrix_tbl_f {
  font-size: 12px;
}
body.eng #livematrix #p-contract .text-bold {
  font-family: "MCQGlobal_A_Md";
}
body.eng #livematrix #last_update{
  font-size: 12px;
  font-family: "MCQGlobal_A_Md";
}
body.eng #livematrix #indexfuture table tr th{
  font-size: 14px;
  line-height: normal;
}
body.eng #livematrix #indexfuture table tr td, 
body.eng #livematrix #indexfuture table tr td a{
  font-size: 14px;
  line-height: 1.42857143;
}
body.eng #livematrix #indexfuture .priceUpdateInfo em {
  font-size: 16px;
}
body.eng #livematrix #dailyhsi .news-title{
  font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.42857143;
}
body.eng #livematrix #dailyhsi .news-summary{
  font-size: 16px;
  line-height: normal;
}
#livematrix .__react_component_tooltip{
  padding: 0px;
  opacity: 1;
}
#livematrix .__react_component_tooltip .bg-tooltip{
  max-width: 280px;
}
#livematrix .__react_component_tooltip .tooltip-content{
  font-size: 21px;
  line-height: 1;
}

#livematrix .select_align_center {
  text-align: center;
}
#livematrix .select_align_center input {
  text-align: center;
  display: flex;
  min-width: 100% !important;
  width: 100% !important;
}
/*******************************************************************************
Responsive @media
*******************************************************************************/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  #livematrix .container-fluid > .row > .col-md-8 > .section{
    padding: 40px;
  }
  #livematrix .container-fluid > .row > .col-md-4 > .section{
    padding: 20px;
  }
  #livematrix .container-fluid > .row > .col-md-4 #indexfuture.section{
    padding: 20px;
  }
}

@media screen and (max-width: 991px) {
  #livematrix #defination .panel-heading button {
    border: none;
    background-color: transparent;
    width: 100%;
    text-align: center;  
    padding: 0px;
  }
  body.eng #livematrix #defination .panel-title {
    font-size: 14px;
  }
  #livematrix #defination .panel-heading {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 2px 10px;
  }
  #livematrix #defination .panel-title {
    font-size: 21px;
  }
  #livematrix #defination #collapse_dwcompare3 #lmatrix_tbl_f{
    display: block !important;
  }
  #livematrix ~ #stickerheader .section {
    padding: 0px 10px;
  }
  body.eng #livematrix ~ #stickerheader #lmtablecontainer th{
    font-family: "MCQGlobal_A_Md";
    line-height: normal;
  }
}

@media screen and (max-width: 768px) { 
  body.eng #livematrix .page-header h1 {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  #livematrix #defination .panel-heading {
    padding: 3.4px 10px;
  }
  #livematrix .table > tbody > tr > td,
  #livematrix .table > tbody > tr > th,
  #livematrix .table > tfoot > tr > td,
  #livematrix .table > tfoot > tr > th,
  #livematrix .table > thead > tr > td,
  #livematrix .table > thead > tr > th {
    padding-left: 6px;
    padding-right: 6px;
  }
  #livematrix #lmtablecontainer table *,
  #livematrix #srtable table * {
    font-size: 17px;
  }
  #livematrix #srtable table tbody tr > td:nth-child(2) {
    border-right: 0;
  }
  #livematrix #srtable > table th,
  #livematrix #srtable > table td {
    padding: 2px;
  }
  #livematrix h3 {
    font-size: 24px;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  #livematrix .lmtab {
    margin-left: 0;
    margin-right: 0;
  }
  #livematrix #five_day_table .subtable th {
    font-size: 18px;
  }
  #livematrix #five_day_table .subtable td {
    font-size: 12px;
  }
  #livematrix .show-index-sm {
    display: block;
  }
  #livematrix #top_area > .lmtabcontainer > table tr td{
    padding: 8px 6px;
    font-size: 21px;
    line-height: 1;
  }
  #livematrix #bottom_area > table tr th,
  #livematrix #bottom_area > table tr td{
    font-size: 21px;
  }
  #livematrix ~ #stickerheader #last_update{
    font-size: 14px;
  }
  #livematrix ~ #stickerheader #lmtablecontainer th{
    font-size: 17px;
  }
  #livematrix .table-noHeaderborder tr th,
  #livematrix .table-noHeaderborder tr td{
    font-size: 21px;
  }
  #livematrix .priceUpdateInfo em{
    font-size: 21px;
  }
  #livematrix .__react_component_tooltip .tooltip-content {
    font-size: 16px;
  }
  body.eng #livematrix .page-header h1 {
    font-size: 18px;
  }
  body.eng #livematrix .table-noHeaderborder tr th, 
  body.eng #livematrix .table-noHeaderborder tr td{
    font-size: 14px;
    padding: 2px !important;
  }
  body.eng #livematrix .priceUpdateInfo em {
    font-size: 14px;
  }
  body.eng #livematrix #top_area > .lmtabcontainer > table tr td {
    font-size: 14px;
    line-height: 1.42857143;
  }
  body.eng #livematrix #lmtablecontainer table *, 
  body.eng #livematrix #srtable table * {
    font-size: 11px;
  }
  body.eng #livematrix #bottom_area > table tr th, 
  body.eng #livematrix #bottom_area > table tr td {
    font-size: 14px;
  }
  body.eng #livematrix ~ #stickerheader #lmtablecontainer th {
    font-size: 11px;
  }
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  #livematrix .time-number1 {
    display: none;
  }
  #livematrix .time-number2 {
    display: block;
  }
  #livematrix .color-tip {
    width: 130px;
  }
  #livematrix .color-tip .tip-text {
    margin-left: 5px;
  }
  #livematrix #last_update {
    float: left;
    font-size: 14px;
    /*margin-top: 10px;*/
  }
  #livematrix .indicator {
    margin-bottom: 50px !important;
  }
}

@media screen and (max-width: 500px) {
  #livematrix #five_day_table .subtable th {
    font-size: 18px;
  }
  #livematrix #five_day_table .subtable td {
    font-size: 9px;
  }
}

@media screen and (max-width: 400px) {
  #livematrix #five_day_table .subtable th {
    font-size: 15px;
  }
  #livematrix #five_day_table .subtable td {
    font-size: 8px;
  }
}

@media print {
  /* lmtablecontainer */
  header {
    display: none !important;
  }
  .footer {
    display: none !important;
  }
  .stripe-bg {
    display: none !important;
  }
  #bottom_area {
    display: none;
  }
  .page-header {
    display: none !important;
  }
  #abc {
    display: none;
  }
  #dropdown-livematrix {
    display: none;
  }
  #p_contract_image {
    display: none;
  }
  .show-index-sm {
    display: none;
  }
  .lmtabcontainer {
    display: none;
  }
  .indicator {
    display: none;
  }
  .index_futures {
    display: none;
  }
  .bgcolor-08 {
    background-color: #f5f6f7 !important;
  }
  #livematrix {
    margin: 0 !important;
  }
  .section {
    margin: 0 !important;
    padding: 0 !important;
  }
  #lmtablecontainer .bgcolor-08 td,
  #lmtablecontainer .bgcolor-08 th {
    background-color: #f5f6f7 !important;
  }
  #lmtablecontainer td.bgcolor-07 {
    background-color: rgba(9, 124, 207, 0.2) !important;
  }
  #orientationcontainer {
    display: none;
  }

  .breadcrumbbar {
    display: none;
  }

  #lmtablecontainer {
    display: block;
  }

  body {
    -webkit-print-color-adjust: exact !important;
  }
  html {
    filter: inherit;
    -webkit-filter: inherit;
    -moz-filter: inherit;
    -ms-filter: inherit;
    -o-filter: inherit;
  }
}

/* @media print {
  header {
    display: none;
  }
  .footer {
    display: none;
  }
  .pageobj * {
    display: none;
  }

  #print_content {
    display: block;
  }

  body {
    -webkit-print-color-adjust: exact !important;
  }

  #lmtablecontainer .bgcolor-08 td,
  #lmtablecontainer .bgcolor-08 th {
    background-color: #f5f6f7 !important;
  }
  #lmtablecontainer td.bgcolor-07 {
    background-color: rgba(9, 124, 207, 0.2) !important;
  }

  #five_day_table .innertable td {
    font-size: 12px !important;
  }

  #five_day_table td.bgcolor-01 {
    background-color: rgba(250, 250, 0, 0.6) !important;
  }
  #five_day_table td.bgcolor-02 {
    background-color: rgba(248, 217, 138, 0.6) !important;
  }

  #lmtablecontainer .skipholiday tr {
    background-color: #f5f6f7;
  }
  #lmtablecontainer .skipholiday td {
    background-color: rgba(228, 172, 118, 0.6) !important;
  }
  #lmtablecontainer .skipholiday td:nth-child(2) {
    background-color: rgba(228, 172, 118, 0.4) !important;
  }

  html {
    filter: inherit;
    -webkit-filter: inherit;
    -moz-filter: inherit;
    -ms-filter: inherit;
    -o-filter: inherit;
  }

  #livematrix .lbtable, #livematrix .lbtable thead th {
    border-bottom: 1px solid #fecc0a !important;
    text-align: center;
    margin-bottom: 0px;
}
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }
  #lmtablecontainer .bgcolor-08 td,
  #lmtablecontainer .bgcolor-08 th {
    background-color: #f5f6f7 !important;
  }
  #lmtablecontainer td.bgcolor-07 {
    background-color: rgba(9, 124, 207, 0.2) !important;
  }
  #five_day_table .innertable td {
    font-size: 12px !important;
  }
  html {
    filter: inherit;
    -webkit-filter: inherit;
    -moz-filter: inherit;
    -ms-filter: inherit;
    -o-filter: inherit;
  }

  #livematrix .lbtable, #livematrix .lbtable thead th {
    border-bottom: 1px solid #fecc0a !important;
    text-align: center;
    margin-bottom: 0px;
}
}


@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }
  #lmtablecontainer .bgcolor-08 td,
  #lmtablecontainer .bgcolor-08 th {
    background-color: #f5f6f7 !important;
  }
  #lmtablecontainer td.bgcolor-07 {
    background-color: rgba(9, 124, 207, 0.2) !important;
  }
  #five_day_table .innertable td {
    font-size: 12px !important;
  }
  html {
    filter: inherit;
    -webkit-filter: inherit;
    -moz-filter: inherit;
    -ms-filter: inherit;
    -o-filter: inherit;
  }
  #livematrix .lbtable, #livematrix .lbtable thead th {
    border-bottom: 1px solid #fecc0a !important;
    text-align: center;
    margin-bottom: 0px;
}
} */
