#unsubscribe {
  background-color: #000;
  color: #ffffff;
  padding-bottom: 30px;
  margin-bottom: 0px;
}
#unsubscribe .pageobj > .container-fluid {
  padding-right: 15px;
  padding-left: 15px;
}
#unsubscribe .container-fluid {
  max-width: 1200px;
}
#unsubscribe .container-fluid .row {
  margin-left: -10px;
  margin-right: -10px;
}
#unsubscribe .unsubscribe_box {
  text-align: center;
  margin: 30px 0 auto;
}
#unsubscribe .unsubscribe_box > h3 {
  font-size: 40px;
}
body.eng #unsubscribe .unsubscribe_box > h3{
  font-size: 30px;
}
#unsubscribe .unsubscribe_box > p,
#unsubscribe .unsubscribe_box > h3,
#unsubscribe .unsubscribe_box button {
  color: #ffffff;
}
#unsubscribe .unsubscribe_box button {
  font-size: 15px;
  margin-top: 20px;
}
#unsubscribe .sharebtn-fb {
  background: url(../../assets/images/unsubscribe/facebook_icon.png) center
    center no-repeat;
  background-size: 50px 50px;
}
#unsubscribe .unsubscribe_box p input {
    margin-right: 25px;
}
#unsubscribe .unsubscribe_box>p {
  font-size: 26px;
}
body.eng #unsubscribe .unsubscribe_box>p {
  font-size: 18px;
}

@media screen and (max-width: 991px) {
  
}

@media screen and (max-width: 767px) {
  
}
