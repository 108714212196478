#subscribe h1, h2, h3, h4, h5, h6, p{
    color: white;
}

#subscribe .bg-layer img{
    height: 100%;
    width: auto;
}
#subscribe .content-layer>.page-header{
    text-align: center !important;
    border: 0;
    display: block !important;
}
#subscribe h1{
    font-size: 105px !important;
    font-family: 'DBHeaventLight';
}
body.eng #subscribe h1{
    font-size: 70px !important;
    font-family: 'MCQGlobal_A_Lt';
}
#subscribe .title_2{
    font-size: 68px;
    line-height: 1;
}
body.eng #subscribe .title_2{
    font-size: 45px;
    line-height: 1.42857143;
}
#subscribe .title_3{
    font-family: 'DBHeaventMedium';
    margin-top: 15px;
    font-size: 24px;
    line-height: 1;
}
body.eng #subscribe .title_3{
    font-size: 16px;
    font-family: 'MCQGlobal_A_Md';
    line-height: 1.42857143;
}
#subscribe .title_4{
    font-size: 24px;
    line-height: 1;
}
body.eng #subscribe .title_4{
    font-size: 16px;
    line-height: 1.42857143;
}
#subscribe #view-sample-btn{
    display: inline-block;
    width: 180px;
    font-size: 30px;
    padding: 2px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #0675C2;
    cursor: pointer;
    line-height: 1;
}
body.eng #subscribe #view-sample-btn{
    font-size: 20px;
    line-height: 1.42857143;
}
#subscribe .section{
    position: relative;
    padding: 0;
    margin-bottom: 40px;
    overflow: hidden;
}
#subscribe .content-layer{
    position: absolute;
    top: 40px;
    bottom: 40px;
    left: 40px;
    right: 40px;
    color: white !important;
}
#subscribe .content-layer .tips-layer{
    width: 92%;
    margin-left: auto;
    margin-right: auto;
}
#subscribe .content-layer .tips-layer .row{
    margin-left: -15px;
    margin-right: -15px;
}
#subscribe .content-layer .tips-layer .col-xs-12,
#subscribe .content-layer .tips-layer .col-sm-4{
    z-index: 100;
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
}
.imgModal{
    background-color: transparent;
    width: auto !important;
    margin: 0px auto;
}
.imgModal .modal-content{
    width: auto !important;
    margin: 0px auto;
}

#subscribe .content-layer .tips-layer .col-sm-4>div{
    border-radius: 5px;
    background-color: white;
    height: 260px;
    padding: 20px 8px;
    text-align: center;
}
#subscribe .content-layer .tip-icon{
    height: 35%;
}
#subscribe .content-layer .tip-icon img{
    height: 100%;
    width: auto;
}
#subscribe .content-layer .tip-item{
    color: #666666;
}
#subscribe .content-layer .tip-item .tip-title{
    font-family: "DBHeaventMedium";
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 8px;
}
body.eng #subscribe .content-layer .tip-item .tip-title{
    font-family: "MCQGlobal_A_Md";
    font-size: 20px;
}
#subscribe .shadow-layer{
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.6);
}

#subscribe .signup-layer{
    width: 530px;
    margin: auto;
    margin-top: 130px;
}
body.eng #subscribe .signup-layer{
    margin-top: 120px;
}
#subscribe .signup-layer h3{
    color: #fff;
    margin: 0px;
}
body.eng #subscribe .signup-layer h3{
    font-size: 26px;
}
#subscribe .signup-layer p.small{
    color: #fff;
    margin-bottom: 10px;
}
#subscribe .title_email{
    font-size: 30px;
    padding: 4px 0;
}
body.eng #subscribe .title_email{
    font-size: 20px;
}
#subscribe #email{
    width: 100%;
    border: 0;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    color: black;
}
#subscribe .warning{
    height: 20px;
    color: red;
    margin-bottom: 0px;
    padding-left: 8px;
}
body.eng #subscribe .warning{
    height: 10px;
    margin-bottom: 5px;
}
#subscribe .warning2{
    height: 20px;
    color: red;
    margin-bottom: 5px;
    padding-left: 8px;
}
#subscribe #submitBtn{
    width: 100%;
    border: 0;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    color: #666666;
    background-color: #FECC0A;
    border-radius: 0px;
    margin-top: 0px;
}
#subscribe #popup-customized h5{
    max-width: 440px;
    padding: 20px;
    margin: auto;
    text-align: center;
    background-color: white;
    color: black;
}
#subscribe input.agreechckbox{
    position: relative;
    top: 3px;
    margin-right: 7px;
    margin-top: 4px;
}
#subscribe .jCaptcha{
    color: #333333;
    width: 200px;
}
.subsimgModal{
    transform: none !important;
    height: 100% !important;
    margin: 0px auto !important;
    background-color: transparent;
    border: none;
    width: auto !important;
    min-width: auto !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.subsimgModal .modal-content{
    background-color: transparent;
    border: none;
    padding: 0px;
    width: auto;
    border-radius: 0px;
    box-shadow: none;
    display: inline-block;
}
.subsimgModal .modal-content .btn-close{
    width: 20px;
    height: 20px;
}
.subsimgModal .modal-content .image{
    width: auto;
    height: auto;
    max-width: 100vw;
    max-height: 100vh;
    margin: auto;
}
.modal-backdrop.fade.show{
    opacity: 0.6;
}
.modal.fade.show{
    opacity: 1;
}
.success-speak.subscribe{
    position: relative !important;
    margin: 0px auto;
    max-width: 440px;
    transform: none !important;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.success-speak.subscribe h4{
    margin: 0px;
    padding: 20px;
    background-color: #fff;
    color: #000;
    font-size: 24px;
    line-height: 1;
    font-family: 'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif;
    text-align: center;
}
body.eng .success-speak.subscribe h4{
    font-size: 16px;
    font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
    line-height: normal;
}







/* ============ */







@media screen and (min-width: 768px) and (max-width: 991px){
    #subscribe .content-layer{
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
    }
    #subscribe h1{
        font-size: 50px;
    }
    #subscribe .title_4{
        font-size: 16px;
    }
    #subscribe .content-layer .tips-layer{
        font-size: 14px;
        line-height: 1.2;
        width: 95%;
    }

    #subscribe .content-layer .tips-layer .col-sm-4>div{
        height: 240px;
        padding: 14px 8px;
    }
    #subscribe .shadow-layer{
        position: absolute;
        top: 43%;
    }
    #subscribe .signup-layer{
        margin-top: 180px;
    }

    #subscribe h1 {
        font-size: 75px !important;
    }
    #subscribe .title_4 {
        font-size: 24px;
    }
    #subscribe .content-layer .tip-item {
        font-size: 21px;
    }
    #subscribe .section .hidden-sm.hidden-xs{
        display: block !important;
        height: 900px !important;
    }
    body.eng #subscribe h1{
        font-size: 50px !important;
    }
    body.eng #subscribe .content-layer .tip-item{
        font-size: 14px;
    }
    body.eng #subscribe .signup-layer {
        margin-top: 180px;
    }
}

@media screen and (max-width: 767px){
    #subscribe .page-header h1{
        font-size: 32px;
    }
    #subscribe .title_2{
        font-size: 33px;
    }
    #subscribe .title_3{
        font-size: 21px;
    }
    #subscribe .title_4{
        font-size: 18px;
    }

    #subscribe #view-sample-btn{
        font-size: 21px;
        padding: 6px;
    }

    #subscribe .section{
        height: 1520px;
        overflow: hidden;
    }

    #subscribe .bg-layer.visible-xs-block img{
        width: auto;
        height: 100%;
    }

    #subscribe .content-layer{
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
    }

    #subscribe .content-layer .tips-layer .col-sm-4>div{
        height: 220px;
        padding: 10px 8px;
        max-width: 300px;
        margin: auto;
    }

    #subscribe .content-layer .tip-item .tip-title{
        font-size: 27px;
        margin: 4px 0;
    }

    #subscribe .shadow-layer{
        position: static;
        max-width: 300px;
        margin: auto;
        margin-top: 40px;
        padding: 20px 10px;
    }
    #subscribe .signup-layer{
        width: 100%;
        margin: 0;
    }
    #subscribe .page-header{
        margin-top: 0;
    }


    #subscribe h1 {
        font-size: 48px !important;
    }
    #subscribe .section .hidden-sm.hidden-xs{
        display: block !important;
        height: 100% !important;
    }
    #subscribe .section{
        /* height: 1340px; */
    }
    #subscribe .bg-layer img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    .subsimgModal{
        background-color: #000;
    }
    body.eng #subscribe h1{
        font-size: 32px !important;
    }
    body.eng #subscribe .title_2{
        font-size: 22px;
    }
    body.eng #subscribe .title_3{
        font-size: 14px;
    }
    body.eng #subscribe .title_4{
        font-size: 12px;
    }
    body.eng #subscribe #view-sample-btn{
        font-size: 14px;
    }
    body.eng #subscribe .content-layer .tip-item .tip-title{
        font-size: 18px;
    }
    body.eng #subscribe .signup-layer {
        margin-top: 0px;
    }
}