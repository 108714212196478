#contactus a{
    color: inherit;
}
#contactus .outer_area{
    text-align: center;
    padding: 10px 0;
}
#contactus .outer_area img{
    width: 50px;
}
#contactus .outer_area .inner_title{
    font-family:"DBHeaventMedium";
}
#contactus .col-md-12 > .section > .row{
    margin-left: -10px;
    margin-right: -10px;
}
body.eng #contactus a{
    color: inherit;
}
body.eng #contactus .outer_area{
    text-align: center;
    padding: 10px 0;
}
body.eng #contactus .outer_area img{
    width: 50px;
}
body.eng #contactus .outer_area .inner_title{
    font-family:"MCQGlobal_A_Md";
}


#termsofuse .title{
    font-size: 26px;
    color:#000000;
    margin-bottom: 10px;
    font-weight: 500;
}
body.eng #termsofuse .title{
    font-size: 17px;
}


#privacypolicy .title{
    font-size: 26px;
    color:#000000;
    margin-bottom: 10px;
    font-weight: 500;
}
#privacypolicy li{
    margin-bottom: 10px;
}
body.eng #privacypolicy .title{
    font-size: 17px;
}


#importantinformation .title{
    font-size: 26px;
    color:#000000;
    margin-bottom: 10px;
    font-weight: 500;
}
body.eng #importantinformation .title{
    font-size: 17px;
    color:#000000;
    margin-bottom: 10px;
}
body.eng #importantinformation table.table tr td{
    line-height: 1.42857143;
}






@media screen and (min-width: 992px) and (max-width: 1199px) {
    #disclaimers .container-fluid > .row > .col-md-12 > .section,
    #contactus .container-fluid > .row > .col-md-12 > .section,
    #termsofuse .container-fluid > .row > .col-md-12 > .section,
    #privacypolicy .container-fluid > .row > .col-md-12 > .section,
    #importantinformation .container-fluid > .row > .col-md-12 > .section{
        padding: 40px;
    }
}

@media screen and (max-width: 991px) {
    #contactus .outer_area .inner_title{
        font-size: 24px;
    }
    body.eng #contactus .outer_area .inner_title{
        font-size: 16px;
    }
}

@media screen and (max-width: 767px) {
    body.eng #disclaimers .page-header h1,
    body.eng #contactus .page-header h1,
    body.eng #termsofuse .page-header h1,
    body.eng #privacypolicy .page-header h1,
    body.eng #importantinformation .page-header h1{
        font-size: 18px;
    }


    #contactus .outer_area .inner_title {
        font-size: 21px;
    }
    body.eng #contactus .outer_area .inner_title {
        font-size: 14px;
    }


    #termsofuse .title{
        font-size: 21px;
    }
    body.eng #termsofuse .title{
        font-size: 14px;
    }


    #privacypolicy .title{
        font-size: 21px;
    }
    body.eng #privacypolicy .title{
        font-size: 14px;
    }


    #importantinformation .title{
        font-size: 21px;
    }
    body.eng #importantinformation .title{
        font-size: 14px;
    }
    #importantinformation table.table tr td{
        font-size: 21px;
    }
    body.eng #importantinformation table.table tr td {
        font-size: 14px;
    }
}
