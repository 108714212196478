#supertrader1 .section{
    font-family: 'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif;
    font-size: 24px;
    line-height: 1;
}
#supertrader1 .section .btn {
    font-size: 24px;
    line-height: 1;
}
body.eng #supertrader1 .section .btn{
    line-height: 1.42857143;
}
#supertrader1 .page-header h1, 
#supertrader1 .page-header h2, 
#supertrader1 .page-header h3, 
#supertrader1 .page-header h4, 
#supertrader1 .page-header h5, 
#supertrader1 .page-header h6,
#supertrader1 .page-header span {
    font-family: 'DBHeaventLight';
    margin: 0;
}
#supertrader1  h1 {
    font-size: 54px;
}
#supertrader1 h1, 
#supertrader1 h2, 
#supertrader1 h3, 
#supertrader1 h4, 
#supertrader1 h5, 
#supertrader1 h6 {
    line-height: 1;
}
#supertrader1 .fbSharebtn {
    font-style: italic;
    display: inline-block;
    width: 200px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin: 10px 0 10px 10px;
    cursor: pointer;
    color: white;
    background-color: #666;
    font-size: 16px;
    font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
}
#supertrader1 .fbSharebtn>i {
    margin-right: 20px;
}
#supertrader1 .row{
    margin-left: -10px;
    margin-right: -10px;
}
#supertrader1 .part{
    margin-top: 0;
    color: #FFFFFF;
    background-color: #000000;
    padding: 40px;
    padding-top: 0;
}
#supertrader1 .page-header{
    display: flex;
}
#supertrader1 .btn-area{
    margin-left: auto;
}
#supertrader1 .fbSharebtn{
    margin: 0;
}
#supertrader1 input{
    color:#333333;
}
#supertrader1 .section img{
    width: 100%;
}
#supertrader1 .top-banner{
    padding: 0;
}
#supertrader1 .from-area{
    background-color: #333333;
    padding: 20px;
}
#supertrader1 .section-header{
    font-weight: 800;
    font-size: 30px;
    text-align: center;
}
#supertrader1 .input-area{
    width: 70%;
    margin: auto;
    padding-bottom: 20px;
}
#supertrader1 .input-area .txt-input{
    width: 100%;
}
#supertrader1 .input-area .txt-input.fileUpload {
    min-width: auto;
    line-height: 1;
}
body.eng #supertrader1 .input-area .txt-input.fileUpload{
    line-height: 1.42857143;
}
#supertrader1 .fileUpload{
    padding: 2px 12px;
}
#supertrader1 .submit-area{
    max-width: 200px;
    margin: auto;
    padding-bottom: 20px;
}
#supertrader1 .submit-area input{
    width: 100%
}
#supertrader1 .note{
    text-align: center;
    padding-bottom: 20px;
}
#supertrader1 .terms{
    font-weight: 800;
    color:#FFFFFF;
}
#supertrader1 .hsi-btn{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
#supertrader1 .hsi-link{
    max-width: 200px;
    margin: 0 5px;
    text-align: center;
}
#supertrader1 .hsi-link p{
    color: #000000;
    font-weight: 800;
    margin: auto;
}
#supertrader1 .disclaimer,
#supertrader1 .disclaimer em{
    font-size: 18px;
}
#supertrader1 .disclaimer a, 
#supertrader1 .terms-area a{
    color: #F2C900;
    text-decoration: underline;
}
#supertrader1 #popup-customized h5{
    max-width: 440px;
    padding: 20px;
    margin: auto;
    text-align: center;
    background-color: white;
    color: black;
}

.success-speak.subscribe.activity .modal-content{
    width: 100%;
    box-shadow: none;
    border-radius: 0px;
    padding: 0px;
    border: none;
}
.success-speak.subscribe.activity .modal-content .modal-body{
    padding: 0px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    #supertrader1 .container-fluid > .section{
        padding: 40px;
    }
}

@media screen and (max-width: 767px){
    #supertrader1 .page-header{
        display: block;
    }
    #supertrader1 .page-header h1{
        font-size: 27px;
    }
    #supertrader1 .btn-area{
        margin: 0px;
    }
    #supertrader1 .fbSharebtn{
        width: 100%;
    }
    #supertrader1 .section,
    #supertrader1 .section .btn{
        font-size: 21px;
    }
    body.eng #supertrader1 .page-header h1 {
        font-size: 18px;
    }
    body.eng #supertrader1 .fbSharebtn{
        width: 200px;
    }
    body.eng #supertrader1 .section, 
    body.eng #supertrader1 .section .btn {
        font-size: 24px;
    }
}