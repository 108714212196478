#individualtip .pnbar-prev a, 
#individualtip .pnbar-prev a:visited, 
#individualtip .pnbar-prev a:hover, 
#individualtip .pnbar-next a, 
#individualtip .pnbar-next a:visited, 
#individualtip .pnbar-next a:hover {
    font-family: "DBHeaventMedium";
    font-size: 21px;
    color: #0675C2;
}
body.eng #individualtip .pnbar-prev a, 
body.eng #individualtip .pnbar-prev a:visited, 
body.eng #individualtip .pnbar-prev a:hover, 
body.eng #individualtip .pnbar-next a, 
body.eng #individualtip .pnbar-next a:visited, 
body.eng #individualtip .pnbar-next a:hover{
    font-family: "MCQGlobal_A_Md";
    font-size: 14px;
}




@media screen and (min-width: 992px) and (max-width: 1199px) {
    #individualtip .container-fluid > .section{
        padding: 40px;
    }
    
}

@media screen and (max-width: 767px) {
    body.eng #individualtip .page-header h1{
        font-size: 18px;
    }
}