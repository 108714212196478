  
#dwvideo .gallery-item{
    max-height: 1170px;
    cursor: pointer;
}
#dwvideo .gallery, #dwvideo .videorack{
    margin-left: -40px;
    margin-right: -40px;
}
#dwvideo .section-p{
    min-height: inherit;
    margin-bottom: 20px;
}
#dwvideo .videorack .videorack-title{
    padding-left:40px;
    padding-right:40px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(204, 204, 204, 1);
    font-size: 30px;
}
body.eng #dwvideo .videorack .videorack-title{
    font-size: 20px;
}
#dwvideo .videorack-body{
    position: relative;
    overflow: hidden;
    margin: 20px 40px;
    padding-left: 30px;
    padding-right: 30px;
}
#dwvideo .videorack-nav{
    position: absolute;
    background-color: rgba(245, 246, 247, 1);
    width:30px;
    top:0;
    bottom:0;
    height: 100%;
}
#dwvideo .videorack-nav.backward{
    left:0;
}
#dwvideo .videorack-nav.forward{
    right:0;
}

#dwvideo .videorack-nav-icon{
    display: block;
    position: absolute;
    width: 16px;
    height: 26px;
    left: 8px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
#dwvideo .videorack-nav.backward .videorack-nav-icon{
    background: url("../../../assets/images/bullet/nav-backward.png") center center no-repeat; 
}
#dwvideo .videorack-nav.forward .videorack-nav-icon{
    background: url("../../../assets/images/bullet/nav-forward.png") center center no-repeat; 
}
#dwvideo .swiper-button-next, #dwvideo .swiper-container-rtl .swiper-button-prev,
#dwvideo .swiper-button-prev, #dwvideo .swiper-container-rtl .swiper-button-next{
    background-image: none;
}
#dwvideo .swiper-button-next, #dwvideo .swiper-button-prev{
    margin-top: 0;
}
#dwvideo .videorack-page{
    table-layout: fixed;
/*    margin-left: auto;
    margin-right: auto;*/
}
#dwvideo .videorack-page td{
    max-width: 330px;
    border-left: 10px solid white;
    border-right: 10px solid white;
    vertical-align: top;
}
#dwvideo .videorack-item{
    width: 100%;
    cursor: pointer;
}
#dwvideo .videorack-item-hidden{
    visibility: hidden;
}
#dwvideo .videorack-item-txt{
    margin-top: 6px;
    height: 46px;
    overflow: hidden;
    text-overflow: ellipsis;
}

#dwvideo .tablist{
    position: relative;
    margin-top: 20px;
    margin-bottom: 10px;
}
#dwvideo .tablist-item{
    width:100%;
    height:40px;
    display:table;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom:5px solid #E0E0E0;
    background-color:#EEF0F2;
    cursor:pointer;
}
#dwvideo .tablist table{
    table-layout:fixed;
    width:100%
}
#dwvideo .tablist table td{
    border-right:10px solid #fff;
    width: 50%;
}
#dwvideo .tablist table td:last-child{
    border-right:0;
}
#dwvideo .tablist-item-txt{
    display:table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
    line-height: normal;
    padding: 0 5px;
}
#dwvideo .videolist-item{
    max-width: 320px;   
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    cursor: pointer;
}
#dwvideo .videolist-item-hidden{
    visibility: hidden;
}
#dwvideo .videolist-item-txt{
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}
#dwvideo .tablist .active .tablist-item{
    background-color: #FECC0A;
    border-bottom:5px solid #333;
}
#dwvideo .text-bold{
    font-weight: bold;
}

/*******************************************************************************
popup
*******************************************************************************/
.hotline-layer{
    width: 100%;
    overflow: hidden;
}
.hotline-layer iframe{
    width: 100%;
    height: 50vh;
}
.overlay {
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0,0,0,0.6);
}
.overlay-inner {
    margin: 15vh auto;
    width: 80%;
    height: auto;
    background: #FFFFFF;
}
#ppboxHTML2 .overlay-inner {
    margin: 25vh auto;
    width: 50%;
    height: auto;
    background: #000000;
    color: #ffffff;
}
.popupbox-content {
    padding: 60px;
}
.btn-close {
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px;
}
/* .noscroll{position:relative;overflow:hidden;} */

#ppboxHTML2 .page-header {
    margin-top: 0px;
}

#ppboxHTML2 p, #ppboxHTML2 h3 {
    color: #ffffff;
}

#ppboxHTML2 input {
    width: 100%;
    margin-bottom: 10px;
    color: #333333;
}

/* =============== */

#dwvideo .gallery .swiper-pagination span {
    border-radius: 50px;
}

#dwvideo .gallery .swiper-slide {
    width: 100% !important;
}
#dwvideo .videorack-body .swiper-slide {
    width: 100% !important;
}
#dwvideo #videogallery > .slick-slider{
    margin-right: -40px;
    margin-left: -40px;
    margin-bottom: -7px;
}
#dwvideo #videogallery > .slick-slider > button{
    display: none !important ;
}
#dwvideo #videogallery ul.slick-dots{
    bottom: 25px;
}
#dwvideo #videogallery ul.slick-dots li{
    width: 16px;
    height: 16px;
}
#dwvideo #videogallery ul.slick-dots li button::before{
    display: none;
}
#dwvideo #videogallery ul.slick-dots li button{
    padding: 0px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: solid 1px #ddd;
    background-color: transparent;
}
#dwvideo #videogallery ul.slick-dots li.slick-active button{
    background-color: #FECC0A;
    border-color: #FECC0A;
}

#dwvideo .videorack-custom .slick-list{
    border-left: 5px solid white;
    border-right: 5px solid white;
    margin-right: 25px;
}
#dwvideo .videorack-custom .slick-track{
    margin: 0px;
}
#dwvideo .videorack-custom .slick-track .slick-slide{
    max-width: 330px;
    width: 100% !important;
    border-left: 5px solid white;
    border-right: 5px solid white;
}
#dwvideo .videorack-custom button.slick-arrow,
#dwvideo .videorack-custom button.control-arrow{
    width: 30px;
    height: 100%;
    background-color: rgba(245, 246, 247, 1);
    opacity: 1;
    display: block;
    padding: 0px;
}
#dwvideo .videorack-custom button.slick-arrow::before,
#dwvideo .videorack-custom button.control-arrow::before{
    content: "";
    opacity: 1;
    width: 16px;
    height: 26px;
    display: block;
    background-repeat: no-repeat;
    margin: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
}
#dwvideo .videorack-custom button.slick-arrow.slick-prev{
    left: -30px;
}
#dwvideo .videorack-custom button.slick-arrow.slick-prev::before,
#dwvideo .videorack-custom button.control-arrow.control-prev::before{
    background-image: url("../../../assets/images/bullet/nav-backward.png");
}
#dwvideo .videorack-custom button.slick-arrow.slick-next{
    right: -30px;
}
#dwvideo .videorack-custom button.slick-arrow.slick-next::before,
#dwvideo .videorack-custom button.control-arrow.control-next::before{
    background-image: url("../../../assets/images/bullet/nav-forward.png");
}
#dwvideo .videorack-custom button.slick-arrow.slick-disabled,
#dwvideo .videorack-custom button.control-arrow.control-disabled{
    cursor: default;
    opacity: 0.35;
}
#dwvideo #gsrack .videorack-item-txt,
#dwvideo #edurack .videorack-item-txt,
#dwvideo #dmarack .videorack-item-txt{
    text-align: left;
    margin-bottom: 7px;
}
#dwvideo #testimonialrack .videorack-item-txt{
    text-align: left;
}
#dwvideo #gsrack .carousel > .thumbs-wrapper,
#dwvideo #testimonialrack .carousel > .thumbs-wrapper,
#dwvideo #edurack .carousel > .thumbs-wrapper,
#dwvideo #dmarack .carousel > .thumbs-wrapper{
    display: none;
}
#dwvideo #gsrack .videorack-body,
#dwvideo #testimonialrack .videorack-body,
#dwvideo #edurack .videorack-body,
#dwvideo #dmarack .videorack-body{
    padding: 0px;
}
#dwvideo #gsrack .videorack-body .carousel.carousel-slider,
#dwvideo #testimonialrack .videorack-body .carousel.carousel-slider,
#dwvideo #edurack .videorack-body .carousel.carousel-slider,
#dwvideo #dmarack .videorack-body .carousel.carousel-slider{ 
    padding-left: 30px;
    padding-right: 30px;
}

#dwvideo #dmarack #ppboxHTML2{ 
    display: block;
}
#dwvideo #dmarack #ppboxHTML2 .btn-close{
    top: 15px;
    right: 15px;
}


@media screen and (min-width: 992px) and (max-width: 1199px) {
    #dwvideo .container-fluid > .section{
        padding: 40px;
    }
}

@media screen and (max-width: 992px){
    #dwvideo .gallery, #dwvideo .videorack{
        margin-left: -10px;
        margin-right: -10px;
    }
    #dwvideo .videorack .videorack-title{
        padding-left:20px;
        padding-right:20px;
        height: 30px;
        line-height: 30px;
        font-size: 21px;
    }
    #dwvideo .videorack-item-txt{
        font-size: 21px;
    }
    #dwvideo .videorack-body{
        margin: 20px 20px;
    }
    
    #ppboxHTML2 .overlay-inner {
        margin: 15vh auto;
        width: 90%;
    }
    .popupbox-content {
        padding: 20px;
    }
    .overlay-inner .popupbox-content {
        padding-left: 30px;
        padding-right: 30px;
    }
    #dwvideo #videogallery > .slick-slider {
        margin-right: -10px;
        margin-left: -10px;
    }
    #dwvideo #dmarack ul.slider li.slide > .slide-tab,
    #dwvideo #gsrack ul.slider li.slide > .slide-tab,
    #dwvideo #edurack ul.slider li.slide > .slide-tab{
        width: 100% !important;
    }
    body.eng #dwvideo .videorack .videorack-title{
        font-size: 14px;
    }
    body.eng #dwvideo .videorack-item-txt{
        font-size: 14px;
    }
    #dwvideo #videogallery > .slick-slider > button{
        display: none !important ;
    }
}

@media screen and (max-width: 768px){
    body.eng #dwvideo .page-header h1 {
        font-size: 36px;
    }
}

@media screen and (max-width: 767px){
    body.eng #dwvideo .page-header h1 {
        font-size: 18px;
    }
    body.eng #dwvideo .tablist-item-txt{
        font-size: 13px;
    }
    #dwvideo ~ #ppboxHTML2{
        display: block;
    }
    #dwvideo ~ #ppboxHTML2 .btn-close {
        top: 15px;
        right: 15px;
        width: 16px;
        height: 16px;
    }
}