#dwcompare .table>tbody>tr>td,
#dwcompare .table>tbody>tr>th,
#dwcompare .table>tfoot>tr>td,
#dwcompare .table>tfoot>tr>th,
#dwcompare .table>thead>tr>td,
#dwcompare .table>thead>tr>th {
    padding-left: 2px;
    padding-right: 2px;
    vertical-align: middle;
}
#dwcompare .table{
    font-size: 14px;
}
#dwcompare .panel-collapse>div{
    min-height: 180px;
    max-height: 260px;
    overflow-y: auto;
}
#dwcompare .bold{
    font-weight: bold !important;
}
#dwcompare h4#collapse_title2{
    font-size: 24px;
    padding: 0px 25px 0px 0px;
    margin-bottom: 0px;
}
body.eng #dwcompare h4#collapse_title2{
    font-size: 16px;
}
#dwcompare h4#collapse_title2 i{
    position: absolute;
    right:10px;
}
#dwcompare #accordion .panel-heading{
    position: relative;
}
#dwcompare #accordion .panel-heading a{
    color: #333333;
}

#dwcompare .disabled-area {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ddd;
    z-index: 1;
    -khtml-opacity: 0.6; 
    -moz-opacity: 0.6; 
    -ms-filter:"alpha(opacity=60)";
    filter: alpha(opacity=60);
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.6);
    opacity:0.6; 
}
#dwcompare #accordion .panel-collapse .table{
    margin: 0;
    text-align: center;
}
#dwcompare #accordion .panel-collapse .table td{
    white-space: nowrap;
}
#dwcompare #collapse_dwcompare1 .table-striped td{
    cursor: pointer;
}
#dwcompare #collapse_dwcompare2 .table-striped>tbody>tr:nth-of-type(odd){
    background-color: transparent;
}
#dwcompare #collapse_dwcompare2 .table-striped>tbody>tr:first-child,
#dwcompare #collapse_dwcompare2 .table-striped>tbody>tr:nth-of-type(even){
    background-color: #F5F6F7;
}

#dwcompare #orientationcontainer{
    display: none;
    border-radius: 6px;
    padding: 8px 0;
    text-align: center;
    background-color: #E8E8E8;
}


#dwcompare .table-striped > tbody > tr:nth-of-type(odd){
    --bs-table-striped-bg: transparent;
}
#dwcompare table {
    border-collapse: collapse;
}
#dwcompare .css-b62m3t-container{
    width: 100%;
    text-align: center;
}
#dwcompare .css-b62m3t-container .css-1s2u09g-control{
    border-color: #999;
    border-radius: 3px;
}
#dwcompare .css-b62m3t-container > #react-select-3-listbox,
#dwcompare .css-b62m3t-container > #react-select-2-listbox{
    z-index: 100;
    margin-top: 0px;
}


#dwcompare #tradeBtn1{
    margin-left: 0;
}
#dwcompare #tradeBtn1 a.tradeEl{
    min-width: auto;
}
#dwcompare .css-b62m3t-container .css-1pahdxg-control ~ div{
    z-index: 100;
    margin-top: 1px;
}
#dwcompare span.css-1okebmr-indicatorSeparator{
    display: none;
}
#dwcompare .css-b62m3t-container .css-1s2u09g-control svg,
#dwcompare .css-b62m3t-container .css-1pahdxg-control svg{
    color: #000;
}
#dwcompare div.section p{
    margin-bottom: 10px;
}
#dwcompare .css-b62m3t-container .css-1s2u09g-control .css-tlfecz-indicatorContainer,
#dwcompare .css-b62m3t-container .css-1pahdxg-control .css-1gtu0rj-indicatorContainer{
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
}
#dwcompare .css-b62m3t-container .css-1pahdxg-control{
    box-shadow: 0 0 0 1px #000;
    border-color: #000;
}
#dwcompare .css-b62m3t-container .css-6j8wv5-Input,
#dwcompare .css-b62m3t-container .css-1pndypt-Input,
#dwcompare .css-b62m3t-container .css-ackcql,
#dwcompare .css-b62m3t-container .css-vwja0k{
    display: flex;
    justify-content: center;
}
#dwcompare .css-b62m3t-container .css-6j8wv5-Input input,
#dwcompare .css-b62m3t-container .css-1pndypt-Input input,
#dwcompare .css-b62m3t-container .css-ackcql input,
#dwcompare .css-b62m3t-container .css-vwja0k input{
    min-width: 100% !important;
    width: 100% !important;
    text-align: center;
}
#dwcompare #accordion{
    margin-bottom: 0px;
}
#dwcompare #accordion .panel-default{
    border-radius: 4px 4px 0px 0px;
    border-bottom: none;
}
#dwcompare #accordion.outer .panel-default{
    border-radius: 4px;
    border-bottom: solid 1px #ddd;
}
#dwcompare .table-outer{
    min-height: 180px;
    max-height: 260px;
    overflow-y: auto;
    border: solid 1px #ddd;
    border-radius: 0px 0px 2px 2px;
    margin-bottom: 20px;
    border-top: none;
}
#dwcompare .table-outer table{
    margin-bottom: 0px;
}
#dwcompare .table-outer table tr th,
#dwcompare .table-outer table tr td{
    border: none;
    font-size: 21px;
    line-height: 1;
}
body.eng #dwcompare .table-outer table tr th,
body.eng #dwcompare .table-outer table tr td{
    font-size: 14px;
    line-height: 1.42857143;
}
#dwcompare .table-outer table tr td .checkbox{
    padding-left: 20px;
}
#dwcompare .table-outer table tr td .checkbox label{
    padding-left: 20px;
}
#dwcompare hr.hr-hmgbm{
    background-color: #bbb;
}
#dwcompare #comparetbl .table th,
#dwcompare #comparetbl .table td{
    border-bottom: none;
    line-height: 1.42857143;
}
#dwcompare #comparetbl .table{
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 20px;
}
#dwcompare #comparetbl .table>tbody th{
    width: 25%;
    color: #0675C2;
}
#dwcompare #comparetbl .table>tbody td{
    text-align: center;
    border-left: 1px solid #ddd;
    font-size: 20px;
    line-height: 1; 
}
body.eng #dwcompare #comparetbl .table>tbody td{
    font-size: 13px;
    line-height: 1.42857143;
}
body.eng #dwcompare #comparetbl .table>tbody td a,
body.eng #dwcompare #comparetbl .table>tbody tr.rw-issuer td{
    font-family: "MCQGlobal_A_Md";
}
#dwcompare #comparetbl .table>tbody tr:last-child{
    border-bottom: 1px solid #FECC0A;
}
#dwcompare #comparetbl .table>tbody tr.rw-ticker td,
#dwcompare #comparetbl .table>tbody tr.rw-issuer td{
    font-family: "DBHeaventMedium";
}
#dwcompare #comparetbl .table tr th .__react_component_tooltip{
    padding: 5px 9px;
    margin: 0px;
    opacity: 1;
    pointer-events: all;
}
#dwcompare #comparetbl .table tr th{
    position: relative;
    font-size: 20px;
    padding: 8px 2px;
    line-height: 1;
}
body.eng #dwcompare #comparetbl .table tr th{
    font-size: 13px;
    line-height: 1.42857143;
}
#dwcompare #comparetbl .table tr th .__react_component_tooltip p{
    padding: 0px;
    margin: 0px;
    font-weight: 400;
    font-size: 21px;
    line-height: 21px;
}
body.eng #dwcompare #comparetbl .table tr th .__react_component_tooltip p{
    font-size: 14px;
    line-height: normal;
}
#dwcompare #comparetbl .table tr th .__react_component_tooltip p strong{
    font-weight: 700;
}
#comparetbl tr.rw-symbl td{
    font-weight: bold;
}

#defination .panel-heading{
    background-color: #666666;
    padding: 4px 10px;
}
#defination .panel-title{
    font-size: 14px;
}
#defination .glyphicon{
    color: white;
}
#defination #collapse_dwcompare3{
    font-size: 12px;
    padding: 10px;
}
#defination .table{
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 15px;
}
#defination .table td{
    padding-left: 0;
}
#defination .table tr td:first-child{
    white-space: nowrap;
}

#dwcompare #comparetbl table .btn-primary{
    border-radius: 3px;
    border-color: #FECC0A !important;
    min-width: auto;
    line-height: 1.42857143;
}

#comparetbl table .text-bold{
    font-family: MCQGlobal_W_Md;
}



#dwcompare .carousel-root.ad-section{
    margin-top: 20px;
    width: 100%;
    height: auto;
    padding: 0px;
}
#dwcompare .ad-section .slider-wrapper {
    background: #000000;
}
#dwcompare .ad-section .slider-wrapper li.slide iframe {
    width: 100%;
    margin: 0px;
    min-height: 190px;
}
#dwcompare .ad-section .slider-wrapper li.slide a{
    height: 100%;
}
#dwcompare .ad-section .carousel .carousel-status{
    display: none;
}
#dwcompare .ad-section .carousel .control-dots .dot{
    width: 15px;
    height: 15px;
    box-shadow: none;
    background-color: #000;
    border: solid 1px #fff;
    opacity: 1;
    margin: 0px 5px;
}
#dwcompare .ad-section .carousel .control-dots .dot.selected{
    background-color: #FECC0A;
    border-color: #FECC0A;
}


/*******************************************************************************
 underlying section combobox
*******************************************************************************/
.combobox {
    position: relative;
  }
  .combobox-toggle {
    position: absolute;
    display:block;
    top: 0;
    bottom: 0;
    right:0;
    width: 36px;
    padding: 0;
    cursor: pointer;
  }
  .combobox-toggle, .combobox-toggle:focus, .combobox-toggle:hover, .combobox-toggle:visited{
    color: #000;
  }
  .combobox-input {
    margin: 0;
    padding: 5px 10px;
    width:100%;
    text-align: center;
  }
  .combobox-toggle-icon{
      margin-top: 10px;
      margin-right: 12px;
  }
  .ui-widget{
      font-family: 'MCQGlobal_W_Lt','MCQGlobal_W_Bd','Roboto', Arial, sans-serif;
      font-size: 14px;
  }
  .ui-menu .ui-menu-item{
      text-align: center;
  }
  .ui-menu .ui-menu-item a.ui-state-hover{
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5; 
      border-color:#f5f5f5;
  }
  .ui-menu .ui-menu-item a:visited,
  .ui-menu .ui-menu-item a:focus,
  .ui-menu .ui-menu-item a:hover{
      color: #262626
  }
  .ui-autocomplete.ui-menu{
      max-height: 300px;
      overflow: hidden;
      overflow-y: scroll;
      z-index: 999 !important;
  }
  /*******************************************************************************
 How to get started
*******************************************************************************/  
.page-header{
    display: flex; 
    justify-content: space-between;
}
.tradeDropDown{
    position: absolute;
    border: 1px solid #000000;
    text-align: center;
    z-index: 9;
    background: #ffffff;
    width: 350px;
    padding: 10px 0;
}
.tradeDropDown a{
    margin-bottom: 5px;
}
#dwcompare .carousel-root.ad-section{
    padding: 0px;
    width: 100%;
    margin-top: 20px;
    overflow: hidden;
}
#dwcompare .page-header input.btn.btn-primary.tradeBtn[aria-expanded="true"]{
    background-color: #999999 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}
.popupbox-content.warrant-comparsion{
    padding: 30px;
}
#dwcompare .select-warrant .css-6j8wv5-Input,
#dwcompare .select-warrant .css-1pndypt-Input{
    display: flex;
    justify-content: center;
}
#dwcompare .select-warrant .css-1s2u09g-control input,
#dwcompare .select-warrant .css-1pahdxg-control input{
    text-align: center;
    min-width: 100% !important;
    max-width: 100% !important;
}
#dwcompare .select-warrant .css-1s2u09g-control,
#dwcompare .select-warrant .css-1pahdxg-control{
    text-align: center;
    border-color: #999;
}
#dwcompare .select-warrant .css-1pahdxg-control{
    border-color: #000;
    box-shadow: 0 0 0 1px #000;
}
#dwcompare .dropdown-or{
    display: flex;
    justify-content: space-evenly;
}
#dwcompare .dropdown-or hr{
    width: 40%;
    margin-top: 10px;
    margin-bottom: 0px;
    background-color: #afadad;
}
#dwcompare .dropdown-or p{
    margin: 0 0 5px;
}
.selectedTrade, .selectedTrade:hover, .selectedTrade:focus{
    background-color: #999999 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}
.tradeDropDown .btn-primary:hover, .tradeDropDown .btn-primary:focus{
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}
#dwcompare .broker_link{
    width: calc(50% - 8px);
    margin: 0px 2px 5px;
}
#dwcompare .tradeDropDown .row.tradeEl{
    display: block;
}
/*******************************************
popupbox
*********************************************/
.overlay {
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0,0,0,0.6);
    height: 100%;
    width: 100%;
}
.overlay-inner {
    margin: 15vh auto;
    width: 80%;
    height: auto;
    background: #FFFFFF;
}
#ppboxHTML .overlay-inner{
    margin: 25vh auto;
    width: 50%;
    height: auto;
    background: #000000;
    color: #ffffff;
}
.popupbox-content {
    padding: 30px;
}
.btn-close {
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px;
}
/* .noscroll{position:relative;overflow:hidden;} */

#ppboxHTML .page-header{
    margin-top: 0px;
}

#ppboxHTML p, #ppboxHTML h3{
    color: #ffffff;
}

.popupbox-content.warrant-comparsion input{
    width: 30% !important;
    margin: 0 10px !important;
    color: #333333 !important;
    min-width: auto;
}
#collapse_title2 .arrow-icon{
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    width: 40px;
    cursor: pointer;
}
#collapse_title2 .arrow-icon svg{
    height: 100%;
    color: #000;
    margin: 0px;
    padding: 10px;
    width: 100%;
    line-height: 100px;
    box-sizing: border-box;
    transform: translateY(-3px);
}
#collapse_title2 .arrow-icon svg.result-sort-up{
    transform: translateY(5px);
}
.disabled-area ~ #collapse_title2 .arrow-icon{
    z-index: 0;
}
.position-unset{
    position: unset !important;
}
.textCenter{
    text-align: center;
}

#dwcompare .select_align_center {
    text-align: center;
  }
#dwcompare .select_align_center input {
text-align: center;
display: flex;
min-width: 100% !important;
width: 100% !important;
}
/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (min-width: 992px) and (max-width: 1199px) {
    #dwcompare .container-fluid > .row > .col-md-8 > .section{
        padding: 40px;
    }
    #dwcompare .container-fluid > .row > .col-md-4 > .section{
        padding: 20px;
    }
}


@media screen and (max-width: 991px){
    #ppboxHTML .overlay-inner {
        margin: 15vh auto;
        width: 90%;
    }
    .overlay-inner{
        width: 90%;
    }
    .overlay-inner .popupbox-content{
        padding-left: 30px;
        padding-right: 30px;
    }
    .tradeDropDown{
        right: 20px;
        width: 320px;
    }
    #dwcompare .col-md-8.sm-w-100{
        width: 100%;
    }
    #dwcompare #defination_comparision .panel-heading {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        padding: 3.5px 10px;
        background-color: #666666;
    }
    #dwcompare #defination_comparision .panel-heading button {
        border: none;
        background-color: transparent;
        width: 100%;
        text-align: center;
        padding: 0px;
    }
    #dwcompare #defination_comparision .panel-heading button > span::before{
        display: none;
    }
    #dwcompare #defination_comparision .panel-title {
        font-size: 21px;
        position: relative;
    }
    body.eng #dwcompare #defination_comparision .panel-title{
        font-size: 14px;
    }
    #dwcompare #defination_comparision .panel-heading button img {
        width: 14px;
        position: absolute;
        top: 3px;
        right: 0px;
    }
    #dwcompare #defination_comparision #collapse_dwcompare3 {
        font-size: 12px;
        padding: 10px;
        /* border: solid 1px #ddd; */
        border-radius: 0px 0px 4px 4px;
        /* box-shadow: 0 1px 1px rgb(0 0 0 / 5%); */
    }
    #dwcompare #defination_comparision #collapse_dwcompare3.hide {
        display: none;
    }
    #dwcompare #defination_comparision .table td {
        padding: 8px 2px 8px 0px;
        padding-left: 0;
        border: none;
        font-size: 21px;
        vertical-align: middle;
        line-height: 1;
    }
    body.eng #dwcompare #defination_comparision .table td{
        font-size: 14px;
        line-height: 1.42857143;
    }
    #dwcompare #defination_comparision .table td > b {
        display: none;
    }
    #dwcompare #defination_comparision .table td:first-child{
        white-space: nowrap;
    }
    #dwcompare #defination_comparision .table {
        border-bottom: 1px solid #FECC0A;
        margin-bottom: 15px;
    }
    #dwcompare #defination_comparision .table.table-striped > tbody > tr:nth-of-type(odd){
        --bs-table-striped-bg:transparent;
    }
    #dwcompare #defination_comparision hr{
        margin: 10px 0px;
        background-color: #eee;
        opacity: 1;
    }
    #dwcompare #defination_comparision{
        margin-bottom: 20px; 
    }
}
@media screen and (max-width: 767px){
    .table>tbody>tr>td,.table>tbody>tr>th,.table>tfoot>tr>td,.table>tfoot>tr>th,.table>thead>tr>td,.table>thead>tr>th {
        padding-left: 2px;
        padding-right: 2px;
    }
    label{
        line-height: 20px;
    }
    .table,
    .panel-collapse .table,
    #dwcompare #comparetbl .table{
        font-size: 11px;
    }
    #dwcompare #comparetbl .table{
        margin-top: 10px;
    }
    h3{
        font-size: 16px;
        margin-bottom: 0px;
        margin-top: 10px;
    }
    p.small {
        font-size: 11px;
    }
    #accordion .panel-title{
        font-size: 14px;
    }
    #dwcompare #defination #collapse_dwcompare3{
        font-size: 11px;
    }
    #dwcompare .section > .page-header ~ p.small{
        font-size: 17px;
    }
    #dwcompare .table-outer table tr th, 
    #dwcompare .table-outer table tr td{
        font-size: 17px;
    }
    #dwcompare .table-outer table tr td .checkbox {
        padding-left: 0px;
    }
    #dwcompare .table-outer table tr td .checkbox a{
        padding-left: 7px;
        position: relative;
        top: -2px;
    }
    body.eng #dwcompare .table-outer table tr th, 
    body.eng #dwcompare .table-outer table tr td{
        font-size: 11px;
    }
    #dwcompare .css-b62m3t-container .css-1s2u09g-control,
    #dwcompare .css-b62m3t-container .css-1pahdxg-control{
        min-height: 34px;
    }
    #dwcompare #accordion .panel.panel-default table tr.data-row > td:first-child{
        white-space: nowrap;
    }
    #dwcompare #accordion .panel.panel-default table tr td .checkbox label{
        padding-left: 0px;
    }
    #dwcompare #accordion .panel.panel-default table tr td .checkbox label span{
        margin-right: 6px;
        display: inline-block;
        transform: translateY(2px);
    }
    #dwcompare #orientationcontainer span{
        font-size: 21px;
    }
    body.eng #dwcompare #orientationcontainer span{
        font-size: 14px;
    }
    #dwcompare #comparetbl .table tr th .__react_component_tooltip p{
        font-size: 11px;
    }
    #dwcompare #comparetbl .table tr th .__react_component_tooltip{
        padding: 4px;
    }
    #dwcompare #defination_comparision .table td{
        font-size: 17px;
    }
    body.eng #dwcompare #defination_comparision .table td{
        font-size: 11px;
    }
    #dwcompare #orientationcontainer img {
        width: auto;
    }
    #dwcompare #comparetbl .table tr th,
    #dwcompare #comparetbl .table > tbody td{
        font-size: 17px;
    }
    #dwcompare h4#collapse_title2{
        font-size: 21px;
    }
    body.eng #dwcompare h4#collapse_title2{
        font-size: 14px;
    }
    #dwcompare div.section p.small{
        font-size: 17px;
    }
    body.eng #dwcompare div.section p.small{
        font-size: 11px;
    }
    #dwcompare #accordion .panel.panel-default table tr th, 
    #dwcompare #accordion .panel.panel-default table tr td{
        font-size: 17px;
    }
    body.eng #dwcompare #accordion .panel.panel-default table tr th, 
    body.eng #dwcompare #accordion .panel.panel-default table tr td,
    body.eng #dwcompare #comparetbl .table tr th,
    body.eng #dwcompare #comparetbl .table>tbody td{
        font-size: 11px;
    }
}
@media screen and (max-width: 767px) and (orientation:portrait){
    /* Style adjustments for portrait mode goes here */
    .hide-xs-portrait{
        display: none !important;
    }
    #dwcompare #orientationcontainer{
        display: block;
        margin-top: 20px;
    }
    #dwcompare #comparetbl .table>tbody tr td:nth-child(6),
    #dwcompare #comparetbl .table>tbody tr td:nth-child(4),
    #dwcompare #comparetbl .table>tbody tr td:nth-child(5){
        display: none !important;
    }
}
@media screen and (min-width: 768px){
    .checkbox input[type=checkbox] {
        position: relative;
        margin-left: 0px;
        margin-right: 20px;
        top: 2px;
    }
}




.carousel .carousel-status {
    color: transparent !important;
    text-shadow: none !important;
}
.ad-section{
    position: relative;
    height: 372.6px;
    width: 372.6px;
    background-color: #fff;
    padding: 2px;
    margin-top: 18px;
}
.ad-img{
    width: 100%;
    height: 100%;
}

.__react_component_tooltip {
    
    background: #fecc0a !important;
}