/* #investor_academy #main {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  padding-top: 0;
  transform: translateY(-50%);
} */
body:not(.eng) #investor_academy{
  font-family: 'DBHeavent';
}
#investor_academy ~ .footer {
  display: none;
}
#investor_academy ~ .sharebtn {
  display: none;
}
body {
  background-color: black;
}
body.xs,
body.xxs {
  background-size: 100% 100%;
}
.next {
  width: 23px;
  height: 23px;
  background: url("../../../assets/images/education/img/next.png");
  float: right;
  cursor: pointer;
}
.prev {
  width: 23px;
  height: 23px;
  background: url("../../../assets/images/education/img/prev.png");
  float: left;
  cursor: pointer;
}
.right {
  float: right;
}
.left {
  float: left;
}
.table {
  display: table;
}
.prevtitle,
.nexttitle {
  color: #ffd843;
  font-family: thincond;
  letter-spacing: 1px;
}
body.eng .prevname,
body.eng .nextname {
  color: #8f8f8f !important;
  letter-spacing: 1px;
  cursor: pointer;
  line-height: normal;
  font-family: 'MCQGlobal_A_Rg';
}
.prevname a,
.nextname a {
  color: #8f8f8f !important;
  letter-spacing: 1px;
  line-height: normal;
}
#nine {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 715px;
  width: 100%;
  margin: auto;
}
#nine > div.ninep {
  height: 144px;
  width: calc(33.33% - 3px);
  margin: 0px 3px 3px 0px;
}
/* #investor_academy #home {
  width: 100%;
  height: 527px;
} */
#investor_academy #home {
  width: 100%;
  height: 527px;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  padding-top: 0;
  transform: translateY(-50%);
}
#investor_academy #maintable {
  display: table;
  width: 100%;
  table-layout: fixed;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 70px;
}
#nine > div.ninep > div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: top center;
}
#nine > div.ninep > div a {
  display: block;
  width: 100%;
  overflow: hidden;
  height: 100%;
}
/* /home/techno/pulkit/MQTH/public/img/education/img/en.png */
#nine #n1 > div {
  background-image: url("../../../assets/images/education/img/1_th.png");
}
body.eng #nine #n1 > div {
  background-image: url("../../../assets/images/education/img/en1.png");
}
#nine #n2 > div {
  background-image: url("../../../assets/images/education/img/2_th.png");
}
body.eng #nine #n2 > div {
  background-image: url("../../../assets/images/education/img/en2.png");
}
#nine #n3 > div {
  background-image: url("../../../assets/images/education/img/3_th.png");
}
body.eng #nine #n3 > div {
  background-image: url("../../../assets/images/education/img/en3.png");
}
#nine #n4 > div {
  background-image: url("../../../assets/images/education/img/4_th.png");
}
body.eng #nine #n4 > div {
  background-image: url("../../../assets/images/education/img/en4.png");
}
#nine #n5 > div {
  background-image: url("../../../assets/images/education/img/5_th.png");
}
body.eng #nine #n5 > div {
  background-image: url("../../../assets/images/education/img/en5.png");
}
#nine #n6 > div {
  background-image: url("../../../assets/images/education/img/6_th.png");
}
body.eng #nine #n6 > div {
  background-image: url("../../../assets/images/education/img/en6.png");
}
#nine #n7 > div {
  background-image: url("../../../assets/images/education/img/7_th.png");
}
body.eng #nine #n7 > div {
  background-image: url("../../../assets/images/education/img/en7.png");
}
#nine #n8 > div {
  background-image: url("../../../assets/images/education/img/8_th.png");
}
body.eng #nine #n8 > div {
  background-image: url("../../../assets/images/education/img/en8.png");
}
#nine #n9 > div {
  background-image: url("../../../assets/images/education/img/9_th.png");
}
body.eng #nine #n9 > div {
  background-image: url("../../../assets/images/education/img/en9.png");
}
.ninep {
  float: left;
  cursor: pointer;
  background: url("../../../assets/images/education/bg_video_box_75.png") repeat
    0 0;
  /*filter: brightness(80%);
    -webkit-filter: brightness(0.8);*/
  /*background-color:rgba(0,0,0,1);*/
  /*background-color: #303030;*/
}
.ninep:hover {
  background-color: #303030;
  filter: brightness(100%);
  -webkit-filter: brightness(1);
  /*box-shadow: inset 0 0 10px 10px rgba(255, 255, 255, 0.1);*/
}
.xxs .hidden-xxs {
  display: none !important;
}
#dotbar {
  position: fixed;
  height: 334px;
  width: 50px;
  right: 0px;
  top: 50%;
  margin-top: -137px;
  z-index: 99999;
}
#dotbar .dbtn > a {
  display: block;
  width: 100%;
  height: 100%;
}
#menu #accordion {
  width: 80%;
  font-size: 10px;
}
.ui-accordion .ui-accordion-header .ui-icon {
  right: 10px !important;
}
#menu #accordion .text {
  padding: 10px;
  padding-left: 42px;
  width: 90%;
}
#menu #accordion {
  font-size: 14px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}
#investor_academy #dotbar .__react_component_tooltip {
  padding: 0px;
  border-radius: 0px;
  color: #555;
  background: #FFFFA3 !important;
  border: 1px solid #F1D031;
}
#investor_academy #dotbar .__react_component_tooltip .tooltip-content {
  font-size: 10.5px;
  line-height: 12px;
}
#investor_academy #dotbar .__react_component_tooltip:before,
#investor_academy #dotbar .__react_component_tooltip:after {
  /* display: none; */
  border-left-color: #ffffa3;
}
#investor_academy #dotbar .__react_component_tooltip:after{
  background-color: #FFFFA3;
}
#menu #accordion .subtopiclist {
  font-size: 14px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}
#menu .subtopiclist {
  display: none;
}
#menu .subtopiclist .subtopic {
  font-size: 14px;
  font-weight: bold;
}
#menu #accordion .subtopic {
  margin: 0;
  font-size: 14px;
  line-height: 1.5em;
}
#menu #accordion.ui-accordion .ui-accordion-header {
  min-height: 32px;
}
#menu #accordion.ui-accordion .ui-state-default,
#menu #accordion.ui-accordion .ui-state-active,
#menu #accordion.ui-accordion .ui-state-hover {
  color: #000000 !important;
  background: #fff2c8 !important;
  border: 0;
}
#menu #accordion.ui-accordion .ui-corner-all,
#menu #accordion.ui-accordion .ui-corner-top {
  border-radius: 0 !important;
}
#menu #accordion.ui-accordion .ui-widget-content {
  border: 0;
  background: #fff2c8;
}
#menu #accordion.ui-accordion .ui-accordion-content {
  /*background-color: #FFF2C8;*/
  background-color: #f6f6f6;
  padding: 0 42px;
}
.homebtn {
  cursor: pointer;
  width: 18px;
  height: 16px;
  background: url("../../../assets/images/education/img/home.png");
  margin-bottom: 15px;
}
.dotbtn {
  cursor: pointer;
  width: 14px;
  height: 14px;
  background: url("../../../assets/images/education/img/dot.png") center center
    no-repeat;
  margin-bottom: 15px;
  margin-left: 2px;
}
/* .homebtn.selected {
    background: url('../../../assets/images/education/img/homeactive.png') !important;
} */
.dotbtn.selected {
  background: url("../../../assets/images/education/img/dotactive.png") center
    center no-repeat !important;
}
#menucontent .subtopic {
  cursor: pointer;
}
#menucontent .subtopic:hover {
  color: #ffd843;
}
#menucontent .accordion-top {
  cursor: pointer;
}
#menucontent .subtopic:hover {
  color: #ffd843;
}
.xs #maincontent,
.xxs #maincontent {
  margin-left: 10% !important;
  margin-right: 10% !important;
  padding-top: 20px !important;
}
.xs #mainscroll,
.xxs #mainscroll {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.xs #main,
.xxs #main {
  bottom: 30px !important;
}
.xs #ptitle,
.xxs #ptitle {
  font-size: 18px !important;
  line-height: 18px !important;
}
.xs #pname,
.xxs #pname {
  font-size: 18px !important;
  line-height: 18px !important;
}
div:focus {
  outline: none;
}
img.landscape {
  -webkit-transform: translateY(-100%) rotate(90deg);
  /* Safari */
  -moz-transform: translateY(-100%) rotate(90deg);
  /* Firefox 3.6 Firefox 4 */
  /*-moz-transform-origin: right top; */
  -ms-transform: translateY(-100%) rotate(90deg);
  /* IE9 */
  -o-transform: translateY(-100%) rotate(90deg);
  /* Opera */
  transform: translateY(-100%) rotate(90deg);
  /* W3C */
  -webkit-transform-origin: left bottom;
  -moz-transform-origin: left bottom;
  -ms-transform-origin: left bottom;
  -o-transform-origin: left bottom;
  transform-origin: left bottom;
}
.ia-seminarbtn {
  position: absolute;
  top: calc(50% - 110px);
  right: 30px;
}
.xxs .ia-seminarbtn,
.xs .ia-seminarbtn,
.sm .ia-seminarbtn {
  display: none;
}
.dbtn-seminarbtn {
  margin-left: -80px;
  padding-bottom: 10px;
}
.xs .dbtn-seminarbtn,
.sm .dbtn-seminarbtn {
  display: none;
}
/* a {
    color:black !important;
} */

.customSteps ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: #000000;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  border-bottom: solid 5px #ffd400;
  overflow: hidden;
}
.customSteps ul li {
  width: 25%;
  position: relative;
}
.customSteps ul li a {
  display: block;
  width: 100%;
  position: relative;
  padding: 12px 10px;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}
.customSteps ul li a:after {
  position: absolute;
  content: "";
  right: -45px;
  top: 0;
  height: 100%;
  width: 40px;
  background-color: #000000;
  border-left: solid 6px #404040;
  border-bottom: solid 6px #404040;
  transform: rotate(225deg) translate(20px, -25px);
  z-index: 10;
}
.customSteps ul li:last-child a:after {
  display: none;
}
.customSteps ul li.active a,
.customSteps ul li.active a:after {
  background-color: #ffd400;
  color: #000000;
  font-weight: 600;
}
#investor_academy #maintable #leftbar{
  display: table-cell;
  width: 80px;
}
#investor_academy #maintable #maincontainer {
  display: table-cell;
  width: 100%;
}
#investor_academy #maintable #mainnav table{
  width: 100%;
  table-layout: fixed;
  border-bottom: 1px solid rgb(53, 50, 35);
}
#investor_academy #maintable #mainnav table tr td {
  padding: 0px;
}
#investor_academy #maintable #mainnav #pagetitlediv{
  width: 365px;
  height: 100%;
  border-bottom: 1px solid rgb(255, 216, 67);
  margin: auto;
}
body.eng #investor_academy #maintable #mainnav #pagetitlediv{
  font-family: 'MCQGlobal_A_Rg';
}
#investor_academy #maintable #mainnav #pname {
  line-height: normal;
  font-size: 30px;
  color: rgb(255, 216, 67);
  letter-spacing: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
}
#investor_academy #maintable #mainnav #ptitle {
  line-height: normal;
  font-size: 25px;
  color: white;
  letter-spacing: 1px;
}
#investor_academy #maintable #maincontent{
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 40px;
  margin-bottom: 60px;
}
#investor_academy #maintable #mainscroll{
  height: 100%;
  overflow: hidden auto;
  padding-left: 90px;
  padding-right: 90px;
}
#investor_academy #maintable #rightbar{
  display: table-cell;
  width: 80px;
}
#investor_academy #bottom{
  position: fixed;
  width: 100%;
  bottom: 0px;
  background-color: rgb(16, 16, 16);
  z-index: 10000;
}
#investor_academy #bottom #copyright{
  display: table;
  width: 100%;
  text-align: center;
  color: rgb(143, 143, 143);
  font-size: 11px;
  line-height: 43px;
}
body.eng #investor_academy #bottom #copyright{
  font-family: 'MCQGlobal_A_Rg';
}
#investor_academy #bottom .leftUrl{
  position: absolute;
  width: 46%;
  height: 35px;
  bottom: 5px;
  left: 10px;
}
#investor_academy #bottom .rightUrl{
  position: absolute;
  width: 46%;
  height: 35px;
  bottom: 5px;
  right: 10px;
}
#investor_academy #bottom .hasprev,
#investor_academy #bottom .hasnext{
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
#investor_academy #bottom .hasprev .prevname{
  text-align: left;
  font-size: 13px;
}
#investor_academy #bottom .hasnext .nextname{
  text-align: right;
  font-size: 13px;
}
#investor_academy #maintable .contentbox p {
  text-align: justify;
  color: white !important;
  line-height: 1.42857143 !important;
  margin-bottom: 10px;
  font-size: 14px;
}
body.eng #investor_academy #maintable .contentbox p{
  font-family: 'MCQGlobal_A_Rg';
}
#investor_academy #maintable .contentbox > h2 {
  margin-top: 20px;
}
#investor_academy #maintable .contentbox p.showtile {
  color: #ffd843 !important;
  text-align: center;
}
body.eng #investor_academy #maintable .contentbox p.showtile{
  font-family: 'MCQGlobal_A_Rg';
}
#investor_academy #maintable .contentbox .tipholder p {
  text-align: left;
}
#investor_academy #maintable .contentbox .tipholder h2 {
  margin-bottom: 9px;
}
#investor_academy #maintable .contentbox ul {
  padding-left: 40px;
  margin-bottom: 10px;
}
#investor_academy #maintable .contentbox .customSteps ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
#investor_academy #maintable .contentbox .txtbox p {
  margin-bottom: 0px;
  text-align: left;
}
#investor_academy #maintable .contentbox .ruletable.ctable tr td.title {
  color: #fff;
  font-family: inherit;
  font-size: 16px;
  line-height: normal;
}
#investor_academy #maintable .contentbox .ruletable.ctable tr td {
  padding: 0px;
}
#investor_academy #maintable .contentbox .ruletable.ctable tr td.col1 {
  padding-left: 1em;
}
#investor_academy #maintable .contentbox .tipholder .tipbox h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}
#investor_academy #maintable .contentbox .tipholder .tipbox p{
  text-align: justify;
  font-size: 14px;
}
#investor_academy #maintable .exampleholder .examplebox > table tr td {
  padding: 0px;
  line-height: normal;
}
#investor_academy #maintable .contentbox p em {
  font-size: 16px;
}
#investor_academy #maintable .formulabox .reqt table tr td {
  padding: 0px;
  line-height: normal;
}
#investor_academy #maintable .formulabox .leqt table tr td {
  line-height: normal;
}
#investor_academy #maintable .contentbox .formulabox p {
  margin: 0px;
}
#investor_academy #maintable .contentbox ol {
  margin-bottom: 10px;
  padding-left: 40px;
}
#investor_academy #maintable .contentbox .edulist p {
  text-align: left;
}
#investor_academy #maintable ul.closing-time li {
  line-height: 1.42857143;
}
#investor_academy #maintable .subtitle.bg-img {
  background-image: url(../../../assets/images/education/yellow01.png);
}
#investor_academy #maintable .subtitle.bg-img h3 {
  color: #ffda44;
}
#investor_academy #maintable .contentbox.intrinsic-value p {
  text-align: left;
}
#investor_academy #maintable .contentbox table.how-call-warrant tr td {
  padding: 0px;
}
#investor_academy #maintable .contentbox table.how-call-warrant tr td.col1 {
  padding-left: 1em;
}
#investor_academy #maintable .contentbox table.how-call-warrant tr td.disclaimer {
  padding-top: 6px;
  line-height: normal;
}
#investor_academy #imgpopup{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1000000;
}
#investor_academy #imgpopup #closebtn2{
  cursor: pointer;
  position: absolute;
  right: 0px;
  width: 58px;
  height: 58px;
  z-index: 3;
  background: url("../../../assets/images/education/img/btn_close.png");
}
#investor_academy #imgpopup .imgHold{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#investor_academy #imgpopup .imgHold img.landscape{
  transform: rotate(90deg);
  transform-origin: center;
  width: auto;
  height: auto;
  max-width: 100%;
}
#investor_academy .contentbox table tr td label{
  font-weight: normal;
}

@media (min-width: 992px) {
  body:not(.eng) #investor_academy #maintable #mainnav #pname {
      font-size: 36px !important;
  }
  body:not(.eng) #investor_academy #bottom .hasprev .prevname,
  body:not(.eng) #investor_academy #bottom .hasnext .nextname,
  body:not(.eng) #investor_academy #bottom #copyright{
    font-size: 16px !important;
  }
  body:not(.eng) #investor_academy #maintable #mainnav #ptitle{
    font-size: 32px !important;
  }
  body:not(.eng) #investor_academy #maintable .contentbox p,
  body:not(.eng) #investor_academy .contentbox .formulabox table tr td,
  body:not(.eng) #investor_academy #maintable .contentbox p.showtile,
  body:not(.eng) #investor_academy .formulabox .hltxt,
  body:not(.eng) #investor_academy .listholder ul li,
  body:not(.eng) #investor_academy .contentbox .imgholder h3{
    font-size: 20px !important;
  }
  body:not(.eng) #investor_academy .contentbox table tr td *,
  body:not(.eng) #investor_academy #maintable .contentbox > h2,
  body:not(.eng) #investor_academy #maintable .contentbox .tipholder .tipbox h2,
  body:not(.eng) #investor_academy .exampleholder .examplebox h3,
  body:not(.eng) #investor_academy #maintable .contentbox .txtbox h2{
    font-size: 22px !important;
  }
  body:not(.eng) .contentbox table tr td label{
    font-weight: normal !important;
  }
  body:not(.eng) #investor_academy .contentbox table tfoot td.disclaimer,
  body:not(.eng) #investor_academy #maintable .contentbox .tipholder .tipbox p,
  body:not(.eng) #investor_academy #maintable .contentbox .ruletable.ctable tr td.title,
  body:not(.eng) #investor_academy .exampleholder .examplebox .col .lbl, 
  body:not(.eng) #investor_academy .exampleholder .examplebox .col .val,
  body:not(.eng) #investor_academy #maintable .contentbox .txtbox p,
  body:not(.eng) #investor_academy .contentbox .formulaholder table.fraction tr td,
  body:not(.eng) #investor_academy .contentbox .formulaholder table.fraction tr td > *,
  body:not(.eng) #investor_academy .contentbox .formulaholder .leqt table tr td,
  body:not(.eng) #investor_academy .contentbox .formulaholder .leqt table tr td > *,
  body:not(.eng) #investor_academy .contentbox .formulaholder .reqt table tr td,
  body:not(.eng) #investor_academy .contentbox .formulaholder .reqt table tr td > *{
    font-size: 18px !important;
  }
  body:not(.eng) #investor_academy .prevname a,
  body:not(.eng) #investor_academy .nextname a {
    font-size: 13.5px;
  }
}

@media screen and (max-width: 767px){
  #nine > div.ninep {
    height: 91.9149px;
    width: 150px;
    margin-right: 3px;
    margin-bottom: 3px;
  }
  #nine {
    justify-content: center;
  }
  #investor_academy #bottom{
    padding: 10px;
  }
  #investor_academy #bottom .leftUrl{
    display: inline-block;
    width: 46%;
    float: left;
    position: relative;
    bottom: inherit;
    left: inherit;
    height: auto;
  }
  #investor_academy #bottom .rightUrl{
    display: inline-block;
    width: 46%;
    float: right;
    position: relative;
    bottom: inherit;
    right: inherit;
    height: auto;
  }
  #investor_academy #maintable #mainnav #pname{
    font-size: 16px;
    line-height: 18px;
  }
  #investor_academy #maintable #mainnav #pagetitlediv{
    width: 100%;
  }
  #investor_academy #maintable #maincontent{
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 20px;
  }
  #investor_academy #maintable #mainscroll{
    padding-left: 0px;
    padding-right: 0px;
  }
  #investor_academy #maintable #mainnav #ptitle{
    font-size: 15px;
    line-height: 16px;
  }
}


@media screen and (max-width: 480px){
  #nine > div.ninep {
    height: 140px;
    width: calc(50% - 3px);
    margin-right: 3px;
    margin-bottom: 3px;
  }
  #nine {
    justify-content: flex-start;
    width: calc(100% - 16px);
  }
  /* #investor_academy #main{
    top: 70px;
    transform: none;
  } */
  #investor_academy #home{
    top: 70px;
    transform: none;
  }
}


