#calculator #wcalbox{
    margin-bottom:30px;
}
#calculator #wcalbox>table{
    table-layout:fixed;
    text-align: left;
    width:100%
}
#calculator #ticker{
    margin-bottom: 0px;
    font-family: "MCQGlobal_A_Md";
    margin-top: 0px;
}
#calculator #wcalbox>table tr td{
    /*padding:5px 30px 5px 0px;*/ 
    vertical-align: middle;
}
#calculator #wcalbox .react-date-picker .react-date-picker__wrapper{
    opacity: 0;
}
#calculator .react-date-picker__calendar {
    width: 250px;
    max-width: 250px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    font-size: 12px !important;
}
#calculator #dropdown-calc .css-b62m3t-container {
    width: 100%;
    text-align: center;
}
#calculator #dropdown-calc .css-qbdosj-Input,
#calculator #dropdown-calc .css-166bipr-Input,
#calculator #dropdown-calc .css-19bb58m,
#calculator #dropdown-calc .css-1cfo1cf{
    display: flex;
    justify-content: center;
}
#calculator #dropdown-calc .css-qbdosj-Input input,
#calculator #dropdown-calc .css-166bipr-Input input,
#calculator #dropdown-calc .css-19bb58m input,
#calculator #dropdown-calc .css-1cfo1cf input{
    text-align: center;
    min-width: 100% !important;
    width: 100% !important;
}
#calculator #wcalbox .react-date-picker .react-date-picker__inputGroup{
    display: none;
}
#calculator #wcalbox .react-date-picker button.react-date-picker__calendar-button{
    width: 100%;
}
#calculator #wcalbox .react-date-picker {
    position: absolute;
    left: 0;
    top: 7.5px;
}
#calculator #wcalbox>table tr{
    height: 42px;
}
#calculator #wcalbox .wcalbox-row-lbl{
    height: 30px;
}
#calculator #wcalbox .wcalbox-col-lbl{
    text-align: center;
    width: 215px;
}
#calculator #wcalbox .wcalbox-col-slider{
    padding-left: 10px;
}
#calculator #wcalbox .wcalbox-row-ctr{
    height:40px;
    padding-bottom:20px;
}
#calculator #wcalbox .wcalbox-input{
    /*height: 100%;*/
    height: 29px;
    max-height: 100%;
    text-align: center;
    width: 100%
}
body.eng #calculator #wcalbox .wcalbox-input{
    height: 27px;
}
#calculator #wcalbox .slider.slider-horizontal{
    width: 100%;
    margin-top: -6px;
}
#calculator #wcalbox .slider.slider-horizontal .slider-track{
    height: 14px;
}
#calculator #wcalbox .slider-handle{
    top: 1px;
} 
#calculator #wcalbox{
    border-bottom: 1px #CCCCCC dotted;
}
#calculator #wcalbox td .range-slider__wrap{
    height: auto;
}


#calculator #dropdown-calc .css-b62m3t-container .css-qc6sy-singleValue {
    text-align: center;
    font-size: 24px;
}
body.eng #calculator #dropdown-calc .css-b62m3t-container .css-qc6sy-singleValue {
    text-align: center;
    font-size: 16px;
}
#calculator #dropdown-calc .css-1s2u09g-control .css-6j8wv5-Input input,
#calculator #dropdown-calc .css-1s2u09g-control .css-1pndypt-Input input {
    text-align: center;
    margin: 0px auto;
}
#calculator #dropdown-calc .css-1s2u09g-control,
#calculator #dropdown-calc .css-1pahdxg-control {
    border-radius: 0px;
    border: solid 1px #aeaeae !important;
    cursor: pointer;
    min-height: auto;
}
#calculator #dropdown-calc .css-2613qy-menu,
#calculator #dropdown-calc .css-6j8wv5-Input,
#calculator #dropdown-calc .css-ackcql,
#calculator #dropdown-calc .css-1pndypt-Input {
    display: flex;
    justify-content: center;
    margin: 0px !important;
    padding: 0px !important;
}
#calculator #dropdown-calc .css-1s2u09g-control input,
#calculator #dropdown-calc .css-1pahdxg-control input,
#calculator #dropdown-calc .css-1s2u09g-control .css-6j8wv5-Input input,
#calculator #dropdown-calc .css-1s2u09g-control .css-ackcql input,
#calculator #dropdown-calc .css-1pndypt-Input input.focus-visible,
#calculator #dropdown-calc .css-vwja0k input.focus-visible,
#calculator #dropdown-calc .css-6j8wv5-Input input.focus-visible,
#calculator #dropdown-calc .css-ackcql input.focus-visible {
    margin: 0px auto !important;
    text-align: center !important;
    min-width: 100% !important;
}
#calculator #dropdown-calc .css-1pahdxg-control {
    border-radius: 0px;
    border: solid 1px #aeaeae !important;
    box-shadow: none;
}
#calculator #dropdown-calc .css-1pahdxg-control .css-6j8wv5-Input,
#calculator #dropdown-calc .css-1s2u09g-control .css-ackcql,
#calculator #dropdown-calc .css-vwja0k {
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 24px;
    margin: 0px !important;
    padding: 0px !important;
}
#calculator #dropdown-calc .css-1s2u09g-control .css-tlfecz-indicatorContainer {
    color: #333;
}
#calculator #dropdown-calc .css-1s2u09g-control .css-14el2xx-placeholder,
#calculator #dropdown-calc .css-1pahdxg-control .css-14el2xx-placeholder{
    font-size: 24px;
}
body.eng #calculator #dropdown-calc .css-1s2u09g-control .css-14el2xx-placeholder,
body.eng #calculator #dropdown-calc .css-1pahdxg-control .css-14el2xx-placeholder{
    font-size: 16px;
}
#calculator #dropdown-calc .css-1s2u09g-control span,
#calculator #dropdown-calc .css-1pahdxg-control span {
    display: none;
}


#calculator #pstable{
    margin-bottom:30px;
    border-bottom:1px #CCCCCC dotted;
}
#calculator #pstable>table{
    table-layout: fixed;
    margin-bottom: 0;
}
#calculator #pstable th{
    width:45%;
}
body.eng #calculator #pstable th,
body.eng #calculator #pstable td{
    line-height: 1.42857143;
}
body.eng #calculator #initloadingbox > h3{
    font-size: 26px;
}

#calculator #srtable{
    border-bottom: 1px #CCCCCC dotted;
    margin-bottom: 30px;
}
#calculator #srtable>table{
    table-layout: fixed;
    margin-bottom: 0;
}
#calculator #srtable>table th, #calculator #srtable>table td{
    vertical-align: middle;
}
#calculator #srtable .srtable-leftlbl{
    width:30%;
}
#calculator #srtable .srtable-leftval{
    width:20%;
    border-right:20px solid #FFFFFF;
}
#calculator #srtable .srtable-rightlbl{
    width:30%;
    border-left:20px solid #FFFFFF;
}
#calculator #srtable .srtable-rightval{
    width:20%;
}
#calculator #ivtable th, 
#calculator #ivtable td{
    text-align: center;
}
#calculator #ivtable th.active, 
#calculator #ivtable td.active{
    background-color: #CEE5F5;
}
#calculator #ivtable th{
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
}
body.eng #calculator #ivtable th{
    font-family: "MCQGlobal_A_Md";
    line-height: normal;
}
body.eng #calculator #ivtable td{
    line-height: 1.42857143;
}

#calculator #dropdown-calc{
    margin-top: 10px;
    margin-bottom: 30px;
}


/*******************************************************************************
 Responsive @media
******************************************************************************/
@media screen and (min-width: 992px) and (max-width: 1199px) {
    #calculator .container-fluid > .row > .col-md-8 > .section{
        padding: 40px;
    }
    #calculator .container-fluid > .row > .col-md-4 > .section{
        padding: 20px;
    }
}

@media screen and (max-width: 991px){
    #calculator #wcalbox .wcalbox-row{
        margin-bottom:8px;
    }
    #calculator #wcalbox .wcalbox-col{
        margin-bottom:3px;
        text-align: center;
    }
    #calculator #wcalbox .slider.slider-horizontal .slider-track{
        height: 10px;
    }
    #calculator #wcalbox .slider-handle{
        width: 16px;
        height: 16px;
    } 
    #calculator #wcalbox .wcalbox-input{
        width:100%;
        height:30px;
    }
    #calculator #wcalbox .row{
        margin-right: -10px;
        margin-left: -10px;
    }
    #calculator #pstable th{
        width: 45%;
    }
    #calculator #pstable td{
        width: 55%;
    }
    #calculator #wcalbox .react-date-picker{
        top: 0;
    }
    body.eng #calculator #wcalbox .wcalbox-input{
        height: 30px;
    }
}


@media screen and (max-width: 768px){
    #calculator #pstable, #calculator #srtable, #ivtable{
        font-size: 17px;
    }
    #calculator #pstable th,
    #calculator #pstable td{
        width:50%;
        font-size: 17px;
    }
    #calculator h3{
        font-size: 24px;
    }
    #calculator #srtable .srtable-leftlbl{
        width:24%;
    }
    #calculator #srtable .srtable-leftval{
        width:26%;
        border-right:0;
    }
    #calculator #srtable .srtable-rightlbl{
        width:24%;
        border-left:0;
    }
    #calculator #srtable .srtable-rightval{
        width:26%;
    }
    #calculator #dropdown-calc .css-1pahdxg-control .css-6j8wv5-Input, 
    #calculator #dropdown-calc .css-1s2u09g-control .css-ackcql, 
    #calculator #dropdown-calc .css-vwja0k,
    #calculator #dropdown-calc .css-b62m3t-container .css-qc6sy-singleValue{
        font-size: 21px;
    }
    #calculator #dropdown-calc .css-1pahdxg-control .css-1gtu0rj-indicatorContainer,
    #calculator #dropdown-calc .css-1s2u09g-control .css-tlfecz-indicatorContainer{
        padding: 5px;
    }
    body.eng .page-header h1{
        font-size: 18px;
    }
    body.eng #calculator #pstable th,
    body.eng #calculator #pstable td{
        font-size: 11px;
    }
    body.eng #calculator #initloadingbox > h3{
        font-size: 16px;
    }
    body.eng #calculator #ivtable{
        font-size: 11px;
    }
}

