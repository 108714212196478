#whydw28 .container-fluid img{
    width: 100%;
}
#whydw28 .subscribe_area .btn-close{
    top: 50%;
    right: 10px;
    background: transparent;
    opacity: 1;
    padding: 0px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
}
#whydw28 .subscribe_area .btn-close:hover{
    opacity: 1;
}
#whydw28 hr.hr-hmgbm{
    background-color: #c0c0c0;
}
#whydw28 #bar-subscribe.subscribe_area{
    display: none;
    position: fixed;
    top: 50px;
    width: 100%;
    height: 50px;
    background-color: rgba(0,0,0,0.6);
    z-index: 999;
}
#whydw28 #area_1{
    position: relative;
    margin-top: 20px;
    background: url("../../../assets/images/whydw28/Background1.png") no-repeat;
    background-size: cover;
    padding: 40px;
    padding-bottom: 60px;
}
#whydw28 #area_2 {
    position: relative;
    background: url("../../../assets/images/whydw28/Background2.png") no-repeat;
    background-size: cover;
    padding: 40px;
    padding-bottom: 20px;
}
#whydw28 #area_2 .td-text .Text2 {
    text-align: center;
    font-size: 48px;
    color: white;
}
body.eng #whydw28 #area_2 .td-text .Text2{
    font-family: 'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif;
}
#whydw28 #area_2 .td-text .Text2 .title-2 {
    color: #FECC0A;
    font-size: 72px;
    font-weight: bold;
    margin: 0;
}
#whydw28 #area_2 .td-text .Text2 p {
    font-size: 24px;
    color: white;
    margin: 0;
}
#whydw28 .td-video {
    height: 500px;
}
#whydw28 #area_3 {
    position: relative;
    margin-top: 20px;
    background: url("../../../assets/images/whydw28/Background3.png") no-repeat;
    background-size: cover;
    padding: 20px;
}
#whydw28 .hotlist-table {
    border-bottom: 1px solid #FECC0A;
    display: block;
    max-height: 316px;
    overflow-y: scroll;
}
#whydw28 .hotlist-table table tbody td {
    padding: 15px 0;
}
/* #whydw28 #resulttable-lg{
    width: 100%;
} */
#whydw28 #resulttable-lg tr td{
    text-align: center;
}
#whydw28 #resulttable-lg > tbody > tr{
    background-color: #fff;
}
#whydw28 #resulttable-lg > tbody > tr:nth-of-type(odd) {
    background-color: #F5F6F7;
}
#whydw28 #resulttable-lg thead th {
    width: 10%;
    border-right: 1px solid #fff;
    position: sticky;
    top: 0;
}
#whydw28 #resulttable-lg thead th:last-child{
    border: none;
}
#whydw28 #resulttable-lg tr td:not(:last-child){
    border-right: 1px solid #ccc;
}
#whydw28 #area_1 .inner_title{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 240px;
    margin-left: -120px;
    margin-top: -40px;
    text-align: center;
}
#whydw28 #area_1 .subscribe_area{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: rgba(0,0,0,0.6);
}
#whydw28 .subscribe_area table{
    width: 100%;
    height: 100%;
    text-align: center;
}
#whydw28 .subscribe_area .description{
    display: inline-block;
    color: white;
}
#whydw28 #btn-subscribe{
    display: inline-block;
    border: 1px solid #FECC0A;
    border-radius: 5px;
    line-height: 30px;
    width: 200px;
    margin-left: 43px;
}

#whydw28 .tablearea{
    background-color: white;
}
#whydw28 .tablearea .td-text{
    padding: 0 50px;
    width: 45.13%;
}
#whydw28 .inner_title{
    font-size: 66px;
    line-height: normal;
    padding-bottom: 15px;
    font-family: 'DBHeaventLight';
}
body.eng #whydw28 .inner_title{
    font-size: 44px;
    font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
}
#whydw28 #area_3 #hotlist {
    min-height: 500px;
}


/* ================== */

@media screen and (min-width: 992px) and (max-width: 1199px) {
    #whydw28 #area_3 #hotlist.section{
        padding: 40px;
    }
}



@media screen and (max-width: 991px){
    #whydw28 .tablearea .td-text{
        padding: 0 20px;
    }
    #whydw28 .inner_title{
        font-size: 28px;
    }
    body.eng #whydw28 .inner_title{
        font-size: 28px;
    }
    #whydw28 .inner_content{
        font-size: 21px;
    }
    body.eng #whydw28 .inner_content{
        font-size: 14px;
    }
    #whydw28 .inner_title {
        font-size: 42px;
    }

}

@media screen and (max-width: 767px){
    #whydw28 .subscribe_area .description{
        font-size: 17px;
        margin-right: 3px;
    }
    body.eng #whydw28 .subscribe_area .description{
        font-size: 11px;
    }
    #whydw28 #btn-subscribe{
        width: auto;
        margin-left: 5px;
        margin-right: 20px;
        padding: 0 5px;
    }
    #whydw28 .tablearea .td-text{
        padding: 40px 10px;
        width: auto;
    }
    #whydw28 .tablearea .altarea{
        background-color: #333;
        color: white;
    }
    #whydw28 #area_1 {
        padding: 0px;
        padding-top: 50px;
    }
    #whydw28 #area_1 .subscribe_area{
        bottom: auto;
        top: 0;
    }
    #whydw28 #area_2 {
        padding: 0px;
 
    }
    #whydw28 #area_1 > table tr,  
    #whydw28 #area_2 > table tr {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        padding: 0;
        width: 100%;
        justify-content: center;
    }
    #whydw28 #area_2 > table tr {
        flex-direction: column;
    }
    #whydw28 #area_1 > table tr td,   
    #whydw28 #area_2 > table tr td {
        padding: 10px !important;
        width: 100% !important;
        text-align: center;
    }
    #whydw28 #area_1 > table tr td img, 
    #whydw28 #area_2 > table tr td img{
        padding: 0px;
        max-width: 300px;
        width: 100%;
    }
    #whydw28 #area_1 table tr td.td-video, 
    #whydw28 #area_2 table tr td.td-video {
        height: 300px;
        padding: 0px !important;
    }
    #whydw28 #area_1 table tr td.td-text, 
    #whydw28 #area_2 table tr td.td-text {
        padding: 40px 10px !important;
    }
    #whydw28 div#area_3 table > tr {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    #whydw28 div#area_3 table > tr td {
        width: 100% !important;
        padding: 0px !important;
        margin-bottom: 30px;
    }
    #whydw28 #area_3 {
        padding: 20px 10px;
    }
     #whydw28 div#area_3 table > tr td .hotlist-table tr {
        display: revert;
    }
    #whydw28 .img_cont_set .img_dire_rirst tr {
        flex-direction: column;
    }
    #whydw28 #area_2 #area_3 table tr td.td-text{
        padding: 20px 10px 40px !important;
    }
    #whydw28 .hotlist-table table tbody td.dwSymbol{
        font-size: 15px;
    }
    body.eng #whydw28 .hotlist-table table tbody td.dwSymbol{
        font-size: 10px;
    }
    #whydw28 table.tablearea.img_dire tr{
        display: flex;
        flex-direction: column-reverse;
    }
    #whydw28 table.tablearea.img_dire.img_dire_rirst tr{
        flex-direction: column;
    }
    #whydw28 table.tablearea.img_dire.img_dire_rirst tr td.td-text{
        background-color: #333;
        color: #fff;
    }
    
}