#set50seminar{
    font-family: "MCQGlobal_A_Lt";
}
#set50seminar .head-text {
    background: url('../../../../assets/images/game/Head-Text.png') no-repeat;
    background-size: contain;
    background-position: center;
    padding-bottom: 33.5%;
    width: 100%;
    margin: auto;
    background-color: black;
}
#set50seminar  .section-btn-wapper{
    text-align: center;
}
#set50seminar  .team-leader {
    background: url('../../../../assets/images/game/Head-Team leader-2.png') no-repeat;
    padding-bottom: 93.5%;
    width:100%;
    background-size: contain;
    /*    margin-bottom: -100%;*/
    position: relative;
}
#set50seminar .block2 .video {
    position: absolute;
    background-size: cover;
    padding: 5.4% 6.58% 5.1% 6.7%;
    width: 78%;
    height: 50%;
    left: 11%;
    top: 35%;
    margin: 0px;
    background-image: url("../../../../assets/images/education/VDO-Frame.png");
}
#set50seminar  .block2 .video iframe {
    width: 100%;
    height: 100%;
}
#set50seminar  .pic-frame {
    width: 61.1%;
    margin: auto;
}
#set50seminar .pic {
    width: 100%;
    padding-bottom: 100%;
    z-index: 1000;
    margin: 0 auto;
}
#set50seminar  .pic1 {
    background-position: top left;
    background-size: contain;
}
#set50seminar  .pic2 {
    background-position: top left;
    background-size: contain;
}
#set50seminar  .partner .title {
    margin: auto;
    width: 70%;
}
#set50seminar  .sponsors5 {
    height: 12vw;
    margin: auto;
    width: 75%;
}
#set50seminar  .sponsors5 div {
    width: 29%;
    height: 100%;
    float: left;
    margin-left: 4%;
}
#set50seminar  .sponsor_img53 {
    background: url('../../../../assets/images/game/5-Sponsor-Kaohoon.png') no-repeat;
    background-position: center left;
    background-size: contain;
}
#set50seminar  .sponsor_img52 {
    background: url('../../../../assets/images/game/5-Sponsor-Phillip.png') no-repeat;
    background-position: center left;
    background-size: contain;
}
#set50seminar  .sponsor_img51 {
    background: url('../../../../assets/images/game/5-Sponsor-AECs.png') no-repeat;
    background-position: center left;
    background-size: contain;
    width: 16% !important;
    margin-left: 8% !important;
}
#set50seminar  .team-description {
    background: url('../../../../assets/images/game/2-BG.png') repeat-y;
    background-size: 100% 100%;
    margin-bottom: 5%;
    background-color: black;
    padding-bottom: 5%;
}
#set50seminar  .team-description.team-a {
    padding-bottom: 8vw;
    margin-bottom: 0vw;
}
#set50seminar strong, 
#set50seminar h1, 
#set50seminar h2, 
#set50seminar .text-bold, 
#set50seminar .title{
    font-weight: 900;
}
#set50seminar .bio {
    float: right;
    width: 54%;
    margin-right: 11%;
}
#set50seminar ~ .footer hr {
    margin-top: 7%;
    margin-bottom: 3%;
}
#set50seminar .team-a-bg {
    background: url('../../../../assets/images/game/team-a-bg.png') no-repeat;
    background-size: contain;
    padding-bottom: 63.4%;
    width: 100%;
    margin: auto;
    margin-bottom: -52%;
}
#set50seminar .team-b-bg {
    background: url('../../../../assets/images/game/team-b-bg.png') no-repeat;
    background-size: contain;
    padding-bottom: 68.8%;
    width: 100%;
    margin: auto;
    margin-bottom: -56%;
}
#set50seminar .bio-text {
    background-size: contain;
    padding-bottom: 100%;
    width: 100%;
    margin: auto;
}
#set50seminar .team-a .section-details1 {
    background: url('../../../../assets/images/game/1-Juti-T1.png') no-repeat;
    background-size: contain;
    padding-bottom: 100%;
    width: 100%;
}
#set50seminar .team-a .section-details2 {
    background-size: contain;
    padding-bottom: 64%;
    width: 100%;
}
#set50seminar .team-b .section-details1 {
    background: url('../../../../assets/images/game/1-Man-T4.png') no-repeat;
    background: url('../../../../assets/images/game/1-Man-T4.png') no-repeat;
    background-size: contain;
    padding-bottom: 100%;
    width: 100%;
}
#set50seminar .team-b .section-details2 {
    background-size: contain;
    padding-bottom: 64%;
    width: 100%;
}
#set50seminar .team-a .col-right {
    width: 33%;
    float: right;
    margin-right: 17%;
    padding-left: 0px;
    padding-right: 0px;
}
#set50seminar .team-b .col-left {
    width: 33%;
    float: left;
    margin-left: 17%;
    padding-left: 0px;
    padding-right: 0px;
}
#set50seminar .team-description .col-left p,
#set50seminar .team-description .col-left h1,
#set50seminar .team-description .col-left h2 {
    text-align: right;
}
#set50seminar .team-description .bio p {
    text-align: center;
}
#set50seminar .col-left .title {
    text-align: right;
}
#set50seminar .team-a .title {
    background-color: #FECC0A;
    color: #242424;
}
#set50seminar .team-b .title {
    background-color: #1A92CA;
    color: #FFFFFF;
}
#set50seminar .col-left .title {
    right: 49%;
}
#set50seminar .separater-extra {
    background: url('../../../../assets/images/game/Head-1.png') no-repeat;
    background-size: contain;
    padding-bottom: 11.68%;
    width: 100%;
    margin: auto;
    margin-top: -5.8%;
}
#set50seminar .extra-bg {
    background: url('../../../../assets/images/game/3-BG.png') no-repeat;
    background-size: contain;
    background-color: black;
    padding-bottom: 34%;
    width: 100%;
}
#set50seminar .extra {
    color: #EEEEEE;
    padding-bottom: 5vw;
}
#set50seminar .extra .col-left {
    float: left;
    width: 40%;
}
#set50seminar .extra .col-right {
    float: left;
    width: 60%;
}
#set50seminar .partner-bg {
    background-size: contain;
    background-color: black;
    padding-bottom: 58%;
    width: 100%;
}
#set50seminar .split > div {
    float: left;
    width: 50%;
}
#set50seminar .section-btn-wapper {
    clear:both;
    padding-top: 3vw;
}
#set50seminar .extra .section-btn-wapper{
    margin-top: 0px;
}
#set50seminar .section-btn-wapper a {
    width: 20vw;
    height: 5vw;
    font-size: 3vw;
    line-height: 5vw;
    padding: 0;
    min-width: auto;
}
#set50seminar .section-btn-wapper a,
#set50seminar .section-btn-wapper p {
    font-weight: 900;
}
#set50seminar .section-btn-wapper p {
    margin-bottom: 0.5vw;
    font-size: 2.5vw;
}
#set50seminar .partner {
    background: url('../../../../assets/images/game/4-BG.png') no-repeat;
    background-size: cover;
    background-color: black;
    padding-bottom: 3vw;
}
#set50seminar .partner-content {
    position: relative;
    background: url('../../../../assets/images/game/4-content-02.png') no-repeat;
    background-size: cover;
    padding-bottom: 56.2%;
}
#set50seminar  .partner-content .btn-overlay {
    position: absolute;
    display:block;
    width: 24%;
    height: 11%;
    z-index: 10;
}
#set50seminar .partner-content .btn-left{
    top: 77%;
    left: 20%;
}
#set50seminar .partner-content .btn-right{
    top: 77%;
    left: 56%;
}
#set50seminar .partner .section-btn-wapper {
    padding-top: 0vw;
}
#set50seminar .sponsors4-box {
    margin: auto;
    background-size: contain;
    width: 75%;
    padding-top: 25%;
}
#set50seminar .sponsors4-box div {
    position: relative;
    width: 40%;
    height: 100%;
    float: left;
    margin-left: 4%;
    bottom: 0%;
}
#set50seminar .team-a .btn-title {
    margin: auto;
    background: url("../../../../assets/images/game/1-Juti-T4.png") no-repeat;
    background-size: contain;
    width: 30%;
    padding-top: 4%;
}
#set50seminar .team-b .btn-title {
    margin: auto;
    background: url("../../../../assets/images/game/2-Man-T4.png") no-repeat;
    background-size: contain;
    width: 30%;
    padding-top: 4%;
}
#set50seminar .extra .btn-title {
    margin: auto;
    background: url("../../../../assets/images/game/3-Chaloke-T2.png") no-repeat;
    background-size: contain;
    width: 20%;
    padding-top: 4%;
}
#set50seminar .partner .btn-title {
    margin: auto;
    background-size: contain;
    width: 22%;
    padding-top: 4%;
}
#set50seminar td, th {
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
}
#set50seminar .sponsor-btn-title {
    margin: auto;
    background: url("../../../../assets/images/game/5-Sponsor-T1.png") no-repeat;
    background-size: contain;
    width: 22%;
    margin-top:4%;
    padding-top: 4%;
    padding-bottom: 4%;
}
#set50seminar .score-title img,
#set50seminar .score-tbl img{
    width: 100%;
}
#set50seminar .score-tbl table{
    width: 100%;
    text-align: center;
    vertical-align: middle;
    font-family: 'DBHeaventLight';
    line-height: 1em;
    font-size: 26px;
    border-bottom: 2px solid #D4D4D4;
}
#set50seminar .score-tbl td.bordered{
    border-right: 2px solid #D4D4D4;
}
#set50seminar .score-tbl-head1{
   font-family: 'DBHeaventMedium';
   font-size: 36px; 
}
#set50seminar .score-tbl-head2{
   font-family: 'DBHeaventMedium';
   font-size: 26px; 
}
#set50seminar .score-tbl-head2 td{
    background-color: #EFEFEF;
}
#set50seminar .score-tbl-row.first,
#set50seminar .score-tbl-row:first-child{
    font-family: 'DBHeaventMedium';
}
#set50seminar .txt-light{
    font-family: "DBHeaventLight";
}
#set50seminar .page-header{
    display: flex;
}
#set50seminar  .btn-area{
    margin-left: auto;
}
#set50seminar .fbSharebtn{
    margin: 0;
    font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
}
#set50seminar .breadcrumb li, 
#set50seminar .breadcrumb li > a {
    font-size: 18px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    #set50seminar .container-fluid > .row > .col-md-12 > .section {
        padding: 40px;
    }
}

@media screen and (max-width: 767px){
    #set50seminar .score-tbl table{
        font-size: 18px;
    }
    #set50seminar .score-tbl-head1{
        font-size: 26px; 
    }
    #set50seminar .score-tbl-head2{
        font-size: 18px; 
    }
    #set50seminar  .page-header{
        display: unset;
    }
    #set50seminar  .fbSharebtn{
        margin: 10px 0; 
    }
}

@media screen and (max-width: 520px){
    #set50seminar .score-tbl table{
        font-size: 14px;
    }
    #set50seminar  .score-tbl-head1{
        font-size: 22px; 
    }
    #set50seminar .score-tbl-head2{
        font-size: 14px; 
    }
}

@media screen and (max-width: 420px){
    #set50seminar  .score-tbl table{
        font-size: 12px;
    }
    #set50seminar  .score-tbl-head1{
        font-size: 20px; 
    }
    #set50seminar  .score-tbl-head2{
        font-size: 14px; 
    }
}