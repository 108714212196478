#photogallery #albums{
    max-width: 1090px;
    min-height: 200px;
}
#photogallery .albumtmpl{
    position: relative;
    display: table;
    width: 100%;
    border-bottom: 4px solid #FECC0A;
}
hr.hr-hmgbm{
    background-color: #b5b5b5;
}
#photogallery .page-header + p{
    margin-bottom: 10px;
}
#photogallery .albumtmpl .bg-layer{
    display: table-cell;
    width: 100%;
    /*height: 465px;*/
    text-align: center;
    vertical-align: middle;
    background-color: black;
}
#photogallery .albumtmpl img{
    width: 100%;
}
#photogallery .albumtmpl .content-layer{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.6);
}
#photogallery .albumtmpl .content-layer table{
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
}
#photogallery h2{
    color: white;
    width: 80%;
    margin: auto;
    padding: 10px;
}
body.eng #photogallery #albums h2{
    font-size: 30px;
}
#photogallery .view_btn{
    display: inline-block;
    background-color: #FECC0A;
    width: 150px;
    height: 35px;
    line-height: 35px;
    color: #666666;
}

@media screen and (min-width: 992px) and (max-width: 1199px){
    #photogallery .container-fluid > .section{
        padding: 40px;
    }
}

@media screen and (max-width: 768px){
    body.eng #photogallery .page-header h1 {
        font-size: 36px;
    }
}

/* Extra small devices (phones, less than 768px) */
@media screen and (max-width: 767px){
    #photogallery h2{
        font-size: 24px;
        width: 90%;
    }
    body.eng #photogallery #albums h2 {
        font-size: 16px;
    }
    #photogallery .view_btn{
        display: none;
    }
    body.eng #photogallery .page-header h1 {
        font-size: 18px;
    }
}