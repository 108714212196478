#season3 .row{
    margin-left: -10px;
    margin-right: -10px;
}

#season3 .section img{
    width: 100%;
}

#season3  #table_1{
    width: 65%;
    margin: auto;
}
#season3  #table_1 .td_img{
    text-align: right;
    width: 28%;
}
#season3  #table_1 img{
    width: 64px;
}
#season3  #table_1 tr:not(:first-child){
    border-top: 4px solid #dddddd;
}
#season3  #table_1 td{
    padding: 10px;
}
#season3 #table_1 .text_style1{
    font-size: 48px;
}
body.eng #season3 #table_1 .text_style1{
    font-size: 32px;
}
#season3 #table_1 .text_style2{
    font-size: 68px;
    color: #ED6F01;
}
body.eng #season3 #table_1 .text_style2{
    font-size: 45px;
}
#season3 #area_1{
    position: relative;
    background-color: lightgrey;
    margin-top: 40px;
    height: 130px;
}
#season3 #area_1 .inner_area1{
    position: absolute;
    top: -18px;
    left: 50%;
    margin-left: -70px;
    width: 140px;
    text-align:center;
    background-image: url('../../../../assets/images/game/superTrader13.png');
    background-repeat: no-repeat;
    background-size: 100%;
    font-size: 30px;
    line-height: 36px;
    color: white;
}
body.eng #season3 #area_1 .inner_area1{
    font-size: 20px;
}
#season3 #table_2{
    position: absolute;
    top: 30px;
    left: 50%;
    width: 660px;
    margin-left: -330px;
    font-size: 27px;
}
body.eng #season3 #table_2{
    font-size: 18px;
}
#season3 #table_2 .text_style3{
    font-size: 45px;
}
body.eng #season3 #table_2 .text_style3{
    font-size: 30px;
}
#season3 #table_2 td:nth-child(1){
    width: 90px;
}
#season3 #table_2 td:nth-child(2){
    width: 280px;
}
#season3 #table_2 td:nth-child(3){
    vertical-align: top;
    width: 30px;
}
#season3 #table_2 td:nth-child(4){
    padding-left: 5px;
    padding-right: 20px;
}
#season3 .inner_title{
    background-color: #ED6F01;
    text-align: center;
    font-size: 30px;
    color: white;
    height: 44px;
    line-height: 44px;
    width: 100%;
}
body.eng #season3 .inner_title{
    font-size: 20px;
}
#season3 .inner_title_light{
    background-color: #FEDCBF;
    text-align: center;   
    color: black;
    height: 44px;
    line-height: 44px;
    width: 100%;
    font-size: 30px;
}
body.eng #season3 .inner_title_light{
    font-size: 20px;
}
#season3 .panel-group{
    margin: 0;
}
#season3 .panel-group .panel+.panel{
    margin: 0;
}
#season3 .panel-group .panel{
    border: 0;
    border-bottom: 1px solid #dddddd;
    border-radius: 0;
}
#season3 .panel-default>.panel-heading{
    background-color: #E6E6E6 !important;
}
#season3 .collapse_item .row{
    margin: 0;
    border-style: solid;
    border-color: #999;
    border-top-width: 0;
    border-left-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
}
#season3 .collapse_item .row>div{
    padding: 10px 0;
}
/*            .collapse_item .inner_col1{
    border-right: 1px solid #999;
}*/
#season3 .collapse_item .inner_col1 table{
    width: 300px;
    margin: auto;
}
#season3 .collapse_item .inner_col1 td:nth-child(1){
    width: 90px;
}
#season3 .collapse_item .inner_col1 td:nth-child(2){
    padding-left: 20px;
}
#season3 .collapse_item .text_style4{
    font-size: 26px;
}
body.eng #season3 .collapse_item .text_style4{
    font-size: 17px;
}
#season3 .collapse_item .text_style5{
    font-size: 38px;
}
body.eng #season3 .collapse_item .text_style5{
    font-size: 25px;
}
#season3 .collapse_item .inner_col2{
    border-left: 1px solid #999;
}
#season3 .collapse_item .inner_col2>div{
    width: 300px;
    margin: auto;
    font-size: 23px;             
}
body.eng #season3 .collapse_item .inner_col2>div{
    font-size: 15px;
}
#season3 .collapse_item .inner_col2>div:first-child{
    margin-top: 10px;
    text-align: center;
    font-size: 23px
}
body.eng #season3 .collapse_item .inner_col2>div:first-child{
    font-size: 15px;
}
#season3 .collapse_item .inner_col2>div:nth-child(2){
    margin-top: 20px;
    text-align: right;
}

#season3 #table_3{
    width: 600px;
    margin: auto;
}
#season3 #table_3 td{
    width: 50%;
    height: 60px;
    vertical-align: middle;
    padding-left: 30px;
}
#season3 #table_3 .img_style1{
    width: 40px;
    float: left;
}
#season3 #table_3 .text_style6{
    margin-left: 50px;
    font-size: 30px;
    color: black;
    height: 37px;
    line-height: 37px;
}
body.eng #season3 #table_3 .text_style6{
    font-size: 20px;
}
#season3 .page-header{
    display: flex;
}
#season3 .btn-area{
    margin-left: auto;
}
#season3 .fbSharebtn{
    margin: 0;
    font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
}


@media screen and (min-width: 992px) and (max-width: 1199px) {
    #season3 .container-fluid > .row > .col-md-12 > .section{
        padding: 40px;
    }
}


@media screen and (min-width: 768px) and (max-width: 991px){
    #season3 #table_2{
        width: 620px;
        margin-left: -310px;
    }

    #season3 #table_2 td:nth-child(2){
        width: 270px;
    }
}
@media screen and (max-width: 767px){
    #season3 #table_1{
        width: 65%;
        margin: auto;
    }
    #season3 #table_1 .td_img{
        text-align: right;
        width: 28%;
    }
    #season3 #table_1 img{
        width: 38px;
    }
    #season3 #table_1 tr:not(:first-child){
        border-top: 4px solid #dddddd;
    }
    #season3 #table_1 td{
        padding: 10px;
    }
    #season3 #table_1 .text_style1{
        font-size: 26px;
    }
    body.eng #season3 #table_1 .text_style1{
        font-size: 17px;
    }
    #season3 #table_1 .text_style2{
        font-size: 32px;
    }
    body.eng #season3 #table_1 .text_style2{
        font-size: 21px;
    }
    #season3 #area_1{
        margin-top: 10px;
        height: 100px;
    }
    #season3 #area_1 .inner_area1{
        position: absolute;
        top: -12px;
        left: 50%;
        margin-left: -45px;
        width: 90px;
        font-size: 20px;
        line-height: 24px;
    }
    body.eng #season3 #area_1 .inner_area1{
        font-size: 13px;
    }
    #season3 .inner_title{
        font-size: 27px;
        height: 35px;
        line-height: 35px;
    }
    #season3 .panel-title{
        margin-top: 0;
        margin-bottom: 0;
        font-size: 18px;
        color: inherit;  
    }
    #season3 #table_2{
        left: 0;
        width: 100%;
        margin-left: 0;
        font-size: 18px;
    }
    #season3 #table_2 .text_style3{
        font-size: 18px;
    }
    #season3 #table_2 td:nth-child(1){
        width: 40px;
    }
    #season3 #table_2 td:nth-child(2){
        width: 100px;
    }
    #season3 #table_2 td:nth-child(3){
        vertical-align: top;
        width: 20px;
    }
    #season3 #table_2 td:nth-child(4){
        padding-left: 0px;
        padding-right: 0px;
    }
    #season3 .collapse_item .inner_col1 table{
        width: 250px;
        margin: auto;
    }
    #season3 .collapse_item .text_style5{
        font-size: 26px;
    }
    #season3 .collapse_item .inner_col2>div{
        width: 250px;
        font-size: 20px !important;
    }
    #season3 .collapse_item .inner_col1{
        border-right: 0;
        border-bottom: 1px solid #999;
    }

    #season3 #table_3{
        width: 100%;
    }
    #season3 #table_3 td{
        height: 30px;
        padding-left: 0px;
    }
    #season3 #table_3 .img_style1{
        width: 20px;
        float: left;
    }
    #season3 #table_3 .text_style6{
        margin-left: 20px;
        font-size: 17px;
        color: black;
        height: 20px;
        line-height: 20px;
    }
    #season3 .page-header{
        display: block;
    }
    #season3 .fbSharebtn{
        margin: 10px 0; 
    }
    body.eng #season3 .fbSharebtn{
        font-size: 14px;
    }
    body.eng #season3 #table_2 .text_style3{
        font-size: 12px;
    }
    body.eng #season3 #table_2 {
        font-size: 12px;
    }
    body.eng #season3 .inner_title{
        font-size: 18px;
    }
    body.eng #season3 .panel-title{
        font-size: 12px;
    }
    body.eng #season3 .collapse_item .text_style5{
        font-size: 17px;
    }
    body.eng #season3 .collapse_item .inner_col2>div{
        font-size: 13px !important;
    }
    body.eng #season3 #table_3 .text_style6{
        font-size: 11px;
    }
}