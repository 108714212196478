  
#dwvideoind .gallery-item{
    max-height: 1170px;
    cursor: pointer;
}
#dwvideoind .section-p{
    min-height: inherit;
    margin-bottom: 20px;
}
#dwvideoind .videorack .videorack-title{
    padding-left:40px;
    padding-right:40px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(204, 204, 204, 1);
    font-size: 30px;
}
body.eng #dwvideoind .videorack .videorack-title{
    font-size: 20px;
}
#dwvideoind .videorack-body{
    position: relative;
    overflow: hidden;
    margin: 20px 0px;
    padding-left: 30px;
    padding-right: 30px;
}
#dwvideoind #tmrack .videorack-body{
    padding-left: 0px;
    padding-right: 0px;
}
#dwvideoind .videorack-nav{
    position: absolute;
    background-color: rgba(245, 246, 247, 1);
    width:30px;
    top:0;
    bottom:0;
    height: 100%;
}
#dwvideoind .videorack-nav.backward{
    left:0;
}
#dwvideoind .videorack-nav.forward{
    right:0;
}

#dwvideoind .videorack-nav-icon{
    display: block;
    position: absolute;
    width: 16px;
    height: 26px;
    left: 8px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
#dwvideoind .videoplayer {
    width: 100%;
    margin-bottom: 20px;
}
#dwvideoind .videoplayer-container {
    height: 0;
    width: 100%;
    padding-bottom: 39%;
    overflow: hidden;
    position: relative;
}
#dwvideoind iframe{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}
#dwvideoind .videorack-nav.backward .videorack-nav-icon{
    background: url("../../../assets/images/bullet/nav-backward.png") center center no-repeat; 
}
#dwvideoind .videorack-nav.forward .videorack-nav-icon{
    background: url("../../../assets/images/bullet/nav-forward.png") center center no-repeat; 
}
#dwvideoind .swiper-button-next, #dwvideoind .swiper-container-rtl .swiper-button-prev,
#dwvideoind .swiper-button-prev, #dwvideoind .swiper-container-rtl .swiper-button-next{
    background-image: none;
}
#dwvideoind .swiper-button-next, #dwvideoind .swiper-button-prev{
    margin-top: 0;
}
#dwvideoind .videorack-page{
    table-layout: fixed;
    margin-left: auto;
    margin-right: auto;
    width: 94%;
}
#dwvideoind #tmrack .videorack-page{
    width: 100%;
    margin: 0px;
    border: none;
    border-collapse: inherit;
}
#dwvideoind .videorack-page td{
    width: 25%;
    border-left: 10px solid white;
    border-right: 10px solid white;
    vertical-align: top;
}
#dwvideoind #tmrack .videorack-page td {
    width: 20%;
    border-left: 5px solid white;
    border-right: 5px solid white;
    vertical-align: top;
}
#dwvideoind #tmrack .videorack-page td:first-child{
    border-left: 0px;
}
#dwvideoind #tmrack .videorack-page td:last-child{
    border-right: 0px;
}
#dwvideoind .videorack-item{
    width: 100%;
    cursor: pointer;
    display: inline-block;
}
#dwvideoind .videorack-item-hidden{
    visibility: hidden;
}
#dwvideoind .videorack-item-txt{
    margin-top: 6px;
    height: 46px;
    /* overflow: hidden; */
    text-overflow: ellipsis;
}
body.eng #dwvideoind .videorack-item-txt{
    overflow: hidden;
}

#dwvideoind .tablist{
    position: relative;
    margin-top: 20px;
    margin-bottom: 10px;
}
#dwvideoind .tablist-item{
    width:100%;
    height:40px;
    display:table;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom:5px solid #E0E0E0;
    background-color:#EEF0F2;
    cursor:pointer;
}
#dwvideoind .tablist table{
    table-layout:fixed;
    width:100%
}
#dwvideoind .tablist table td{
    border-right:10px solid #fff;
    width: 50%;
}
#dwvideoind .tablist table td:last-child{
    border-right:0;
}
#dwvideoind .tablist-item-txt{
    display:table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
    line-height: 1;
    padding: 0 5px;
}
body.eng #dwvideoind .tablist-item-txt{
    font-size: 13px;
    line-height: normal;
}
#dwvideoind .videolist-item{
    max-width: 320px;   
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    cursor: pointer;
}
#dwvideoind .videolist-item-hidden{
    visibility: hidden;
}
#dwvideoind .videolist-item-txt{
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}
#dwvideoind .tablist .active .tablist-item{
    background-color: #FECC0A;
    border-bottom:5px solid #333;
}
#dwvideoind .text-bold{
    font-weight: bold;
}

/*******************************************************************************
popup
*******************************************************************************/
.hotline-layer{
    width: 100%;
    overflow: hidden;
}
.hotline-layer iframe{
    width: 100%;
    height: 50vh;
}
.overlay {
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0,0,0,0.6);
}
.overlay-inner {
    margin: 15vh auto;
    width: 80%;
    height: auto;
    background: #FFFFFF;
}
#ppboxHTML2 .overlay-inner {
    margin: 25vh auto;
    width: 50%;
    height: auto;
    background: #000000;
    color: #ffffff;
}
.popupbox-content {
    padding: 60px;
}
.btn-close {
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px;
}
/* .noscroll{position:relative;overflow:hidden;} */

#ppboxHTML2 .page-header {
    margin-top: 0px;
}

#ppboxHTML2 p, #ppboxHTML2 h3 {
    color: #ffffff;
}

#ppboxHTML2 input {
    width: 100%;
    margin-bottom: 10px;
    color: #333333;
}

/* =============== */

#dwvideoind .gallery .swiper-pagination span {
    border-radius: 50px;
}

#dwvideoind .gallery .swiper-slide {
    width: 100% !important;
}
#dwvideoind .videorack-body .swiper-slide {
    width: 100% !important;
}
#dwvideoind #videogallery > .slick-slider{
    margin-right: -40px;
    margin-left: -40px;
    margin-bottom: -7px;
}
#dwvideoind #videogallery ul.slick-dots{
    bottom: 20px;
}
#dwvideoind #videogallery ul.slick-dots li{
    width: 16px;
    height: 16px;
}
#dwvideoind #videogallery ul.slick-dots li button::before{
    display: none;
}
#dwvideoind #videogallery ul.slick-dots li button{
    padding: 0px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: solid 1px #ddd;
    background-color: transparent;
}
#dwvideoind #videogallery ul.slick-dots li.slick-active button{
    background-color: #FECC0A;
    border-color: #FECC0A;
}

#dwvideoind .videorack-custom .slick-list{
    border-left: 5px solid white;
    border-right: 5px solid white;
    margin-right: 25px;
}
#dwvideoind .videorack-custom .slick-track{
    margin: 0px;
}
#dwvideoind .videorack-custom .slick-track .slick-slide{
    max-width: 330px;
    width: 100% !important;
    border-left: 5px solid white;
    border-right: 5px solid white;
}
#dwvideoind .videorack-custom button.slick-arrow,
#dwvideoind .videorack-custom button.control-arrow{
    width: 30px;
    height: 100%;
    background-color: rgba(245, 246, 247, 1);
    opacity: 1;
    display: block;
    padding: 0px;
}
#dwvideoind .videorack-custom button.slick-arrow::before,
#dwvideoind .videorack-custom button.control-arrow::before{
    content: "";
    opacity: 1;
    width: 16px;
    height: 26px;
    display: block;
    background-repeat: no-repeat;
    margin: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
}
#dwvideoind .videorack-custom button.slick-arrow.slick-prev{
    left: -30px;
}
#dwvideoind .videorack-custom button.slick-arrow.slick-prev::before,
#dwvideoind .videorack-custom button.control-arrow.control-prev::before{
    background-image: url("../../../assets/images/bullet/nav-backward.png");
}
#dwvideoind .videorack-custom button.slick-arrow.slick-next{
    right: -30px;
}
#dwvideoind .videorack-custom button.slick-arrow.slick-next::before,
#dwvideoind .videorack-custom button.control-arrow.control-next::before{
    background-image: url("../../../assets/images/bullet/nav-forward.png");
}
#dwvideoind .videorack-custom button.slick-arrow.slick-disabled,
#dwvideoind .videorack-custom button.control-arrow.control-disabled{
    cursor: default;
    opacity: 0.35;
}
#dwvideoind #seminarrack .videorack-item-txt,
#dwvideoind #edurack .videorack-item-txt{
    text-align: left;
    margin-bottom: 7px;
}
body.eng #dwvideoind #seminarrack .videorack-item-txt,
body.eng #dwvideoind #edurack .videorack-item-txt{
    margin-bottom: 0px;
}
#dwvideoind #testimonialrack .videorack-item-txt{
    text-align: left;
}
#dwvideoind #seminarrack .carousel > .thumbs-wrapper,
#dwvideoind #testimonialrack .carousel > .thumbs-wrapper,
#dwvideoind #edurack .carousel > .thumbs-wrapper{
    display: none;
}
#dwvideoind #seminarrack .videorack-body,
#dwvideoind #testimonialrack .videorack-body,
#dwvideoind #edurack .videorack-body,
#dwvideoind #dmarack .videorack-body{
    padding: 0px;
}
#dwvideoind #dmarack .videorack-body li > .slide-tab,
#dwvideoind #seminarrack .videorack-body li > .slide-tab{
    max-width: 1070px !important;
    margin: 0px auto !important;
    width: calc(100% - 20px) !important;
}
#dwvideoind #dmarack .videorack-body li > .slide-tab td:first-child,
#dwvideoind #seminarrack .videorack-body li > .slide-tab td:first-child{
    border-left: 0px;
}
#dwvideoind #dmarack .videorack-body li > .slide-tab td:last-child,
#dwvideoind #seminarrack .videorack-body li > .slide-tab td:last-child{
    border-right: 0px;
}
#dwvideoind #dmarack .videorack-item-txt{
    text-align: left;
}
#dwvideoind .videoinfo-title {
    font-family: "DBHeaventMedium";
    margin-bottom: 10px;
    font-weight: normal;
}
body.eng #dwvideoind .videoinfo-title{
    font-family: "MCQGlobal_A_Md";
}
#dwvideoind .videoinfo-p {
    margin-bottom: 0px;
    margin-top: -8px;
}
#dwvideoind .videoinfo .fbSharebtn{
    margin: 10px 0 10px 10px !important;
}
#dwvideoind #dmarack #ppboxHTML2{
    display: block;
}
#dwvideoind #dmarack #ppboxHTML2 .btn-close{
    top: 15px;
    right: 15px;
}
#dwvideoind #dmarack #ppboxHTML2 iframe{
    position: inherit;
    height: 50vh;
}

@media screen and (min-width: 992px) and (max-width: 1199px){
    #dwvideoind .container-fluid > .section{
        padding: 40px;
    }
}
@media screen and (max-width: 992px){
    #dwvideoind .videorack-page{
        width: 92%;
    }
    #ppboxHTML2 .overlay-inner {
        margin: 15vh auto;
        width: 90%;
    }
    .popupbox-content {
        padding: 20px;
    }
    .overlay-inner .popupbox-content {
        padding-left: 30px;
        padding-right: 30px;
    }
    #dwvideoind #tmrack .videorack-page{
        max-width: 92% !important;
        margin: 0px auto !important;
    }
}
@media screen and (max-width: 768px) {
    body.eng #dwvideoind .page-header h1 {
        font-size: 36px;
    }
}
@media screen and (max-width: 767px){
    body.eng #dwvideoind .page-header h1 {
        font-size: 18px;
    }
    #dwvideoind .videoplayer-container{
        padding-bottom: 56%;
    }
    #dwvideoind .videoinfo .fbSharebtn {
        margin: 10px 0px !important;
    }
    #dwvideoind ~ #ppboxHTML2{
        display: block;
    }
    #dwvideoind ~ #ppboxHTML2 .btn-close {
        top: 15px;
        right: 15px;
        width: 16px;
        height: 16px;
    }
}