#supertrader body{
    font-family: 'DBHeaventLight';
    font-size: 28px;
}
#supertrader body .breadcrumbbar{
    font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif ;
}
#supertrader .text-bold{
    font-family: 'DBHeaventMedium';
}
#supertrader .text-center{
    text-align: center;
}
#supertrader p{
    line-height: 1em;
    font-size: 28pxs
}
#supertrader .row{
    margin-left: -10px;
    margin-right: -10px;
}
#supertrader .section img{
    width: 100%;
}
#supertrader .img-holder{
   position:relative;         
}
#supertrader .img-holder a{
    display: block;
    position: absolute;
}
#supertrader .img-holder #href-pdf-01{   
    left: 62%;
    right: 14%;
    top: 54%;
    bottom: 21%;
}
#supertrader #area_1{
    background-color: lightgrey;
    position: relative;
    min-height: 134px;
    padding-top: 70px;
    padding-bottom: 40px;
}
#supertrader #area_1 .inner_area1{
    position: absolute;
    display:inline-block;
    top: -27px;
    left: 50%;
    margin-left: -115px;
    width: 230px;
    border-radius: 26px;
    text-align: center;
    line-height: 55px;
    color: white;
    background-color: #1793C5;
    font-size: 42px;
    font-family: "DBHeaventMedium";
}
#supertrader #table_2{
    margin-left: auto;
    margin-right: auto;
    border-spacing: 0;
    padding:0;
}
#supertrader #table_2 .img-flight{
    max-width: 100px;
    margin-right: 20px;
}
#supertrader #table_2 .img-flight-m{
    max-width: 20px;
}
#supertrader #table_2 .text_style3{
    font-family: "DBHeaventMedium";
    font-size: 66px;
    line-height: 0.5em;
    margin-bottom: 20px;
}
#supertrader #table_2 .text_style4{
    font-family: "DBHeaventMedium";
    font-size: 42px;
    line-height: 0.5em;
}
#supertrader #table_2 .text_style5{
    font-size: 30px;
    margin-left: 10px;
}
#supertrader #table_2 .btn_cc{
    background-color: #1793C5;
    display: inline-block;
    width: 256px;
    text-align: center;
    height: 48px;
    line-height: 48px;
    margin-left: 10px;
    font-size: 34px;
}
#supertrader #table_2 .btn_cc a{
    text-decoration: none;
    color: white;
}
#supertrader .inner_title{
    font-family: "DBHeaventMedium";
    background-color: #FFD800;
    text-align: center;
    color: #3C3C3C;
    height: 62px;
    line-height: 62px;
    width: 100%;
    font-size: 48px;
}
#supertrader .inner_title_light{
    font-family: "DBHeaventMedium";
    background-color: #FEDCBF;
    text-align: center;
    color: black;
    height: 44px;
    line-height: 44px;
    width: 100%;
}
#supertrader .inner_title_bottom{
    border-top: 5px solid #FFD800;
    height: 0
}
#supertrader .panel-group{
    margin: 0;
}
#supertrader .panel-group .panel+.panel{
    margin: 0;
}
#supertrader .panel-group .panel{
    border: 0;
    border-bottom: 1px solid #dddddd;
    border-radius: 0;
}
#supertrader .panel-default>.panel-heading{
    background-color: #E6E6E6;
    padding: 14px 15px;
}
#supertrader .panel-heading .panel-title{
    font-size: 28px;
    color: #333333;
}
#supertrader .panel-heading .icon-more{
    position: relative;
    width: 30px;
    margin-left: 20px;
    top: -2px;
}
#supertrader .collapse_item .row{
    margin: 0;
    border-color: #FFFFFF;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 2px solid #D6D6D6;
}
#supertrader .collapse_item .row.last,
#supertrader .collapse_item .row:last-child{
    border-bottom: 2px solid #FFFFFF;
}
#supertrader .collapse_item .row>div{
    padding: 10px;
}
#supertrader .collapse_item .inner_col1 table{
    margin: auto;
}
#supertrader .collapse_item .inner_col1 td:nth-child(1){
    width: 90px;
}
#supertrader .collapse_item .inner_col1 td:nth-child(2){
    padding-left: 20px;
}
#supertrader .collapse_item .text_style4{
    font-family: "DBHeaventMedium";
}
#supertrader .collapse_item .text_style5{
    font-family: "DBHeaventMedium";
}
#supertrader .collapse_item .inner_col2{
    border-left: 2px solid #D6D6D6;
}
#supertrader .collapse_item .inner_col2>div{
    width: 300px;
    margin: auto;          
}
#supertrader .collapse_item .inner_col2>div:first-child{
    font-family: "DBHeaventMedium";
    margin-top: 10px;
    text-align: center;
}
#supertrader .collapse_item .inner_col2>div:nth-child(2){
    margin-top: 20px;
    text-align: right;
}
#supertrader #table_3{
    width: 600px;
    margin: auto;
}
#supertrader #table_3 td{
    width: 50%;
    height: 60px;
    vertical-align: middle;
    padding-left: 30px;
}
#supertrader #table_3 .img_style1{
    width: 40px;
    float: left;
}
#supertrader #table_3 .text_style6{
    margin-left: 50px;
    /*font-size: 20px;*/
    color: black;
    height: 37px;
    line-height: 37px;
}
#supertrader .greybg{
    background-color: #F5F5F5;
}
#supertrader .area_sharing{
    padding-top: 30px;
    padding-bottom: 20px;
}
#supertrader .area_sharing .htxt{
    font-family: "DBHeaventMedium";
    text-align: center;
    height: 44px;
    line-height: 44px;
    font-size: 32px;
    margin-bottom: 30px;
}
#supertrader .area_sharing .col_left{
    text-align: right
}
#supertrader .area_sharing .col_middle{
    text-align: center;
}
#supertrader .area_sharing .col_right{
    text-align: left
}
#supertrader .area_sharing .link-item{
    position: relative;
    display: inline-block;
    height: 40px;
    margin-bottom: 20px;
    text-align: left !important;
    color: #333333 !important;
}
#supertrader .area_sharing .link-item-img{
    position: absolute;
    width: 40px;
    border-radius: 8px;
}
#supertrader .area_sharing .link-item-txt{
    line-height: 40px;
    margin-left: 50px;
}
#supertrader .page-header{
    display: flex;
}
#supertrader .btn-area{
    margin-left: auto;
}
#supertrader .fbSharebtn{
    margin: 0;
    font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif ;
    font-size: 16px;
}
            
@media screen and (max-width: 991px){
    #supertrader body{
          font-size: 22px;
    }
    #supertrader .inner_title{
        height: 40px;
        line-height: 40px;
        font-size: 32px;
    }
    #supertrader .panel-heading .panel-title {
        font-size: 22px;
    }
    #supertrader .panel-default>.panel-heading{
        padding-top: 5px;
        padding-bottom: 5px;
    }
    #supertrader .panel-heading .icon-more{
        margin-left: 10px;
        width: 25px;
    }
    #supertrader .area_sharing{
        padding-top: 10px;
        padding-bottom: 10px;    
    }
    #supertrader .area_sharing .htxt{
        height: 36px;
        line-height: 36px;
        font-size: 26px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 767px){
    #supertrader body{
        font-size: 20px;
    }
    #supertrader .inner_title{
        height: 36px;
        line-height: 36px;
        font-size: 32px;
    }
    #supertrader .area_sharing .htxt{
        height: 32px;
        line-height: 32px;
        font-size: 24px;
    }
    #supertrader .area_sharing .col_left,
    #supertrader .area_sharing .col_right,
    #supertrader .area_sharing .col_middle{
        text-align: left;
    }
    #supertrader .area_sharing .link-row{
        width: 240px;
        margin-left: auto;
        margin-right: auto;
    }
    #supertrader #table_1{
        width: 65%;
        margin: auto;
    }
    #supertrader #table_1 .td_img{
        text-align: right;
        width: 28%;
    }
    #supertrader #table_1 img{
        width: 38px;
    }
    #supertrader #table_1 tr:not(:first-child){
        border-top: 4px solid #dddddd;
    }
    #supertrader #table_1 td{
        padding: 10px;
    }
    #supertrader .collapse_item .inner_col1 table{
        margin: auto;
    }
    #supertrader .collapse_item .inner_col2>div{
        width: 250px;
    }
    #supertrader .collapse_item .inner_col1{
        border-right: 0;
    }
    #supertrader .collapse_item .inner_col2{
        border-left: 2px solid #FFFFFF;
    }
    #supertrader .page-header{
        display: unset;
    }
    #supertrader .fbSharebtn{
        margin: 10px 0; 
    }
}

@media screen and (max-width: 420px){
    #supertrader body{
        font-size: 18px;
    }
    #supertrader .inner_title{
        height: 28px;
        line-height: 28px;
        font-size: 24px;
    }
    #supertrader .area_sharing .htxt{
        height: 28px;
        line-height: 28px;
        font-size: 20px;
    }
    #supertrader .panel-heading .panel-title {
        font-size: 18px;
    }
    #supertrader .panel-default>.panel-heading{
        padding-top: 3px;
        padding-bottom: 3px;
    }
    #supertrader .panel-heading .icon-more{
        margin-left: 10px;
        width: 25px;
    }
}
