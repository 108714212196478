 
#seminar .section img{
    width: 100%;
}
#seminar .btn-area{
    text-align: right;
}
#seminar #bookBtn{
    display: inline-block;
    width: 150px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin: 10px 0 10px 10px;
    cursor: pointer;
}
#seminar .loadingindicator {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("../../../assets/images/bullet/loading.gif") center center no-repeat;
    background-color: white;
    z-index: 990;
    opacity: 0.6;
}
#seminar #shareBtn{
    font-style: italic;
    display: inline-block;
    width: 200px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin: 10px 0 10px 14px;
    cursor: pointer;
    color: white;
    background-color: #666;
    font-size: 16px;
    font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
}
#seminar #shareBtn>i{
    margin-right: 20px;
}
#seminar .more_seminars.row{
    /*margin-left: -8px;*/
    /*margin-right: -8px;*/
    margin-bottom: 30px;
}
#seminar .more_seminars .col-sm-4,
#seminar .more_seminars .col-xs-12{
    padding-top: 10px;
    /*padding: 8px;*/
}
#seminar .more_seminars .row{
    margin-left: -10px;
    margin-right: -10px;
    display: flex;
    flex-wrap: wrap;
}
#seminar .more_seminars .row > div {
    cursor: pointer;
    padding: 10px 10px 0px !important;
}

#seminar ~ #popup-customized #book_area{
    margin: auto;
    max-width: 700px;
    background-color: black;
    padding: 40px;
    height: 100vh;
}
#seminar ~ #popup-customized #book_area .page-header{
    margin-top: 0;
    margin-bottom: 40px;
}
#seminar ~ #popup-customized #book_area h1,
#seminar ~ #popup-customized #book_area p{
    color: white;
}
#seminar ~ #popup-customized #book_area input[type=text]{
    width: 100%;
    padding: 4px;
}
#seminar ~ #popup-customized #book_area .warning{
    height: 20px;
    color: red;
    margin-bottom: 5px;
    padding-left: 8px;
}
#seminar ~ #popup-customized #submitBtn{
    width: 100%;
    border: 0;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    color: #666666;
    background-color: #FECC0A;
}
#seminar ~ #popup-customized{
    background-color: rgba(0,0,0,0.6);
}
#seminar ~ #popup-customized h5{
    max-width: 440px;
    padding: 20px;
    margin: auto;
    text-align: center;
    background-color: white;
    color: black;
}

.nomsgbar{
    padding: 20px;
    text-align: center;
}



/* NEW-CSS-M */

.sem_imglf {
    padding-left: 0px !important;
}

.breadcrumbbar .breadcrumb>li+li:before {
    padding: 0px 1px 0px 9px;
}

.navbar-inverse.navbar-small .navbar-nav{
    margin-right: 0px;
}

#seminar ~ #popup-customized div#book_area {
    overflow: auto;
}
#seminar ~ #popup-customized div#book_area img.btn-close{
    width: 20px;
    height: 20px;
}

/* ============================================================ RESPONSIVE START =================================================================*/
@media screen and (min-width: 992px) and (max-width: 1199px){
    #seminar .container-fluid > .row > .col-md-8 > .section {
        padding: 40px;
    }
    #seminar .container-fluid > .row > .col-md-4 > .section {
        padding: 20px;
    }
}

@media screen and (max-width: 767px){
    #seminar #bookBtn{
        display: block;
        width: 100%;
        margin: 10px 0;
    }
    #seminar #shareBtn{
        display: block;
        width: 100%;
        margin: 10px 0;
        font-size: 14px;
    }
}
