#toptradedstock .tablist{
    position:relative;
    height:60px;
    margin-top:20px;
    margin-bottom:15px;
}
#toptradedstock .tablist-left{
    position: absolute;
    top:0;
    bottom:0;
    right:0;
}
#toptradedstock .tablist-left table{
    table-layout:fixed;
}
#toptradedstock .tablist-left table td{
    border-right:10px solid #fff;
}
#toptradedstock .tablist-left table td:last-child{
    border-right:0;
}
#toptradedstock .tablist-item{
    width:130px; 
    height:55px;
    display:table;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom:5px solid #E0E0E0;
    background-color:#EEF0F2;
    cursor:pointer;
}
#toptradedstock .tablist-item-txt{
    display:table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
    line-height: normal;
    padding: 0 5px;
}
body.eng #toptradedstock .tablist-item-txt{
    font-size: 13px;
}
#toptradedstock .tablist .active .tablist-item{
    background-color: #FECC0A;
    border-bottom:5px solid #333;
}
#toptradedstock .tablist .tablist-item:focus, #toptradedstock .tablist .tablist-item:hover{
    background-color: #FECC0A;
    border-bottom:5px solid #333;
}


#toptradedstock #topTbl table {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 10px;
}
#toptradedstock #topTbl table th {
    font-family: "DBHeaventMedium";
    font-weight: normal;
    text-align: center;
        border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
}
body.eng #toptradedstock #topTbl table th{
    font-family: "MCQGlobal_A_Md";
}
#toptradedstock #topTbl table th,
#toptradedstock #topTbl table td {
    padding: 8px;
    line-height: 1;
    vertical-align: top;
}
body.eng #toptradedstock #topTbl table th,
body.eng #toptradedstock #topTbl table td{
    line-height: 1.42857143;
}
#toptradedstock #topTbl table td.right-b01{
      border-right: 1px solid #ddd;
}
#toptradedstock #topTbl table tbody tr.none{
    display: none;
}

#toptradedstock #topTbl.calldw .col_put{
    display: none;
}
#toptradedstock #topTbl.putdw .col_call{
    display: none;
}

#toptradedstock #topTbl.putdw .hide-putdw {
    display:none !important;
}

#toptradedstock #topTbl.calldw .hide-calldw {
    display:none !important;
}

#toptradedstock .lastupdatebox{
    display:block;
    text-align: right;
    font-size: 18px;
    margin-bottom: 40px;
    width: 100%;
}
body.eng #toptradedstock .lastupdatebox{
    font-size: 12px;
}

@media screen and (min-width: 992px) and (max-width: 1199px){
    #toptradedstock .container-fluid > .section{
        padding: 40px;
    }
}

@media screen and (max-width: 991px) {
    #toptradedstock #topTbl table td,
    #toptradedstock #topTbl table th{
        padding-left: 2px;
        padding-right: 2px;
        font-size: 18px;
    }
    body.eng #toptradedstock #topTbl table td,
    body.eng #toptradedstock #topTbl table th{
        padding-left: 2px;
        padding-right: 2px;
        font-size: 12px;
    }
}
@media screen and (max-width: 767px){
    #toptradedstock .tablist{
        margin-bottom: 0px;
    }
    #toptradedstock #topTbl table td,
    #toptradedstock #topTbl table th{
        font-size: 17px;
        padding-left: 2px;
        padding-right: 2px;
    }
    #toptradedstock .lastupdatebox {
        font-size: 17px;
    }
    #toptradedstock .tablist .tablist-item{
        width: 65px;
    }
    body.eng #toptradedstock .page-header h1{
        font-size: 18px;
    }
    body.eng #toptradedstock #topTbl table td, 
    body.eng #toptradedstock #topTbl table th{
        font-size: 11px;
    }
    body.eng #toptradedstock .lastupdatebox{
        font-size: 11px;
    }
}
@media screen and (max-width: 375px) {
    #toptradedstock #topTbl table td,
    #toptradedstock #topTbl table th{
        font-size: 15px;
    }
}
@media screen and (max-width: 340px) {
    #toptradedstock #topTbl table td,
    #toptradedstock #topTbl table th{
        font-size: 12px;
    }
}
