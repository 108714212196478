.photo-holder{
    background: url("../../../assets/images/bullet/loading.gif") center center no-repeat;
    background-color: #000;
}

#photo-main .photo-holder{
    height: 730px;
}

.section .row{
    margin: 0;
}
.section .col-xs-6,
.section .col-sm-3{
    padding: 0;
}
.section .photo-item{
    position: relative;
}
#photo-list .photo-holder{
    height: 180px;
}
#photo-list .photo-shadow{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.6);
    cursor: pointer;
}
#photos .photo-item:hover .photo-shadow{
    background: url("../../../assets/images/marketnews/zoom-in-button.png") center center no-repeat;
}
#photos .photo-item:hover .photo-shadow.active,
#photos .photo-item .photo-shadow.active{
    background-image: none;
    background-color: rgba(0,0,0,0);
}
#photo-mobile .photo-holder{
    height: 180px;
    /*margin: 10px;*/
    /*box-shadow: 0px 0px 20px 10px black;*/
}
#individualgallery #photo-list.mobile{
    display: none;
}

@media screen and (min-width: 992px) and (max-width: 1199px){
    #individualgallery .container-fluid > .row > .col-md-12 > .section{
        padding: 40px;
    }
}

@media only screen and (max-width: 991px) {
    #individualgallery #photos > #photo-main{
        display: none;
    }
    #individualgallery #photo-list.desktop{
        display: none;
    }
    #individualgallery #photo-list.mobile{
        display: block;
    }
    #individualgallery #popup-image .btn-close{
        width: 20px;
        height: 20px;
    }
    #individualgallery #popup-image img.image{
        width: 100%;
    }
}