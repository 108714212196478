#dwsearch .filter {
  margin-top: 30px;
  margin-bottom: 20px;
  z-index: 2;
  position: relative;
}
#dwsearch .filter > .row {
  margin-right: 0;
  margin-left: 0;
}

#dwsearch table#resulttable-lg .result-sort th svg {
  font-size: 24px;
  line-height: inherit;
  height: 24px;
  width: 14px;
}
body.eng #dwsearch table#resulttable-lg .result-sort th svg{
  font-size: 16px;
  line-height: inherit;
  height: 16px;
  width: 11px;
}

#dwsearch .filter .col-sm-4 {
  padding-left: 0;
  padding-right: 0;
}
#dwsearch .filter .filter-item {
  margin-bottom: 16px;
  margin-right: 8px;
  margin-left: 8px;
}
#dwsearch #tradeEl5 .dropdown-or.tradeEl {
  justify-content: space-around;
}
#dwsearch input.tradeEl.tradeBtn[aria-expanded="true"]:focus,
#dwsearch input.tradeEl.tradeBtn[aria-expanded="false"]:focus {
  box-shadow: none;
}
#dwsearch input.tradeEl.tradeBtn[aria-expanded="true"] {
  background-color: #999999 !important;
  border-color: #999999 !important;
  color: #fff !important;
  box-shadow: none;
} 
#dwsearch .filter .filter-item .css-b62m3t-container span[aria-live="polite"] > * {
  text-align: center;
  background-color: #000000;
}
#dwsearch .filter .filter-item .css-b62m3t-container .css-qc6sy-singleValue {
  text-align: center;
  font-size: 24px;
}
body.eng #dwsearch .filter .filter-item .css-b62m3t-container .css-qc6sy-singleValue {
  text-align: center;
  font-size: 16px;
}
#dwsearch .filter .filter-item .css-1s2u09g-control .css-6j8wv5-Input input,
#dwsearch .filter .filter-item .css-1s2u09g-control .css-1pndypt-Input input {
  text-align: center;
  margin: 0px auto;
}
#dwsearch .filter .filter-item .css-1s2u09g-control,
#dwsearch .filter .filter-item .css-1pahdxg-control {
  border-radius: 0px;
  border: solid 1px #aeaeae !important;
  cursor: pointer;
  min-height: auto;
}
#dwsearch .filter .filter-item .css-2613qy-menu,
#dwsearch .filter .filter-item .css-6j8wv5-Input,
#dwsearch .filter .filter-item .css-ackcql,
#dwsearch .filter .filter-item .css-1pndypt-Input {
  display: flex;
  justify-content: center;
  margin: 0px !important;
  padding: 0px !important;
}
#dwsearch .filter .filter-item .css-1s2u09g-control input,
#dwsearch .filter .filter-item .css-1pahdxg-control input,
#dwsearch .filter .filter-item .css-1s2u09g-control .css-6j8wv5-Input input,
#dwsearch .filter .filter-item .css-1s2u09g-control .css-ackcql input,
#dwsearch .filter .filter-item .css-1pndypt-Input input.focus-visible,
#dwsearch .filter .filter-item .css-vwja0k input.focus-visible,
#dwsearch .filter .filter-item .css-6j8wv5-Input input.focus-visible,
#dwsearch .filter .filter-item .css-ackcql input.focus-visible {
  margin: 0px auto !important;
  text-align: center !important;
  min-width: 100% !important;
}
body.eng #dwsearch .filter .filter-item .css-1s2u09g-control .css-ackcql input, 
body.eng #dwsearch .filter .filter-item .css-1s2u09g-control input,
body.eng #dwsearch .filter .filter-item .css-1pahdxg-control input{
  font-size: 16px !important;
}
#dwsearch .filter .filter-item .css-1pahdxg-control {
  border-radius: 0px;
  border: solid 1px #aeaeae !important;
  box-shadow: none;
}
#dwsearch .filter .filter-item .css-1pahdxg-control .css-6j8wv5-Input,
#dwsearch .filter .filter-item .css-1s2u09g-control .css-ackcql,
#dwsearch .filter .filter-item .css-vwja0k {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 24px;
  margin: 0px !important;
  padding: 0px !important;
}
#dwsearch .filter .filter-item .css-1s2u09g-control .css-tlfecz-indicatorContainer {
  color: #333;
}
#dwsearch .filter .filter-item .css-1s2u09g-control .css-14el2xx-placeholder,
#dwsearch .filter .filter-item .css-1pahdxg-control .css-14el2xx-placeholder{
  font-size: 24px;
}
body.eng #dwsearch .filter .filter-item .css-1s2u09g-control .css-14el2xx-placeholder,
body.eng #dwsearch .filter .filter-item .css-1pahdxg-control .css-14el2xx-placeholder{
  font-size: 16px;
}
#dwsearch .filter .filter-item .css-1s2u09g-control span,
#dwsearch .filter .filter-item .css-1pahdxg-control span {
  display: none;
}
#dwsearch .filter-item button {
  position: relative;
  padding-right: 30px;
  height: 38px;
  margin-top: 0px;
  border-radius: 0px;
  display: none;
}
#dwsearch .filter-item .dropdown-label {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#dwsearch .filter-item .glyphicon {
  position: absolute;
  right: 10px;
  top: 50%;
  margin: 0px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
#dwsearch .filter-item .checkbox {
  padding: 5px 10px;
}
#dwsearch .filter .col-sm-4:last-child .filter-item {
  margin-right: 0px;
  position: relative;
}
#dwsearch .filter .col-sm-4:last-child .filter-item .dropdown-content ul li label {
  padding: 5px 10px !important;
  font-size: 14px;
}
#dwsearch .filter .col-sm-4:last-child .filter-item .dropdown-content ul span{
  font-size: 14px !important;
  font-weight: 100;
  top: -3px;
  position: relative;
}
#dwsearch .filter .col-sm-4:last-child .filter-item .dropdown-content ul.options > label.select-item{
  padding: 15px 10px 5px;
  margin-bottom: 0px;
}
#dwsearch .filter .col-sm-4:last-child .filter-item-button {
  margin-left: 8px;
}
#dwsearch .filter .col-sm-4:last-child .filter-item-button button {
  padding: 6px 30px 6px 12px;
  width: 100%;
  height: 36px;
  line-height: 1;
}
#dwsearch .filter .col-sm-4:first-child .filter-item {
  margin-left: 0px;
}
#dwsearch
  .filter
  .col-sm-4:first-child
  .filter-item:first-child
  option:first-child {
  background-color: #000 !important;
}
#dwsearch
  .filter
  .col-sm-4:first-child
  .filter-item:first-child
  option:first-child {
  background-color: #ffdd00 !important;
}
#dwsearch .filter .filter-item.last {
  margin-right: 0;
}
#dwsearch .filter-item-group {
  background-color: transparent;
  table-layout: fixed;
  width: 100%;
}
#dwsearch .filter-item-left {
  width: 60%;
}
#dwsearch .filter-item-right {
  padding-left: 16px;
  width: 40%;
}
#dwsearch.indicator {
  text-align: right;
  margin-bottom: 20px;
}
#dwsearch .indicator .indicator-list {
  margin-right: -5px;
  margin-bottom: 10px;
  margin-left: -5px;
}
#dwsearch .indicator .indicator-list li {
  margin-right: 20px;
  width: auto;
  display: inline-block;
  padding: 0px 5px;
}
#dwsearch .indicator .indicator-list li .indicator-item{
  line-height: 1;
  font-size: 85%;
}
#dwsearch .indicator .indicator-list li:last-child {
  margin-right: 0px;
}
#dwsearch .indicator-item-icon {
  display: inline-block;
  width: 10px;
  height: 14px;
  margin-right: 6px;
}
#dwsearch .indicator .indicator-list .indicator-item-icon {
  margin-right: 9.5px;
}
#dwsearch .indicator .indicator-list .ttip {
  /* font-size: 20px; */
  font-family: 'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif;
}
body.eng #dwsearch .indicator .indicator-list .ttip {
  /* font-size: 13.6px; */
  font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
}
#dwsearch .icon-hotdw {
  background: url(../../../assets/images/bullet/fasttrack.png) 0 0 no-repeat;
  background-size: contain;
  width: 22px;
  height: 14px;
  margin-bottom: -1px;
}
#dwsearch .icon-caution {
  background: url(../../../assets/images/bullet/caution.png) 0 0 no-repeat;
  width: 14px;
  height: 14px;
  margin-bottom: -2px;
  background-size: contain;
}
#dwsearch .icon-liquidity {
  /* background: url("../../../assets/images/bullet/liquidity.fw.png") 0 0 no-repeat;*/
  width: 13px;
  height: 13px;
  margin-bottom: -2px;
}
#dwsearch .icon-expiry {
  background: url(../../../assets/images/bullet/expiry.fw.png) 0 0 no-repeat;
  width: 12px;
  height: 13px;
  margin-bottom: -2px;
}
#dwsearch .icon-soldout {
  background: url(../../../assets/images/bullet/soldout.fw.png) 0 0 no-repeat;
  width: 12px;
  height: 12px;
  margin-bottom: -2px;
}
#dwsearch .icon-time {
  background: url(../../../assets/images/bullet/time.png) 0 0 no-repeat;
  width: 14px;
  height: 14px;
  margin-bottom: -2px;
  background-size: contain;
}
#dwsearch .icon-effGearing {
  /* background: url("../../../assets/images/bullet/effGearing.png") 0 0 no-repeat; */
  width: 13px;
  height: 16px;
  margin-bottom: -2px;
  background-size: contain;
}
#dwsearch .icon-new {
  /* background: url("../../../assets/images/bullet/new.png") 0 0 no-repeat; */
  width: 10px;
  height: 16px;
  margin-bottom: -2px;
  background-size: contain;
}
#dwsearch .icon-responsive {
  background: url(../../../assets/images/bullet/responsive.png) 0 0 no-repeat;
  width: 10px;
  height: 14px;
  margin-bottom: -2px;
  background-size: contain;
}
#dwsearch .icon-timeDecay {
  /* background: url("../../../assets/images/bullet/timeDecay.png") 0 0 no-repeat; */
  width: 13px;
  height: 16px;
  margin-bottom: -2px;
  background-size: contain;
}
#dwsearch .result {
  margin-top: -13px;
}
#dwsearch #stickerheader .result {
  margin-top: 0px;
}
#dwsearch .result > table {
  font-size: 14px;
  width: 100%;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fecc0a;
  margin-bottom: 20px;
  border-collapse: collapse;
}
.ttip-bg-color {
  background-color: #fecc0a !important;
}
.bg-tooltip {
  border: 0;
  /* background-color: #FECC0A !important; */
  max-width: 250px;
  min-width: 50px;
  direction: ltr;
  box-shadow: none;
}
.tooltip-content {
  padding: 5px 9px;
  text-align: left;
  word-wrap: break-word;
  font-size: 14px;
  line-height: normal;
}
#dwsearch .result > table th {
  text-align: center;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding-top: 6px;
  padding-bottom: 6px;
  font-weight: normal;
  line-height: 1;
}
#dwsearch table#resulttable-lg .result-sort th {
  padding-top: 4px;
  padding-bottom: 5px;
}
body.eng #dwsearch table#resulttable-lg .result-sort th {
  padding-top: 6px;
  padding-bottom: 7.15px;
}
#dwsearch .result > table .result-hdr1 th {
  font-family: "DBHeaventMedium";
  padding: 6px 0px;
  line-height: 1;
  font-size: 18px;
}
body.eng #dwsearch .result > table .result-hdr1 th{
  font-family: "MCQGlobal_A_Md";
  font-size: 12px;
  line-height: 1.42857143;
}
#dwsearch .result > table .result-hdr2 th {
  overflow-wrap: break-word;
  font-size: 18px;
}
body.eng #dwsearch .result > table .result-hdr2 th {
  overflow-wrap: break-word;
  font-size: 12px;
  line-height: 1.42857143;
}
#dwsearch .result > table tbody tr:nth-child(odd) {
  background-color: #f5f6f6;
}
#dwsearch .result > table td {
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 6px 0px;
  line-height: 1.42857143;
}
#dwsearch .result > table td.bordered,
#dwsearch .result > table th.bordered {
  border-right: 1px solid #ccc;
  /* position: static; */
  
}
#dwsearch .result-sort-up,
#dwsearch .result-sort-down {
  cursor: pointer;
}
#dwsearch .result-sort-up {
  color: #008022;
  position: relative;
  top: 8px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin-left: 5px;
}
body.eng #dwsearch .result-sort-up{
  top: 5px;
}
#dwsearch #resulttable > ol{
  padding-left: 10px;
  font-size: 18px;
}
body.eng #dwsearch #resulttable > ol{
  font-size: 12px;
}
#dwsearch .result-sort-down {
  color: #ff0000;
  position: relative;
  top: -3px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
#dwsearch .result-disclaimer ul {
  margin-left: 25px;
}
#dwsearch .result-disclaimer ol,
.showmore ol,
#dwsearch .result-disclaimer ul,
.showmore ul {
  padding-left: 10px;
  font-size: 18px;
}
body.eng #dwsearch .result-disclaimer ol,
body.eng .showmore ol,
body.eng #dwsearch .result-disclaimer ul,
body.eng .showmore ul {
  font-size: 12px;
}
#dwsearch .result hr,
#dwsearch .showmore hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid #dfdbcf;
}
#dwsearch .result a,
#dwsearch .result:focus,
#dwsearch .result a:hover,
#dwsearch .result a:visited {
  color: #0675c2;
  text-decoration: none;
}
#dwsearch .rowtmpl-lg.hover td,
#dwsearch .rowtmpl-xs.hover td {
  background-color: #cee5f5;
}

#dwsearch .indicatortbl table td {
  height: 20px;
}
#dwsearch .indicatortbl table.visible-xs {
  width: 100%;
  font-size: 18px;
}
body.eng #dwsearch .indicatortbl table.visible-xs{
  font-size: 12px;
}
#dwsearch .indicatortbl table.sm-view .indicator-item{
  font-size: 14px;
}
#dwsearch .indicatortbl table.sm-view .indicator-item-icon {
  margin-right: 3px;
}
#dwsearch .indicatortbl table.sm-view {
  min-width: 460px;
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
  justify-content: center;
}

#dwsearch .showmore {
  margin-top: 20px;
}

#dwsearch #resulttable-xs {
  margin-bottom: 10px;
  margin-top: 10px;
}
/*#dwsearch #resulttable-xs thead tr:last-child{
    border-bottom: 1px solid #FECC0A;
}*/
#dwsearch #resulttable-xs thead tr:last-child th {
  border-bottom: 1px solid #fecc0a;
}
#dwsearch #resulttable-xs th {
  padding-left: 3px;
  padding-right: 3px;
  /*    border-left:inherit;
    border-bottom:inherit;*/
}
#dwsearch #resulttable-xs .result-sort th {
  padding-top: 0;
  padding-bottom: 0;
  height: 15px;
}
#dwsearch #resulttable-xs th.active {
  color: #0675c2;
}
#dwsearch #resulttable-xs .active .glyphicon {
  color: #fecc0a;
}
#dwsearch .sorttip {
  font-size: 17px;
  margin: 16px 0px 10px;
}
body.eng #dwsearch .sorttip {
  font-size: 11px;
}
#dwsearch .ttip {
  color: inherit;
}
#dwsearch #search-disclaimer table {
  border-bottom: 1px solid #fecc0a;
  margin-bottom: 15px;
}

#dwsearch #defination {
  margin-top: 20px;
  margin-bottom: 20px; 
}
#dwsearch #defination .panel-heading {
  background-color: #666666;
  padding: 4px 10px;
}
#dwsearch #defination .panel-title {
  font-size: 14px;
}
#dwsearch #defination .glyphicon {
  color: white;
}
#dwsearch #defination #collapse_dwcompare3 {
  font-size: 16px;
  padding: 10px;
}
#dwsearch #defination #collapse_dwcompare3.hide{
  display: none;
}
body.eng #dwsearch #defination #collapse_dwcompare3 ol{
  font-size: 12px;
}
#dwsearch #defination .table {
  border-bottom: 1px solid #fecc0a;
  margin-bottom: 15px;
}
#dwsearch #defination .table-striped > tbody > tr:nth-of-type(odd){
  --bs-table-striped-bg: none;
}
#dwsearch #defination .table td {
  padding: 8px;
  padding-left: 0;
  border: none;
  font-size: 18px;
  vertical-align: top;
  line-height: 1;
}
body.eng #dwsearch #defination .table td{
  font-size: 12px;
  line-height: 1.42857143;
}
#dwsearch #defination ul{
  padding-left: 40px;
  margin-bottom: 10px;
}
#dwsearch #defination .table ~ p{
  margin-bottom: 10px; 
}
#dwsearch #defination hr{
  margin: 20px 0px;
}
#dwsearch .section > .page-header ~ p.section-txt{
  margin-bottom: 10px;
}
#dwsearch #defination .table tr td:first-child {
  white-space: nowrap;
}
#dwsearch .mac-dropdown-list {
  width: 100%;
  display: block;
  overflow: auto;
  border-radius: 5px;
  border: solid 1px #ddd;
  padding: 10px;
  box-shadow: 0px 7px 10px rgb(0 0 0 / 10%);
  background-color: #fff;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 100%;
}
#dwsearch .mac-dropdown-list .input-group:not(:last-child) {
  margin-bottom: 10px;
}
#dwsearch .mac-dropdown-list .input-group label {
  font-size: 14px;
}
#dwsearch .mac-dropdown-list .input-group input {
  margin-right: 8px;
  transform: translateY(2px);
}
#dwsearch #stickerheader {
  position: fixed;
  left: 0;
  right: 0;
  top: 39px;
  height: 165px;
  display: none;
  overflow: hidden;
}
body.eng #dwsearch #stickerheader{
  height: 140px;
}
#dwsearch .rmsc .dropdown-container {
  border: 1px solid #aeaeae;
  border-radius: 0px;
}
#dwsearch .rmsc .dropdown-container:focus-within {
  box-shadow: none;
}
#dwsearch .rmsc .dropdown-heading {
  line-height: 34px;
  height: auto;
  padding: 0px 3px;
  cursor: pointer;
}
#dwsearch .filter .filter-item .rmsc.multi-select option {
  font-size: 30px;
  color: #008022;
}
#dwsearch .rmsc .dropdown-heading svg {
  color: #000;
}
#dwsearch
  .filter
  .filter-item
  .css-1pahdxg-control
  .css-1gtu0rj-indicatorContainer,
#dwsearch
  .filter
  .filter-item
  .css-1s2u09g-control
  .css-tlfecz-indicatorContainer {
  color: #333;
  cursor: pointer;
  padding: 7px 5px;
}
#dwsearch .rmsc .dropdown-heading .dropdown-heading-value {
  text-align: center;
}
#dwsearch #stickerheader .container-fluid {
  padding-left: 15px;
  padding-right: 15px;
}
#dwsearch #stickerheader .section {
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

#dwsearch tr.text-bold td,
#dwsearch tr.text-bold a {
  font-family: "DBHeaventMedium";
  font-size: 18px;
  line-height: 1;
}
#dwsearch #resulttable-lg{
  margin-top: 13px;
}
body.eng #dwsearch #resulttable-lg tr.text-bold td,
body.eng #dwsearch #resulttable-lg tr.text-bold a {
  font-family: "MCQGlobal_A_Md";
  font-size: 12px;
  line-height: 1.42857143;
}
#dwsearch #resulttable-lg tr td{
  font-size: 18px;
  line-height: 1;
}
body.eng #dwsearch #resulttable-lg tr td{
  font-size: 12px;
  font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
  line-height: 1.42857143;
}
#dwsearch .result .width133{
  min-width: 133px;
}
#dwsearch .result .width78{
  min-width: 78px;
}
#dwsearch .result .width72{
  min-width: 72px;
}
#dwsearch .result .width71{
  min-width: 71px;
}
#dwsearch .result .width67{
  min-width: 67px;
}
#dwsearch .result .width56{
  min-width: 56px;
}
#dwsearch .result .width55{
  min-width: 55px;
}
#dwsearch .result .width51{
  min-width: 51px;
}
#dwsearch .result .col_sticky {
  position: sticky;
  left: 0;
}
#dwsearch .result .result-sort .col_sticky.color_white{
  background-color: #fff;
  /* z-index: 1; */
}
#dwsearch .result tbody tr:nth-child(odd) .col-dwSymbol {
  background-color: #F5F6F6;
  position: sticky;
  left: 0;
}
#dwsearch .result tbody tr:nth-child(even) .col-dwSymbol {
  background-color: #FFFFFF;
  position: sticky;
  left: 0;
}
/*******************************************************************************
 underlying section combobox
*******************************************************************************/
.combobox {
  position: relative;
}
.combobox-toggle {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  width: 36px;
  padding: 0;
  cursor: pointer;
}
.combobox-toggle,
.combobox-toggle:focus,
.combobox-toggle:hover,
.combobox-toggle:visited {
  color: #000;
}
.combobox-input {
  margin: 0;
  padding: 5px 10px;
  padding-right: 25px;
  width: 100%;
  text-align: center;
}
/*  .combobox-toggle-icon.glyphicon{
      margin-top: 10px;
      margin-right: 12px;
  }*/
.ui-widget {
  font-family: "HelveticaNeueLight", "HelveticaNeue", "Roboto", Arial,
    sans-serif;
  font-size: 14px;
}
/* .ui-menu .ui-menu-item{
      text-align: center;
  } */
.ui-menu .ui-menu-item a.ui-state-hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.ui-menu .ui-menu-item a:visited,
.ui-menu .ui-menu-item a:focus,
.ui-menu .ui-menu-item a:hover {
  color: #262626;
}
.ui-autocomplete.ui-menu {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}
/*******************************************************************************
 How to get started
*******************************************************************************/
.page-header {
  display: flex;
  justify-content: space-between;
}
.page-header input.btn.btn-primary {
  min-width: auto;
  line-height: 1.42857143;
}
.tradeDropDown {
  position: absolute;
  border: 1px solid #000000;
  text-align: center;
  z-index: 9;
  background: #ffffff;
  width: 350px;
  padding: 10px 0;
  margin-left: -155px;
}
.tradeDropDown a {
  margin-bottom: 5px;
}
.dropdown-or {
  display: flex;
}
.dropdown-or hr {
  width: 40%;
  margin-top: 10px;
  margin-bottom: 0px;
}
.dropdown-or p {
  margin: 0 0 5px;
}
.selectedTrade,
.selectedTrade:hover,
.selectedTrade:focus {
  background-color: #999999 !important;
  color: #ffffff !important;
  border-color: #888888 !important;
  box-shadow: none;
}
.tradeDropDown .btn-primary:hover,
.tradeDropDown .btn-primary:focus {
  background-color: #000000 !important;
  color: #ffffff !important;
  border-color: #888888 !important;
}
.broker_link {
  width: 45%;
  margin-bottom: 5px;
}
#dwsearch #tradeEl5 a.btn.tradeEl{
  min-width: auto;
  line-height: 1.42857143;
}
#dwsearch #tradeEl5 hr.tradeEl{
  background-color: #eee;
  opacity: 1;
}
#dwsearch #tradeEl5 > p.tradeEl{
  margin-bottom: 10px;
}
#dwsearch .result-disclaimer p.small{
  margin-bottom: 10px;
}
#dwsearch .result-disclaimer ul{
  margin-bottom: 10px;
}
#dwsearch .result-disclaimer hr{
  opacity: 1;
}
#tradeEl5 .tradeEl.row {
  display: flex;
  justify-content: space-between;
  padding: 0px 6px;
  margin: 0px;
}
#tradeEl5 input.broker_link {
  min-width: auto !important;
  max-width: calc(50% - 2px);
  width: 100%;
}
#tradeEl5 input.broker_link:focus,
.btn.btn-primary:focus,
button:focus,
a:focus {
  box-shadow: none !important;
}
#dwsearch ul.indicator-list .__react_component_tooltip,
#dwsearch #resulttable-lg .__react_component_tooltip {
  background-color: #fecc0a;
  padding: 5px 10px;
  opacity: 1;
}
/* #dwsearch ul.indicator-list .__react_component_tooltip:before{
    border-color: #fecc0a;
} */
#dwsearch ul.indicator-list .__react_component_tooltip p.tooltip-content,
#dwsearch #resulttable-lg .__react_component_tooltip p.tooltip-content {
  margin: 0px;
  padding: 0px;
  font-family: 'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif;
  font-size: 21px;
  line-height: 1;
}
body.eng #dwsearch ul.indicator-list .__react_component_tooltip p.tooltip-content,
body.eng #dwsearch #resulttable-lg .__react_component_tooltip p.tooltip-content {
  margin: 0px;
  padding: 0px;
  font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
  font-size: 14px;
  line-height: normal;
}



.filter-item #react-select-3-option-1,.filter-item #react-select-3-option-2{
  background-color: #fecc0a;
}
.filter-item #react-select-3-option-1:hover,.filter-item #react-select-3-option-2:hover{
  background-color: #007fff;
}

/*******************************************
popupbox
*********************************************/
.overlay {
  position: fixed;
  overflow-y: scroll;
  display: table;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
}
.overlay-inner {
  margin: 15vh auto;
  width: 80%;
  height: auto;
  background: #ffffff;
}
#ppboxHTML .overlay-inner {
  margin: 25vh auto;
  width: 50%;
  height: auto;
  background: #000000;
  color: #ffffff;
}
.popupbox-content {
  padding: 30px;
}
.btn-close {
  top: 15px;
  right: 15px;
  width: 16px;
  height: 16px;
}
/* .noscroll {
  position: relative;
  overflow: hidden;
} */

#ppboxHTML .page-header {
  margin-top: 0px;
}

#ppboxHTML p,
#ppboxHTML h3 {
  color: #ffffff;
}

#ppboxHTML input {
  width: 30%;
  margin: 0 10px;
  color: #333333;
}

#dwsearch .indicatortbl table.visible-sm {
  min-width: 460px;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  font-size: 24px;
}
body.eng #dwsearch .indicatortbl table.visible-sm{
  font-size: 16px;
}
#dwsearch .indicatortbl table.visible-sm .indicator-item-icon {
  margin-right: 3px;
}
#dwsearch .filter-item .warrant-search_dropdown span{
  display: none;
}
#dwsearch .filter-item .warrant-search_dropdown svg{
  color: #000;
}
#dwsearch .filter-item .warrant-search_dropdown .css-13cymwt-control,
#dwsearch .filter-item .warrant-search_dropdown .css-t3ipsp-control{
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 0px;
  border-color: #AEAEAE;
  min-height: 36px;
}
#dwsearch .filter-item .warrant-search_dropdown .css-13cymwt-control > div:first-child,
#dwsearch .filter-item .warrant-search_dropdown .css-t3ipsp-control > div:first-child{
  width: 100%;
  padding: 0px 29px 0px 10px;
  display: flex;
  justify-content: center;
  font-size: 24px;
  min-height: 30px;
}
body.eng #dwsearch .filter-item .warrant-search_dropdown .css-13cymwt-control > div:first-child, 
body.eng #dwsearch .filter-item .warrant-search_dropdown .css-t3ipsp-control > div:first-child{
  font-size: 16px;
}
#dwsearch .filter-item .warrant-search_dropdown .css-13cymwt-control > div:last-child,
#dwsearch .filter-item .warrant-search_dropdown .css-t3ipsp-control > div:last-child{
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
#dwsearch .filter-item .warrant-search_dropdown .css-13cymwt-control > div:first-child > div:first-child,
#dwsearch .filter-item .warrant-search_dropdown .css-t3ipsp-control > div:first-child > div:first-child{
  min-width: 100%;
  text-align: center;
}
#dwsearch .filter-item .warrant-search_dropdown .css-13cymwt-control > div:first-child > div:last-child,
#dwsearch .filter-item .warrant-search_dropdown .css-13cymwt-control > div:first-child > input,
#dwsearch .filter-item .warrant-search_dropdown .css-t3ipsp-control > div:first-child > div:last-child,
#dwsearch .filter-item .warrant-search_dropdown .css-t3ipsp-control > div:first-child > input{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0px !important;
  padding: 0px !important;
}
#dwsearch .filter-item .warrant-search_dropdown .css-13cymwt-control > div:first-child > div:last-child input,
#dwsearch .filter-item .warrant-search_dropdown .css-t3ipsp-control > div:first-child > div:last-child input{
  width: 100% !important;
  text-align: center;
}
#dwsearch .filter-item .warrant-search_dropdown .css-13cymwt-control > div:first-child > div:last-child,
#dwsearch .filter-item .warrant-search_dropdown .css-t3ipsp-control > div:first-child > div:last-child{
  display: flex;
  justify-content: center;
}
#dwsearch .filter-item .warrant-search_dropdown .css-13cymwt-control > div:first-child > div:last-child::after,
#dwsearch .filter-item .warrant-search_dropdown .css-t3ipsp-control > div:first-child > div:last-child::after{
  display: none;
}



/*******************************************************************************
 Responsive @media
*******************************************************************************/

@media screen and (min-width: 992px) and (max-width: 1199px) {
  #dwsearch .container-fluid > .section{
    padding: 40px;
  }
}

@media screen and (max-width: 991px) {
  #dwsearch #resulttable table th,
  #dwsearch #resulttable table td,
  #dwsearch #stickerheader table th,
  #dwsearch #stickerheader table td {
    font-size: 18px;
  }
  body.eng #dwsearch #resulttable table th,
  body.eng #dwsearch #resulttable table td,
  body.eng #dwsearch #stickerheader table th,
  body.eng #dwsearch #stickerheader table td {
    font-size: 12px;
  }
  /* #dwsearch #resulttable table td.bordered{
    border: none; 
  } */
  #ppboxHTML .overlay-inner {
    margin: 15vh auto;
    width: 90%;
  }
  .overlay-inner {
    width: 90%;
  }
  .overlay-inner .popupbox-content {
    padding-left: 30px;
    padding-right: 30px;
  }
  .tradeDropDown {
    right: 20px;
    width: 320px;
    margin-left: 0;
  }
  .result-hdr1.sm-view,
  .result-hdr2.sm-view{
    display: table-row !important;
  }
  /* #dwsearch .result > table .result-hdr2 th,
  #dwsearch .result > table .result-hdr1 th{
    padding: 6px 3px;
  } */
  #dwsearch .result > table#resulttable-lg.table-fixed{
    table-layout: auto;
    margin-bottom: 10px;
    width: 100%;
    display: table;
    margin-top: 10px;
  }
  /* #dwsearch .result > table#resulttable-lg .result-hdr1 th{
    font-family: "HelveticaNeueLight";
  } */
  #dwsearch #defination .panel-heading{
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 2px 10px;
  }
  #dwsearch #defination .panel-heading button {
    border: none;
    background-color: transparent;
    width: 100%;
    text-align: center;
    padding: 0px;
  }
  #dwsearch #defination .panel-title {
    font-size: 21px;
    position: relative;
  }
  body.eng #dwsearch #defination .panel-title{
    font-size: 14px;
  }
  body.eng #dwsearch #defination .panel-heading button img {
    width: 16px;
    top: -3px;
    position: relative;
  }
  /* #dwsearch #defination .panel-heading button img {
    width: 14px;
    position: absolute;
    top: 3px;
    right: 0px;
  } */
  #dwsearch .indicatortbl table.sm-view .indicator-item-icon {
    margin-right: 6px;
  }
  #dwsearch .indicatortbl table.sm-view .indicator-item {
    font-size: 16px;
    line-height: 1.42857143;
  }

  #dwsearch #resulttable.result{
    overflow-x: auto;
  }
  /*       */
  #dwsearch #resulttable #resulttable-lg th.sticky,
  #dwsearch #resulttable #resulttable-lg td.col-dwSymbol,
  #dwsearch #stickerheader table th.sticky {
    position: sticky;
    left: 0;
    background-color: #fff;
  }
  #dwsearch #resulttable #resulttable-lg tbody tr:nth-child(odd) td.col-dwSymbol{
    background-color: #f5f6f6;
  }
  #dwsearch #resulttable #resulttable-lg tbody tr:nth-child(even) td.col-dwSymbol{
    background-color: #fff;
  }
  /* #dwsearch .result-sort-down,
  #dwsearch .result-sort-up{
    color: #000;
  } */
  #dwsearch #resulttable #resulttable-lg tbody tr:hover td{
    background-color: #CEE5F5;
  }
  /* #dwsearch table#resulttable-lg .result-sort th {
    padding-top: 0px;
    padding-bottom: 2px;
  } */
  #dwsearch #resulttable table th span.ttip,
  #dwsearch .result > table .result-hdr2 th span.ttip{
    pointer-events: none;
  }
  /* #dwsearch #stickerheader{
    height: 127px;
  } */
  /* body.eng #dwsearch #stickerheader {
    height: 96px;
  } */
  #dwsearch #stickerheader .result{
    overflow-x: auto;
  }
  #dwsearch .filter-item-right{
    padding-left: 10px;
  }
}
@media screen and (max-width: 768px) {
  body.eng #dwsearch .page-header h1 {
    font-size: 36px;
  }
}
@media screen and (max-width: 767px) {
  #dwsearch .filter {
    margin-top: 10px;
    margin-bottom: 0;
  }
  #dwsearch .result {
    margin-top: 0;
  }
  #dwsearch .result > table {
    font-size: 11px;
    border-top: 0;
    border-left: 0;
  }
  #dwsearch .result #resulttable-lg thead tr:last-child{
    border-bottom: 1px solid #FECC0A;
  }
  /* #dwsearch .result > table th {
    border: 0;
  } */
  #dwsearch .result th.col-symbol {
    width: 85px;
  }
  #dwsearch .filter .filter-item {
    margin-left: 0;
    margin-right: 0;
  }
  #dwsearch #search-disclaimer table {
    font-size: 11px;
  }
  #dwsearch p.small {
    font-size: 11px;
  }
  #dwsearch #defination #collapse_dwcompare3 {
    font-size: 17px;
  }
  body.eng #dwsearch #resulttable-lg tr.text-bold td, 
  body.eng #dwsearch #resulttable-lg tr.text-bold a{
    font-size: 11px;
  }
  #dwsearch #resulttable table .result-hdr2 th,
  #dwsearch #resulttable table td {
    font-size: 17px;
    width: inherit !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  #dwsearch #resulttable table .result-hdr2 th{
    padding-left: 3px;
    padding-right: 3px;
  }
  body.eng #dwsearch #resulttable table .result-hdr2 th,
  body.eng #dwsearch #resulttable table td{
    font-size: 11px;
  }
  #dwsearch .result > table#resulttable-lg.table-fixed{
    display: table;
  }
  #dwsearch tr.text-bold td, 
  #dwsearch tr.text-bold a{
    font-size: 17px;
  }
  /* #dwsearch table#resulttable-lg .result-sort th svg{
    height: 16px;
    width: 10px;
  } */
  #dwsearch .result-sort-up{
    margin-left: 2px;
  }
  #dwsearch .filter .col-sm-4:last-child .filter-item-button{
    margin-left: 0;
  }
  .result-hdr1.sm-view{
    display: none !important;
  }
  /* #dwsearch #resulttable table tr.result-hdr2.sm-view th,
  #dwsearch .result > table#resulttable-lg .result-hdr2 th{
    background-color: transparent !important;
    padding-bottom: 0px;
  } */
  /* #dwsearch #resulttable #resulttable-lg th {
    background-color: #fff !important;
  } */
  #dwsearch .result > table#resulttable-lg .result-sort th{
    padding-top: 3px;
    padding-bottom: 4px;
  }
  /* #dwsearch .result > table#resulttable-lg td.bordered{
    border: none; 
  }  */
  #dwsearch .indicatortbl table.sm-view{
    display: none !important;
  }
  #dwsearch #defination .table td{
    font-size: 17px;
  }
  #dwsearch #defination .panel-heading{
    padding: 3.4px 10px;
  }
  #dwsearch #defination p.small {
    font-size: 17px;
  }
  body.eng #dwsearch #defination p.small,
  body.eng #dwsearch #defination .table td {
    font-size: 11px;
  }
  #dwsearch .indicatortbl table.xs-view{
    display: table !important;
  }

  #dwsearch .filter .filter-item .css-1pahdxg-control .css-6j8wv5-Input, 
  #dwsearch .filter .filter-item .css-1s2u09g-control .css-ackcql, 
  #dwsearch .filter .filter-item .css-vwja0k,
  #dwsearch .filter .filter-item .css-b62m3t-container .css-qc6sy-singleValue{
    font-size: 21px;
  }
  #dwsearch .filter .col-sm-4:nth-child(1) .filter-item .css-1pahdxg-control .css-1gtu0rj-indicatorContainer,
  #dwsearch .filter .col-sm-4:nth-child(1) .filter-item .css-1s2u09g-control .css-tlfecz-indicatorContainer{
    padding: 5px;
  }
  body.eng #dwsearch .page-header h1{
    font-size: 18px;
  }
  #dwsearch .sorttip{
    position: sticky;
    left: 0;
  } 
  #dwsearch #stickerheader #resulttable-lg tr th{
    font-size: 17px;
  }
  body.eng #dwsearch #stickerheader #resulttable-lg tr th{
    font-size: 11px;
    /* background-color: #fff !important; */
  }
  #dwsearch #stickerheader{
    top: 52px;
    height: 145px;
  }
  #dwsearch #stickerheader .sorttip{
    margin-top: 0px;
  }
  body.eng #dwsearch #stickerheader {
    height: 117px;
  }
  body.eng #dwsearch .filter .filter-item .css-1s2u09g-control .css-ackcql input, 
  body.eng #dwsearch .filter .filter-item .css-1s2u09g-control input,
  body.eng #dwsearch .filter .filter-item .css-1pahdxg-control input{
    font-size: 16px !important;
  }
  #dwsearch .indicator .indicator-list{
    margin-top: 16px;
  }
  /* #dwsearch .indicator .indicator-list .ttip{
    font-size: 17.85px;
  } */
  #dwsearch #resulttable-lg{
    margin-top: 0px;
  }
  #dwsearch #resulttable table th, 
  #dwsearch #resulttable table td, 
  #dwsearch #stickerheader table th, 
  #dwsearch #stickerheader table td {
    font-size: 17px;
  }
}
@media screen and (max-width: 767px) and (orientation: portrait){
  #dwsearch .result > table#resulttable-lg .result-sort th.hide-xs-portrait {
    display: table-cell !important;
  }
}

@media screen and (max-width: 380px) {
  #dwsearch .result > table#resulttable-lg .result-hdr2 th.hide-xs-portrait,
  #dwsearch .result > table#resulttable-lg .result-sort th.hide-xs-portrait,
  #dwsearch #resulttable table tbody td.hide-xs-portrait{
    display: none !important;
  }
}

@media screen and (max-width: 360px) {
  #dwsearch #resulttable-xs th,
  #dwsearch #resulttable-xs td {
    font-size: 9px;
  }
  #dwsearch #resulttable table .result-hdr2 th,
  #dwsearch #resulttable table td {
    font-size: 15px;
  }
  #dwsearch tr.text-bold td, #dwsearch tr.text-bold a {
    font-size: 15px;
  }
}

.css-14el2xx-placeholder {
  color: hsl(0, 0%, 50%);
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  text-align: center;
}

.up {
  color: #6cc263 !important;
}

.down {
  color: #c61c2d !important;
}
.css-1s2u09g-control:hover {
  border-color: hsl(0, 0%, 70%) !important;
}

#dwsearch .loader-div {
  justify-content: center;
  position: relative;
  align-items: center;
  text-align: center;
}
.span-issuer {
  position: absolute;
  top: 8px;
  right: 165px;
}
/* .dropdown-heading-value span{
    display: none !important;
} */

/* .dropdown-heading-value span{
    display:none;
   }
   
   .dropdown-heading-value:after{
    content: "All Issuer";   
   } */

.dropdown-heading-dropdown-arrow {
  cursor: pointer !important;
}

.dropdown-content ul li .select-item {
  background-color: #ffffff !important;
}
.dropdown-content ul li {
  background-color: #ffffff !important;
}
.dropdown-content ul li :hover {
  background-color: #ffffff !important;
}

.dropdown-content ul :first-child {
  background-color: #ffffff !important;
}