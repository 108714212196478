#individualtip #tip-content{
    margin-bottom:30px;
}
body.eng #individualtip #tip-content table thead tr th{
    font-family: "MCQGlobal_A_Md";
    line-height: normal;
}
body.eng #individualtip #tip-content table tbody tr td,
body.eng #individualtip #tip-content table tbody tr th{
    line-height: 1.42857143;
}
#individualtip .pnbar{
    position: relative;
    height: 22px;
}
#individualtip .pnbar-prev{ 
    position: absolute;
    right: 50%;
    margin-right: 30px;   
}
#individualtip .pnbar-next{
    position: absolute;
    left:50%;
    margin-left: 30px;
}
#individualtip .pnbar-vl{
    position: absolute;
    right: 50%;
    top:2px;
    bottom:0;
    width:0;
    border-left: 1px solid #CCC;
}
#individualtip .pnbar-prev a,
#individualtip .pnbar-prev a:visited,
#individualtip .pnbar-prev a:hover,
#individualtip .pnbar-next a,
#individualtip .pnbar-next a:visited,
#individualtip .pnbar-next a:hover{
    font-family: "DBHeaventMedium";
    font-size: 21px;
    color: #0675C2;
}
#individualtip .pnbar-prev a.disabled,
#individualtip .pnbar-prev a.disabled:visited,
#individualtip .pnbar-prev a.disabled:hover,
#individualtip .pnbar-next a.disabled,
#individualtip .pnbar-next a.disabled:visited,
#individualtip .pnbar-next a.disabled:hover{
    color: #CCC;
}
#individualtip .hltbl th,
#individualtip .hltbl td{
    text-align: center;
}
#individualtip .topContent, .fullContent{    
    padding-top:  10px;
}
#individualtip .topContent{
    text-align: center;
}
#individualtip .leftImage{        
    display: inline-block;
    text-align: center;
}
#individualtip .leftImage img{    
    max-width:  100%;
    width: auto;
}
#individualtip .shortContent{            
    display: inline-block;
    padding-left: 10px;
    vertical-align:  top;
    max-width: 70%;
}
#individualtip .section-btn-wapper{
    text-align: right;
}
#individualtip .pnbar-next a.disabled,
#individualtip .pnbar-prev a.disabled {
    pointer-events: none;
}
/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (max-width: 767px){
    #individualtip .page-header{
        display: unset;
    }
    #individualtip .leftImage{        
        display: block;
        max-width: 100%;
    }
    #individualtip .shortContent{            
        display: block;
        padding-left: 0px;
        padding-top: 10px;
        vertical-align:  top;        
        max-width: 100%;
    }
    #individualtip #tip-content table{
        font-size: 21px;
    }
    body.eng #individualtip #tip-content table{
        font-size: 14px;
    }
}

@media screen and (max-width: 450px){
    #individualtip table.hltbl{
        font-size: 18px;
    }
    #individualtip table.hltbl th,
    #individualtip table.hltbl td{
        padding-left: 2px;
        padding-right: 2px;
    }
}

@media screen and (max-width: 350px){
    #individualtip table.hltbl{
        font-size: 17px;
    }
    #individualtip table.hltbl th,
    #individualtip table.hltbl td{
        padding-left: 0px;
        padding-right: 0px;
    }
}
