#faq .question-header{
    text-align: center;
    height: 35px;
    line-height: 35px;
    background-color: #666;
}
#faq .question-list{
    border: 1px solid #EAEAEA;
}
#faq .question-list a{
    background-color: #F5F6F7;
    margin: 10px;
    padding: 7px 10px;
    border-radius: 5px;
    /* color: #333 !important; */
    display: block;
}
#faq .carousel-root.ad-section{
    margin-top: 20px;
    width: 100%;
    padding: 0px;
    height: auto;
}
#faq .slider-wrapper {
    background: #000000;
}
#faq .slider-wrapper li.slide iframe {
    width: 100%;
    margin: 0px;
    min-height: 190px;
}
#faq .slider-wrapper li.slide a{
    height: 100%;
}
#faq .carousel .carousel-status{
    display: none;
}
#faq .carousel .control-dots .dot{
    width: 15px;
    height: 15px;
    box-shadow: none;
    background-color: #000;
    border: solid 1px #fff;
    opacity: 1;
    margin: 0px 5px;
}
#faq .carousel .control-dots .dot.selected{
    background-color: #FECC0A;
    border-color: #FECC0A;
}
#faq .answer-area{
    padding-bottom: 30px;
}
#faq .answer-area p{
    margin-bottom: 10px;
    line-height: normal;
}
#faq .answer-area ul{
    padding-left: 40px;
}
#faq .answer-area ul li{
    line-height: 1.42;
}
#faq .answer-area thead th{
    text-align: center;
}

#faq .answer-area .content-question{
    font-family:"HelveticaNeueMedium";
    font-size: 17px;
    text-align: center;
    border-bottom: 1px solid #FECC0A;
    margin-top: 40px;
    margin-bottom: 10px;
    padding-bottom: 5px;
}
#faq .answer-area table{
    width: 80%;
    margin: auto;
}
#faq .answer-area table td{
    text-align: center;
}

#faq .question-list img{
    margin-top: -3px;
    cursor: pointer;
    width: 22px;
    margin-left: 5px;
}
#faq .answer-area img{
    width: 22px;
    float: right;
    -ms-transform: rotate(270deg); /* IE 9 */
    -webkit-transform: rotate(270deg); /* Chrome, Safari, Opera */
    transform: rotate(270deg);
    cursor: pointer;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    #faq .container-fluid > .row > .col-md-8 > .section{
        padding: 40px;
    }
    #faq .container-fluid > .row > .col-md-4 > .section{
        padding: 20px;
    }
}

@media screen and (max-width: 767px){
    #faq .answer-area .content-question{
        font-size: 14px;
    }
}