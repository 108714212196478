/* .Seminar {
    padding-top: 30px;
    padding-bottom: 30px;
} */
.landing{
    position: relative;
}
.landing .container-fluid{
    padding:0px;
    max-width:none;
    /* background:#ECECEC; */
}
.landing .banners{
    width:100%;
    min-height: 450px;
    background-image: url('../../../src/assets/images/landing/1.1_BG.jpg');
    margin-top: 77px;
}
.landing .banners>div>div{
    height:300px;
    margin-top:50px;
}
.landing .videorack-item {
    width: 100%;
    cursor: pointer;
}
.landing .videorack-item img{
    max-width: 280px;
}
.landing .banners h1,.landing .banners h2,.landing .banners h3,.landing .banners p,.landing .banners h3,.landing .banners h4{
    color:#fff!important;
}
.Seminar .banner-img {
    width: 100%;
    height: auto;
}
.landing  .videorack-page td {
    max-width: 330px;
    border-left: 10px solid #fff;
    border-right: 10px solid #fff;
    vertical-align: top;
}
.landing .section button.control-arrow.control-disabled, .landing .section button.slick-arrow.slick-disabled {
    cursor: default;
    opacity: .35;
}
.landing  .section button.control-arrow, .landing  .section button.slick-arrow {
    width: 30px;
    height: 100%;
    background-color: #f5f6f7;
    opacity: 1;
    display: block;
    padding: 0;
}
.landing .carousel-slider button.control-arrow.control-next{
    background-color: #7f7f7f!important;
}
.landing .carousel.carousel-slider {
    padding-left: 60px;
    padding-right: 60px;
}
.landing .container-fluid .carousel-slider button.control-arrow {
    background-color: #7f7f7f!important;
    z-index:0!important;
}
.landing .container-fluid button.control-arrow.control-next:before, .landing .container-fluid button.slick-arrow.slick-next:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAaCAYAAAC+aNwHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAG+SURBVDhPlZStTsNQFMfb2wmSdQtyggdAINcWEhCVhOBA7A2Qm0DgcAhI2BsgEEPgRwJJDWZZ5yr2AIglmCVbMrMP/qc9LWz33rX8ktt7zuk9//t9jZQwDA9R+uCKQ4Uw6YPEfVQBSo385XLZcl23TXYe5mZyCkQaEHlhV4tYrVZPqNeSCSHEM6Zzwa4WYZpmyPYmJfzr5ImIyWTSQv2auBKxCKZ5zL6E8H1/DpEGbK0IptnVicS7QARBsGPb9jt6VDaEyBTlCAsbcSgmEyCiKLJns1lXJwJGKH69Xh8m7oYA8V8RSYBgkT5E6Iyo+FosFo7neSOlANHr9Wo4C4FOBOsxxGHztQIEiViW1Ye5l0QkQsGGknK5PEU1TjwZjGJXK5AuJsyDJCIxwvTOLXbWwJkoYf7bzsQY/05oJ6QRUHKlUulsSZ5Sz8ptTJNhKi8QJ58i+ZNDRjaCvGQwx7Zd/k0mMoFqtXqPSnt10XsDB+eN3YxYYDAYPKJBM44ooCfOcRzlbRV4MG7zkre9j/QinbEtkZdMCDS6QT1P3F8Qvy7yMgs0+qAFgp2JYFRtxB/YLQY9nijfKHccKoBh/AAoXNp+5gvhJgAAAABJRU5ErkJggg==);
}
.landing .container-fluid  button.control-arrow:before, .landing .container-fluid  button.slick-arrow:before {
    content: "";
    opacity: 1;
    width: 16px;
    height: 26px;
    display: block;
    background-repeat: no-repeat;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    border: none;
}
.landing .container-fluid button.control-arrow.control-prev:before, .landing .container-fluid button.slick-arrow.slick-prev:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAaCAYAAAC+aNwHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHUSURBVDhPlZQ/S8NAGMbzb7DQbg4d/AAiWQotjaBDhoIdBAcXRzcHF7+Lu4KDg90cOjhEoUtJwaVDP4BIB8FCA83QPz7P5RITL23jD65379t73rt7771o/2EwGJyhvff7/RPp0nTZb8X3/XNd1x8xtFarVYBxu16v9woFwKpH6Dw0SzhAHGRrAIoxuYvJZelKMzbkIBeInQ1i8rp2BxDvo+O2q8LxBwTulUql/CMgy1XTNH0M9yJPllhs23agHIFiwzC48jrxyLKsU4ppZwLEYpyZ21egeLlcurVabSJdv3VQVNxsNsfSJTD5MxwOy/jzDeID4VUZLxaLY8dxPqWdYFA8m826GNuRS4EruhB/RGYWIwzDO6zMSstDiFGyo8hUYRJ3o6EKzj3B1pOE5WFgwgUTJO0MTCgTywRLl4K4hQKFw7s/TF9fjKgDeTUttMwVxXAn8/n8mQmXroRMKRet/7gKifIWGAQTfaya+wIZJAiCluu6IW3lLfDKIG5jYrJKGl55pVJ58DxPfFxyXyPhhwTdC9qOcKh0ptPpxdoAJP0djDwKtxsDkE1BcMyvrQGIDPIkzTSXShLzaDQaHbzWG2kKaCPh99IsBnZyjfaNdhV5NO0HR+Tivbr5dpUAAAAASUVORK5CYII=);
}
.landing .container-fluid  button.control-arrow:before, .landing .container-fluid  button.slick-arrow:before {
    content: "";
    opacity: 1;
    width: 16px;
    height: 26px;
    display: block;
    background-repeat: no-repeat;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    border: none;
}

.landing  .flex{
    /* height:300px; */
    display: flex;
}
.landing  .flex div{
    padding:0px;
    margin:0px;
    /* background:yellow; */
}
.landing  .flex div img{
    width:100%;
    /* height:100%; */
}
.landing  .dWTips div:first-child{
    width: 32.5%;
}
.landing  .dWTips div:last-child{
    flex: 1;
}
.landing  .dWTips2 div{
    width:33%;
}
.landing .subscribe #email , .landing .subscribe #submitBtn{
    width:100%;
}
.landing .sharebtn {
    /* position: absolute;
    right: 1%;
    top: 8%;
    z-index: 999; */
    margin-top: -112px!important;
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 999;
}
.landing .sharebtn a,.landing .sharebtn button {
    border-radius: 8px;
    display: block;
    height: 50px;
    margin: 0 0 5px auto;
    width: 50px;
}
.landing .sharebtn-sb {
    background: url('../../../src/assets/images/landing/Social_buttons-03.png') 50% no-repeat;
    background-size: 50px 50px;
}
.landing .sharebtn-hl {
    background: url('../../../src/assets/images/landing/Social_buttons-04.png') 50% no-repeat;
    background-size: 50px 50px;
}
.landing .sharebtn-fb {
    background: url('../../../src/assets/images/landing/Social_buttons-01.png') 50% no-repeat;
    background-size: 50px 50px;
}
.landing .sharebtn-tl {
    background: url('../../../src/assets/images/landing/Social_buttons-02.png') 50% no-repeat;
    background-size: 50px 50px;
}
@media screen and (max-width: 1200px){
    .landing .container-fluid button.control-arrow{
        display: block!important;
        width: 30px!important;
    }
    .landing .carousel.carousel-slider {
        padding-left: 30px!important;
    }
}
@media screen and (max-width: 767px){
    .landing .carousel.carousel-slider {
        padding-left: 30px;
        padding-right: 30px;
    }
    .banners .row{
        padding: 0px 10px 0px 10px!important;
    }
    .landing .banners>div>div:first-child {
        height: 200px;
        margin-top: 20px;
    }
    .landing .banners>div>div:last-child {
        margin-top: 20px;
        height:320px;
    }
    #line img{
        height:580px!important;
    }
    .landing .subscribe>div{
        padding:0px;
    }
    .landing .banners{
        min-height: 600px!important;
    }
    .landing .subscribe>div:first-child{
        padding:15px 0px 15px 0px;
        margin-bottom: 15px;
        height:auto!important;
    }
    .landing .subscribe>div:last-child{
        margin-top: 15px;
    }
    .landing .Seminar  {
        margin: -30px 0px 15px 0px;
    }
    .landing .title1 {
        font-size: 27px!important;
    }
    .landing .title2 {
        font-size: 24px!important;
    }
    .landing .title3 {
        font-size: 24px!important;
    }
}

