#pagesearch .pagesearch-top{
    background-color: #ebecec;
    margin-top: 80px;
}
#pagesearch .pagesearch-top .container-fluid{
    padding-top: 16px;
    padding-bottom: 16px;
}
#pagesearch .searchbox-lbl{
    display: inline-block;
}
#pagesearch .psearchbox{
    max-width: 480px;
}
#pagesearch .psearchbox input{
    font-size: 36px;
    height: 48px;
    text-align: left;
}
body.eng #pagesearch .psearchbox input{
    font-size: 24px;
}
#pagesearch .psearchbox input:focus {
    outline: none;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
#pagesearch .psearchbox .btn{
    font-size: 30px;
    padding: 6px 10.5px;
    color: #ccc !important;
}
body.eng #pagesearch .psearchbox .btn{
    font-size: 24px;
    padding: 6px 12px;
}
#pagesearch .psearchbox .glyphicon{
    color: #CCC;
}
#pagesearch .pagesearch-body{
    background-color: #ffffff;
}
#pagesearch .search-resultbox{
    margin-top: 30px;
    margin-bottom: 30px;
    min-height: 300px;
}
#pagesearch .search-result{
    padding: 16px 0;
    border-bottom: 1px solid #cccccc;
}
#pagesearch .search-result a{
    color: #00a1e0;
    text-decoration: none;
}
#pagesearch .search-result-title{
    font-size: 27px;
}
body.eng #pagesearch .search-result-title{
    font-size: 18px;
}
#pagesearch .search-resultbox .nomatch{
    text-align: center;
}
#pagesearch .loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
    font-size: xx-large;
    color: #999;
    padding: 30px 0px;
    min-height: 300px;
}
#pagesearch .nomatch{
    margin-top: 30px;
    margin-bottom: 30px;
    min-height: 300px;
}