#toptradewarrant .tablist{
    position:relative;
    height:115px;
    margin-top:20px;
}
#toptradewarrant .tablist-left{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
}
#toptradewarrant .tablist-right{
    position: absolute;
    right:0;
    bottom:0px;
    
}
#toptradewarrant .table tr.emptymsg.none{
    display: none;
}
#toptradewarrant .loader-div{
    width: 100%;
    left: 0;
}
#toptradewarrant .tablist-left table{
    table-layout:fixed;
}
#toptradewarrant .tablist-left table td{
    border-right:10px solid #fff;
}
#toptradewarrant .tablist-left table td:last-child{
    border-right:0;
}
#toptradewarrant .tablist-item{
    width:80px;
    height:55px;
    display:table;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom:5px solid #E0E0E0;
    background-color:#EEF0F2;
    cursor:pointer;
}
#toptradewarrant .tablist-item-txt{
    display:table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
    line-height: 1;
    padding: 0 5px;
}
body.eng #toptradewarrant .tablist-item-txt{
    font-size: 13px;
    line-height: normal;
}
#toptradewarrant .tablist .active .tablist-item{
    background-color: #FECC0A;
    border-bottom:5px solid #333;
}
#toptradewarrant .tablist .tablist-item:focus, #toptradewarrant .tablist .tablist-item:hover{
    background-color: #FECC0A;
    border-bottom:5px solid #333;
}

#toptradewarrant .lastupdatebox{
    display:inline-block;
    font-size: 18px;
}
body.eng #toptradewarrant .lastupdatebox{
    font-size: 12px;
}
#toptradewarrant .table td, 
#toptradewarrant .table th{
    font-size: 21px;
    text-align: center;
}
body.eng #toptradewarrant .table th{
    font-size: 14px;
    font-family: "MCQGlobal_A_Md";
    line-height: normal;
}
#toptradewarrant .table td{
    overflow-wrap: break-word; 
    overflow:hidden;
    text-overflow:ellipsis;
}
body.eng #toptradewarrant .table td{
    font-size: 14px;
    line-height: 1.42857143;
}
#toptradewarrant .table a, .table a:focus, .table a:hover, .table a:visited{
    color: #0675C2;
    text-decoration: none;
}
#toptradewarrant .table .type{
    text-transform: uppercase;
}
#toptradewarrant .checkbox-mqonly{
    text-align: right;
    font-size: 18px;
}
body.eng #toptradewarrant .checkbox-mqonly{
    font-size: 12px;
}
#toptradewarrant .checkbox-mqonly label{
    font-weight: normal;
    position: relative;
    top: -2px;
    margin-left: 5px;
}
#toptradewarrant .new28only{
    display:none;
}
.table_dw28{
    height: 409px;
    overflow-y: scroll;
}
#toptradewarrant .carousel-root.ad-section{
    margin-top: 20px;
    width: 100%;
    height: auto;
    padding: 0px;
}
#toptradewarrant .ad-section .slider-wrapper {
    background: #000000;
}
#toptradewarrant .ad-section .slider-wrapper li.slide iframe {
    width: 100%;
    margin: 0px;
    min-height: 190px;
}
#toptradewarrant .ad-section .slider-wrapper li.slide a{
    height: 100%;
}
#toptradewarrant .ad-section .carousel .carousel-status{
    display: none;
}
#toptradewarrant .ad-section .carousel .control-dots .dot{
    width: 15px;
    height: 15px;
    box-shadow: none;
    background-color: #000;
    border: solid 1px #fff;
    opacity: 1;
    margin: 0px 5px;
}
#toptradewarrant .ad-section .carousel .control-dots .dot.selected{
    background-color: #FECC0A;
    border-color: #FECC0A;
}




/*******************************************************************************
 Responsive @media
*******************************************************************************/

@media screen and (min-width: 992px) and (max-width: 1199px) {
    #toptradewarrant .col-md-8 > .section{
        padding: 40px;
    }
    #toptradewarrant .col-md-4 > .section{
        padding: 20px;
    }
}

@media screen and (max-width: 991px){
    #toptradewarrant .table td, 
    #toptradewarrant .table th{
        font-size: 17px;
        padding-left:3px !important;
        padding-right:3px !important;
    }
    #toptradewarrant .tablist-left table{
        width:100%;
    }
    #toptradewarrant .tablist-item{
        width:100%;
    }
    #toptradewarrant .tablist-item-txt{
        font-size: 18px;
    }
    #toptradewarrant #topTabs ul li{
        width:32.5%;
    }
    body.eng #toptradewarrant .tablist-item-txt{
        font-size: 12px;
    }
    body.eng #toptradewarrant .table th,
    body.eng #toptradewarrant .table td{
        font-size: 11px;
    }
    
}
@media screen and (max-width: 767px){
    body.eng #toptradewarrant .page-header h1{
        font-size: 18px;
    }
}
@media screen and (max-width: 330px){
    #toptradewarrant .table td, #toptradewarrant .table th{
        font-size: 14px;
        padding-left:2px !important;
        padding-right:2px !important;
    }
}