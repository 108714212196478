#dwterms .uppertbl{
    width:100%;
    height:auto;
    table-layout: fixed;
    margin-bottom: 20px;
    border-collapse: collapse;
    margin-top: 10px;
}
#dwterms .ad-section{
    padding: 0px;
    width: 100%;
    margin-top: 20px;
    overflow: hidden;
}

#dwterms .uppertbl-col-bid{
   border-right:10px solid white;
    vertical-align: middle;
    width:50%;    
}
#dwterms .highcharts-area-series{
    opacity: 1!important;
}
#dwterms .select_align_center {
    text-align: center;
}

#dwterms .select_align_center input {
    text-align: center;
    display: flex;
    min-width: 100% !important;
    width: 100% !important;
}

#dwterms .uppertbl-col-chglbl{
   border-bottom:10px solid white;
    width:25%;
}
#dwterms .uppertbl-col-chgval{
   border-bottom:10px solid white;
    text-align:right;
    width:25%;    
}
#dwterms .uppertbl-col-pchgval{
    text-align:right
}
#dwterms .uppertbl td{
    padding:15px;

}
#dwterms .bidprice.val{
    font-size:60px;
    line-height:40px;
}
body.eng #dwterms .bidprice.val{
    font-size: 40px;
}
#dwterms #bidpchg.uppertbl-col-chgval {
    border-bottom: none;
}
#dwterms .page-header input.btn.btn-primary[aria-expanded="true"]{
    background-color: #999 !important;
    border-color: #999 !important;
    color: #fff !important;
}
#dwterms #srtable{
    border-bottom: none;
}
#dwterms #srtable tbody tr:last-child td,
#dwterms #srtable tbody tr:last-child th{
    border-bottom: none;
}
#dwterms .disclaimer .hr-hmgbm{
    background-color: #c9c8c8;
}
#dwterms #srtable tr td,
#dwterms #srtable tr th{
    padding: 8px;
    /* font-size: 16px;
    line-height: 22px; */
    border: none;
}
#dwterms #srtable{
    margin-bottom: 0px;
    border-bottom: 1px #CCCCCC dotted;
}
#dwterms #srtable>table{
    table-layout: fixed;
    margin-bottom: 0px;
    border-collapse: collapse;
}
#dwterms #srtable .srtable-leftlbl{
    width:30%;
}
body.eng #dwterms #srtable tr th,
body.eng #dwterms #srtable tr td{
    font-size: 16px;
}
#dwterms #srtable .srtable-leftval{
    width:20%;
    border-right:none;
}
#dwterms #srtable .srtable-rightlbl{
    width:30%;
    /* border-left:none; */
    border-left: 20px solid #fff;
}
#dwterms #srtable .srtable-rightval{
    width:20%;
}

#dwterms #powersearch{
    margin-bottom: 30px !important;
}
#dwterms #powersearch .css-1s2u09g-control{
    border-color: rgb(118, 118, 118);
    text-align: center;
    min-height: auto;
    border-radius: 2px; 
}
#dwterms #powersearch .css-1pahdxg-control{
    border-color: #000;
    box-shadow: 0 0 0 1px #000;
    text-align: center;
    min-height: auto;
}
#dwterms #powersearch .css-6j8wv5-Input,
#dwterms #powersearch .css-1pndypt-Input,
#dwterms #powersearch .css-ackcql,
#dwterms #powersearch .css-vwja0k{
    display: flex;
    justify-content: center;
    margin: 1.5px 2px;
}
#dwterms #powersearch .css-1pahdxg-control > div:last-child,
#dwterms #powersearch .css-1s2u09g-control > div:last-child{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
#dwterms #powersearch .css-1pahdxg-control > div:last-child > span,
#dwterms #powersearch .css-1s2u09g-control > div:last-child > span{
    display: none;
}
#dwterms #powersearch .css-1pahdxg-control > div:last-child > div,
#dwterms #powersearch .css-1s2u09g-control > div:last-child > div{
    padding: 5px 9px;
    color: #000;
    cursor: pointer;
}
#dwterms #powersearch .css-6j8wv5-Input input,
#dwterms #powersearch .css-1pndypt-Input input,
#dwterms #powersearch .css-ackcql input,
#dwterms #powersearch .css-vwja0k input{
    text-align: center;
    width:100% !important;
    min-width: 100% !important;
    padding: 0px 30px !important;
}
#dwterms #pstable,#dwterms #indtable{
    display:table;width:100%;margin-bottom:20px;
}
#dwterms #pstable{
    margin-bottom:40px;
}
#dwterms #indtable table{
    table-layout: fixed;
    border-collapse: collapse;
}
#dwterms #indtable table th{
    font-weight: normal;
    color: #0675C2;
    /* padding: 8px;
    line-height: 1.42;
    vertical-align: baseline;
    border:none;
    font-size: 16px; */
}
body.eng #dwterms #indtable table th,
body.eng #dwterms #indtable table td{
    line-height: 1.42857143;
}
body.eng #dwterms #indtable > h3{
    font-size: 26px;
}

/* #dwterms #indtable table td{
    padding: 8px;
    line-height: 1.42;
    vertical-align: baseline;
    border:none;
    font-size: 16px;
} */

#dwterms #indtable .table-striped > tbody > tr:nth-of-type(odd){
    --bs-table-striped-bg: transparent;
}
#dwterms #indtable table.visible-md th{
    width:25%
}
#dwterms #chartbox{
    margin-bottom:35px;
}
#dwterms #indtable table th > div {
    padding: 0px;
    opacity: 1;
    max-width: 300px;
    pointer-events: all;
}
#dwterms #indtable table th > div .bg-tooltip{
    max-width: 100%;
}
#dwterms #indtable table th > div p {
    margin: 0px;
}
#dwterms .tradeDropDown{
    margin-left: 0;
}
#dwterms .tradeDropDown .dropdown-or{
    justify-content: space-evenly;
}
#dwterms .tradeDropDown .dropdown-or hr{
    background-color: #b3b3b3;
}

#dwterms .ctab{
    width:100%;
    margin-bottom: 20px;
    border-collapse: collapse;
}
#dwterms .ctab-item{
    text-align: center;
    width:25%;
    border: 2px solid #FECC0A !important;
    height: 33px;
    cursor: pointer;
    /* font-size: 14px; */
}
#dwterms .ctab-item.active{
    background-color: #FECC0A;   
    color: black !important;
}
#dwterms .ctab-item-space{
    width:16px;
    padding: 0px;
}
#dwterms .chart-legend{
    margin-bottom: 20px;
}
#dwterms .chart-legend > .row{
    margin-left: -10px;
    margin-right: -10px;
}
#dwterms .chart-legend-left,
#dwterms .chart-legend-right{
    text-align: center;
}
#dwterms .chart-legend-bar{
    display: inline-block;
    position: relative;
    width: 20px;
    height: 3px;
    bottom: 4px;
    margin-left: 5px;
}
#dwterms .chart-legend-bar.warrant{
    background-color: #F7941E;
}
#dwterms .chart-legend-bar.underlying{
    background-color: #097CCF;
}
#dwterms .chart-legend-tbl{
    width: 200px;
    margin: 0 auto;
}
#dwterms .__react_component_tooltip .tooltip-content{
    font-size: 21px;
    line-height: 1;
}
body.eng #dwterms .__react_component_tooltip .tooltip-content{
    font-size: 14px;
    line-height: normal;
}

#dwterms .nochart{
    width:100%;
    height:100%;
    border: 1px solid #CCC;
    position:relative;
}
#dwterms .nochart-txt{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
#dwterms #dwterms-disclaimer table{
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 15px;
}
#dwterms #dwterms-disclaimer table tr>td:first-child{
    white-space: nowrap;
}

#dwterms #defination{
    margin-top:10px;   
    margin-bottom: 20px;
}
#dwterms #defination .panel-heading{
    background-color: #666666;
    padding: 4px 10px;
}
#dwterms #defination .panel-title{
    font-size: 14px;
}
#dwterms #defination .glyphicon{
    color: white;
}
#dwterms #defination #collapse_dwcompare3{
    font-size: 16px;
    padding: 10px;
}
body.eng #dwterms #defination #collapse_dwcompare3 ol{
    font-size: 12px;
  }
#dwterms #defination .table{
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 15px;
}
#dwterms #defination .table td{
    padding-left: 0;
    font-size: 18px;
}
#dwterms #defination .table tr td:first-child{
    white-space: nowrap;
}
body.eng #dwterms #defination .table td{
    font-size: 12px;
    line-height: 1.42857143;
}
#dwterms #defination ul{
    padding-left: 40px;
    margin-bottom: 10px;
}
#dwterms #defination .table ~ p{
    margin-bottom: 10px; 
}
#dwterms #defination hr{
    margin: 20px 0px;
}
#dropdown-dwterms{
    margin-top: 10px;
    margin-bottom: 30px;
}

.popupbox-content.warrant-terms{
    padding: 30px;
}
.popupbox-content.warrant-terms p.small{
    margin-bottom: 10px;
}
.popupbox-content.warrant-terms .btn.btn-primary {
    width: auto !important;
    margin: 0px 10px !important;
}
.modal-dialog.terms{
    max-width: 675px;
}

.ricbar{
    position: relative;
    width:100%;
    margin-top: 18px;
    margin-bottom: 10px;
    height: 36px;
}
#dwterms .tickerbar{
    font-family: "MCQGlobal_A_Md";
    font-size: 24px;
    height: 36px;
    border: none;
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 1px 10px;
    line-height: 1.42857143;
} 
.ricbar-btn{
    position: absolute;
    top: 0;
    right: 0;
    width: 190px;
    bottom: 0;
}
/*******************************************************************************
 dwterms section combobox
*******************************************************************************/
 .combobox {
    position: relative;
  }
  .combobox-toggle {
    position: absolute;
    display:block;
    top: 0;
    bottom: 0;
    right:0;
    width: 36px;
    padding: 0;
    cursor: pointer;
  }
  .combobox-toggle, .combobox-toggle:focus, .combobox-toggle:hover, .combobox-toggle:visited{
    color: #000;
  }
  .combobox-input {
    margin: 0;
    padding: 5px 10px;
    width:100%;
    text-align: center;
  }
  .combobox-toggle-icon{
      margin-top: 10px;
      margin-right: 16px;
  }
  .ui-widget{
      font-family: 'HelveticaNeueLight','HelveticaNeue','Roboto', Arial, sans-serif;
      font-size: 14px;
  }
  .ui-menu .ui-menu-item{
      text-align: center;
  }
  .ui-menu .ui-menu-item a.ui-state-hover{
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5; 
      border-color:#f5f5f5;
  }
  .ui-menu .ui-menu-item a:visited,
  .ui-menu .ui-menu-item a:focus,
  .ui-menu .ui-menu-item a:hover{
      color: #262626
  }
  .ui-autocomplete.ui-menu{
      max-height: 300px;
      overflow: hidden;
      overflow-y: scroll;
  }
  /*******************************************************************************
 How to get started
*******************************************************************************/  
.page-header{
    display: flex; 
    justify-content: space-between;
}
.tradeDropDown{
    position: absolute;
    border: 1px solid #000000;
    text-align: center;
    z-index: 9;
    background: #ffffff;
    width: 350px;
    padding: 10px 0;
}
.tradeDropDown a{
    margin-bottom: 5px;
}
.dropdown-or{
    display: flex;
}
.dropdown-or hr{
    width: 40%;
    margin-top: 10px;
    margin-bottom: 0px;
}
.dropdown-or p{
    margin: 0 0 5px;
}
.selectedTrade, .selectedTrade:hover, .selectedTrade:focus{
    background-color: #999999 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}
.tradeDropDown .btn-primary:hover, .tradeDropDown .btn-primary:focus{
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}
.broker_link{
    width: 45%;
    margin-bottom: 5px;
	min-width:auto !important;
}
/*******************************************
popupbox
*********************************************/
.overlay {
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0,0,0,0.6);
    height: 100%;
    width: 100%;
}
.overlay-inner {
    margin: 15vh auto;
    width: 80%;
    height: auto;
    background: #FFFFFF;
}
#ppboxHTML .overlay-inner{
    margin: 25vh auto;
    width: 50%;
    height: auto;
    background: #000000;
    color: #ffffff;
}
.popupbox-content {
    padding: 30px;
}
.btn-close {
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px;
}
/* .noscroll{position:relative;overflow:hidden;} */

#ppboxHTML .page-header{
    margin-top: 0px;
}

#ppboxHTML p, #ppboxHTML h3{
    color: #ffffff;
}

#ppboxHTML input{
    width: 30%;
    margin: 0 10px;
    color: #333333;
}
p.small a {
    text-decoration: none;
}





#dwterms .carousel-root.ad-section{
    margin-top: 20px;
    width: 100%;
    height: auto;
    padding: 0px;
}
#dwterms .ad-section .slider-wrapper {
    background: #000000;
}
#dwterms .ad-section .slider-wrapper li.slide iframe {
    width: 100%;
    margin: 0px;
    min-height: 190px;
}
#dwterms .ad-section .slider-wrapper li.slide a{
    height: 100%;
}
#dwterms .ad-section .carousel .carousel-status{
    display: none;
}
#dwterms .ad-section .carousel .control-dots .dot{
    width: 15px;
    height: 15px;
    box-shadow: none;
    background-color: #000;
    border: solid 1px #fff;
    opacity: 1;
    margin: 0px 5px;
}
#dwterms .ad-section .carousel .control-dots .dot.selected{
    background-color: #FECC0A;
    border-color: #FECC0A;
}
#dwterms .prior_table table{
    width: 100%;
}
#dwterms .prior_table table th{
    width: 30%;
    padding: 8px;
    font-size: 24px;
    vertical-align: top;
}
#dwterms .prior_table table td{
    width: 20%;
    padding: 8px;
    vertical-align: top;
}
#dwterms .prior_table table td.srtable-leftval{
    border-right: 20px solid #fff;
}
#dwterms .prior_table table td.srtable-rightlbl{
    border-left: 20px solid #fff;
}

/*******************************************************************************
 Responsive @media
*******************************************************************************/

@media screen and (min-width: 992px) and (max-width: 1199px) {
    #dwterms .container-fluid > .row > .col-md-8 > .section{
        padding: 40px;
    }
    #dwterms .container-fluid > .row > .col-md-4 > .section{
        padding: 20px;
    }
}

@media screen and (max-width: 991px){
    #ppboxHTML .overlay-inner {
        margin: 15vh auto;
        width: 90%;
    }
    .overlay-inner{
        width: 90%;
    }
    .overlay-inner .popupbox-content{
        padding-left: 30px;
        padding-right: 30px;
    }
    .tradeDropDown{
        right: 20px;
        width: 320px;
    }
    .col-md-8.w-100-md{
        width: 100%;
    }
    #dwterms #indtable table.sm-view.xs-view{
        display: table !important;
    }
    #dwterms #defination_term .panel-heading {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        padding: 2px 10px;
        background-color: #666666;
    }
    #dwterms #defination_term .panel-heading button {
        border: none;
        background-color: transparent;
        width: 100%;
        text-align: center;
        padding: 0px;
    }
    #dwterms #defination_term .panel-title {
        font-size: 18px;
        position: relative;
    }
    #dwterms #defination_term .panel-heading button img {
        width: 14px;
        position: absolute;
        top: 3px;
        right: 0px;
    }
    #dwterms #defination_term #collapse_dwcompare3 {
        font-size: 16px;
        padding: 10px;
        border: solid 1px #ddd;
        border-radius: 0px 0px 4px 4px;
        box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    }
    #dwterms #defination_term #collapse_dwcompare3.hide {
        display: none;
    }
    #dwterms #defination_term .table td {
        padding: 8px;
        padding-left: 0;
        border: none;
        font-size: 16px;
        vertical-align: top;
        line-height: 1.42857143;
        min-width: 140px;
    }
    #dwterms #defination_term .table {
        border-bottom: 1px solid #FECC0A;
        margin-bottom: 15px;
    }
    #dwterms #defination_term .table.table-striped > tbody > tr:nth-of-type(odd){
        --bs-table-striped-bg:transparent;
    }
    #dwterms #defination_term hr{
        margin: 10px 0px;
        background-color: #eee;
        opacity: 1;
    }
    #dwterms #defination_term{
        margin-bottom: 20px; 
    }

    #dwterms #defination .panel-heading button {
        border: none;
        background-color: transparent;
        width: 100%;
        text-align: center;
        padding: 0px;
    }
    #dwterms #defination .panel-heading button:focus-visible{
        outline: none;
    }
    #dwterms #defination .panel-heading{
        padding: 2px 10px;
    }
    body.eng #dwterms #defination .panel-heading {
        padding: 2.6px 10px;
    }
    #dwterms #defination .panel-title {
        font-size: 21px;
        position: relative;
    }
    body.eng #dwterms #defination .panel-title {
        font-size: 14px;
        position: relative;
    }
    body.eng #dwterms #defination .panel-heading .pull-right{
        line-height: 1;
    }
    body.eng #dwterms #defination .panel-heading img{
        width: 16px;
    }
    #dwterms #defination hr{
        margin: 10px 0px;
    }
}
@media screen and (max-width: 767px){
    #dwterms h3{
        font-size: 24px;
        margin-bottom: 0px !important;
        margin-top: 10px !important;
    }
    #dwterms .uppertbl{
        margin-top:0;
    }
    #dwterms .uppertbl td{
        padding:4px 10px;  
        line-height: 1;
    }

    #dwterms .uppertbl-col-bid{
        border-right:0;
        width:40%;
    }
    #dwterms .uppertbl-col-bid .lbl, .uppertbl-col-chglbl, .uppertbl-col-pchglbl{
        font-size: 18px;
        line-height: 1;
    }
    body.eng #dwterms .uppertbl-col-bid .lbl, .uppertbl-col-chglbl, .uppertbl-col-pchglbl{
        font-size: 12px;
        line-height: 1.42857143;
    }
    #dwterms .uppertbl-col-bid .val{
        font-size: 21px;
        line-height: normal;
    }
    body.eng #dwterms .bidprice.val{
        font-size: 14px;
    }
    #dwterms .uppertbl-col-chgval, #dwterms .uppertbl-col-pchgval{
        font-size: 24px;
    }
    body.eng #dwterms .uppertbl-col-chgval, body.eng #dwterms .uppertbl-col-pchgval{
        font-size: 16px;
        line-height: 1.42857143;
    }
    #dwterms .uppertbl-col-chglbl{
        border-bottom:0;
        width:35%;    
    }
    #dwterms .uppertbl-col-chgval{
        border-bottom:0;
        width:25%;
    }
    #dwterms #srtable>table{
        font-size: 16px;
    }
    #dwterms #srtable .srtable-leftlbl{
        width:15%;
    }
    #dwterms #srtable .srtable-leftval{
        border-left:0;
    }
    #dwterms #srtable .srtable-rightlbl{
        border-right:0;
    }
    /* #dwterms #srtable .srtable-rightval{
    } */
    #dwterms  #indtable table{
        font-size: 11px;
    }
/*    #dwterms  .showmore{
        margin-top:10px;
    }*/
    #dwterms #indtable{
        margin-bottom:0;
    }
    #dwterms #indtable table{
        margin-bottom:0;
    }
    /*#dwterms .chart-legend-item{
        width: 170px;
        margin: 0 auto;
        text-align: left;
    }*/
    #dwterms #dwterms-disclaimer table{
        font-size: 11px;
    }
    #dwterms p.small{
        font-size: 17px;
    }
    body.eng #dwterms p.small{
        font-size: 11px;
    }
    #dwterms #defination .table td{
        font-size: 17px;
    }
    body.eng #dwterms #defination .table td{
        font-size: 11px;
    }
    body.eng #dwterms #defination .panel-heading {
        padding: 4px 10px;
    }
    #dwterms #defination #collapse_dwcompare3{
        font-size: 11px;
    }
    #dwterms .ricbar{
        height: 25px;
    }
    #dwterms .ricbar .ricbar-btn{
        font-family: HelveticaNeueLight;
        width: 130px;
        padding: 0;
        min-width: auto;
    }
    #dwterms .tickerbar{
        font-size: 16px;
        text-align: center;
        overflow: hidden;
        line-height: 1.42857143;
        height: 24px;
    }
    #dwterms #srtable tr td, 
    #dwterms #srtable tr th{
        font-size: 18px;
        line-height: 1;
        vertical-align: top;
        white-space: break-spaces;
    }
    #dwterms .chart-legend-bar{
        margin-left: 30px;
    }
    body.eng #dwterms .chart-legend-bar{
        margin-left: 5px;
    }
    #dwterms #indtable table th > div p.tooltip-content{
        font-size: 17px;
    }
    body.eng #dwterms #indtable table th > div p.tooltip-content{
        font-size: 11px;
    }
    #dwterms #indtable table td,
    #dwterms #indtable table th{
        font-size: 17px;
    }
    body.eng #dwterms #indtable table td,
    body.eng #dwterms #indtable table th{
        font-size: 11px;
    }
    #dwterms #defination_term{
        margin-top: 10px;
    }
    #dwterms #defination_term .table td{
        font-size: 11px;
    }
    #dwterms #defination_term .table td:first-child{
        white-space: nowrap;
        min-width: auto;
    }
    #dwterms #defination_term p.small{
        font-size: 11px;
        margin-bottom: 10px;
    }
    #dwterms #defination .panel-heading {
        padding: 3.25px 10px;
    }
    body.eng #dwterms .page-header h1{
        font-size: 18px; 
    }
    body.eng #dwterms #srtable tr th, 
    body.eng #dwterms #srtable tr td{
        font-size: 12px;
        line-height: 1.42857143;
    }
    body.eng #dwterms #indtable > h3{
        font-size: 16px;
    }
}



.carousel .carousel-status {
    color: transparent !important;
    text-shadow: none !important;
}
.ad-section{
    position: relative;
    height: 372.6px;
    width: 372.6px;
    background-color: #fff;
    padding: 2px;
    margin-top: 18px;
}
.ad-img{
width: 100%;
height: 100%;
}