#turnoverdist .tblh{
    position:relative;
    margin-top:20px;
    height:40px;
    margin-bottom:10px;
}
#turnoverdist .tblh-left{
    position: absolute;
    left:0;
    bottom:0;
}
#turnoverdist .tblh h2.small{
    font-family: 'DBHeaventMedium';
    font-size: 24px;
    font-weight: normal;
    margin: 0;
}
body.eng #turnoverdist .tblh h2.small{
    font-size: 16px;
    font-family: 'MCQGlobal_A_Md';
}
#turnoverdist p{
    margin-bottom: 10px;
}
#turnoverdist #topTbl table{
    font-size: 20px;
    white-space: nowrap;
    margin-bottom: 20px;
    border-collapse: collapse;
}
#turnoverdist #topTbl table tbody{
    border-top: none;
}
#turnoverdist #topTbl th,
#turnoverdist  #topTbl td{
    text-align: center;
    padding: 8px;
    border: none;
    line-height: 1;
    font-size: 21px;
}
body.eng #turnoverdist #topTbl th,
body.eng #turnoverdist  #topTbl td{
    font-size: 14px;
}
body.eng #turnoverdist #topTbl th{
    font-family: "MCQGlobal_A_Md";
    line-height: normal;
}
body.eng #turnoverdist  #topTbl td{
    line-height: 1.42857143;
}
#turnoverdist #topTbl tr td:first-child{
    width:130px;
    font-weight: bold;
}
#turnoverdist .table-striped > tbody > tr:nth-of-type(odd){
    --bs-table-striped-bg: transparent;
}
#turnoverdist .chartbox-turn-over-dist{
    position:relative;
}
#turnoverdist .chartbox-turn-over-dist .apexcharts-canvas{
    margin: 0px auto;
}
#turnoverdist .chartbox-turn-over-dist h2.small{
    font-family: 'DBHeaventMedium';
    font-size: 24px;
    font-weight: normal;
    margin: 0;
    text-align: center;
    margin-bottom: 20px;
}
body.eng #turnoverdist .chartbox-turn-over-dist h2.small{
    font-size: 16px;
    font-family: 'MCQGlobal_A_Md';
}
#turnoverdist .chartbox-pie-turn-over-dist{
    height:420px;
    width:100%;
}
#turnoverdist .chartbox-legend-turn-over-dist{
    position:absolute;
    right:0;
}
#turnoverdist .chartbox-legend-item-turn-over-dist{
    font-size: 18px;
    position: relative;
    height: 18px;
    margin-bottom: 10px;
}
body.eng #turnoverdist .chartbox-legend-item-turn-over-dist{
    font-size: 12px;
}
#turnoverdist .chartbox-legend-square-turn-over-dist{
    display:inline-block;
    width:18px;
    height: 18px;
    position:absolute;
    top:-1px;
}
#turnoverdist .chartbox-legend-txt-turn-over-dist{
    margin-left:23px;
}
#turnoverdist .chartbox-legend-square-turn-over-dist.cbg00{
    background-color:#0675C2;
}
#turnoverdist .chartbox-legend-xs-turn-over-dist-turn-over-dist{
    margin-top:10px;
    min-width:280px;
    margin-left:auto;
    margin-right:auto;
}
#turnoverdist .carousel-root.ad-section{
    width: 100%;
    padding: 0px;
    margin-top: 20px;
    overflow: hidden;
}
#turnoverdist .chartbox-legend-xs-turn-over-dist table{
    table-layout:fixed;
    width:100%;
}
#turnoverdist .chartbox-legend-xs-turn-over-dist table td{
    padding-bottom:10px;
}
#turnoverdist .chartbox-legend-xs-turn-over-dist .chartbox-legend-item-turn-over-dist{
    margin-bottom:0;
}

#turnoverdist .chartbox-legend-square-turn-over-dist.cbg01{
    background-color:#00A1E0;
}
#turnoverdist .chartbox-legend-square-turn-over-dist.cbg02{
    background-color:#6CC263;
}
#turnoverdist .chartbox-legend-square-turn-over-dist.cbg03{
    background-color:#F7941E;
}
#turnoverdist .chartbox-legend-square-turn-over-dist.cbg04{
    background-color:#FECC0A;
}
#turnoverdist .chartbox-legend-square-turn-over-dist.cbg05{
    background-color:#C61C2D;
}



#turnoverdist .carousel-root.ad-section{
    margin-top: 20px;
    width: 100%;
    padding: 0px;
    height: auto;
}
#turnoverdist .ad-section .slider-wrapper {
    background: #000000;
}
#turnoverdist .ad-section .slider-wrapper li.slide iframe {
    width: 100%;
    margin: 0px;
    min-height: 190px;
}
#turnoverdist .ad-section .slider-wrapper li.slide a{
    height: 100%;
}
#turnoverdist .ad-section .carousel .carousel-status{
    display: none;
}
#turnoverdist .ad-section .carousel .control-dots .dot{
    width: 15px;
    height: 15px;
    box-shadow: none;
    background-color: #000;
    border: solid 1px #fff;
    opacity: 1;
    margin: 0px 5px;
}
#turnoverdist .ad-section .carousel .control-dots .dot.selected{
    background-color: #FECC0A;
    border-color: #FECC0A;
}

#turnoverdist .tblh .tblh-right{
    position: absolute;
    right: 0;
    bottom: 0;
}
#turnoverdist .lastupdatebox {
    font-size: 18px;
}
body.eng #turnoverdist .lastupdatebox{
    font-size: 12px;
}


/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (min-width: 992px) and (max-width: 1199px) {
    #turnoverdist .row > .col-md-8 > .section{
        padding: 40px;
    }
    #turnoverdist .row > .col-md-4 > .section{
        padding: 20px;
    }
}
@media screen and (max-width: 991px){ 
    #turnoverdist .col-md-8.sm-w-100{
        width: 100%;
    }
}
@media screen and (max-width: 767px){    
    #turnoverdist #topTbl table{
        font-size: 11px;
    }
    #turnoverdist #topTbl table td,
    #turnoverdist #topTbl table th{
        padding-left: 4px;
        padding-right: 4px;
        font-size: 17px;
    }
    #turnoverdist .chartbox-legend-turn-over-dist.visible-xs{
        position: relative;
        right: inherit;
        display: flex !important;
        flex-wrap: wrap;
    }
    #turnoverdist .chartbox-legend-item-turn-over-dist{
        width: 33.33%;
    }
    body.eng #turnoverdist .page-header h1{
        font-size: 18px;
    }
    body.eng #turnoverdist #topTbl th, 
    body.eng #turnoverdist #topTbl td {
        font-size: 11px;
    }
}
@media screen and (max-width: 767px) and (orientation:portrait){    
    #topTbl table{
        font-size: 11px;
    }
}
