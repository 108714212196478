#dwtipssup .section-p{
    margin-bottom: 30px;
    min-height: inherit;
}
#dwtipssup .newstbl-htxt{
    font-family: "DBHeaventLight";
    height: 30px;
    line-height: 30px;
    background-color: #CCC;
    padding-left: 10px;
}
body.eng #dwtipssup .newstbl-htxt{
    font-family: "MCQGlobal_A_Lt";
    height: 30px;
    line-height: 30px;
}
#dwtipssup .newstbl-row{
    position:relative;
    margin-top: 10px;
    background-color: rgb(245, 246, 247);
}
#dwtipssup .newstbl-content{
    margin-bottom:20px;
}
#dwtipssup .newstbl-content #newsswiper{
    min-height: 300px;
}
#dwtipssup .newstbl-content .newstbl-row:last-child{
    border-bottom: none !important;
}
#dwtipssup .newstbl-col-right{
    margin-left:20px;
    display: flex;
    align-items: center;
}
body.eng #dwtipssup .newstbl-col-right{
    margin-bottom: 6px;
}
#dwtipssup .newstbl-col-left{
    position:absolute;
    top:26px;
    left:0;
    text-align: left;
}
#dwtipssup .newstbl-col-left span{
    font-family: "DBHeaventMedium";
    color: #0675C2;
}
#dwtipssup .newstbl-col-dt{
    font-family: "DBHeaventMedium";
    margin:0;
    font-size: 24px;
    padding-top:8px;
    padding-bottom:8px;
    display: inline-block;
}
body.eng #dwtipssup .newstbl-col-dt{
    font-family: "MCQGlobal_A_Md";
    font-size: 16px;
}
#dwtipssup .newstbl-col-dd{
    margin-bottom: 30px;
    max-height: 76px;
    overflow: hidden;
}
#dwtipssup .newstbl-col-rm{
    text-align: right;
    display: inline-block;
    line-height: 1;
    font-family: "DBHeaventMedium";
    min-width: 50px;
    padding-right: 15px;
}
#dwtipssup .newstbl-col-rm img{
    width: 50%;
    margin-left: auto;
}
#dwtipssup .newstbl-col-rm .btn{
    width: 150px;
}
#dwtipssup .newstbl-bttm{
    position:relative;
    padding-left:40px;
    padding-right:40px;
    padding-top:8px;
    padding-bottom:8px;
    height: 32px;
    margin-bottom: 20px;
}
#dwtipssup .newstbl-cal-icon.input-group{
    position: absolute;
    top:0px;
    right: 0px;
    display: inline-block;
    border-collapse: inherit;
}
#dwtipssup .input-group-addon{
    text-align:right;
    padding:0;
    color: inherit;
    background-color: inherit;
    border: 0;
    border-radius: inherit;
    display:inline-block;
}
#dwtipssup .newstbl .swiper-container {
    width: 100%;
    min-height: 300px;
} 
#dwtipssup .newstbl .swiper-pagination{
    position:inherit;
}
#dwtipssup .carousel-root.ad-section{
    margin-top: 20px;
    width: 100%;
    height: auto;
    padding: 0px;
}
#dwtipssup .ad-section .slider-wrapper {
    background: #000000;
}
#dwtipssup .ad-section .slider-wrapper li.slide iframe {
    width: 100%;
    margin: 0px;
    min-height: 190px;
    position: relative;
}
#dwtipssup .ad-section .slider-wrapper li.slide a{
    height: 100%;
}
#dwtipssup .ad-section .carousel .carousel-status{
    display: none;
}
#dwtipssup .ad-section .carousel .control-dots .dot{
    width: 15px;
    height: 15px;
    box-shadow: none;
    background-color: #000;
    border: solid 1px #fff;
    opacity: 1;
    margin: 0px 5px;
}
#dwtipssup .ad-section .carousel .control-dots .dot.selected{
    background-color: #FECC0A;
    border-color: #FECC0A;
}
#dwtipssup #newsswiper button.slick-arrow{
    display: none !important;
}
#dwtipssup #newsswiper ul.slick-dots{
    position: relative;
    display: block;
    bottom: inherit;
    margin-top: 40px;
    padding: 0px 40px 8px;
}
#dwtipssup #newsswiper ul.slick-dots li{
    margin: 0px 2px;
    width: auto;
    height: auto;
}
#dwtipssup #newsswiper ul.slick-dots li button{
    padding: 0px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    overflow: hidden;
    background-color: transparent;
    color: #ddd;
    border: solid 1px #ddd;
}
#dwtipssup #newsswiper ul.slick-dots li.slick-active button{
    background-color: #FECC0B;
    color: #FECC0B;
    border-color: #FECC0B;
}
#dwtipssup #newsswiper ul.slick-dots li button::before{
    display: none;
}

/* ========= */

@media screen and (min-width: 992px) and (max-width: 1199px) {
    #dwtipssup .container-fluid > .row > .col-md-8 > .section{
        padding: 40px;
    }
    #dwtipssup .container-fluid > .row > .col-md-4 > .section{
        padding: 20px;
    }
}

@media screen and (max-width:767px){
    #dwtipssup .newstbl-col-dd{
        max-height: 68px;
    }
    body.eng #dwtipssup #tipssection .page-header h1{
        font-size: 18px;
    }
}


