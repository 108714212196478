#highlight #highlight-content {
  margin-bottom: 30px;
}
#highlight #highlight-chart img {
  display: block;
  max-width: 690px;
  width: 100%;
  margin: 0 auto 20px auto;
}
#highlight p {
  margin-bottom: 10px;
}
#highlight #highlight-date {
  margin-bottom: 0px;
}
#highlight ul {
  margin-bottom: 10px;
  list-style-type: disc;
  padding-left: 40px;
  line-height: normal;
}
#highlight ul li {
  margin-bottom: 0px;
}
#highlight .fbSharebtn {
  margin: 0px;
}
#highlight .pnbar {
  position: relative;
  height: 22px;
}
#highlight #toptradeddw > .row{
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  flex-wrap: wrap;
}
#highlight .pnbar-prev {
  position: absolute;
  right: 50%;
  margin-right: 30px;
}
#highlight .page-header .btn-area{
  margin-left: auto;
}
#highlight .pnbar-next {
  position: absolute;
  left: 50%;
  margin-left: 30px;
}
#highlight .pnbar div a.disabled{
  pointer-events: none;
}
#highlight .pnbar-vl {
  position: absolute;
  right: 50%;
  top: 2px;
  bottom: 0;
  width: 0;
  border-left: 1px solid #ccc;
}
#highlight .topImage {
  max-width: 100%;
  display: block;
  margin: auto;
  padding-top: 5px;
  padding-bottom: 15px;
  width: auto;
}
#highlight .pnbar-prev a,
#highlight .pnbar-prev a:visited,
#highlight .pnbar-prev a:hover,
#highlight .pnbar-next a,
#highlight .pnbar-next a:visited,
#highlight .pnbar-next a:hover {
  font-family: "HelveticaNeueMedium";
  font-size: 14px;
  color: #0675c2;
}
#highlight .pnbar-prev a.disabled,
#highlight .pnbar-prev a.disabled:visited,
#highlight .pnbar-prev a.disabled:hover,
#highlight .pnbar-next a.disabled,
#highlight .pnbar-next a.disabled:visited,
#highlight .pnbar-next a.disabled:hover {
  color: #ccc;
}
#highlight .hltbl th,
#highlight .hltbl td {
  text-align: center;
}
body.eng #highlight .hltbl th{
  font-family: "MCQGlobal_A_Md";
  line-height: normal;
}
body.eng #highlight .hltbl td{
  line-height: 1.42857143;
}
#highlight-date {
  margin-bottom: 0;
  text-align: right;
}
.page-header {
  display: flex;
  justify-content: space-between;
}
.btn-area {
  margin-left: 20px;
}
.fbSharebtn {
  margin: 8px 0 0 0;
  width: 200px;
  padding: 0px;
  margin-left: auto !important;
}
/* #highlight-content table {
  display: none;
} */
/*******************************************************************************
lightbox
********************************************************************************/
#lightbox {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  text-align: center;
}
#lightbox > div {
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.6);
}
#lightbox .inner-area {
  display: inline-block;
  position: relative;
  z-index: 3000;
}
#lightbox .inner-area img {
  width: 100%;
}
#lightbox .btn-close {
  position: absolute;
  left: 2px;
  top: 8px;
  width: 32px;
  height: 32px;
}
#lightbox .btn-close:before,
#lightbox .btn-close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 17px;
  width: 2px;
  background-color: #333333;
}
#lightbox .btn-close:before {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Safari */
  transform: rotate(45deg);
}
#lightbox .btn-close:after {
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg); /* IE 9 */
  -webkit-transform: rotate(-45deg); /* Safari */
  transform: rotate(-45deg);
}
#lightbox .noshow {
  font-size: 12px;
  text-decoration: underline;
  /*color:#333333;*/
  color: #ffffff;
  position: absolute;
  bottom: 6px;
  left: 8px;
}
#lightbox .countdown-txt {
  position: absolute;
  top: 5px;
  right: 8px;
  font-size: 12px;
  color: #333333;
}
#lightbox .animatedVideo {
  position: absolute;
  top: 20%;
  bottom: 20%;
  left: 5%;
  right: 5%;
}
#lightbox .animatedVideo iframe {
  width: 100%;
  height: 100%;
}




/* ============ */
#highlight .fbSharebtn {
  font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
  font-weight: 300;
}
#highlight .pnbar-prev a, 
#highlight .pnbar-prev a:visited, 
#highlight .pnbar-prev a:hover,
#highlight .pnbar-next a, 
#highlight .pnbar-next a:visited, 
#highlight .pnbar-next a:hover {
  font-size: 21px;
  font-family: "DBHeaventMedium";
}
body.eng #highlight .pnbar-prev a, 
body.eng #highlight .pnbar-prev a:visited, 
body.eng #highlight .pnbar-prev a:hover,
body.eng #highlight .pnbar-next a, 
body.eng #highlight .pnbar-next a:visited, 
body.eng #highlight .pnbar-next a:hover {
  font-family: "MCQGlobal_A_Md";
  font-size: 14px;
}



#highlight .disabled-link {
  pointer-events: none;
  cursor: auto;
}
#highlight .disply_pc {
  display: none !important;
}
#highlight #toptradeddw h2.small {
  font-size: 39px;
  font-family: 'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif;
  text-align: left;
}
body.eng #highlight #toptradeddw h2.small {
  font-size: 26px;
  font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
}
#highlight #toptradeddw table tr th,
#highlight #toptradeddw table tr td,
#highlight #toptradeddw table tr td a{
  font-size: 24px;
  text-align: left;
}
body.eng #highlight #toptradeddw table tr th,
body.eng #highlight #toptradeddw table tr td,
body.eng #highlight #toptradeddw table tr td a{
  font-size: 16px;
}
body.eng #highlight #toptradeddw table tr th{
  font-family: "MCQGlobal_A_Md";
  line-height: normal;
}
body.eng #highlight #toptradeddw table tr td{
  line-height: 1.42857143;
}
#highlight #toptradeddw > .section-btn-wapper{
  margin-top: 0px;
}
/* #highlight .hltbl th:last-child, 
#highlight .hltbl td:last-child{
    display: none;  
} */
#highlight .hltbl tr.emptymsg td:last-child{
    display: table-cell;
}
body.eng #highlight #toptradeddw table tr.emptymsg td{
  text-align: center;
}


/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  #highlight .container-fluid > .section{
    padding: 40px;
  }
  #highlight #toptradeddw{
    padding: 40px;
  }
  #highlight #toptradeddw table tr th,
  #highlight #toptradeddw table tr td{
    padding: 8px;
  }
}

@media screen and (max-width: 991px) {
  
  div#highlight div#toptradeddw table.table tr {
    font-size: 18px;
  }

  div#toptradeddw h2.small {
      font-size: 30px;
  }

}


@media screen and (max-width: 767px) {
  .page-header {
    display: block;
  }
  .btn-area {
    margin: 10px 0 10px 0;
  }
  #lightbox .countdown-txt {
    font-size: 9px;
    top: 4px;
  }
  /*    .fbSharebtn{ margin-left: auto;}*/
  #highlight .hltbl .th-ss.hide, #highlight  .hltbl .col_sstvt.hide {
    display: table-cell!important;
}
  #highlight .page-header .btn-area {
    margin-top: 0;
    margin-bottom: 0;
  }

  div#highlight div#toptradeddw table.table tr {
    font-size: 16px;
  }

  div#toptradeddw h2.small {
      font-size: 24px;
  }
  #highlight table.hltbl {
    font-size: 21px;
  }
  body.eng #highlight table.hltbl {
    font-size: 14px;
  }
  #highlight .disply_pc {
    display: table-cell !important;
  }
  #highlight .disply_none {
    display: none;
  }
  body.eng #highlight .fbSharebtn{
    font-size: 14px;
  }
  #highlight #toptradeddw table tr th, 
  #highlight #toptradeddw table tr td, 
  #highlight #toptradeddw table tr td a{
    font-size: 21px;
  }
  body.eng #highlight #toptradeddw table tr th, 
  body.eng #highlight #toptradeddw table tr td, 
  body.eng #highlight #toptradeddw table tr td a {
    font-size: 14px;
  }
  #highlight #toptradeddw > .row > .col-sm-6{
    overflow: hidden;
  }
  #highlight .hltbl th:last-child, 
  #highlight .hltbl td:last-child{
    display: table-cell;
  }
}


@media screen and (max-width: 450px) {
  #highlight table.hltbl th,
  #highlight table.hltbl td {
    padding-left: 2px;
    padding-right: 2px;
  }
  #highlight table.hltbl {
    font-size: 18px;
  }
  body.eng #highlight table.hltbl {
    font-size: 12px;
  }
}
@media screen and (max-width: 350px) {
  #highlight table.hltbl {
    font-size: 17px;
  }
  body.eng #highlight table.hltbl {
    font-size: 11px;
  }
  #highlight table.hltbl th,
  #highlight table.hltbl td {
    padding-left: 0px;
    padding-right: 0px;
  }
}


