#dwranking .section-p{
    min-height: 30px;
}

#dwranking .align_center{
    text-align: center;
}
#dwranking .uric{
    margin-top:10px;
    margin-bottom:20px;
}
#dwranking .table th, 
#dwranking .table td{
    text-align: center;
}
body.eng #dwranking .table th{
    font-family: "MCQGlobal_A_Md" !important;
    line-height: normal;
}
body.eng #dwranking .table td{
    line-height: 1.42857143;
    font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif; 
}
#dwranking .table tr:last-child{
    border-bottom: 1px solid #FECC0A;
}
#dwranking #indexfuture table th {
    white-space: pre-wrap;
    font-size:20px;
}
body.eng  #dwranking #indexfuture .priceUpdateInfo em {
    font-size: 15px;
    line-height: normal; 
}
#indexfuture .priceUpdateInfo em {
    font-size: 22px;
    line-height: normal; 
}
body.eng #dwranking #indexfuture table td {
    font-size: 12px;
}
body.eng #dwranking #indexfuture table th {
    font-size: 13px;
}

#dwranking #indexfuture table td {
    font-size: 17px;
}
#dwranking hr{
    margin-top: 10px;
    margin-bottom: 10px;
}
#dwranking .top-table{
    margin-bottom: 40px;
}
#dwranking .table a, #dwranking .table a:focus,#dwranking .table a:hover,#dwranking .table a:visited{
    font-family: "DBHeaventMedium";
    color:#0675C2;
    text-decoration:none;
}
#dwranking .warrant-search_dropdown .css-1s2u09g-control,
#dwranking .warrant-search_dropdown .css-1pahdxg-control{
    border-color: rgb(118 118 118);
    border-radius: 2px;
    min-height: auto;
}
#dwranking .warrant-search_dropdown .css-1s2u09g-control:hover,
#dwranking .warrant-search_dropdown .css-1pahdxg-control:hover{
    border-color: rgb(118 118 118) !important;
}
#dwranking .warrant-search_dropdown .css-1pahdxg-control,
#dwranking .warrant-search_dropdown .css-1pahdxg-control:focus{
    box-shadow: 0px 0px 0px 1px #000;
}
#dwranking .warrant-search_dropdown .css-1s2u09g-control > div:last-child > span,
#dwranking .warrant-search_dropdown .css-1pahdxg-control > div:last-child > span{
    display: none;
}
#dwranking .warrant-search_dropdown .css-1s2u09g-control > div:last-child svg,
#dwranking .warrant-search_dropdown .css-1pahdxg-control > div:last-child svg{
    color: #000;
}
#dwranking .warrant-search_dropdown .css-1s2u09g-control > div:first-child,
#dwranking .warrant-search_dropdown .css-1pahdxg-control > div:first-child{
    padding: 0px;
}
#dwranking .warrant-search_dropdown .css-1s2u09g-control > div:first-child div,
#dwranking .warrant-search_dropdown .css-1pahdxg-control > div:first-child div{
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 24px;
}
body.eng #dwranking .warrant-search_dropdown .css-1s2u09g-control > div:first-child div,
body.eng #dwranking .warrant-search_dropdown .css-1pahdxg-control > div:first-child div,
body.eng #dwranking .warrant-search_dropdown .css-1pahdxg-control > div:first-child input, 
body.eng #dwranking .warrant-search_dropdown .css-1s2u09g-control > div:first-child input{
    font-size: 16px;
}
#dwranking .warrant-search_dropdown .css-1s2u09g-control > div:first-child input,
#dwranking .warrant-search_dropdown .css-1pahdxg-control > div:first-child input{
    text-align: center;
    min-width: 100% !important;
}
#dwranking .__react_component_tooltip{
    padding: 5px 9px;
    margin: 0px;
}
#dwranking .__react_component_tooltip p.tooltip-content{
    padding: 0px;
    margin: 0px;
    font-size: 21px;
    line-height: 1;
    font-family: 'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif;
}
body.eng #dwranking .__react_component_tooltip p.tooltip-content,
body.eng #dwranking .__react_component_tooltip p.tooltip-content a{
    font-size: 14px;  
    font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
    line-height: normal;
}
#dwranking .icon-hotdw{
    /*background:url('../../../img/bullet/hot.fw.png')0 0 no-repeat;*/
    display:inline-block;
    background:url('../../../assets/images/bullet/fasttrack.png') 0 0 no-repeat;
    background-size: contain; 
    width: 22px;
    height: 14px;
    margin-bottom:-1px;
}
#dwranking .icon-expiry{
    display:inline-block;
    background:url('../../../assets/images/bullet/expiry.fw.png') 0 0 no-repeat;
    width: 12px;
    height: 14px;
    margin-bottom:-2px;
}
#dwranking .icon-soldout{
    display:inline-block;
    background:url('../../../assets/images/bullet/soldout.fw.png') 0 0 no-repeat;
    width: 12px;
    height: 13px;
    margin-bottom:-2px;
}
#dwranking .icon-caution{
    display:inline-block;
    background:url('../../../assets/images/bullet/caution.png') 0 0 no-repeat; 
    width: 14px;
    height: 12px;
    margin-bottom:-2px;
    background-size: contain;
}
#dwranking .icon-time{
    display:inline-block;
    background:url('../../../assets/images/bullet/time.png') 0 0 no-repeat;
    width: 14px;
    height: 12px;
    margin-bottom:-2px;
    background-size: contain;
}
#dwranking .icon-responsive{
    display:inline-block;
    background:url('../../../assets/images/bullet/responsive.png') 0 0 no-repeat;
    width: 12px;
    height: 13px;
    margin-bottom:-2px;
}
#dwranking table td .icon-hotdw{
    margin-left: 5px;
    margin-bottom: -1px;
}
#dwranking table td .icon-expiry{
    margin-left: 5px;
    margin-bottom: -1px;
}
#dwranking table td .icon-soldout{
    margin-left: 5px;
    margin-bottom: -1px;
}
#dwranking table td .icon-caution{
    margin-left: 5px;
    margin-bottom: -1px;
}
#dwranking table td .icon-time{
    margin-left: 5px;
    margin-bottom: -1px;
}
#dwranking table td .icon-responsive{
    margin-left: 5px;
    margin-bottom: -1px;
}
#dwranking ul.description{
    list-style-type: none;
    padding: 0;
    font-size: 18px;
    font-weight: normal;
}
body.eng #dwranking ul.description{
    font-size: 12px;
}
#dwranking ul.description li{
    margin-bottom: 10px;

}
#dwranking ul.description .description-hotdw{
    /*background:url('../../../img/bullet/hot.fw.png')0 0 no-repeat;*/
    background:url('../../../assets/images/bullet/fasttrack.png') 0 0 no-repeat;
    background-size: 22px; 
    padding-left: 35px;
    margin-left: -5px;
}
#dwranking ul.description .description-expiry{
    background:url('../../../assets/images/bullet/expiry.fw.png') 0 0 no-repeat;
    background-size: 14px;
    padding-left: 30px;
}
#dwranking ul.description .description-soldout{
    background:url('../../../assets/images/bullet/soldout.fw.png') 0 0 no-repeat;
    background-size: 14px;
    padding-left: 30px;
}
#dwranking ul.description .description-caution{
    background:url('../../../assets/images/bullet/caution.png') 0 0 no-repeat;
    background-size: 18px;
    padding-left: 30px;
}
#dwranking ul.description .description-time{
    background:url('../../../assets/images/bullet/time.png') 0 0 no-repeat;
    background-size: 18px;
    padding-left: 30px;
}
#dwranking ul.description .description-responsive{
    background:url('../../../assets/images/bullet/responsive.png') 0 0 no-repeat;
    background-size: 14px;
    padding-left: 30px;
}
#dwranking #defination .panel-heading{
    background-color: #666666;
    padding: 4px 10px;
}
#dwranking #defination .panel-title{
    font-size: 21px;
}
#dwranking #defination .glyphicon{
    color: white;
}
#dwranking #defination #collapse_dwcompare3{
    font-size: 18px;
    padding: 10px;
}
#dwranking #dwranking #defination .table{
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 15px;
}
#dwranking #defination .table td{
    padding-left: 0;
}
#dwranking #defination .table tr td:first-child{
    white-space: nowrap;
}
#dwranking #calltable, #dwranking #puttable{
    table-layout: fixed;
}
#dwranking table thead .th-nm{  
    width: 26%;
}
#dwranking table thead .th-id{  
    width: 20%;
}
#dwranking table thead .th-bd{  
    width: 18%;
}
#dwranking table thead .th-eg{  
    width: 18%;
    /* font-family: "MCQGlobal_A_Lt" !important; */
}
#dwranking table thead .th-ss{  
    width: 18%;
    /* font-family: "MCQGlobal_A_Lt" !important; */
}
#dwranking table thead .th-si{  
    width: 18%;
}
#dwranking table tbody tr td img{
    height: 20px;
    margin-left: 5px;
    width: auto;
}
#dwranking #dwtblgroup td.col_tkr a{
    font-size: 16px;
    font-family: "MCQGlobal_A_Lt" !important;
}

#dwranking .select_align_center {
    text-align: center;
}

#dwranking .select_align_center input {
    text-align: center;
    display: flex;
    min-width: 100% !important;
    width: 100% !important;
}
/*******************************************************************************
 dwranking section combobox
*******************************************************************************/
 .combobox {
    position: relative;
  }
  .combobox-toggle {
    position: absolute;
    display:block;
    top: 0;
    bottom: 0;
    right:0;
    width: 36px;
    padding: 0;
    cursor: pointer;
  }
  .combobox-toggle, .combobox-toggle:focus, .combobox-toggle:hover, .combobox-toggle:visited{
    color: #000;
  }
  .combobox-input {
    margin: 0;
    padding: 2.6px 10px;
    width:100%;
    text-align: center;
  }
  .combobox-toggle-icon{
      margin-top: 10px;
      margin-right: 12px;
  }
  .ui-widget{
      font-family: 'DBHeaventLight','DBHeavent';
      font-size: 21px;
  }
  .ui-menu .ui-menu-item{
      text-align: center;
  }
  .ui-menu .ui-menu-item a.ui-state-hover{
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5; 
      border-color:#f5f5f5;
  }
  .ui-menu .ui-menu-item a:visited,
  .ui-menu .ui-menu-item a:focus,
  .ui-menu .ui-menu-item a:hover{
      color: #262626
  }
  .ui-autocomplete.ui-menu{
      max-height: 300px;
      overflow: hidden;
      overflow-y: scroll;
  }







  

#dwranking .carousel-root.ad-section{
    margin-top: 20px;
    width: 100%;
    height: auto;
    padding: 0px;
}
#dwranking .ad-section .slider-wrapper {
    background: #000000;
}
#dwranking .ad-section .slider-wrapper li.slide iframe {
    width: 100%;
    margin: 0px;
    min-height: 190px;
}
#dwranking .ad-section .slider-wrapper li.slide a{
    height: 100%;
}
#dwranking .ad-section .carousel .carousel-status{
    display: none;
}
#dwranking .ad-section .carousel .control-dots .dot{
    width: 15px;
    height: 15px;
    box-shadow: none;
    background-color: #000;
    border: solid 1px #fff;
    opacity: 1;
    margin: 0px 5px;
}
#dwranking .ad-section .carousel .control-dots .dot.selected{
    background-color: #FECC0A;
    border-color: #FECC0A;
}



/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (min-width: 992px) and (max-width: 1199px) {
    #dwranking .container-fluid > .row > .col-md-8 > .section{
        padding: 40px;
    }
    #dwranking .container-fluid > .row > .col-md-4 > .section{
        padding: 20px;
    }
}
@media screen and (max-width: 991px){
    #dwranking #defination .panel-heading {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        padding: 2px 10px;
        background-color: #666666;
    }
    #dwranking #defination .panel-heading button {
        border: none;
        background-color: transparent;
        width: 100%;
        text-align: center;
        padding: 0px;
    }
    #dwranking #defination .panel-title {
        font-size: 21px;
        position: relative;
    }
    body.eng #dwranking #defination .panel-title{
        font-size: 14px;
    }
}
@media screen and (max-width: 768px){
    #dwranking .table{
        font-size: 17px;
    }
    body.eng #dwranking .table{
        font-size: 11px;
    }
    #dwranking #defination #collapse_dwcompare3{
        font-size: 17px;
    }
    #dwranking #defination p.small{
        font-size:17px;
    }
    body.eng #dwranking #defination p.small{
        font-size:11px;
    }
    body.eng #dwranking #dwtblgroup td.col_tkr a{
        font-size: 9px;
    }
    #dwranking #dwtblgroup td.col_tkr a{
        font-size: 9px;
    }
}
@media screen and (max-width: 767px){
    #dwranking .__react_component_tooltip p.tooltip-content{
        font-size: 17px;
    }
    body.eng #dwranking .warrant-search_dropdown .css-1s2u09g-control > div:first-child div,
    body.eng #dwranking .warrant-search_dropdown .css-1pahdxg-control > div:first-child div,
    body.eng #dwranking .warrant-search_dropdown .css-1pahdxg-control > div:first-child input, 
    body.eng #dwranking .warrant-search_dropdown .css-1s2u09g-control > div:first-child input{
        font-size: 14px;
    }
    body.eng #dwranking .__react_component_tooltip p.tooltip-content,
    body.eng #dwranking .__react_component_tooltip p.tooltip-content a{
        font-size: 11px;
    }
}
@media screen and (max-width: 340px){
    #dwranking .table{
        font-size: 15px;
    }
}