

#menucontent .subtopic:hover {
    color: #FFD843 !important; 
}

#menucontent .subtopic{
    display: block;
    color:rgb(51 51 51)
}
