#dailycommentary .section-p {
    margin-bottom: 30px;
    min-height: inherit;
}
#dailycommentary .newstbl-htxt {
    height: 30px;
    line-height: 30px;
    background-color: #CCC;
    padding-left: 10px;
}
#dailycommentary .newstbl-row {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #CCC;
}
#dailycommentary .newstbl-content {
    margin-bottom: 40px;
}
#dailycommentary .newstbl-content .slick-slide>div:last-child .newstbl-row {
    border-bottom: 1px solid #ccc;
}
#dailycommentary #newsswiper>.slick-slider>button {
    display: none !important;
}
#dailycommentary #newsswiper>.slick-slider ul.slick-dots {
    position: absolute;
    bottom: -80px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
#dailycommentary #newsswiper>.slick-slider ul.slick-dots li {
    width: 16px;
    height: 16px;
    overflow: hidden;
    margin: 0px 2px;
}
#dailycommentary #newsswiper>.slick-slider ul.slick-dots li button {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #ddd;
    padding: 0px;
}
#dailycommentary #newsswiper>.slick-slider ul.slick-dots li.slick-active button {
    background-color: #FECC0A;
    border-color: #FECC0A;
}
#dailycommentary #newsswiper>.slick-slider ul.slick-dots li button::before {
    display: none;
}
#dailycommentary .newstbl-content .slick-slide>div:last-child .newstbl-row {
    border-bottom: none;
}
#dailycommentary .newstbl-col-right {
    margin-left: 100px;
}
#dailycommentary .newstbl-col-left {
    position: absolute;
    top: 26px;
    left: 0;
    text-align: left;
}
#dailycommentary .newstbl-col-left span {
    font-family: "DBHeaventMedium";
    color: #0675C2;
}
body.eng #dailycommentary .newstbl-col-left span{
    font-family: "MCQGlobal_A_Md";
}
#dailycommentary .newstbl-col-dt {
    font-family: "DBHeaventMedium";
    margin: 0;
    font-size: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 6px;
}
body.eng #dailycommentary .newstbl-col-dt{
    font-family: "MCQGlobal_A_Md";
    font-size: 16px;
}
#dailycommentary .newstbl-col-dd {
    margin-bottom: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
#dailycommentary .newstbl-col-rm {
    text-align: right;
}
#dailycommentary .newstbl-col-rm .btn {
    min-width: 150px;
    border: none;
    line-height: 1;
}
body.eng #dailycommentary .newstbl-col-rm .btn{
    line-height: 1.42857143;
}
#dailycommentary .react-date-picker__calendar {
    width: 280px !important;
    position: absolute;
    top: -280px !important;
    left: -280px !important;
    z-index: 1000;
}
#dailycommentary .react-calendar__navigation button{
    min-width: 35px;
}
#dailycommentary .newstbl-bttm {
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: 32px;
    margin-bottom: 20px;
}
#dailycommentary .newstbl-cal-icon.input-group {
    position: absolute;
    top: 0px;
    right: 0px;
    display: inline-block;
    border-collapse: inherit;
}
#dailycommentary #datepicker img.cal-icon {
    width: 30px;
}
#dailycommentary .newstbl .input-group-addon {
    text-align: right;
    padding: 0;
    color: inherit;
    background-color: inherit;
    border: 0;
    border-radius: inherit;
    display: inline-block;
}
#dailycommentary .carousel-root.ad-section {
    margin-top: 20px;
    padding: 0px;
    width: 100%;
    height: auto;
}
#dailycommentary .input-group-addon label {
    position: relative;
    cursor: pointer;
}
#dailycommentary .input-group-addon label .react-date-picker {
    position: absolute;
    top: 0;
    width: 30px;
    height: 100%;
    border: none;
}
#dailycommentary .input-group-addon label .react-date-picker .react-date-picker__wrapper {
    border: none;
}
#dailycommentary .input-group-addon label .react-date-picker .react-date-picker__inputGroup,
#dailycommentary .input-group-addon label .react-date-picker .react-date-picker__clear-button {
    display: none;
}
#dailycommentary .input-group-addon label .react-date-picker .react-date-picker__calendar-button {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10000;
}
#dailycommentary .input-group-addon label .react-date-picker .react-date-picker__calendar-button svg {
    opacity: 0;
}
#dailycommentary .input-group-addon label .react-datepicker-wrapper {
    position: absolute;
    width: auto;
    opacity: 0;
    right: 0px;
    visibility: hidden;
    max-width: 40px;
    height: 100%;
}
#dailycommentary .newstbl .swiper-container {
    width: 100%;
    min-height: 300px;
}
#dailycommentary .newstbl .swiper-pagination {
    position: inherit;
}
.video {
    margin-top: 12px;
}
.video,
iframe {
    width: 100%;
}
#dailycommentary .carousel-root.ad-section {
    margin-top: 20px;
    width: 100%;
    padding: 0px;
}
#dailycommentary .ad-section .slider-wrapper {
    background: #000000;
}
#dailycommentary .ad-section .slider-wrapper li.slide iframe {
    width: 100%;
    margin: 0px;
    min-height: 190px;
}
#dailycommentary .ad-section .slider-wrapper li.slide a {
    height: 100%;
}
#dailycommentary .ad-section .carousel .carousel-status {
    display: none;
}
#dailycommentary .ad-section .carousel .control-dots .dot {
    width: 15px;
    height: 15px;
    box-shadow: none;
    background-color: #000;
    border: solid 1px #fff;
    opacity: 1;
    margin: 0px 5px;
}
#dailycommentary .ad-section .carousel .control-dots .dot.selected {
    background-color: #FECC0A;
    border-color: #FECC0A;
}

/* ============= RESPONSIVE START =========== */
@media screen and (min-width: 992px) and (max-width: 1199px) {
    #dailycommentary .container-fluid > .row > .col-md-8 > .section{
        padding: 40px;
    }
    #dailycommentary .container-fluid > .row > .col-md-4 > .section{
        padding: 20px;
    }
}

@media screen and (max-width:991px) {
 
}

@media screen and (max-width:767px) {
    #dailycommentary .newstbl-col-dd {
        max-height: 68px;
    }
    #dailycommentary .newstbl-col-dt{
        padding-top: 4px;
    }
    body.eng #dailycommentary .newstbl-col-dt{
        padding-top: 8px;
    }
}


 